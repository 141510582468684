import React,{useState,useEffect,useContext} from 'react';
import LoraOrgApiKeyTable from '../../../components/LoraNetworks/LoraOrgApiKeyTable/LoraOrgApiKeyTable';
import CreateOrgApiKey from '../../../components/LoraNetworks/CreateOrgApiKey/CreateOrgApiKey';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';

function LoraOrgApiKeys() {
    const [orgApiKeys, setOrgApiKeys] = useState([]);
    const axiosInstance = useAxios();
    const {state , actions} = useContext(Context);
    
    useEffect(() => {
		fetchOrgKeyList();
    }, [axiosInstance]);
    
    //Code to fetch Organization Api Keysand store in state
    function fetchOrgKeyList(){
        let orgid = localStorage.getItem('orgid');
        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_LORA_URL}/internal/api-keys?limit=100&organizationID=${orgid}`)
				.then((response) => {
					// console.log(response);
					setOrgApiKeys(response.data.result);

					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
					// console.log(err);
					// setOrgApiKeys([
                    //     {'id' : 'da160a31-88e3-49df-93fb-cf4f5e381e9b', "name" : 'new key'},
                    //     {'id' : '0e64fd42-957b-469d-94cf-025817b7b9ae', "name" : 'new key2'}
                    // ])
					actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
    }

    // const sampleList = sampleProjectsList;
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchOrgKeyList();
			}, 2000);

		};
	};

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Organization API keys</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        {orgApiKeys.length > 0 ? (
                            <>
                                <div className="project-table-group">
                                    <LoraOrgApiKeyTable data={orgApiKeys} status={handleSubmit} />
                                </div>
                                <br />
                                <div className="col-md-12 text-right no-padding" >
                                    {/* <CreateOrgApiKey status={handleSubmit} /> */}
                                </div>
                            </>
                        ) : (
                                <div className="default-content-center" style={{ margin: '3%', textAlign: 'center' }}>
                                    You don't have any Org. API Keys right now
                                    <br />
                                    <br />
                                    <div>
                                      {/*  <CreateOrgApiKey status={handleSubmit} />  */}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom}/>
        </>
    )
}

export default LoraOrgApiKeys
