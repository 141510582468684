import {useEffect,useState} from 'react';
import { ToastContainer, Zoom,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PopUp = (msg) => {
    
        toast.success(msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            className: "successToast",
            hideProgressBar: true,
        })
    
}

export default PopUp