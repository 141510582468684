import React, { useState, useEffect, useContext, useCallback } from 'react'
import Layout from '../../components/Layout/Layout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../utils/Axios';
import Context from '../../store/Context';
import ErrorPopUp from '../../components/UIElements/ErrorPopup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Paginate from '../../components/Common/Pagination.css';
import { ToastContainer, Zoom } from 'react-toastify';
import ReactPaginate from "react-paginate";
import Table from 'react-bootstrap/Table';
import './UmbExperimentHistory.css';

ChartJS.register(ArcElement, Tooltip, Legend);

function UmbExperimentHistory() {
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [nodeType, setNodeType] = useState('ALL');
    const [tsipExperimentsCount, setTsipExperimentsCount] = useState(0);
    const [brilExperimentsCount, setBrilExperimentsCount] = useState(0);
    const [unlabelledExperimentsCount, setUnlabelledExperimentsCount] = useState(0);
    const [experimentsDeployed, setExperimentsDeployed] = useState(0);
    const [experimentsNotDeployed, setexperimentsNotDeployed] = useState(0);
    const [startDateFormatted, setStartDateFormatted] = useState('');
    const [endDateFormatted, setEndDateFormatted] = useState('');
    const [currentPage, setCurrentPage] = useState(0);


    const [data, setData] = useState([]);
    
    const totalExperiments = {
        labels: ['TSIP', 'BRIL', 'Unlabelled'],
        datasets: [
          {
            label: 'Number of experiments',
            backgroundColor: [
              '#2FDE00',
              '#C9DE00',
              '#B21F00'
            ],
            hoverBackgroundColor: [
            '#501800',
            '#4B5000',
            '#175000'
            ],
            data: [tsipExperimentsCount, brilExperimentsCount, unlabelledExperimentsCount]
          }
        ]
      }

      const deploymentStatusExperiments = {
        labels: ['Experiments Deployed', 'Experiments Not Deployed'],
        datasets: [
          {
            label: 'Deployment status experiments',
            backgroundColor: [
              '#2FDE00',
              '#B21F00'
            ],
            hoverBackgroundColor: [
            '#501800',
            '#4B5000'
            ],
            data: [experimentsDeployed, experimentsNotDeployed]
          }
        ]
      }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    useEffect(() => {
        fetchExperimentHistory();
    }, [axiosInstance]);

    const fetchExperimentHistory = () => {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_EXPERIMENT_URL}/experimentHistory`, {
                    startTime: startDateFormatted,
                    endTime: endDateFormatted,
                    nodeType: nodeType
                })
                .then((response) => {
                    setData(response.data.experimentHistoryData);
                    setTsipExperimentsCount(response.data.tsipExperimentsCount);
                    setBrilExperimentsCount(response.data.brilExperimentsCount);
                    setUnlabelledExperimentsCount(response.data.unlabelledExperimentsCount);

                    setExperimentsDeployed(response.data.experimentsDeployed);
                    setexperimentsNotDeployed(response.data.experimentsNotDeployed);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })                    
                })
                .catch((err) => {
                    ErrorPopUp("Fetch Experiment History Failed "+ err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    };

    const searchExperimentHistory = () => {
        
        if(startDate == undefined || startDate == null || startDate == ''){
            ErrorPopUp("Start Date cannot be empty");
        }else if(endDate == undefined || endDate == null || endDate == ''){
            ErrorPopUp("End Date cannot be empty");
        }else{
            const startDateFormatted = startDate.format("DD/MM/YYYY") + " 00:00:00";
            const endDateFormatted = endDate.format("DD/MM/YYYY") + " 23:59:59";
    
            setStartDateFormatted(startDateFormatted);
            setEndDateFormatted(endDateFormatted);
            setCurrentPage(0);

            if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_EXPERIMENT_URL}/experimentHistory`, {
                    startTime: startDateFormatted,
                    endTime: endDateFormatted,
                    nodeType: nodeType
                })
                .then((response) => {
                    setData(response.data.experimentHistoryData);
                    setTsipExperimentsCount(response.data.tsipExperimentsCount);
                    setBrilExperimentsCount(response.data.brilExperimentsCount);
                    setUnlabelledExperimentsCount(response.data.unlabelledExperimentsCount);
                    setExperimentsDeployed(response.data.experimentsDeployed);
                    setexperimentsNotDeployed(response.data.experimentsNotDeployed);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })                    
                })
                .catch((err) => {
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
        }  
    }

    function downloadExperimentHistory(n) {	 
		if (axiosInstance) {		
			axiosInstance
				.post(`${process.env.REACT_APP_EXPERIMENT_URL}/experimentHistory?download=true`, {
                        startTime: startDateFormatted != null && startDateFormatted != '' ? startDateFormatted : '',
                        endTime: endDateFormatted != null && endDateFormatted != '' ? endDateFormatted : '',
                        nodeType: nodeType != null && nodeType != '' ? nodeType : ''
                    }, { responseType: 'arraybuffer' })
				.then((response) => {
					const a = document.createElement("a");
					a.href = URL.createObjectURL(
						new Blob([response.data], { type: 'application/octet-stream' })
					);
					a.setAttribute("download", `ExperimentHistory.xlsx`);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					
				})
				.catch((err) => {
					const parsedJson = JSON.parse(Buffer.from(err.response.data).toString('utf8'));
					if(parsedJson.detail !== undefined || parsedJson.detail !== null){
						ErrorPopUp(parsedJson.detail);
					}else{
						ErrorPopUp("Download Failed. Try Again");
					}
				});
		}
	}

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        width: 600, 
        height: 600
    };

    const recordsPerPage = 10;
    const pageCount = Math.ceil(data.length / recordsPerPage);
    const pageRangeDisplayed = 5;

    const onPageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const start = currentPage * recordsPerPage;
    const end = start + recordsPerPage;
    const currentData = data.slice(start, end);

    return (
        <>
        <Layout>
            <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '2rem 2.5rem' }}>
                <div className="bg-white jumbotron" style={{ marginRight: '2rem' }}>
                <h5>Umbrella Experiment History</h5>
                <br/>
                    <Form>
                    <div className="table-primary jumbotron" style={{ marginRight: '5rem' }}>
                        <Form.Row className='form-inline text-left'>
                            <Form.Group>
                                <div className="col-md-4">
                                    <Form.Label className="font-weight-bold">Start Date</Form.Label>
                                </div>
                                <div className="col-md-4">                                                                        
                                    <DatePicker
                                        className="form-control"
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        placeholderText="Start Date"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />          
                                </div>
                            </Form.Group>
                            <Form.Group> 
                                <div className="col-md-4">
                                    <Form.Label className="font-weight-bold">End Date</Form.Label>
                                </div>                               
                                <div className="col-md-4">     
                                    <DatePicker
                                        className="form-control"
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        placeholderText="End Date"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </Form.Group>                            
                            <Form.Group>
                                <div className="col-md-6">
                                    <Form.Label className="font-weight-bold">Node Type</Form.Label>
                                </div>
                                <div className="col-md-4">
                                    <Form.Control as="select" name="nodeType" onChange={(e) => setNodeType(e.target.value)}>
                                        <option value='ALL'>ALL</option>
                                        <option value='RSS'>RSS</option>
                                        <option value='RSE'>RSE</option>
                                    </Form.Control>
                                </div>
                            </Form.Group>  
                            <Form.Group>                         
                                <div className="col-md-4">                                
                                    <Button className="btnDefault" onClick={() => searchExperimentHistory()} style={{display: 'block'}}>                                
                                        Search
                                    </Button>
                                </div>
                            </Form.Group>
                        </Form.Row>
                    </div>
                    <Form.Row>
                        <div className="col-md-4">
                            <h6>Total number of experiments: {data.length} </h6>
                        </div>
                    </Form.Row>
                    <br/>
                    {/* Summary Chart Data */}
                    <Form.Row>
                        <div className="col-md-6">
                            <Pie
                                data={totalExperiments}
                                options={options}
                            />                                              
                        </div>
                        <div className="col-md-6">
                            <Pie
                                data={deploymentStatusExperiments}
                                options={options}
                            />  
                        </div>
                    </Form.Row>
                    <br/>
                    {/* Pagination  */}  
                        <div lassName="col-md-6">
                            <Form.Row>
                                <Table responsive borderless className="table-striped">
                                    <thead>
                                        <tr className="table-primary">
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Creater</th>
                                            <th>CreationTime</th>
                                            <th>StartTime</th>
                                            <th>EndTime</th>
                                            <th>Duration</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentData.map((item, index) => (
                                            <tr key={index} >
                                                <td>
                                                    {
                                                        item.id
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.name
                                                    }

                                                </td>
                                                <td>
                                                    {
                                                        item.creater
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.creationTime
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.startTime
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.endTime
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.duration
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.status
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>                       
                            </Form.Row>
                            <ReactPaginate
                                initialPage={0}
                                className= 'pagination'
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                pageCount={pageCount}
                                onPageChange={onPageChange}
                                containerClassName={"pagination"}
                                previousClassName={"previous"}
                                nextClassName={"next"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                pageRangeDisplayed={pageRangeDisplayed}
                                marginPagesDisplayed={1}
                            />
                            </div>
                        <br/>
                        <Form.Row>                        
                        <div className="col-md-4">
                        <Form.Group>
                            <Button className="btnDefault" onClick={e => downloadExperimentHistory(e.target)} style={{display: 'block'}}>                                
                                Download Data
                            </Button>
                        </Form.Group>
                        </div>
                        </Form.Row>
                    </Form>
                </div>
            </div>
        </Layout>
        <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default UmbExperimentHistory;
