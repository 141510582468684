import React,{useState,useEffect,useContext} from 'react';
import ServiceProfilesTable from '../../../components/LoraNetworks/ServiceProfilesTable/ServiceProfilesTable';
import CreateServiceProfile from '../../../components/LoraNetworks/CreateServiceProfile/CreateServiceProfile';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';


function ServiceProfiles() {
    const [serviceProfiles, setServiceProfiles] = useState([]);
    const [nwServers, setNwServers] = useState([]);
    const [optionsEnabled, setOptionsEnabled] = useState({
		createSP : false,
        deleteSP : false
	})
    const axiosInstance = useAxios();
    const {state , actions} = useContext(Context);

    useEffect(() => {
        checkCurrentUser();
    }, [axiosInstance]);
    
    useEffect(() => {
        fetchServiceProfilesList();
    }, [axiosInstance]);

    //code to check current user role
    function checkCurrentUser(){
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ["ln_user"]

		// console.log(roles.includes('ln_admin'));
		if (roles.includes('ln_admin')) {
			setOptionsEnabled(
				prevState => ({
					...prevState,
                    createSP : true,
                    deleteSP : true
				})
			)
		}
		if (roles.includes('ln_orgadmin')) {
			setOptionsEnabled(
				prevState => ({
					...prevState,
                    createSP : true,
                    deleteSP : true
				})
			)
		}
		if (roles.includes('ln_user')) {
			setOptionsEnabled(
				prevState => ({
					...prevState,
                    createSP : false,
                    deleteSP : false
				})
			)
		}
		if (roles.includes('ln_deviceadmin')) {
			setOptionsEnabled(
				prevState => ({
					...prevState,
					createSP : false,
                    deleteSP : false
				})
			)
		}

    }

    //Code to fetch Organization Service Profiles store in state
    function fetchServiceProfilesList(){
        let reqid = localStorage.getItem('orgid');
        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/service-profile?limit=100&organizationID=${reqid}`)
				.then((response) => {
					// console.log(response);
					setServiceProfiles(response.data.result)
                    actions({type : 'setState', payload : {...state , blocking : false}})
                    fetchNwServers();
				})
				.catch((err) => {
                    // console.log(err);
                    // setServiceProfiles([{"id":"9a0149e3-e6d7-4023-ab23-3795ff80eeda","name":"service-test","organizationID":"1","networkServerID":"4","createdAt":"2020-12-17T06:32:11.522084Z","updatedAt":"2020-12-17T06:32:11.522084Z","networkServerName":"network-server-test"},{"id":"18b7bf42-3f31-4ea3-ae75-f482a65236de","name":"test-service","organizationID":"1","networkServerID":"4","createdAt":"2020-12-29T15:47:43.913150Z","updatedAt":"2020-12-29T15:47:43.913150Z","networkServerName":"network-server-test"}]);
                    actions({type : 'setState', payload : {...state , blocking : false}})
                    fetchNwServers();
				});

		}
    }

    //Code to fetch Network-servers store in state
    function fetchNwServers(){
        // let orgid = localStorage.getItem('orgid')
        if (axiosInstance) {
            actions({type : 'setState', payload : {...state , blocking : true}})
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/network-servers?limit=999`)
                .then((response) => {
                    // console.log(response);
                    setNwServers(response.data.result);

                    actions({type : 'setState', payload : {...state , blocking : false}})
                })
                .catch((err) => {
                    // console.log(err);
                    actions({type : 'setState', payload : {...state , blocking : false}})
                });

        }
    }
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchServiceProfilesList();
			}, 2000);

		};
	};
    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Service-profiles</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        {serviceProfiles.length > 0 ? (
                            <>
                                <div className="project-table-group">
                                    <ServiceProfilesTable data={serviceProfiles} status={handleSubmit} deletebtn={optionsEnabled.deleteSP}/>
                                </div>
                                <br />
                                <div className="col-md-12 text-right no-padding" >
                                    {optionsEnabled.createSP == true ? (
                                        <>
                                            <CreateServiceProfile status={handleSubmit} networks={nwServers}/>
                                        </>
                                    ) : null}
                                </div>
                            </>
                        ) : (
                                <div className="default-content-center" style={{ margin: '3%', textAlign: 'center' }}>
                                    You don't have any Service Profiles right now
                                    <br />
                                    <br />
                                    <div>
                                        {optionsEnabled.createSP == true ? (
                                            <>
                                            <CreateServiceProfile status={handleSubmit} networks={nwServers}/>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom}/>
        </>
    )
}

export default ServiceProfiles;
