import React, {useState, useEffect, useContext} from 'react'
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import swal from 'sweetalert';
import Table from 'react-bootstrap/Table';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
// import PopUp from '../../../components/UIElements/PopUp';
import ErrorPopup from '../../../components/UIElements/ErrorPopup';
import Context from '../../../store/Context';

function ManageUsersRn() {
    const [allUsers , setAllUsers] = useState([]);
    const [userInfo , setUserInfo] = useState({})
    const [reqstatus , setReqStatus] = useState({
        userDeregisterInfo : ''
    })
    const axiosInstance = useAxios();
    const {state , actions} = useContext(Context);

    useEffect(() => {
		getUsersList();
    }, [axiosInstance]);

     //Color Customization for users dropdown
     const colourStyles = {

        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            "&:hover": {
                // Overwrittes the different states of border
                background: state.isSelected ? "black" : "black"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'black' : 'black',
            background: 'white'
        }),
    };


    //Code to fetch node networks
	function getUsersList() {
		if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/users/project-config`)
				.then((response) => {
					// console.log(response);
					addLabelToUsers(response.data)
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
                    // console.log(err);
                    actions({type : 'setState', payload : {...state , blocking : false}})
                    ErrorPopup("Userlist Not Found")
				});

		}
    }

    //code to add labels for js objects for dropdown
    function addLabelToUsers(data) {
        let oldArray = data;

        oldArray.map((item, index) => {
            let obj = item
            Object.assign(obj, {label : item.username})
        })

        setAllUsers(oldArray)
    }

    //Code to select user from list 
    function selectUserFromList(e){
        setUserInfo(e);
    }

    //Code to send user deregistration request
    function deRegisterUser(e){
        e.preventDefault();

        // let data = {
        //     "userId" : userInfo.userId
        // }

        if(Object.keys(userInfo).length == 0){
            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "Select User" });
            const timer = setTimeout(() => {
                setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }

        swal({
			title: 'Are you sure?',
			text: 'User will be deregistered',
			icon: 'warning',
			buttons: true,
            dangerMode: true,
            className: "dangerSwal"
		})
        .then((willDelete) => {
            if (willDelete) {
                if (axiosInstance) {
                    actions({type : 'setState', payload : {...state , blocking : true}})
                    axiosInstance
                    .post(`${process.env.REACT_APP_PROJECT_URL}/users/deregistration/${userInfo.userId}`)
                    .then((response) => {
                        if (response.response) {
                            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: response.response.data.detail });
                        }else {
                            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "User Deregistered Successfully" });
                        }
                        actions({type : 'setState', payload : {...state , blocking : false}})
                        
        
                        const timer = setTimeout(() => {
                            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "" });
                        }, 3000);
                        return () => clearTimeout(timer)
        
                    })
                    .catch((err) => {
                        actions({type : 'setState', payload : {...state , blocking : false}})
                        if (err.response) {
                            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: err.response.data.detail });
                        }else {
                            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "Deregisteration Failed." }); 
                        }
                        
                        const timer = setTimeout(() => {
                            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "" });
                        }, 3000);
                        return () => clearTimeout(timer)
                    });
                }
            }
        });
        
    }

    return (
        <>
       <RoboticsLayout>
            <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '2rem 2.5rem 1rem 2.5rem' }}>
                <div className="bg-white jumbotron" style={{ marginRight: '5rem' }}>
                <h5>User Deregistration<span style={{color: 'red'}}> {reqstatus.userDeregisterInfo}</span></h5>
                    <Form onSubmit={(e) => deRegisterUser(e)}>
                        <Form.Row>
                        <div className="col-md-4">
                            <Form.Group controlId="formUsersList">
                                    <Form.Label>Users List</Form.Label>
                                    {allUsers.length > 0 ?
                                        <Select
                                            label="Single select"
                                            options={allUsers}
                                            onChange={(opt) => selectUserFromList(opt)}
                                            styles={colourStyles}
                                        />
                                        :

                                        <Select
                                            label="Single select"
                                            onChange={(opt) => selectUserFromList(opt)}
                                            styles={colourStyles}
                                            required
                                        />
                                    }
                                    
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                        <Form.Group>
                            <Form.Label style={{visibility : 'hidden'}}>Deregister</Form.Label>
                                <Button className="btnDefault" type="submit" style={{display: 'block'}}>
                                    Deregister
                                </Button>
                            </Form.Group>
                        </div>
                        </Form.Row>
                    </Form>
                </div>
            </div>
        </RoboticsLayout>
        <ToastContainer draggable={false} transition={Zoom}/>
    </>
    )
}

export default ManageUsersRn;
