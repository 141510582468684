import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import NewHeader from '../../components/Header/NewHeader';

function UcareDashboard() {
    const [urlconvert, setUrlConvert] = useState('');
    const [showIframe, setShowIframe] = useState(false);
    const [finalString, setFinalString] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        convertUrl();
    }, [])

    useEffect(() => {
        console.log(windowDimensions)
    }, [windowDimensions])


    //code to append url at first time
    const convertUrl = () => {
        setShowIframe(true)
        let usertoken = localStorage.getItem('usertoken');
        let finalUrl = `${process.env.REACT_APP_UCARE_DASHBOARD}&token=${usertoken}`
        setTimeout(() => {
            setWindowDimensions(getWindowDimensions())
            setUrlConvert(finalUrl);
        }, 0);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    function changeURL(name) {
        setShowIframe(false)
        let usertoken = localStorage.getItem('usertoken');
        let finalUrl;
        switch (name) {
            case 'SensorData':
                finalUrl = `${process.env.REACT_APP_UCARE_DASHBOARD}&token=${usertoken}`
                break;
            case 'Awaretag3DModel':
                finalUrl = `${process.env.REACT_APP_AWARETAG_3D_MODEL}`
                break;    
            case 'E2SEnergyData':
                finalUrl = `${process.env.REACT_APP_E2S_ENERGY_DASHBOARD}`  
                break;    
            case 'E2SThermalData':
                finalUrl = `${process.env.REACT_APP_E2S_THERMAL_DASHBOARD}`  
                break;    
            default:
                finalUrl = '';
        }

        setTimeout(() => {
            setUrlConvert(finalUrl);
        }, 0);

        setShowIframe(true);
    }

    return (
        <>
            <div className="container-fluid">
                <NewHeader />
                <div className="row">   
                    <div className="col-md-12 col-sm-12 col-lg-12">                        
                        <div>
                            <div className="sensorUrls">
                                <Button className="btnDefault" onClick={() => changeURL('SensorData')}>
                                    Sensor Data
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('Awaretag3DModel')}>
                                    Awaretag 3D Model
                                </Button>   
                                <Button className="btnDefault" onClick={() => changeURL('E2SEnergyData')}>
                                    E2S Energy Data
                                </Button>  
                                <Button className="btnDefault" onClick={() => changeURL('E2SThermalData')}>
                                    E2S Thermal Data
                                </Button>                             
                            </div>
                        </div>
                    </div>                
                    {showIframe == true ? (
                        <>
                            <div className="col-md-12">                       
                                    <iframe id="grafanaChart" src={urlconvert} width={`${windowDimensions.width - 30}px`} 
                                                    height={`${windowDimensions.height - 150}px`} frameBorder="0"></iframe>
                            </div>
                        </>
                    ) : null}
                </div>                
            </div>
        </>
    )
}

export default UcareDashboard
