import React, { useState, useContext, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import PopUp from '../../../components/UIElements/PopUp';
import ErrorPopUp from '../../../components/UIElements/ErrorPopup';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';
import { ToastContainer, Zoom } from 'react-toastify';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout'
function LoraAdmin() {
    const [usersRequests, setUsersRequests] = useState([]);
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    useEffect(() => {
        fetchUserRequests();
    }, [axiosInstance]);

    //Code to fetch User Requests
    function fetchUserRequests() {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/organizations/pending`)
                .then((response) => {
                    // console.log(response);
                    setUsersRequests(response.data);
                    // fetchNodeNetworks()

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

       //Code to approve New Request
       const approveNewRequest = (idx) => (e) => {
        let obj = usersRequests[idx];
        // let orgid = obj.loraOrgId;
        let uuid = obj.id;
        let data = {
            "name" : obj.name,
            "status" : 'approved'
        }

        // console.log(obj);

        approveDenyCall(uuid,data)
    };

    //Code to approve New Request
    const denyNewRequest = (idx) => (e) => {
        let obj = usersRequests[idx];
        // let orgid = obj.loraOrgId;
        let uuid = obj.id;
        let data = {
            "name" : obj.name,
            "status" : 'denied'
        }

        // console.log(obj);

        approveDenyCall(uuid,data)
    };

        //Code to make api call for approve / deny new user
        function approveDenyCall(uuid,data){
            // console.log('UUID', uuid);
            // console.log('Input Data', data);
            if (axiosInstance) {
                actions({type : 'setState', payload : {...state , blocking : true}})
                axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/users/organizations/${uuid}`, data)
                .then((response) => {
                    actions({type : 'setState', payload : {...state , blocking : false}});
                    if(data.status == 'approved'){
                        PopUp("Approved Successfully");
                    }else if(data.status == 'denied'){
                        PopUp("Denied Successfully");
                    }else{
                        PopUp("Unknown");
                    }
                    fetchUserRequests();
                })
                .catch((err) => {
                    actions({type : 'setState', payload : {...state , blocking : false}});
                    ErrorPopUp("Request Failed. Try Again")
                });
            }
        }

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>User Requests</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        {usersRequests.length > 0 ? (
                            <>
                                <div className="project-table-group">
                                    <Table responsive borderless className="projectTableData">
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                {/* <th>Request Type</th> */}
                                                <th>Organization</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {usersRequests.map((item, index) => (
                                                <tr>
                                                    <td>{item.orgAdmin}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-sm btnDefault btnSpace" onClick={approveNewRequest(index)}>
                                                            Approve
                                                        </button>
                                                        <button type="button" className="btn btn-sm btnNormal" onClick={denyNewRequest(index)}>
                                                            Deny
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        ) : (
                                <>
                                    <div className="project-table-group">
                                        <Table responsive borderless className="projectTableData">
                                            <thead>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Request Type</th>
                                                    <th>Organization</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td rowSpan="4">No Users Requests found</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            )}
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default LoraAdmin;
