import React, { useState, useContext } from 'react';
import './RoboticsLayout.css';
import Context from '../../../store/Context'

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader, Types } from 'react-loaders';

import 'loaders.css/loaders.min.css';
import RoboticsNewSideBar from '../RoboticsSidebar/RoboticsNewSideBar';

function Layout({ children }, props) {

	const [apiLoader, setApiLoader] = useState({ loaderType: 'ball-triangle-path' })

	const { state, actions } = useContext(Context);

	return (
		<BlockUi tag="div" message="Loading" blocking={state.blocking}>
			<RoboticsNewSideBar children={children} />
		</BlockUi>
	)
}

export default Layout; 