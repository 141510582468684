import React from 'react'

const CrateExpNodeChecks = props => {
    return (
        <>
            <div className="createExpNodeCheckItem">
                <input type="checkbox" value={props.name} checked={props.isChecked} onChange={props.event} indexid={props.id} />
                <label>{props.name}</label>
            </div>
        </>
    )
}

export default CrateExpNodeChecks;
