import React, { useState, useEffect, useContext } from 'react';
import Details from './Details';
import EditDevice from './EditDevice';
import KeysOTAA from './KeysOTAA';
import Fuota from './Fuota/Fuota';
import LoraLayout from '../../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../../utils/Axios';
import Context from '../../../../store/Context';
import DeviceFrames from './DeviceFrames';
import DeviceDataDashboard from './DeviceDataDashboard';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

function DeviceInfo(props) {
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [supportsJoin, setSupportsJoin] = useState(false);
    const [optionsEnabled, setOptionsEnabled] = useState({
        configurationEnabled: false,
        keysEnabled: false,
        firmwareEnabled: false,
    });

    const [value, setValue] = React.useState('Details');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    // useEffect(()=>{
    //     console.log(props);
    // },[props])

    useEffect(() => {
        // fetchDevice();
        let check = checkKeysOTAASupport()
        check.then((data) => {
            setSupportsJoin(data)
        }).then(() => {
            actions({ type: 'setState', payload: { ...state, keysOTAA: true } })
        })
    }, [props])

    useEffect(() => {
        checkCurrentUser()
    }, [axiosInstance]);

    //code to check current user role
    function checkCurrentUser() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ["ln_admin"]

        // console.log(roles.includes('ln_admin'));
        if (roles.includes('ln_admin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    configurationEnabled: false,
                    keysEnabled: false,
                    firmwareEnabled: false,
                })
            )
        }
        if (roles.includes('ln_orgadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    configurationEnabled: false,
                    keysEnabled: true,
                    firmwareEnabled: false,
                })
            )
        }
        if (roles.includes('ln_user')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    configurationEnabled: true,
                    keysEnabled: true,
                    firmwareEnabled: true,
                })
            )
        }
        if (roles.includes('ln_deviceadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    configurationEnabled: false,
                    keysEnabled: false,
                    firmwareEnabled: false,
                })
            )
        }

    }

    //code to check keys OTAA support to set the state
    async function checkKeysOTAASupport() {
        // console.log(state.keysOTAA);
        if (state.keysOTAA == true) {
            // setSupportsJoin(true)
            return true
        }

        if (state.keysOTAA == false) {
            // setSupportsJoin(false)
            return false
        }
    }

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Device Info</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Details" value="Details" />
                                        <Tab label="Configuration" value="Configuration" />
                                        <Tab label="Keys (OTAA)" value="keysotaa" />
                                        <Tab label="Lorawan Frames" value="LorawanFrames" />
                                        <Tab label="Device Data Dashboard" value="DeviceDataDashboard" />
                                    </TabList>
                                </Box>
                                <TabPanel value="Details"><Details {...props} /></TabPanel>
                                <TabPanel value="Configuration" disabled={optionsEnabled.configurationEnabled == true ? true : false}><EditDevice {...props} /></TabPanel>
                                <TabPanel value="keysotaa" disabled={optionsEnabled.keysEnabled || supportsJoin == true ? false : true}><KeysOTAA {...props} /></TabPanel>
                                <TabPanel value="LorawanFrames"><DeviceFrames {...props} /></TabPanel>
                                <TabPanel value="DeviceDataDashboard"><DeviceDataDashboard {...props} /></TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default DeviceInfo
