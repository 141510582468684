import React, { useEffect, useState } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import NewHeader from '../../components/Header/NewHeader';
import { Button } from 'react-bootstrap';

function SensorDashboard() {
    const [urlconvert, setUrlConvert] = useState('');
    const [showIframe, setShowIframe] = useState(false);
    const [finalString, setFinalString] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    useEffect(() => {
        // getLocalNodes();
        convertUrl();
    }, [])

    useEffect(() => {
        // getLocalNodes();
        console.log(windowDimensions)
    }, [windowDimensions])

    //code to get nodes from local storage
    const getLocalNodes = () => {
        let nodes = JSON.parse(localStorage.getItem('sensornodes'));
        if (nodes !== null) {
            let str = '';
            for (let i = 0; i < nodes.length; i++) {
                str += `&var-Node=${nodes[i].friendlyName}`
            }
            setFinalString(str);
            convertUrl(str)
        }
    }

    //code to append url at first time
    const convertUrl = () => {
        setShowIframe(true)
        let usertoken = localStorage.getItem('usertoken');
        let finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_BME680}&token=${usertoken}`
        setTimeout(() => {
            setWindowDimensions(getWindowDimensions())
            setUrlConvert(finalUrl);
        }, 0);
    }

    //code to change url based on button click and storing in state
    function changeURL(name) {
        setShowIframe(false)
        let usertoken = localStorage.getItem('usertoken');
        let finalUrl;
        // console.log(name);
        switch (name) {
            case 'BME680':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_BME680}&token=${usertoken}`
                break;
            case 'IMU Conv':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_IMU_Conv}&token=${usertoken}`
                break;
            case 'IMU Res':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_IMU_Res}&token=${usertoken}`
                break;
            case 'MP503':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_MP503}&token=${usertoken}`
                break;
            case 'MiCS6814':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_MiCS6814}&token=${usertoken}`
                break;
            case 'Noise':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_Noise}&token=${usertoken}`
                break;
            case 'Dust':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_Dust}&token=${usertoken}`
                break;
            case 'PMS5003':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_PMS5003}&token=${usertoken}`
                break;
            case 'OX-B431':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_OX_B431}&token=${usertoken}`
                break;
            case 'NO2-B43F':
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_NO2_B43F}&token=${usertoken}`
                break;
			case 'GRAPHENE': 
                finalUrl = `${process.env.REACT_APP_SENSOR_DASHBOARD_GRAPHENE}&token=${usertoken}`
				break;
            default:
                finalUrl = '';
        }

        setTimeout(() => {
            setUrlConvert(finalUrl);
        }, 0);

        setShowIframe(true);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    return (
        <>
            <div className="container-fluid">
                <NewHeader />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div>
                            <div className="sensorUrls">
                                <Button className="btnDefault" onClick={() => changeURL('BME680')}>
                                    Ambient Conditions
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('IMU Res')}>
                                    Accelerometer
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('MP503')}>
                                    VOC
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('MiCS6814')}>
                                    CO, NO2, NH3
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('Noise')}>
                                    Noise Level
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('Dust')}>
                                    Particulates (Nova)
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('PMS5003')}>
                                    Particulates (Plantower)
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('OX-B431')}>
                                    Alphasense OX
                                </Button>
                                <Button className="btnDefault" onClick={() => changeURL('NO2-B43F')}>
                                    Alphasense NO2
                                </Button>
								<Button className="btnDefault" onClick={() => changeURL('GRAPHENE')}>
                                    Graphene NO2
                                </Button>
                            </div>
                        </div>
                    </div>
                    {showIframe == true ? (
                        <>
                            <div className="col-md-12">
                                <iframe id="grafanaChart" src={urlconvert} width={`${windowDimensions.width - 30}px`} height={`${windowDimensions.height - 180}px`} frameBorder="0"></iframe>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default SensorDashboard
