import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import Context from '../../../store/Context'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import { useAxios } from '../../../utils/Axios';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import editIcon from '../../../assests/icons/edit.svg';
import deleteIcon from '../../../assests/icons/delete.svg';
import './ApplicationsTable.css';

function ApplicationsTable({ data, status, networks, editdelete }) {
	const axiosInstance = useAxios();
	const [open, setOpen] = useState(false);
	const { state, actions } = useContext(Context);
	const [editApp, setEditApp] = useState({});
	const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	// useEffect(() => {
	// 	console.log(data);
	// }, [data])

	//Code to update app info
	const changeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
		setEditApp({ ...editApp, [name]: value });
	};

	//Code to edit app
	function editApplication(id) {
		let appid = id.getAttribute('name')
		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_LORA_URL}/applications/${appid}`)
				.then((response) => {
					// console.log(response);
					setEditApp(response.data.application);

					actions({ type: 'setState', payload: { ...state, blocking: false } })
					setOpen(true);
				})
				.catch((err) => {
					// console.log(err);
					// setEditApp({"id":"6842939b-10d2-4cdf-a08d-1e6959756e1e","name":"sample3","organizationID":"21","networkServerName":"network-server-test"})
					// setEditApp({
					// 	"id": "6842939b-10d2-4cdf-a08d-1e6959756e1e",
					// 	"name": "sample3",
					// 	"description": "sample3 description",
					// 	"organizationID": "21",
					// 	"serviceProfileID": "100",
					// })
					setOpen(true);
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}
	}

	//Code to send request to update app
	const handleSubmit = (event) => {
		event.preventDefault();
		let orgid = localStorage.getItem('orgid');
		let updateAppData = { "application": { ...editApp, "organizationID": orgid } }
		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.put(`${process.env.REACT_APP_LORA_URL}/applications/${editApp.id}`, updateAppData)
				.then((response) => {
					setOpen(false);
					PopUp("Application Updated Successfully")
					status(true);
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err);
					ErrorPopUp("Failed. Try Again")
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});
		}
	};

	//Code to delete the Org Key
	function deleteApp(id) {
		const reqId = id.getAttribute('name');
		// console.log(reqId);
		swal({
			title: 'Are you sure?',
			text: 'Application will be deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_LORA_URL}/applications/${reqId}`)
						.then((response) => {
							PopUp("Application Deleted Successfully")
							status(true)
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});


	}

	return (
		<>
			{editdelete == true ? (
				<>
					<Table responsive borderless className="projectTableData">
						<thead>
							<tr>
								<th>ID</th>
								<th>Name</th>
								<th>Service Profile</th>
								<th>Descrition</th>
								<th>Edit</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item) => (
								<tr key={item.id} >
									<td>
										{item.id}
									</td>
									<td>
										<Link to={`/lora/applications/${item.id}`}>

											{
												item.name.length < 21 ? (
													<>{item.name.slice(0, 20)}</>
												) : (
													<>{item.name.slice(0, 20) + '...'}</>
												)
											}

										</Link>
									</td>
									<td>{item.serviceProfileName}</td>
									<td>{item.description}</td>
									<td>
										<img src={editIcon} className="iconCustom" name={item.id} onClick={(e) => editApplication(e.target)} />
									</td>
									<td>
										<img src={deleteIcon} className="iconCustom" name={item.id} onClick={(e) => deleteApp(e.target)} />
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			) : (
				<>

					<Table responsive borderless className="projectTableData">
						<thead>
							<tr>
								<th>ID</th>
								<th>Name</th>
								<th>Service Profile</th>
								<th>Descrition</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item) => (
								<tr key={item.id} >
									<td>
										{item.id}
									</td>
									<td>
										<Link to={`/lora/applications/${item.id}`}>

											{
												item.name.length < 21 ? (
													<>{item.name.slice(0, 20)}</>
												) : (
													<>{item.name.slice(0, 20) + '...'}</>
												)
											}

										</Link>
									</td>
									<td>{item.serviceProfileName}</td>
									<td>{item.description}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}
			<Modal
				show={open}
				onHide={() => setOpen(false)}
				animation={false}
				centered
				keyboard={false}
			>
				<Form onSubmit={handleSubmit}>
				
					<Modal.Header closeButton>
						<Modal.Title>Update Application</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div style={{ color: 'red'}}>
							{error}
						</div>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control
								name="name"
								onChange={changeHandler}
								type="text"
								placeholder="Application name"
								value={editApp.name}
								autoComplete="off"
								required
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Description</Form.Label>
							<Form.Control
								name="description"
								onChange={changeHandler}
								type="text"
								placeholder="Description"
								value={editApp.description}
								autoComplete="off"
								required
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button className = "btnNormal"
							variant="btnColor btn-primary-outline"
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button disabled={!isValid} className="btnDefault" type="submit">
							Update Application
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

		</>
	);
};

export default ApplicationsTable;
