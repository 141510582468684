import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import { useAxios } from '../../../utils/Axios';
import deleteIcon from '../../../assests/icons/delete.svg';
import './DeviceProfilesTable.css';
import { Link } from 'react-router-dom';

function DeviceProfilesTable({ data, status, networks, editdelete }) {
	const axiosInstance = useAxios();


	//Code to edit device Profile
	function editDeviceProfile(e) {

	}

	//Code to delete the Org Key
	function deleteDeviceProfile(e) {
		const reqId = e.getAttribute('name');
		// console.log(reqId);
		swal({
			title: 'Are you sure?',
			text: 'Device Profile will be deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_LORA_URL}/device-profiles/${reqId}`)
						.then((response) => {
							PopUp("Device Profile Deleted Successfully")
							status(true)
						})
						.catch((err) => {
							if (err.response) {
								if(err.response.data.detail !== undefined || err.response.data.detail !== null){
									ErrorPopUp(err.response.data.detail)
								}else{
									ErrorPopUp("Deleting Failed. Please check with support team")
								}
							}
							
							
						});
				}
			});


	}

	return (
		<>
			{editdelete == true ? (
				<>
					<Table responsive borderless className="deviceProfileTable">
						<thead>
							<tr>
								<th>Name</th>
								<th>Network Server </th>
								{/* <th>Edit</th> */}
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item) => (
								<tr key={item.id} >
									<td>
									<Link to = {{pathname: `/lora/device-profiles/${item.id}/edit`, }}>
										 	{item.name}
										 </Link>
									</td>
									<td>{item.networkServerName}</td>
									{/* <td>
										<img src={editIcon} className="iconCustom" name={item.id} onClick={(e) => editDeviceProfile(e.target)} />
									</td> */}
									<td>
										<img src={deleteIcon} className="iconCustom" name={item.id} onClick={(e) => deleteDeviceProfile(e.target)} />
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			) : (

				<Table responsive borderless className="deviceProfileTable">
					<thead>
						<tr>
							<th>Name</th>
							<th>Network Server </th>
						</tr>
					</thead>
					<tbody>
						{data.map((item) => (
							<tr key={item.id} >
								<td>
									{item.name}
								</td>
								<td>{item.networkServerName}</td>
							</tr>
						))}
					</tbody>
				</Table>

			)}
		</>
	);
};

export default DeviceProfilesTable;
