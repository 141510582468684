import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import GoogleApiWrapper from '../../../components/NetworkMap/NetworkMap';
import LoraUserAuth from '../../../components/LoraNetworks/LoraUserAuth/LoraUserAuth';
import Form from 'react-bootstrap/Form';
import { useAxios } from '../../../utils/Axios';
import PopUp from '../../../components/UIElements/PopUp'
import ErrorPopUp from '../../../components/UIElements/ErrorPopup'
import { useEffect, useState, useContext } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Context from '../../../store/Context'

import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout'

const LoraProjectsDashboard = (props) => {
	const [orgInfo, setOrgInfo] =  useState({
		orgName : '',
		displayName: '',
		id: '',
		canHaveGateways: 0,
		maxGatewayCount:0,
		maxDeviceCount:0
	})
	const [mapData, setMapData] = useState({
		data: {
			nodes: [{
				friendlyName: "friendlYName",
				gpsHash: "S0000",
				hostName: "dummyNode",
				lat: 0.022,
				lng: 0.022,
				nodeType: "RSS",
				selected: false,
				status: "Active"
			}]
		}
	});
	const [deviceSummary , setDeviceSummary] = useState({});
    const [optionsEnabled, setOptionsEnabled] = useState({
        deleteLoraOrg : true,
		configurationEnable: true
    })
	const axiosInstance = useAxios();
	const { state, actions } = useContext(Context);
	const history = useHistory();

	useEffect(() => {
		// checkUserStatus();
		fetchOrgnization()
	}, [axiosInstance]);

	useEffect(() => {
		checkCurrentUser()
	},[axiosInstance])

	    //code to check current user role
		function checkCurrentUser() {
			let roles = JSON.parse(localStorage.getItem("roles"));
			// let roles = ["ln_deviceadmin"]
	
			// console.log(roles.includes('ln_admin'));
			if (roles.includes('ln_user')) {
				setOptionsEnabled(
					prevState => ({
						...prevState,
						deleteLoraOrg : false,
						configurationEnable: false
					})
				)
			}
			if (roles.includes('ln_deviceadmin')) {
				setOptionsEnabled(
					prevState => ({
						...prevState,
						deleteLoraOrg : false,
						configurationEnable: false
					})
				)
			}
	
		}

	//Code to user got approved or not
	function checkUserStatus(){
		if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/users/all`)
                .then((response) => {
                    // console.log(response);
                    checkUserPresent(response.data);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
	}

	//Code to check the current login user is present in all users list or not
	function checkUserPresent(input) {
		let currentuser = localStorage.getItem('username');
		let userObj = {};
		let check = function (data) {
			for (let i = 0; i < input.length; i++) {
				if (data[i].name == currentuser) {
					userObj = data[i];
					return true
				}
			}
			return false
		}

		if (check(input) == true) {
			// console.log(userObj)
			chkUserStatus(userObj)
		}
	}
   
    //Code to check User Status for org is Approved or pending
    function chkUserStatus(input){
        // console.log(input);
        if(input.status == "pending" || input.status == "deny"){
			goStatusPage();
		}else {
			getOrgId()
        }
    }

	//Code to get Lora Org Id
    function getOrgId(){
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/users/organizations`)
                .then((response) => {
                   
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    //localStorage.removeItem('orgid');
					let orgid = response.data.loraOrgId;
					let orgname = response.data.name;
					//localStorage.setItem('orgid', orgid);
					localStorage.setItem('orgname', orgname);
					fetchOrgnization();
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
	}
	
    //Code to redirect the user to dashboard
	const goStatusPage = () => history.push('/lora/userorgrequest');
		
	//Code to fetch Organization info
	const fetchOrgnization = () => {
		console.log("fetchorg ", localStorage.getItem('orgid'));
		let orgId = localStorage.getItem('orgid');
		var roles = JSON.parse(localStorage.getItem("roles"));
		let organizationId ;
        if(roles.includes('ln_admin')){
            // console.log('system Admin')
            if (axiosInstance) {
				axiosInstance
					.get(`${process.env.REACT_APP_LORA_URL}/organizations`)
					.then((response) => {
						let data = response.data.result[0];
						console.log(data);
						if(localStorage.getItem("orgid") == null || orgId == null ||
								localStorage.getItem("orgid") == undefined || orgId == undefined ||
								localStorage.getItem("orgid").length == 0 || orgId.length == 0){
							localStorage.setItem('orgid', data.id);
							orgId = data.id;
						}
						organizationId = data.id;
						setOrgInfo({
							orgName: data.name,
							displayName: data.displayName,
							id: data.id,
							canHaveGateways:data.canHaveGateways,
							maxGatewayCount:data.maxGatewayCount,
							maxDeviceCount:data.maxDeviceCount
						})
						fetchDeviceSummary(orgId);
					})
					.catch((err) => {
						fetchDeviceSummary(orgId);
					});
			}
        }else {
			if (axiosInstance) {
				axiosInstance
					.get(`${process.env.REACT_APP_LORA_URL}/organizations/${orgId}`)
					.then((response) => {
						let data = response.data;
						setOrgInfo({
							orgName: data.organization.name,
							displayName: data.organization.displayName,
							id: data.organization.id,
							canHaveGateways:data.organization.canHaveGateways,
							maxGatewayCount:data.organization.maxGatewayCount,
							maxDeviceCount:data.organization.maxDeviceCount
						})
						fetchDeviceSummary(orgId);
					})
					.catch((err) => {
						fetchDeviceSummary(orgId);
					});
			}
        }
	};

	//code to fetch Device Summary
	function fetchDeviceSummary(inputId){
		if (axiosInstance) {
			axiosInstance
				.get(`${process.env.REACT_APP_LORA_URL}/internal/devices/summary?organizationID=${inputId}`)
				.then((response) => {
					// let data = response.data;
					// console.log(typeof(response.data));
					setDeviceSummary(response.data);
				})
				.catch((err) => {
					console.log(err.data);
				});
		}
	}

	// const sampleList = sampleProjectsList;
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {
			const timer = setTimeout(() => {
				fetchOrgnization();
			}, 1000);

		};
	};

	//Code to update Org Name and Disaply Name
	function changeHandler(e) {
		const name = e.target.name;
		const value = e.target.value;
		setOrgInfo({ ...orgInfo, [name]: value });
	}

	//Code to sibmut Organisation Update Request
	function updateOrganisation(e) {
		e.preventDefault();
		const reqId = localStorage.getItem('orgid')
		let updateOrg = {organization:{
				"name" : orgInfo.orgName
			}
		}
		axiosInstance
			.put(`${process.env.REACT_APP_LORA_URL}/organizations/${reqId}`, updateOrg)
			.then((response) => {
				PopUp("Organization Updated Successfully");
				handleSubmit(true);
			})
			.catch((err) => {
				// console.log(err);
                ErrorPopUp("Request Failed. Try Again")
			});
		
	}

	//Code to delete Organization
	function deleteOrganisation(e){
		const reqId = localStorage.getItem('orgid')
		swal({
			title: "Are you sure?",
			text: "Organization will be deleted",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_LORA_URL}/organizations/${reqId}`)
						.then((response) => {
							PopUp("Organization Deleted Successfully");
							history.push('/home');
							// sendUpdateState()
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again");
						});
				}
			});
	}
	return (
		<>
			<LoraLayout>
				<div className="col-md-12 col-sm-12 col-lg-12 no-padding">
					<div className="row" style={{margin : '0 3rem'}}>
						<div
							style={{ marginTop: '1rem', color: '#43425D' }}
							className="col-md-6 col-sm-6 col-lg-6"
						>
							<h5>Organization</h5>
						</div>
						<div
							style={{ marginTop: '1rem' }}
							className="col-md-6 col-sm-6 col-lg-6 text-right"
						>
							{optionsEnabled.deleteLoraOrg == true ? (
								<>
								<Button className="btnNormal" type="submit" onClick={(e)=> deleteOrganisation(e)}>
									DELETE
								</Button>
								</>
							) : null}
						</div>
					</div>
					<div className="default-main-page">
								<h6  style={{textAlign: 'left', marginLeft: '3rem', marginTop: '2rem'}}>Dashboard</h6>
								<Row>
									<Col md={4} lg={3} className="cardsCol">
										<Card>
											<h6 className="cardTitle">Active devices</h6>
											<div className="cardBody">
												{deviceSummary.activeCount == undefined ? (
													<>
														<span>No data available.</span>	
													</>
												) :(
													<>
														{deviceSummary.activeCount == null ?
															'-' : (<>
																{deviceSummary.activeCount}
															</>
														)}
													</>
												)}
											</div>
										</Card>
									</Col>
									{/* <Col md={4} lg={3} className="cardsCol">
										<Card>
											<h6 className="cardTitle">Active gateways</h6>
											<div className="cardBody">
												<span>No data available.</span>
											</div>
										</Card>
									</Col> */}
									<Col md={4} lg={3} className="cardsCol">
										<Card>
											<h6 className="cardTitle">Device data-rate usage</h6>
											<div className="cardBody">
												{deviceSummary.drCount == undefined ? (
													<>
														<span>No data available.</span>	
													</>
												) :(
													<>
														{/* {Object.keys(deviceSummary.drCount).length !== 0 ?
															'-' : (
															<>
																{deviceSummary.drCount}
															</>
														)} */}
														{/* {JSON.stringify(deviceSummary.drCount)} */}
														'-'
													</>
												)}
												
											</div>
										</Card>
									</Col>
								</Row>
								<br />
								{/* <Row>
									<Col md={11} className="mapCol">
										<Card>
											<h6 className="cardTitle">Gateways</h6>
											<GoogleApiWrapper nodelist={mapData} style={{ padding: '2%' }} detailedview={(e) => console.log(e)} />
										</Card>
									</Col>
								</Row> */}

							{/* {optionsEnabled.configurationEnable == true ? (							
							<Tab eventKey="configuration" title="CONFIGURATION" >
								<Row>
									<Col md={11} className="mapCol">
										<Form onSubmit={updateOrganisation}>
											<Form.Group controlId="projectName">
												<Form.Label>Organization Name</Form.Label>
												<Form.Control
													name="orgName"
													onChange={changeHandler}
													type="text"
													placeholder="Enter Organisation Name"
													autoComplete="off"
													value={orgInfo.orgName}
													required
												/>
											</Form.Group>
											<div className="text-right">
												<Button className="btnDefault" type="submit">
													UPDATE ORGANIZATION
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</Tab>
							) : null} */}
						
					</div>
				</div>
			</LoraLayout>
			<ToastContainer draggable={false} transition={Zoom}/>
		</>
	)
}

export default LoraProjectsDashboard;