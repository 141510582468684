import React,{useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import swal from 'sweetalert';
import PopUp from '../UIElements/PopUp'
import ErrorPopUp from '../UIElements/ErrorPopup'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../utils/Axios';
import editIcon from '../../assests/icons/edit.svg';
import deleteIcon from '../../assests/icons/delete.svg';

function Configuration({ configList,projectrole,expdata, status}) {

	let history = useHistory();
	const axiosInstance = useAxios();
	//code to edit Config

	function editConfig() {

	}

	// Code to delete Config
	function deleteConfig(n) {
		//let expId = history.location.pathname;
		let expId = localStorage.getItem('expid');
		let configId = n.getAttribute('configid');

		swal({
			title: 'Are you sure?',
			text: 'Configuration will be permenantly deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/${expId}/configurations/${configId}`)							
						.then((response) => {
							PopUp("Configuration Deleted Successfully");
							status(true);
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});

	}

	return (
		<>
			{configList.length > 0 ?
				(<>
					<Table responsive borderless className="filesTable ">
						<thead>
							<tr>
								<th>Name</th>
								<th>Nodes</th>
								<th>Files</th>
								{/* <th>Edit</th> */}
								{
									projectrole == 'developer' || projectrole == 'projectAdmin' ? (
										<>
											<th>Delete</th>
										</>
									) : null
								}
							</tr>
						</thead>
						<tbody>

							{configList.map((item,index ) => (
								<tr key={index}>
									<td>{item.name}</td>
									<td>{item.nodes.length}</td>
									<td>{item.binaryFiles.length + 1}</td>
									{/* <td><i className="fa fa-edit" aria-hidden="true" style={{ color: "#A3A6B4" }} imagename={item.name} configid={item.configurationId} onClick={e => editConfig(e.target)}></i></td> */}
									{/* <td><i className="fa fa-trash" aria-hidden="true" style={{ color: "#A3A6B4" }} imagename={item.name} configid={item.configurationId} onClick={e => deleteConfig(e.target)}></i></td> */}
									{/* <td><img src={editIcon} className="iconCustom" imagename={item.name} configid={item.configurationId} onClick={e => editConfig(e.target)}/></td> */}
									{
										expdata.status == 'Scheduled' ? '-' : (
											<>
												{
													projectrole == 'developer' || projectrole == 'projectAdmin' ? (
														<>
															<td><img src={deleteIcon} className="iconCustom" imagename={item.name} configid={item.configurationId} onClick={e => deleteConfig(e.target)} /></td>
														</>
													) : '-'
												}
											</>
										)
									}
								</tr>
							))}

						</tbody>
					</Table>
				</>
				) : (
					<p>No Data Available</p>
				)}

		</>
	)
}

export default Configuration;
