import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../utils/Axios';
import ErrorPopUp from '../UIElements/ErrorPopup';

function Chart (props) {
    const axiosInstance = useAxios();
    const [url , setUrl] = useState(props.data.resultUrl)
    const [urlconvert, setUrlConvert] = useState('')

    useEffect(() => {
        convertUrl(url)
    },[url])

    // useEffect(() => {
    //     convertUrl(props)
    // },[props])

    useEffect(() => {
        setTimeout(() => {
            // fetchIframe()
        }, 2000);
    },[axiosInstance])

    //code to convert the url
   const convertUrl = (input) => {
    //    let currentTime = Math.floor(new Date().getTime()/1000.0) *1000
    //    let pastTime = currentTime - (9*60*60*1000)
    let startTime = props.data.schedulingInfo.startTime * 1000
    let endTime = props.data.schedulingInfo.endTime * 1000
    let usertoken = localStorage.getItem('usertoken');

    let dSolo = url.replace(/d/, 'd-solo')

    let finalUrl = `${process.env.REACT_APP_GRAFANA_URL}${dSolo}?orgId=1&from=${startTime}&to=${endTime}&panelId=1&refresh=10s&token=${usertoken}`
    setUrlConvert(finalUrl);
        // console.log(input)
   }

   //code to fetch Iframe source data with header
   function fetchIframe(){
    const iframe = document.getElementById('grafanaChart').contentDocument;
    // const iframe = document.getElementById('grafanaChart');
    if (axiosInstance) {
        // actions({ type: 'setState', payload: { ...state, blocking: true } })
        // axiosInstance
        //     .get(urlconvert)
        //     // .get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/123/logs/download/123.zip`, {responseType: 'arraybuffer'})
        //     .then((response) => {
        //         console.log(response);
        //         // setUrlConvert(urlconvert);
        //         iframe.write(response.data);
        //         // iframe.setAttribute("src",response.config.url)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     });

        // console.log(axiosInstance.get('kjh'))
    }
   }

   //code to download the file
   function downloadFile(e){
        e.preventDefault();
        if (axiosInstance) {
            // actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/${props.data.id}/logs/download/${props.data.id}.zip`, {responseType: 'arraybuffer'})
                // .get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/123/logs/download/123.zip`, {responseType: 'arraybuffer'})
                .then((response) => {
                    console.log(response);
                    console.log(response.data);
                      const a = document.createElement("a");
                      a.href = URL.createObjectURL(
                        new Blob([response.data], {type: 'application/zip'})
                      );
                      a.setAttribute("download", `${props.data.id}.zip`);
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                })
                .catch((err) => {
                    // console.log(err.response);
                    // console.log(err.response.status);
                    // console.log(err.response.statusText);
                    // console.log(err.response.detail);
                    // if(err.response == 500){
                    //     ErrorPopUp(err.response.statusText)
                    // }else{
                    //     if (err.response) {
                    //         ErrorPopUp(err.response.detail)
                    //     }else {
                    //         ErrorPopUp("Download Failed")
                    //     }
                    // }
                    // if(err.response.status == 500){
                    //     ErrorPopUp(err.response.detail)
                    // }
                    // ErrorPopUp(err.response.statusText);
                    ErrorPopUp("Download Failed. Try Again")
                    
                    // actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
   }

    return (
        <>
            {!props.data.configurations.filter(function(config) {
                return config.defaultImageType === 'SERIALLOG';}).length >0 ? (
            <iframe id="grafanaChart" src={urlconvert} width="100%" height="500" frameBorder="0"></iframe>
            /*{ <iframe id="grafanaChart" width="100%" height="500" frameBorder="0"></iframe> }*/
            ) : null}
            <br />
            {props.data.status == 'Ended' && !props.data.configurations.filter(function(config) {
                return config.defaultImageType === 'POWERPROFILE';}).length >0 ? (
                <div style={{textAlign: 'left', marginTop : '2%'}}>
                    <Button className="btnDefault" onClick={(e) => downloadFile(e)}>Download Logs</Button>
                </div>
            ) : null}
        </>
    )

}

export default Chart;