import React, { useState, useEffect } from 'react';
import { Modal} from 'react-bootstrap';
import Chart from "react-apexcharts";
import Button from 'react-bootstrap/Button';

function NodeTimeChart(props) {
    const [seriesData, setSeriesData] = useState([]);
    const [currentDate , setCurrentDate] = useState('');

    useEffect(() => {
        if(props.nodetimedata.length > 0){
            ChartDataFilter()
        }
    }, [props])
  
    // useEffect(()=>{
    //     console.log(props)
    // },[props])

    // useEffect(() => {
    //   console.log(seriesData)
    // }, [seriesData])

  //code to prepare chart data from json
  function ChartDataFilter() {
    let timeslotdata = [...props.nodetimedata];

    let seriesArray = function () {
      let array = [];
      timeslotdata.map((name) => {
        name.details.map((item) => {
          console.log(item);
          let obj = {}
          obj['name'] = name.node
          obj['data'] = [{ 'x': name.node, 'y': [item.from, item.to] }]
          if (item.status == 'available') {
            obj['color'] = '#00E396'
          }
          if(item.status == 'unavailable') {
            obj['color'] = '#FF0000'
          }
          if(item.status == 'Unknown' || item.status == 'NA'){
            obj['color'] = '#808080'
          }
          array.push(obj)
        })
      })
      getCurrentDate(array)
      return array
    }
    setSeriesData(seriesArray);
  }

  //code to get current date
  function getCurrentDate(input){
    // console.log(input[0].data[0].y[0]); 
    let dateAccess = input[0].data[0].y[0];
    let date = new Date(dateAccess * 1000);
    let fulldate = date.toLocaleDateString();
    // let getdate = fulldate.substring(0,9);
    setCurrentDate(fulldate);
  }

//code to set options to the chart
let options = {
      chart: {
        height: 450,
        type: "rangeBar",
        toolbar: {
          show: true,
          tools: {
            download: false
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '60%',
          rangeBarGroupRows: true
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          let a = new Date(val[0] * 1000)
          let aa = a.toLocaleTimeString()
          let b = new Date(val[1] * 1000)
          let bb = b.toLocaleTimeString()
          return aa + ' - ' + bb;
        }
      },
      // fill: {
      //   colors: [function({ value, seriesIndex, w }) {
      //     return '#D9534F'
      //   }]
      // },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        type: "datetime",
        offsetX: 0,
        labels: {
          format: 'HH',
          formatter: function (value) {
            let a = new Date(value * 1000)
            let aa = a.toLocaleTimeString()
            return aa;
          }
        }
      },
      legend: {
        show: false,
      },
      noData: {
        text: 'Loading...'
      }
    }

    return (
        <>
            {props.chartModal == true ? (
                <>
                    <Modal
                        show={props.chartModal}
                        onHide={() => props.closeChartModal()}
                        animation={false}
                        centered
                        backdrop="static"
                        keyboard={false}
                        size="xl"
                        // className="multiconfigModal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="create-configuration-title">Node Time Availability</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <div className="row chartPreNext">
                                    <div className="col-md-4">
                                        <Button className="btnNormal create-exp-btn btnSpace" onClick={() => props.previous()}>
                                            Previous
                                        </Button>
                                    </div>
                                    <div className="col-md-4" style={{textAlign:'center'}}>
                                        <h6>{currentDate}</h6>
                                    </div>
                                    <div className="col-md-4" style={{textAlign:'end'}}>
                                        <Button className="btnNormal create-exp-btn btnSpace" onClick={() => props.next()}>
                                            Next
                                        </Button>
                                    </div>
                                </div>
                                <div className="row chartContainer">    
                                    <div className="col-md-12">
                                        {seriesData.length > 0 ? (
                                            <>
                                                <div id="chart">
                                                    <Chart
                                                        options={options}
                                                        series={seriesData}
                                                        type="rangeBar"
                                                        height={450}
                                                    />
                                                </div>
                                                <div className="chartColorDef">
                                                    <div className="chartColorChild">
                                                      <i className="fa fa-circle greenNode" aria-hidden="true"></i>
                                                      <span>Available</span>
                                                    </div>
                                                    <div className="chartColorChild">
                                                      <i className="fa fa-circle redNode" aria-hidden="true"></i>
                                                      <span>Unavailable</span>
                                                    </div>
                                                    <div className="chartColorChild">
                                                      <i className="fa fa-circle greyNode" aria-hidden="true"></i>
                                                      <span>Unavailable due to maintenance</span>
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            ) : null
            }
        </>
    )
}

export default NodeTimeChart;
