import React, { useState, useEffect, useContext } from 'react';
import CreateExpTable from '../CreateExpTable/CreateExpTable';
import { Modal, Accordion, Card } from 'react-bootstrap';
import NodeTimeChart from '../UIElements/NodeTimeChart';
import ImgRadioButtons from '../UIElements/RadioButton/ImgRadioButtons'
import BinRadioButtons from '../UIElements/RadioButton/BinRadioButtons'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import editIcon from '../../assests/icons/edit.svg';
import deleteIcon from '../../assests/icons/delete.svg';
import ErrorPopUp from '../UIElements/ErrorPopup';
import { useAxios } from '../../utils/Axios';
import Context from '../../store/Context';

function MultipleConfigurations(props) {
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [configList, setConfigList] = useState('');
    const [nestedModal, setNestedModal] = useState(false);
    const [updateNestedModal, setUpdateNestedModal] = useState(false);
    const [nestedModalPageNo, setNestedModalPageNo] = useState(1);
    const [nestedLgModal, setNestedLgModal] = useState('');
    const [updateNestedLgModal, updateSetNestedLgModal] = useState('');
    const [imgRadioState, setImgRadioState] = useState('');
    const [updateImgRadioState, updateSetImgRadioState] = useState('');
    const [binRadioState, setBinRadioState] = useState('');
    const [updatebinRadioState, updateSetBinRadioState] = useState('');
    const [configStep, setConfigStep] = useState(1);
    const [updateconfigStep, setUpdateconfigStep] = useState(0);
    const [expNodeView, setExpNodeView] = useState(false);
    const [experimentNodes, setExperimentNodes] = useState({});
    const [checkUpdateName, setCheckUpdateName] = useState('');
    const [configIndex, setConfigIndex] = useState();
    const [chartIndex, setChartIndex] = useState(0);
    const [chartModal, setChartModal] = useState(false);
    const [nodeTimeData, setNodeTimeData] = useState([]);
    const [blurSelectImage , setBlurSelectImage] = useState(false);

    const [tempConfigNodes, setTempConfigNodes] = useState([]);
    const [tempConfigNodesUpdate, setTempConfigNodesUpdate] = useState([]);

    const [storeDefaultImage, setStoreDefaultImage] = useState({
        serialLog: false,
        powerProfile: false
    });

    const [storeupdateDefaultImage, setStoreupdateDefaultImage] = useState({
        serialLog: false,
        powerProfile: false
    });

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    const [configInfo, setConfigInfo] = useState(
        {
            "name": '',
            "defaultImageType": "NONE",
            "binaryFiles": [],
            "imageFile": { "repoPath": '', "tag": '', "type": '' },
        }
    );

    const [getSingleConfig, setSingleConfig] = useState({});

    const [expConfigNameCheck, setConfigNameCheck] = useState(false);
    const [binarySelectCheck, setBinarySelectCheck] = useState(false);
    const [imageSelectCheck, setImageSelectCheck] = useState(false);
    const [nodeSelectCheck, setNodeSelectCheck] = useState(false);

    useEffect(() => {
        // console.log(props.tempNodes)
        if (Object.keys(props.tempNodes).length > 0) {
            setExperimentNodes(props.tempNodes);
        }
    }, [props.tempNodes])

    // useEffect(() => {
    //     console.log(tempConfigNodes)
    // }, [tempConfigNodes])

    useEffect(() => {
        if(storeDefaultImage.serialLog == true || storeDefaultImage.powerProfile == true){
            let element = document.querySelector(".configImageSelection");
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }
    }, [blurSelectImage])

    useEffect(() => {
        // console.log(storeDefaultImage);
        checkBothDefaultImages()
    }, [storeDefaultImage])

    useEffect(() => {
        // console.log(storeDefaultImage);
        checkupdateBothDefaultImages()
    }, [storeupdateDefaultImage])

    useEffect(() => {
        // console.log(props.singleConfiguration);
        if (Object.keys(props.singleconfiguration).length > 0) {
            setSingleConfig(props.singleconfiguration);
        }
        if (Object.keys(props.singleconfiguration).length > 0) {
            setconfigImageBinary(props.singleconfiguration);
        }
        // console.log(props.singleConfiguration)
    }, [props.singleconfiguration])

    //code to set default iamge and binary and node
    function setconfigImageBinary(data) {
        // console.log(data);
        let configImage = data.imageFile;
        let configBinary = data.binaryFiles[0];
        let nodes = data.nodes;
        setCheckUpdateName(data.name);

        // console.log(configImage);
        // console.log(configBinary);

        if(Object.keys(configImage).length > 0){
            updateSetImgRadioState(`${configImage.repoPath}_${configImage.tag}_${configImage.type}`);
        }

        if(configBinary == undefined){
            
        }else{
            updateSetBinRadioState(`${configBinary.path}_${configBinary.type}_${configBinary.path}`);
        }

        setTempConfigNodesUpdate(nodes);
        setUpdateNestedModal(true);
        setUpdateconfigStep(1);

        setTimeout(() => {
            updateDefaultImageChecker(); // code to call function which checks existing default image
        }, 1000);
    }

    //code to close mdoal
    function closeNestedModal() {
        props.resetNodes(tempConfigNodes);
        setNestedModal(false);
        document.querySelector('.create-exp-second').style.display = 'block';
        setConfigInfo({
            "name": '',
            "defaultImageType": "NONE",
            // "binaryFiles":[{"path": '',"type" : ''}],
            "binaryFiles": [],
            "imageFile": { "repoPath": '', "tag": '', "type": '' },
        })
        setTempConfigNodes([]);
        setImgRadioState('');
        setBinRadioState('');
        setConfigStep(1);
    }

    //code to hide update nested modal
    function closeUpdateNestedModal() {
        setUpdateconfigStep(0);
        setUpdateNestedModal(false);
        setSingleConfig({});
        setTempConfigNodesUpdate([]);
        updateSetNestedLgModal('')
    }

    //code to open nested modal
    function openNestedModal() {
        document.querySelector('.create-exp-second').style.display = 'none';
        setNestedModal(true);
        setNestedLgModal('');
        updateSetNestedLgModal('')
    }

    //code to open first step for add config
    function configFirstModal() {
        setConfigStep(1);
        setNestedLgModal('');
        setBlurSelectImage(!blurSelectImage)
    }

    //code to open first step for update config
    function updateConfigFirstModal() {
        setUpdateconfigStep(1);
        updateSetNestedLgModal('');
    }

    //code to open second step for add config
    function configSecondModal() {

        if (configInfo.name == '') {
            setConfigNameCheck(true)
            return
        } else {
            setConfigNameCheck(false)
        }

        //code to check duplicate config names
        let confignames = [];
        let configs = props.configurations;
        if (configs.length > 0) {
            for (let i = 0; i < configs.length; i++) {
                confignames.push(configs[i].name);
            }

            if (confignames.includes(configInfo.name)) {
                ErrorPopUp("configuration name should not be duplicated")
                return
            }
        }

        if(storeDefaultImage.serialLog == true || storeDefaultImage.powerProfile == true){
            if (configInfo.binaryFiles.length == 0) {
                setBinarySelectCheck(true)
                return
            } else {
                setBinarySelectCheck(false)
            }
        }

        if(storeDefaultImage.serialLog == false && storeDefaultImage.powerProfile == false){
            if (configInfo.imageFile.repoPath == '') {
                setImageSelectCheck(true)
                return
            } else {
                setImageSelectCheck(false)
            }
        }

        // if(configInfo.binaryFiles.length == 0){
        //     // if(createExp.configurations[0].binaryFiles.path == ''){
        //     //     setBinarySelectCheck(true)
        //     //     return
        //     // }else{
        //     //    setBinarySelectCheck(false)
        //     // }   
        //     setBinarySelectCheck(false)
        // }else {
        //     setBinarySelectCheck(false)
        // }

        // setModalPageNo(3)
        // setLgModal('lg')
        setNestedModalPageNo(2);
        setConfigStep(2);
        setNestedLgModal('lg')
        // document.querySelector('#create-exp-title').textContent = 'Add configuration (Step 3 of 3)';
        // document.querySelector('.create-exp-second').style.display = 'none';
        // document.querySelector('.create-exp-third').style.display = 'block';
        setExpNodeView(true)
    };


    //code to update configuration
    function updateconfigSecondModal() {
        // console.log(getSingleConfig);
        if (getSingleConfig.name == '') {
            setConfigNameCheck(true)
            return
        } else {
            setConfigNameCheck(false)
        }

        //code to check duplicate config names
        let confignames = [];
        let configs = props.configurations;
        if (configs.length > 0) {
            for (let i = 0; i < configs.length; i++) {
                confignames.push(configs[i].name);
            }

            if (getSingleConfig.name == checkUpdateName) {
                // console.log("Same name");
            } else {
                if (confignames.includes(getSingleConfig.name)) {
                    ErrorPopUp("configuration name should not be duplicated")
                    return
                }
            }
        }

        if(storeupdateDefaultImage.serialLog == true || storeupdateDefaultImage.powerProfile == true){
            if(getSingleConfig.binaryFiles.length > 0){
                if (getSingleConfig.binaryFiles[0].path == '') {
                    setBinarySelectCheck(true)
                    return
                } else {
                    setBinarySelectCheck(false)
                }
            }else{
                setBinarySelectCheck(true)
                return
            }
        }

        if(storeupdateDefaultImage.serialLog == false && storeupdateDefaultImage.powerProfile == false){
            if (getSingleConfig.imageFile.repoPath == '' || getSingleConfig.imageFile.repoPath == undefined) {
                setImageSelectCheck(true)
                return
            } else {
                setImageSelectCheck(false)
            }
        }

        setUpdateconfigStep(2);
        updateSetNestedLgModal('lg')
    }



    //Storing Experiment Name
    function configurationName(e) {
        const configName = e.target.value;
        // console.log(configName)
        // setConfigInfo((prevState) => {
        //     prevState.configurations['name'] = configName;
        //     return {
        //         ...prevState
        //     };
        // });
        setConfigInfo({ ...configInfo, ['name']: configName });
    }

    //update Experiment Name
    function updateconfigName(e) {
        const configName = e.target.value;
        setSingleConfig({ ...getSingleConfig, ['name']: configName });
    }

    //Storing Selected Image
    function selectImage(e) {
        let name = e.getAttribute('name');
        let version = e.getAttribute('version');
        let type = e.getAttribute('imagetype');

        setImgRadioState(`${name}_${version}_${type}`)

        // setConfigInfo((prevState) => {
        //     // prevState.configurations[0].imageFile = {repoPath : `${name}`, tag : `${version}`, type : `${type}`};
        //     prevState.configurations.imageFile = {repoPath : `${name}`, tag : `${version}`, type : `${type}`};
        //     return {
        //         ...prevState
        //     };
        // });
        setConfigInfo({ ...configInfo, ['imageFile']: { 'repoPath': `${name}`, 'tag': `${version}`, 'type': `${type}` } });
        // props.filterNodesByImage(type);
    }

    //storing selected updated image
    function selectUpdateImage(e) {
        let name = e.getAttribute('name');
        let version = e.getAttribute('version');
        let type = e.getAttribute('imagetype');

        updateSetImgRadioState(`${name}_${version}_${type}`)
        setSingleConfig({ ...getSingleConfig, ['imageFile']: { 'repoPath': `${name}`, 'tag': `${version}`, 'type': `${type}` } });
    }

    //Storing Selected Binary
    function selectBinary(e) {
        let name = e.getAttribute('name');
        let type = e.getAttribute('binarytype');
        // console.log(name);
        setBinRadioState(`${name}_${type}_${name}`)
        // setConfigInfo((prevState) => {
        //     // prevState.configurations[0].binaryFiles = [{path : `${name}`, type: `${type}`}]
        //     prevState.configurations.binaryFiles = [{path : `${name}`, type: `${type}`}]
        //     return {
        //         ...prevState
        //     };
        // });
        setConfigInfo({ ...configInfo, ['binaryFiles']: [{ 'path': `${name}`, 'type': `${type}` }] });
    }

    //Storing Selected Binary
    function selectUpdateBinary(e) {
        let name = e.getAttribute('name');
        let type = e.getAttribute('binarytype');
        // console.log(name);
        updateSetBinRadioState(`${name}_${type}_${name}`)
        setSingleConfig({ ...getSingleConfig, ['binaryFiles']: [{ 'path': `${name}`, 'type': `${type}` }] });
    }

    //Storing Selected Nodes and passing event to map
    function selectedNode(e) {
        // console.log(expNodeList.data.nodes);
        props.selectedNodes(e);

        let { checked } = e.target;

        // console.log(checked);
        // console.log(e);
        // console.log(e.target);

        let nodeid = e.target.getAttribute('index')
        let name = e.target.getAttribute('hostname')
        // console.log(nodeid)

        if (checked == true) {
            let data = [...tempConfigNodes]
            // console.log(data);
            let newElement = { "hostName": name }
            // data.push(newElement)
            setTempConfigNodes(tempConfigNodes => [...tempConfigNodes, newElement])
        }

        if (checked == false) {
            experimentNodes.data.nodes.map((row, index) => {
                if (index == nodeid) {
                    let nodedata = [...tempConfigNodes];
                    let finaldata = function () {
                        for (let i = 0; i < nodedata.length; i++) {
                            if (nodedata[i].hostName == row.hostName) {
                                // console.log(nodedata[i])
                                nodedata.splice(i, 1)
                                return nodedata
                            }
                        }
                    }

                    setTempConfigNodes(finaldata());
                }
            });
        }
    }

    //code to change Node checkboxes based on button click on map view
    function selectedNodesByBtn(e){   
        let nodename = e.target.getAttribute('nodename');
        let nodecheck = e.target.getAttribute('nodecheck');
        // console.log(nodename, nodecheck);

        if (nodecheck == "true") {
            let nodedata = [...tempConfigNodes];
            let newElement = { "hostName": nodename }
            nodedata.push(newElement);
            setTempConfigNodes(nodedata)
        }

        if (nodecheck == "false") {
            let finalarray = [];
            let nodedata = [...tempConfigNodes];
            for (let i = 0; i < nodedata.length; i++) {
                if (nodedata[i].hostName == nodename) {
                   
                }else{
                    finalarray.push(nodedata[i])
                }
            }
            setTempConfigNodes(finalarray);
        }
    }

    //Storing Updated Nodes and passing event to map
    function selectedUpdateNode(e) {
        // console.log(expNodeList.data.nodes);
        props.selectedNodes(e);

        let { checked } = e.target;

        // console.log(e);
        // console.log(e.target);

        let nodeid = e.target.getAttribute('index')
        let name = e.target.getAttribute('hostname')
        // console.log(nodeid)

        if (checked == true) {
            let data = [...tempConfigNodes]
            // console.log(data);
            let newElement = { "hostName": name }
            // data.push(newElement)
            setTempConfigNodesUpdate(tempConfigNodesUpdate => [...tempConfigNodesUpdate, newElement])
        }

        if (checked == false) {
            experimentNodes.data.nodes.map((row, index) => {
                if (index == nodeid) {
                    let nodedata = [...tempConfigNodesUpdate];
                    let finaldata = function () {
                        for (let i = 0; i < nodedata.length; i++) {
                            if (nodedata[i].hostName == row.hostName) {
                                // console.log(nodedata[i])
                                nodedata.splice(i, 1)
                                return nodedata
                            }
                        }
                    }

                    setTempConfigNodesUpdate(finaldata());
                }
            });
        }
    }

    //send event to parent prop
    function selectAllnodes(e , name){

        let { checked } = e.target;

        // console.log(checked);
        
        switch (name) {
            case 'ALL':
                // console.log(name); 
                storeAllNodesTemp(checked);                
                break;
            case 'ACTIVE':
                // console.log(name); 
                break;
            case 'AVAILABLE':
                allAvailableNodesTemp(checked);
                // console.log(name); 
                break;
            default:
                // console.log('Default'); 
                break;
        }

        // console.log(tempConfigNodesUpdate);
    }

    //code to store all nodes for a configuraion
    function storeAllNodesTemp(check){
        let storedconfigs = props.configurations;
        let allhostnames = [];
        for(let i =0;i<storedconfigs.length;i++){
            let nodes = storedconfigs[i].nodes;
            for(let j =0;j<nodes.length;j++){
                allhostnames.push(nodes[j].hostName)
            }    
        }
        // console.log(allhostnames);
        if(check == true){
            // console.log("all true");
            let allnodes = experimentNodes.data.nodes;
            // console.log(allnodes);
            let array=[];
            for(let i =0;i<allnodes.length;i++){
                if(allhostnames.includes(allnodes[i].hostName) == true){

                }else{
                    array.push({ "hostName": allnodes[i].hostName })
                }  
            }
            setTempConfigNodes(array);
            // console.log(allnodes);
            // console.log(props.configurations);
        }

        if(check == false){
            // console.log("all false");
            setTempConfigNodes([]);
            // console.log(props.configurations)
        }

        props.selectallnodes(check);
    }

    function allAvailableNodesTemp(checked){
        props.getnodesbytime(checked);
    }
    //code to send config data back to parent
    function addNewConfig() {

        if (tempConfigNodes.length == 0) {
            setNodeSelectCheck(true);
            return
        } else {
            setNodeSelectCheck(false);
        }

        let obj = { ...configInfo, "nodes": tempConfigNodes }
        // console.log(obj);
        props.addConfig(obj);

        setNestedModal(false);

        document.querySelector('.create-exp-second').style.display = 'block';

        setConfigInfo({
            "name": '',
            "defaultImageType": "NONE",
            // "binaryFiles":[{"path": '',"type" : ''}],
            "binaryFiles": [],
            "imageFile": { "repoPath": '', "tag": '', "type": '' },
        })

        setStoreDefaultImage({
            serialLog: false,
            powerProfile: false
        })
        setTempConfigNodes([]);
        setImgRadioState('');
        setBinRadioState('');
        updateSetNestedLgModal('')
        setConfigStep(1);
    }

    //code to send config data back to parent
    function updateExistingConfig(num) {

        if (tempConfigNodesUpdate.length == 0) {
            setNodeSelectCheck(true);
            return
        } else {
            setNodeSelectCheck(false);
        }

        let obj = { ...getSingleConfig, "nodes": tempConfigNodesUpdate }
        // console.log(obj);
        props.updateConfiguration(obj, num);

        setUpdateNestedModal(false);

        document.querySelector('.create-exp-second').style.display = 'block';

        setSingleConfig({});
        setTempConfigNodesUpdate([]);
        updateSetImgRadioState('');
        updateSetBinRadioState('');
        updateSetNestedLgModal('');
        setUpdateconfigStep(1);
    }

    //code set index number and sent back to prop
    function editConfiguration(num) {
        props.editConfiguration(num);
        setConfigIndex(num);
    }

    //code to check Node Time Availability
    function currentNodeAvailability() {
        let nodes = [...experimentNodes.data.nodes]
        let nodesCount = 0;
        nodes.map((item) => {
            if (item.selected == true) {
                nodesCount++
            }
        })

        if (nodesCount == 0) {
            ErrorPopUp("Select Node")
            return
        } else {
            nodeTimeSvrRequest(chartIndex)
        }

    }

    //code to decrease ChartIndex by -1
    function prevNodeIndex() {
        setChartIndex(chartIndex - 1);
        nodeTimeSvrRequest(chartIndex - 1)
    }

    //code to decrease ChartIndex by -1
    function nextNodeIndex() {
        setChartIndex(chartIndex + 1);
        nodeTimeSvrRequest(chartIndex + 1)
    }

    //code to send server request to check node time availability
    function nodeTimeSvrRequest(inputIndex) {
        let nodes = [...experimentNodes.data.nodes]
        let nodesList = [];
        nodes.map((item) => {
            if (item.selected == true) {
                nodesList.push(item.hostName)
            }
        })

        let date = new Date();
        date.setHours(0, 0, 0, 0)
        let proposedDate = date / 1000;
        // console.log(proposedDate);

        let input = {
            "proposedDateTime": proposedDate,
            "nodes": nodesList,
            "index": inputIndex,
            "period": 24
        }

        // console.log(input)
        if (axiosInstance) {
            // actions({type : 'setState', payload : {...state , blocking : true}})
            axiosInstance
                .post(`${process.env.REACT_APP_EXPERIMENT_URL}/experiments/nextavailabletime`, input)
                .then((response) => {
                    // console.log(response)
                    setChartModal(true);
                    setNodeTimeData(response.data.timeSlots)
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopUp("Data Not available")
                });

        }
    }

    //code to store default Image Text
    function deafultImageCB(e) {
        let check = e.target.checked;
        // console.log(check)
        let name = e.target.getAttribute("name");
        if (name == 'SERIALLOG' && check == true) {
            setStoreDefaultImage(prevState => ({
                ...prevState,
                serialLog: true
            }))
        }
        if (name == 'SERIALLOG' && check == false) {
            setStoreDefaultImage(prevState => ({
                ...prevState,
                serialLog: false
            }))
        }

        if (name == 'POWERPROFILE' && check == true) {
            setStoreDefaultImage(prevState => ({
                ...prevState,
                powerProfile: true
            }))
        }
        if (name == 'POWERPROFILE' && check == false) {
            setStoreDefaultImage(prevState => ({
                ...prevState,
                powerProfile: false
            }))
        }
    }

    //code to check default image seelction from state and act accordingly
    function checkBothDefaultImages() {
        let element = document.querySelector(".configImageSelection");
        // console.log(element)
        if (storeDefaultImage.serialLog == true && storeDefaultImage.powerProfile == false) {
            setConfigInfo({ ...configInfo, 
                defaultImageType: 'SERIALLOG', 
                ['imageFile']: { 'repoPath': '', 'tag': '', 'type': '' }
            });
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }

        if (storeDefaultImage.serialLog == false && storeDefaultImage.powerProfile == true) {
            setConfigInfo({ ...configInfo, 
                defaultImageType: 'POWERPROFILE', 
                ['imageFile']: { 'repoPath': '', 'tag': '', 'type': '' }
            });
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }
        if (storeDefaultImage.serialLog == true && storeDefaultImage.powerProfile == true) {
            setConfigInfo({ ...configInfo, 
                defaultImageType: 'BOTH', 
                ['imageFile']: { 'repoPath': '', 'tag': '', 'type': '' }
            });
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }

        if (storeDefaultImage.serialLog == false && storeDefaultImage.powerProfile == false) {
            setConfigInfo({ ...configInfo, 
                defaultImageType: 'NONE', 
            });
            if(element !== null){
                element.style.pointerEvents = 'auto';
                element.style.opacity = '100%';
            }
        }
    }

    //code to check existing default image and set state accordingly
    function updateDefaultImageChecker(){
        let input = props.singleconfiguration;
        // console.log(input)
        let element = document.querySelector(".updateconfigImageSelection");
        if(input.defaultImageType == 'NONE'){
            setStoreupdateDefaultImage({
                serialLog : false,
                powerProfile : false
            })
            setConfigInfo(prevState => ({
                ...prevState,
                defaultImageType: 'NONE'
            }))
        }
        if(input.defaultImageType == 'BOTH'){
            setStoreupdateDefaultImage({
                serialLog : true,
                powerProfile : true
            })
            setConfigInfo(prevState => ({
                ...prevState,
                defaultImageType: 'BOTH'
            }))
            
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }
        if(input.defaultImageType == 'SERIALLOG'){
            setStoreupdateDefaultImage({
                serialLog : true,
                powerProfile : false
            })
            setConfigInfo(prevState => ({
                ...prevState,
                defaultImageType: 'SERIALLOG'
            }))
            
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }
        if(input.defaultImageType == 'POWERPROFILE'){
            setStoreupdateDefaultImage({
                serialLog : false,
                powerProfile : true
            })
            setConfigInfo(prevState => ({
                ...prevState,
                defaultImageType: 'POWERPROFILE'
            }))
            
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }
    }

    //code to store default Image Text
    function updatedefaultImageCB(e) {
        let check = e.target.checked;
        // console.log(check)
        let name = e.target.getAttribute("name");
        if (name == 'SERIALLOG' && check == true) {
            setStoreupdateDefaultImage(prevState => ({
                ...prevState,
                serialLog: true
            }))
        }
        if (name == 'SERIALLOG' && check == false) {
            setStoreupdateDefaultImage(prevState => ({
                ...prevState,
                serialLog: false
            }))
        }

        if (name == 'POWERPROFILE' && check == true) {
            setStoreupdateDefaultImage(prevState => ({
                ...prevState,
                powerProfile: true
            }))
        }
        if (name == 'POWERPROFILE' && check == false) {
            setStoreupdateDefaultImage(prevState => ({
                ...prevState,
                powerProfile: false
            }))
        }
    }

    //code to check user input from update default image checkboxes and change state accordingly
    function checkupdateBothDefaultImages() {
        let element = document.querySelector(".updateconfigImageSelection");
        // console.log(element)
        if (storeupdateDefaultImage.serialLog == true && storeupdateDefaultImage.powerProfile == false) {
            setSingleConfig({ ...getSingleConfig, defaultImageType: 'SERIALLOG'});
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }

        if (storeupdateDefaultImage.serialLog == false && storeupdateDefaultImage.powerProfile == true) {
            setSingleConfig({ ...getSingleConfig, defaultImageType: 'POWERPROFILE'});
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }
        if (storeupdateDefaultImage.serialLog == true && storeupdateDefaultImage.powerProfile == true) {
            setSingleConfig({ ...getSingleConfig, defaultImageType: 'BOTH'});
            if(element !== null){
                element.style.pointerEvents = 'none';
                element.style.opacity = '40%';
            }
        }

        if (storeupdateDefaultImage.serialLog == false && storeupdateDefaultImage.powerProfile == false) {
            setSingleConfig({ ...getSingleConfig, defaultImageType: 'NONE'});
            if(element !== null){
                element.style.pointerEvents = 'auto';
                element.style.opacity = '100%';
            }
        }
    }

    //code to call crate experiment to prop
    function propCrateExperiment(e){
        props.submitExperiment(e);
        setUpdateNestedModal(false)
    }

    let expConfigNameStatus = expConfigNameCheck ? 'expConfigNameEmpty' : 'expConfigNameFilled';
    let expBinaryStatus = binarySelectCheck ? 'expBinaryEmpty' : 'expBinaryFilled';
    let expImageStatus = imageSelectCheck ? 'expImageEmpty configImageSelection updateconfigImageSelection' : 'expImageFilled configImageSelection updateconfigImageSelection';
    

    return (
        <>

            {props.configurations.length > 0 ? (
                <>
                    <div className="addConfigFilled">
                        <div className="all-config-list">
                            {props.configurations.map((item, index) => (
                                <>
                                    <div className="single-config-item">
                                        <div>
                                            <span>
                                                {
                                                    item.name.length < 18 ? (
                                                        <>{item.name.slice(0, 17)}</>
                                                    ) : (
                                                        <>{item.name.slice(0, 17) + '...'}</>
                                                    )
                                                }
                                            </span>
                                        </div>
                                        <div className="edit-delete-config">
                                            <img src={editIcon} className="iconCustom" name={item.id} onClick={e => editConfiguration(index)} />
                                            <img src={deleteIcon} className="iconCustom" name={item.id} onClick={e => props.deleteConfiguration(index)} />
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className="add-config-button">
                            <Button className="btnNormal btn-sm" onClick={() => openNestedModal()}>
                                + Add Configaration
                            </Button>
                        </div>
                    </div>

                </>
            ) : (
                <>
                    <div className="addConfigEmpty">
                        <p className="add-config-text">Atleast one configuration is required</p>
                        <Button className="btnDefault" onClick={() => openNestedModal(true)}>
                            + Add Configaration
                        </Button>
                    </div>
                </>
            )}
            <div className="create-exp-footer">
                <Button className="btnNormal create-exp-btn btnSpace" onClick={(e) => props.firstmodal(e)}>
                    Back
                </Button>
                <Button type="submit" className="btnDefault" onClick={(e) => propCrateExperiment(e)}>
                    Create Experiment
                </Button>
            </div>

            {/*Code to show add config Modal*/}
            <Modal
                show={nestedModal}
                onHide={() => closeNestedModal()}
                animation={false}
                centered
                backdrop="static"
                keyboard={false}
                size={nestedLgModal}
                className="multiconfigModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="create-configuration-title">Add Configuration (Step {nestedModalPageNo} of 2)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            {/* Add config first step Starts*/}
                            {configStep == 1 ? (
                                <>
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <div style={{ width: "100%" }}>
                                            <p className="create-exp-sub-header">A configuration must be added to assign project files and<br />select nodes before running your experiment.</p>
                                            <div style={{ color: 'red'}}>
                                                {error}
                                            </div>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Configuration Name</Form.Label>
                                                    <Form.Control type="text" name="configuration name" value={configInfo.name} className={expConfigNameStatus} placeholder="Configuration 1" onChange={(e) => 
                                                        {
                                                            const value = e.target.value;
                                                            const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                                                            if (!textFieldRegex.test(value)) {
                                                                setError(e.target.name + ' is invalid');
                                                                setIsValid(false);
                                                            }else{
                                                                setError('');
                                                                setIsValid(true);
                                                            }
                                                            configurationName(e)
                                                        }
                                                        } />
                                                </Form.Group>
                                            </Form>
                                            <Form className="defaultImageForm">
                                                <Row>
                                                    <Col>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="SERIALLOG"
                                                            label="Serial Log"
                                                            checked = {storeDefaultImage.serialLog}
                                                            className="defaultImage"
                                                            disabled={props.binaries.length > 0 ? false : true}
                                                            onChange={(e) => deafultImageCB(e)}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="POWERPROFILE"
                                                            label="Power Profile"
                                                            checked = {storeDefaultImage.powerProfile}
                                                            className="defaultImage"
                                                            disabled={props.binaries.length > 0 ? false : true}
                                                            onChange={(e) => deafultImageCB(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <label ><b>Files</b></label>
                                            <div style={{ margin: "0 auto" }}>
                                                <Accordion defaultActiveKey="0">
                                                    <Card className={expImageStatus}>
                                                        <Accordion.Toggle as={Card.Header} eventKey="0" className="accodianHeader">
                                                            SELECT IMAGE
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="0">
                                                            <Card.Body>
                                                                {props.images.length > 0 ? (
                                                                    <>
                                                                        {props.images.map((item, index) => (
                                                                            <>
                                                                                {item.artifacts.map((version, i) => (
                                                                                    <>{version.scanOverview.severity == 'High' ? (<> </>) : (
                                                                                        <>
                                                                                            <ImgRadioButtons
                                                                                            value={imgRadioState}
                                                                                            name={item.name}
                                                                                            version={version.tag}
                                                                                            imagetype={version.type}                                                                        
                                                                                            disabled={version.disabled}
                                                                                            event={(e) => selectImage(e.target)}
                                                                                            />
                                                                                        </> 
                                                                                       )}  
                                                                                    </>
                                                                                    // <ImgRadioButtons 
                                                                                    //     imgradiostate={imgRadioState} 
                                                                                    //     name={item.name} 
                                                                                    //     version={version.tag} 
                                                                                    //     imagetype = {version.type}
                                                                                    //     disabled={version.disabled} 
                                                                                    //     event={(e) => selectImage(e.target)} 
                                                                                    // />
                                                                                    
                                                                                )
                                                                                )
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>No files Found</p>
                                                                    </>
                                                                )}
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                    <Card className={expBinaryStatus}>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1" className="accodianHeader">
                                                            SELECT BINARY
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body>
                                                                {props.binaries.length > 0 ? (
                                                                    <>
                                                                        {props.binaries.map((item, index) => (
                                                                            <BinRadioButtons
                                                                                binradiostate={binRadioState}
                                                                                num={item.name}
                                                                                name={item.name}
                                                                                binarytype={item.type}
                                                                                disabled={item.disabled}
                                                                                event={(e) => selectBinary(e.target)}
                                                                            />
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>No files Found</p>
                                                                    </>
                                                                )}
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="create-exp-footer">
                                            <Button className="btnNormal create-exp-btn btnSpace" onClick={() => closeNestedModal()}>
                                                Cancel
                                            </Button>
                                            <Button disabled={!isValid} className="btnDefault create-exp-btn" onClick={() => configSecondModal()}>
                                                Next
                                            </Button>
                                        </div>
                                        <p className="text-center">{nestedModalPageNo} of 2</p>
                                    </div>
                                </>
                            ) : null}

                            {/* Add config first step Ends*/}

                            {/* Add config second step Starts*/}
                            {configStep == 2 ? (
                                <>
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <p className="create-exp-sub-header">A configuration must be added to assign project files and select nodes before running your experiment.</p>

                                        {nodeSelectCheck === true ? <h5 style={{ color: 'red' }}>Select Atleast One Node</h5> : <label>Select Nodes</label>}
                                        <div>
                                            {expNodeView == true ?
                                                <>
                                                    {Object.keys(experimentNodes).length > 0 ? (
                                                        <>
                                                            <CreateExpTable
                                                                // back={() => configFirstModal()}
                                                                nodelist={experimentNodes}
                                                                selectednodes={(e) => selectedNode(e)}
                                                                selectednodesbybtn={(e)=>selectedNodesByBtn(e)}
                                                                selectall={(e, name) => selectAllnodes(e, name)}
                                                                confignodes={props.configNodes}
                                                                submitdata={(e) => props.checkNodesCount(e)}
                                                            // addNewConfig={(e)=>addNewConfig(e)}
                                                            />
                                                            <NodeTimeChart
                                                                chartModal={chartModal}
                                                                nodetimedata={nodeTimeData}
                                                                previous={() => prevNodeIndex()}
                                                                next={() => nextNodeIndex()}
                                                                closeChartModal={() => { setChartModal(false); setChartIndex(0) }}
                                                            />
                                                            <div className="row">
                                                                <div className="col-md-6 col-sm-6 col-lg-6">
                                                                    <div style={{ marginTop: "0.8rem" }}>
                                                                        <Button className="btnDefault btnSpace" onClick={(e) => currentNodeAvailability()}>
                                                                            Check Node Availability
                                                                    </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2 col-sm-2 col-lg-2">

                                                                </div>
                                                                <div className="col-md-4 col-sm-4 col-lg-4">
                                                                    <div className="create-exp-footer" style={{ justifyContent: "flex-end" }}>
                                                                        <Button className="btnNormal btnSpace" onClick={(e) => configFirstModal(e)}>
                                                                            Back
                                                                        </Button>
                                                                        <Button className="btnDefault" onClick={(e) => addNewConfig(e)}>
                                                                            ADD
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </>
                                                :
                                                null
                                            }
                                        </div>

                                        <p className="text-center">{nestedModalPageNo} of 2</p>
                                    </div>
                                </>
                            ) : null}

                            {/* Add config second step Ends*/}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


            {/*Code to show update config Modal*/}
            <Modal
                show={updateNestedModal}
                onHide={() => closeUpdateNestedModal()}
                animation={false}
                centered
                backdrop="static"
                keyboard={false}
                size={updateNestedLgModal}
                className="multiconfigModal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="create-configuration-title">Update Configuration (Step {nestedModalPageNo} of 2)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">

                            {/* Update config first step Starts*/}
                            {updateconfigStep == 1 ? (
                                <>
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <div style={{ width: "100%" }}>
                                            <p className="create-exp-sub-header">A configuration must be added to assign project files and<br />select nodes before running your experiment.</p>
                                            <div style={{ color: 'red'}}>
                                                {error}
                                            </div>
                                            <Form>
                                                <Form.Group>
                                                    <Form.Label>Configuration Name</Form.Label>
                                                    <Form.Control type="text" name="configuration name" className={expConfigNameStatus} value={getSingleConfig.name} placeholder="Configuration 1" onChange={(e) => 
                                                        {
                                                            const value = e.target.value;
                                                            const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                                                            if (!textFieldRegex.test(value)) {
                                                                setError(e.target.name + ' is invalid');
                                                                setIsValid(false);
                                                            }else{
                                                                setError('');
                                                                setIsValid(true);
                                                            }
                                                            updateconfigName(e)}
                                                        }
                                                         />
                                                </Form.Group>
                                            </Form>
                                            <Form className="defaultImageForm">
                                                <Row>
                                                    <Col>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="SERIALLOG"
                                                            label="Serial Log"
                                                            checked = {storeupdateDefaultImage.serialLog}
                                                            className="defaultImage"
                                                            onChange={(e) => updatedefaultImageCB(e)}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="POWERPROFILE"
                                                            label="Power Profile"
                                                            checked = {storeupdateDefaultImage.powerProfile}
                                                            className="defaultImage"
                                                            onChange={(e) => updatedefaultImageCB(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <label ><b>Files</b></label>
                                            <div style={{ margin: "0 auto" }}>
                                                <Accordion defaultActiveKey="0">
                                                    <Card className={expImageStatus} >
                                                        <Accordion.Toggle as={Card.Header} eventKey="0" className="accodianHeader">
                                                            SELECT IMAGE
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="0">
                                                            <Card.Body>
                                                                {props.images.length > 0 ? (
                                                                    <>
                                                                        {props.images.map((item, index) => (
                                                                            <>
                                                                                {item.artifacts.map((version, i) => (
                                                                                    <ImgRadioButtons
                                                                                        value={updateImgRadioState}
                                                                                        name={item.name}
                                                                                        version={version.tag}
                                                                                        imagetype={version.type}
                                                                                        disabled={version.disabled}
                                                                                        event={(e) => selectUpdateImage(e.target)}
                                                                                    />
                                                                                )
                                                                                )
                                                                                }
                                                                            </>
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>No files Found</p>
                                                                    </>
                                                                )}
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                    <Card className={expBinaryStatus}>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1" className="accodianHeader">
                                                            SELECT BINARY
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </Accordion.Toggle>
                                                        <Accordion.Collapse eventKey="1">
                                                            <Card.Body>
                                                                {props.binaries.length > 0 ? (
                                                                    <>
                                                                        {props.binaries.map((item, index) => (
                                                                            <BinRadioButtons
                                                                                binradiostate={updatebinRadioState}
                                                                                num={item.name}
                                                                                name={item.name}
                                                                                binarytype={item.type}
                                                                                disabled={item.disabled}
                                                                                event={(e) => selectUpdateBinary(e.target)}
                                                                            />
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>No files Found</p>
                                                                    </>
                                                                )}
                                                            </Card.Body>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                </Accordion>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="create-exp-footer">
                                            <Button className="btnNormal create-exp-btn btnSpace" onClick={() => closeUpdateNestedModal()}>
                                                Cancel
                                            </Button>
                                            <Button disabled={!isValid} className="btnDefault create-exp-btn" onClick={() => updateconfigSecondModal()}>
                                                Next
                                            </Button>
                                        </div>
                                        <p className="text-center">{nestedModalPageNo} of 2</p>
                                    </div>
                                </>
                            ) : null}

                            {/* Update config first step Ends*/}

                            {/* Update config second step Starts*/}
                            {updateconfigStep == 2 ? (
                                <>
                                    <div className="col-md-12 col-sm-12 col-lg-12">
                                        <p className="create-exp-sub-header">A configuration must be added to assign project files and select nodes before running your experiment.</p>

                                        {nodeSelectCheck === true ? <h5 style={{ color: 'red' }}>Select Atleast One Node</h5> : <label>Select Nodes</label>}
                                        <div>
                                            {expNodeView == true ?
                                                <>
                                                    {Object.keys(experimentNodes).length > 0 ? (
                                                        <>
                                                            <CreateExpTable
                                                                // back={() => updateConfigFirstModal()}
                                                                nodelist={experimentNodes}
                                                                selectednodes={(e) => selectedUpdateNode(e)}
                                                                selectall={(e) => selectAllnodes(e)}
                                                                confignodes={props.configNodes}
                                                                submitdata={(e) => props.checkNodesCount(e)}
                                                            // addNewConfig={(e)=>updateExistingConfig(e)}
                                                            />
                                                            <div className="create-exp-footer" style={{ justifyContent: "flex-end" }}>
                                                                <Button className="btnNormal btnSpace" onClick={(e) => updateConfigFirstModal(e)}>
                                                                    Back
                                                            </Button>
                                                                <Button className="btnDefault" onClick={() => updateExistingConfig(configIndex)}>
                                                                    UPDATE
                                                            </Button>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                </>
                                                :
                                                null
                                            }
                                        </div>

                                        <p className="text-center">{nestedModalPageNo} of 2</p>
                                    </div>
                                </>
                            ) : null}
                            {/* Update config second step Ends*/}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MultipleConfigurations;
