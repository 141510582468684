import React, {useEffect, useState,useContext} from 'react';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context'

function SimulatorMode(props) {
    // const [url , setUrl] = useState(props.url)
    const [experimentResult, setExperimentResult] = useState('')
    const { state, actions } = useContext(Context);
    const axiosInstance = useAxios();

    useEffect(() => {
        // convertUrl(url)
        fetchExperimentResult()
    },[axiosInstance])

    // useEffect(() => {
    //     // convertUrl(url)
    //     console.log(experimentResult)
    //     console.log(props.mode)
    // },[experimentResult])

    const fetchExperimentResult = () => {
        // console.log(props.expid)
        if (axiosInstance) {
			// actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${props.expid}/result`)
				.then((response) => {
					setExperimentResult(response.data)
					// actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.then(
					setTimeout(() => {
						// fetchImages()
					}, 2000)
				)
				.catch((err) => {
					// console.log(err);
                    // setExperimentResult(
                    //     {
                    //         "simulationResultUrl": "sampleSimulationUrl",
                    //         "robotArena": {
                    //             "resultVideo1Url": "samplerobotVideo1",
                    //             "resultVideo2Url": "samplerobotVideo2"
                    //         }
                    //     })
                    // actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

		}
    }

    return (
        <>
        {props.mode == 'simulation' ? (
            <>
            	<h5 className="create-proj-text-color">Simulator</h5>
                {/* <iframe src={`${process.env.REACT_APP_GRAFANA_URL}/${experimentResult.simulationResultUrl}`} width="100%" height="500" frameBorder="0"></iframe> */}
		{props.status == 'Running' ? (
                <iframe src={`${experimentResult.resultUrl1}`} width="100%" height="500"></iframe>
		 ): (
                                <>
                                    <h6 className="create-proj-text-color">
                                    <label style={{ paddingTop: "0.8rem" }}><b>Result UI not available for {props.status.toLowerCase()} experiment </b></label></h6>
                                </>
                            )}
	
            </>
        ) : (
            <>
                <h5 className="create-proj-text-color">No Simulator Found</h5>
            </>
        )}
            
        </>
    )
}

export default SimulatorMode
