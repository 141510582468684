import React, {useState,useContext} from 'react';
import './Layout.css';

import Context from '../../store/Context'

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader, Types } from 'react-loaders';

import 'loaders.css/loaders.min.css';
import NewSideBar from '../Sidebar/NewSideBar';

function Layout ({children}, props){

	const [apiLoader , setApiLoader] = useState({loaderType: 'ball-triangle-path'})
	
	// console.log(props)
	const {state , actions} = useContext(Context);

	return(
		// <BlockUi tag="div" blocking={state.blocking} loader={<Loader active type={apiLoader.loaderType} color="#02a17c" />}
		<BlockUi tag="div" message="Loading" blocking={state.blocking}>
			<NewSideBar children={children} />			
		</BlockUi>
	)
}

export default Layout; 