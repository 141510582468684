import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import { useAxios } from '../../../utils/Axios';
import deleteIcon from '../../../assests/icons/delete.svg';
import './LoraOrgApiKeyTable.css';

function LoraOrgApiKeyTable({ data, status, networks }) {
	const axiosInstance = useAxios();

	// useEffect(() => {
	// 	console.log(data);
	// },[data])
	//Code to delete the Org Key
	function deleteKey(id) {
		const reqId = id.getAttribute('name');
		// console.log(reqId);
		swal({
			title: 'Are you sure?',
			text: 'API Key will be deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_LORA_URL}/internal/api-keys/${reqId}`)
						.then((response) => {
							PopUp("Key Deleted Successfully")
							status(true)
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});


	}

	return (
		<>
			<Table responsive borderless className="projectTableData">
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					{data.map((item) => (
						<tr key={item.id} >
							<td>
								{item.id}
							</td>
							<td>{item.name}</td>
							<td>
								<img src={deleteIcon} className="iconCustom" name={item.id} onClick={(e) => deleteKey(e.target)} />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default LoraOrgApiKeyTable;
