import React, { Component, useEffect } from "react";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Play from '@mui/icons-material/PlayCircleOutline';
import Pause from '@mui/icons-material/PauseCircleOutline';
import Download from '@mui/icons-material/CloudDownloadOutlined';
import Delete from '@mui/icons-material/DeleteOutline';
import HelpCircleOutline from '@mui/icons-material/HelpOutline';
import AlertCircleOutline from '@mui/icons-material/WarningOutlined';

import fileDownload from "js-file-download";
import moment from "moment";

import LoRaWANFrameLog from "./LoRaWANFrameLog";
import DeviceStore from "../../../../store/DeviceStore";
import theme from "../../../../theme";
import ErrorPopUp from '../../../../components/UIElements/ErrorPopup';
import axios from 'axios';

class DeviceFrames extends Component {

  constructor(props) {
    super(props);

    this.state = {
      paused: false,
      connected: false,
      frames: [],
      dialogOpen: false,
    };

    this.setConnected = this.setConnected.bind(this);
    this.onFrame = this.onFrame.bind(this);
    this.onDownload = this.onDownload.bind(this);
    this.togglePause = this.togglePause.bind(this);
    this.onClear = this.onClear.bind(this);
    this.toggleHelpDialog = this.toggleHelpDialog.bind(this);
  }

  componentDidMount() {
    let usertoken = localStorage.getItem('usertoken');
    let AuthStr = 'Bearer ' + usertoken;
    axios
      .get(`${process.env.REACT_APP_LORA_URL}/getChirpstackToken`, { 'headers': { 'Authorization': AuthStr } })
      .then((response) => {
        const conn = DeviceStore.getFrameLogsConnection(this.props.match.params.id, this.onFrame, response.data.chirpstackToken);
        this.setState({
          wsConn: conn,
        });

        DeviceStore.on("ws.status.change", this.setConnected);
        this.setConnected();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.detail !== undefined || err.response.data.detail !== null) {
            ErrorPopUp(err.response.data.detail)
          } else {
            ErrorPopUp("Error while getting chirpstack token");
          }
        }
      });
  }

  componentWillUnmount() {
    this.state.wsConn.close();

    DeviceStore.removeListener("ws.status.change", this.setConnected);
  }

  onDownload() {
    const dl = this.state.frames.map((frame, i) => {
      return {
        rxInfo: frame.rxInfo,
        txInfo: frame.txInfo,
        phyPayload: frame.phyPayload,
      };
    });

    fileDownload(JSON.stringify(dl, null, 4), `lorawan-frame-logs.json`);
  }

  togglePause() {
    this.setState({
      paused: !this.state.paused,
    });
  }

  toggleHelpDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  }

  onClear() {
    this.setState({
      frames: [],
    });
  }

  setConnected() {
    this.setState({
      connected: DeviceStore.getWSFramesStatus(),
    });
  }

  onFrame(frame) {
    if (this.state.paused) {
      return;
    }

    let frames = this.state.frames;
    let f = {};
    const now = new Date();

    if (frame.uplinkFrame !== undefined) {
      f = {
        id: now.getTime(),
        publishedAt: frame.uplinkFrame.publishedAt,
        rxInfo: frame.uplinkFrame.rxInfo,
        txInfo: frame.uplinkFrame.txInfo,
        phyPayload: JSON.parse(frame.uplinkFrame.phyPayloadJSON),
      };
    }

    if (frame.downlinkFrame !== undefined) {
      delete frame.downlinkFrame.txInfo['gatewayID'];

      f = {
        id: now.getTime(),
        publishedAt: frame.downlinkFrame.publishedAt,
        gatewayID: frame.downlinkFrame.gatewayID,
        txInfo: frame.downlinkFrame.txInfo,
        phyPayload: JSON.parse(frame.downlinkFrame.phyPayloadJSON),
      };
    }

    if (frames.length === 0 || moment(f.publishedAt).isAfter(moment(frames[0].publishedAt))) {
      frames.unshift(f);
      this.setState({
        frames: frames,
      });
    }
  }

  render() {
    const frames = this.state.frames.map((frame, i) => <LoRaWANFrameLog key={frame.id} frame={frame} />);

    return (
      <Grid container spacing={4}>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.toggleHelpDialog}
          aria-labelledby="help-dialog-title"
          aria-describedby="help-dialog-description"
        >
          <DialogTitle id="help-dialog-title">Help</DialogTitle>
          <DialogContent>
            <DialogContentText id="help-dialog-description">
              The frames below are the raw (and encrypted) LoRaWAN PHYPayload frames as seen by the gateway(s). This data is intedend for debugging only.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleHelpDialog} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <Grid container justify="flex-end" spacing={2} sx={{ mt: 3, ml: 100 }}>
          <Grid item>
            <Button variant="outlined" color="info"  onClick={this.toggleHelpDialog}>
              <HelpCircleOutline color="#1c7b8e"/>
              Help
            </Button>
          </Grid>
          <Grid item>
            {!this.state.paused && (
              <Button variant="outlined" color="secondary"   onClick={this.togglePause}>
                <Pause />
                Pause
              </Button>
            )}

            {this.state.paused && (
              <Button variant="outlined" color="secondary" onClick={this.togglePause}>
                <Play />
                Resume
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button variant="outlined" color="success" onClick={this.onDownload}>
              <Download />
              Download
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="error" onClick={this.onClear}>
              <Delete />
              Clear
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {!this.state.connected && <div sx={{ textAlign: 'center' }}>
            <Chip sx={{ml:3, mb:3}}
              color="secondary"
              label="Not connected to Websocket API"
              avatar={<Avatar><AlertCircleOutline /></Avatar>}
            />
          </div>}
          {(this.state.connected && frames.length === 0 && !this.state.paused) && <div sx={{ textAlign: 'center' }}><CircularProgress sx={{ marginTop: 4 * theme.spacing.unit }} /></div>}
          {frames.length > 0 && frames}
        </Grid>
      </Grid>
    );
  }
}

export default DeviceFrames;
