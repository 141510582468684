import React, { useState, useEffect, useContext } from 'react';
import './CreateServiceProfile.css';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';

function CreateServiceProfile({ status, networks }) {
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [open, setOpen] = useState(false);
    const [checkDevStatusReq , setCheckDevStatusReq] = useState(0)
    const [checkGwMeta, setCheckGwMeta] = useState(false);
    const [nwGeoLoc, setNwGeoLoc] = useState(false);
    const [devStatusBattery, setDevStatusBattery] = useState(false);
    const [devStatusMargin, setDevStatusMargin] = useState(false);
    const [serviceProfile, setServiceProfile] = useState({});
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    // useEffect(() => {
    //     console.log(serviceProfile)
    // }, [serviceProfile])

    //Code to store user inputs
    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setServiceProfile({ ...serviceProfile, [name]: value });
    };

    //Code to store device status request input and enable and disable checkboxes
    const changeDeviceStatusReq = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if(value > 0){
            setCheckDevStatusReq(1)
        }else{
            setCheckDevStatusReq(0)
        }
        setServiceProfile({ ...serviceProfile, [name]: value });
    }

    //Code to check/Uncheck and store checkbox data
    function changeCheckbox(e) {
        let check = e.target.checked;
        let name = e.target.getAttribute('name');
        // console.log(check)

        if (name == 'addGWMetaData') {
            if (check == true) {
                setCheckGwMeta(!checkGwMeta)
                setServiceProfile({ ...serviceProfile, [name]: !checkGwMeta });

            } else {
                setCheckGwMeta(!checkGwMeta);
                setServiceProfile({ ...serviceProfile, [name]: !checkGwMeta });
            }
        }

        if (name == 'nwkGeoLoc') {
            if (check == true) {
                setNwGeoLoc(!nwGeoLoc)
                setServiceProfile({ ...serviceProfile, [name]: !nwGeoLoc });

            } else {
                setNwGeoLoc(!checkGwMeta);
                setServiceProfile({ ...serviceProfile, [name]: !nwGeoLoc });
            }
        }

        if (name == 'reportDevStatusBattery') {
            if (check == true) {
                setDevStatusBattery(!devStatusBattery)
                setServiceProfile({ ...serviceProfile, [name]: !devStatusBattery });

            } else {
                setNwGeoLoc(!devStatusBattery);
                setServiceProfile({ ...serviceProfile, [name]: !devStatusBattery });
            }
        }

        if (name == 'reportDevStatusMargin') {
            if (check == true) {
                setDevStatusMargin(!devStatusMargin)
                setServiceProfile({ ...serviceProfile, [name]: !devStatusMargin });

            } else {
                setDevStatusMargin(!devStatusMargin);
                setServiceProfile({ ...serviceProfile, [name]: !devStatusBattery });
            }
        }

    }

    //Code to send request to create app
    const handleSubmit = (event) => {
        event.preventDefault();
        let orgid = localStorage.getItem('orgid');
        let newserviceData = { "serviceProfile": { ...serviceProfile, "organizationID": orgid} }
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/service-profiles`, newserviceData)
                .then((response) => {
                    setOpen(false);
                    PopUp("Service Profile Created Successfully")
                    status(true);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopUp("Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    };

    return (
        <div>
            <Button className="btnDefault" style={{ margin: '2%' }} onClick={() => setOpen(true)}>
                + Create Service Profile
			</Button>

            <Modal
                show={open}
                onHide={() => setOpen(false)}
                animation={false}
                centered
                keyboard={false}
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>New Service Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{ color: 'red'}}>
                        {error}
                    </div>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                onChange={changeHandler}
                                type="text"
                                placeholder="service profile name"
                                autoComplete="off"
                                required
                            />
                            <Form.Text className="text-muted">
                                A name to identify the service-profile.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Network-Server</Form.Label>
                            <Form.Control
                                as="select"
                                name="networkServerID"
                                onChange={changeHandler}
                                required
                                custom
                            >
                                {
                                    networks.length > 0 ? (
                                        <>
                                            <option value="">Select Network</option>
                                            {networks.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                );
                                            })}
                                        </>
                                    ) : (
                                            <>
                                                <option value="">No Networks Found</option>
                                            </>
                                        )
                                }
                            </Form.Control>
                            <Form.Text className="text-muted">
                                The network-server on which this service-profile will be provisioned. After creating the service-profile, this value can't be changed.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="Add gateway meta-data" name="addGWMetaData" onChange={(e) => changeCheckbox(e)} />
                            <Form.Text className="text-muted">
                                GW metadata (RSSI, SNR, GW geoloc., etc.) are added to the packet sent to the application-server.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" label="Enable network geolocation" name="nwkGeoLoc" onChange={(e) => changeCheckbox(e)} />
                            <Form.Text className="text-muted">
                                When enabled, the network-server will try to resolve the location of the devices under this service-profile. Please note that you need to have gateways supporting the fine-timestamp feature and that the network-server needs to be configured in order to provide geolocation support.
                        </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Device-status request frequency</Form.Label>
                            <Form.Control
                                name="devStatusReqFreq"
                                onChange={changeDeviceStatusReq}
                                type="number"
                                placeholder="Device-status request frequency"
                                autoComplete="off"
                            />
                            <Form.Text className="text-muted">
                            Frequency to initiate an End-Device status request (request/day). Set to 0 to disable.
                            </Form.Text>
                        </Form.Group>
                        {checkDevStatusReq > 0 ? (
                            <>
                                <Form.Group>
                                    <Form.Check type="checkbox" label="Report device battery level to application-server" name="reportDevStatusBattery" onChange={(e) => changeCheckbox(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check type="checkbox" label="Report device link margin to application-server" name="reportDevStatusMargin" onChange={(e) => changeCheckbox(e)} />
                                </Form.Group>
                            </>
                        ) : null}
                        <Form.Group>
                            <Form.Label>Minimum allowed data-rate</Form.Label>
                            <Form.Control
                                name="drMin"
                                onChange={changeHandler}
                                type="number"
                                placeholder="Minimum allowed data-rate"
                                autoComplete="off"
                                required
                            />
                            <Form.Text className="text-muted">
                            Minimum allowed data rate. Used for ADR.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Maximum allowed data-rate</Form.Label>
                            <Form.Control
                                name="drMax"
                                onChange={changeHandler}
                                type="number"
                                placeholder="Maximum allowed data-rate"
                                autoComplete="off"
                                required
                            />
                            <Form.Text className="text-muted">
                            Maximum allowed data rate. Used for ADR.
                            </Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className = "btnNormal"
                            variant="btnColor btn-primary-outline"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
						</Button>
                        <Button disabled={!isValid} className="btnDefault" type="submit">
                            Create Service-profile
						</Button>
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    );
}

export default CreateServiceProfile;
