
// // const __GAPI_KEY__ =  "AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo";
// const __GAPI_KEY__ = "AIzaSyAq-P3UaB54VwXF7vlvaNsOOjoSKvCpBr0"

import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import './NetworkMap.css'
// import activenode from '../../assests/activenode.png';
// import inactivenode from '../../assests/inactivenode.png';
import activenode from '../../assests/bluemarker.png';
import inactivenode from '../../assests/redmarker.png'
import activeNodeSelected from '../../assests/bluemarkerSelected.png';
import inactiveNodeSelected from '../../assests/redmarkerSelected.png'
import nodemodel from '../../assests/nodemodel.png'

// We will use these things from the lib
// https://react-google-maps-api-docs.netlify.com/
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer
} from "@react-google-maps/api";

function MapContainer(props) {
  // const [locations, setLocations] = useState([{"hostName":"dummyNode","nodeType":"RSS","friendlyName":"friendlYName","gpsHash":"S0000","status":"Active","lat":54.526,"lng":15.2551, "selected" : false},{"hostName":"umbrella-c4dc85d3","nodeType":"RSS","friendlyName":"Stellios","gpsHash":"s00000000000","status":"Inactive","lat":54.126,"lng":15.155, "selected" : true}]);
  const [locations, setLocations] = useState(props.nodelist.data.nodes)
  // const [locations, setLocations] = useState([])

  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [mapcenter, setCenter] = useState({ lat: 51.503051, lng: -2.515134 });
  // const [zoom, setZoom] = useState(9);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    // Google Maps API key
    // googleMapsApiKey: "AIzaSyAq-P3UaB54VwXF7vlvaNsOOjoSKv00000"
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_URL
  });

  // let mapcenter = { lat: 51.503051, lng: -2.515134 }

  // useEffect(()=>{
  //   console.log(locations);
  // },[locations])

  useEffect(() => {
    // console.log(loadError)
  }, [loadError])

  // Map Night Version Styling code
  const styles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }]
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }]
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }]
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }]
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }]
    }
  ]

  const activeMarker = {
    // path: "M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z",
    url: activenode,
  }

  const inactiveMarker = {
    url: inactivenode,
  }

  const activeSelectedMarker = {
    url: activeNodeSelected,
  }

  const inactiveSelectedMarker = {
    url: inactiveNodeSelected,
  }

  // const showInfoWindows = () => {
  //   return (
  //     <InfoWindow
  //       position={{ lng: parseFloat(selectedMarker.longitude), lat: parseFloat(selectedMarker.latitude + 0.00007) }}
  //       onCloseClick={ () => setSelectedMarker(null) }
  //       onUnmount={ () => setSelectedMarker(null) }
  //     >
  //       <div>
  //         <p>{ parseFloat(selectedMarker.longitude) } { selectedMarker.longitude }</p>
  //         { selectedMarker.uid }
  //       </div>
  //     </InfoWindow>
  //   )
  // }

  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();
    locations.map(place => {
      bounds.extend({ lat: place.lat, lng: place.lng });
      return place.hostName;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = map => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.hostName]: marker };
    });
  };

  const onCenterChanged = mapRef => {
    if (mapRef && mapRef.getCenter()) {
      const ccenter = mapRef.getCenter().toJSON();
      if (ccenter.lat !== mapcenter.lat && ccenter.lng !== mapcenter.lng) {
        // setCenter(mapRef.getCenter().toJSON());
        setCenter({ lat: 51.503051, lng: -2.515134 });
      }
    }
    // setCenter({ lat: 51.503051, lng: -2.515134 });
  };

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    // if (zoom < 13) {
    //   setZoom(13);
    // }

    // if you want to center the selected Marker
    // setCenter(place.pos);

    // console.log(place)
  };

  // console.log(props.nodelist.data.nodes)
  // const latlon = Geohash.decode('s00000000000')
  // console.log(JSON.stringify(latlon))


  // Code to get Node detailed Information
  const nodeDetailedView = (e) => {
    let nodename = e.target.getAttribute('nodename');
    // console.log(nodename)
    props.detailedview(nodename)

  }

  function getMarker(data) {
    if (data.status === 'Active' && data.selected == true) {
      return activeNodeSelected;
    } else if (data.status === 'Active' && data.selected == false) {
      return activeMarker;
    } else if (data.status === 'InActive' && data.selected == true) {
      return inactiveNodeSelected;
    } else if (data.status === 'InActive' && data.selected == false) {
      return inactiveMarker;
    } else {
      // console.log("No node found")
    }
  }

  // console.log(props)
  const renderMap = () => {
    return (
      <>
        <GoogleMap
          id="maps"
          // onLoad={loadHandler}
          onUnmount={() => console.log()}
          onCenterChanged={() => onCenterChanged(mapRef)}
          onClick={e => setClickedLatLng(e.latLng.toJSON())}
          center={mapcenter}
          zoom={14}
          options={{
            styles: styles,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            panControl: true,
            disableAutoPan : true,
          }}
        >
          {/* <MarkerClusterer>
            {clusterer =>
              locations.map(location => (
                <Marker
                  key={location.hostName}
                  position = {{
                      lng: location.lng,
                      lat: location.lat
                  }}
                  clusterer={clusterer}
                  onLoad={marker => markerLoadHandler(marker, location)}
                  onMouseOver={event => markerClickHandler(event, location)}
                  // options={{styles: location.status == 'Active' ? activeMarker : inactiveMarker}}
                  // icon={
                  //   location.status == 'Active' ? activeMarker : inactiveMarker
                  // }

                  icon = {
                    getMarker(location)
                  }
                />
              ))
            }
          </MarkerClusterer> */}
          {locations.map(location => (
            <Marker
              key={location.friendlyName}
              position={{
                lng: location.lng,
                lat: location.lat
              }}
              options={{
                disableAutoPan: true
              }}
              onLoad={marker => markerLoadHandler(marker, location)}
              onMouseOver={event => markerClickHandler(event, location)}
              icon={
                getMarker(location)
              }
            ></Marker>
          ))
          }
          {infoOpen && selectedPlace && (
            <InfoWindow
              anchor={markerMap[selectedPlace.hostName]}
              options={{
                disableAutoPan: true
              }}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div style={{ textAlign: 'center' }}>
                <h5>{selectedPlace.hostName}</h5>
                <br />
                <div className="miniNode">
                  <p><b>Host Name : </b></p>
                  <span>{selectedPlace.hostName}</span>
                  <p><b>Name : </b></p>
                  <span>{selectedPlace.friendlyName}</span>

                  <p><b>Type : </b></p>
                  <span>{selectedPlace.nodeType}</span>

                  <p><b>Status : </b></p>
                  <span>{selectedPlace.status}</span>
                </div>
                <div>
                  <Image src={nodemodel} width="40%" />
                </div>
                <br />
                <div className="upload-btn-wrapper">
                  <button className="btn" nodename={selectedPlace.hostName} onClick={(event) => nodeDetailedView(event)}>Detailed View</button>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return (
    <div>{isLoaded ? renderMap() : <><p>Map cannot be loaded</p></>}</div>
  );
}

export default MapContainer;