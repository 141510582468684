import React, { useState, useEffect, useContext } from 'react';
import LoraLayout from '../../../../../components/LoraNetworks/LoraLayout/LoraLayout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, Zoom } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PopUp from '../../../../../components/UIElements/PopUp'
import ErrorPopUp from '../../../../../components/UIElements/ErrorPopup'
import { useAxios } from '../../../../../utils/Axios';
import Context from '../../../../../store/Context';
import { useHistory } from 'react-router-dom';

function HTTPCreate(props) {
    const [httpData, setHTTPData] = useState({
        "marshaler": "",
        "eventEndpointURL": "",
        "applicationID": props.match.params.id
    })
    const [headerRow, setHeaderRow] = useState([])
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const history = useHistory();
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

     //code to add new header row
     function addRowHeader(input) {
        let newRow = {}
        setHeaderRow([...headerRow, newRow])
    }

    //Code to store header row data
    const changeHeaderName = (idx) => (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const textFieldRegex = /^[A-Za-z0-9._ \-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}

        let newArr = [...headerRow]; // copying the old datas array
        newArr[idx][name] = value;
        setHeaderRow(newArr)
    } 

    //Code to update Org Name and Disaply Name
    function changeHandler(e) {
        const name = e.target.name;
        const value = e.target.value;

        const textFieldRegex = /^[A-Za-z0-9://.\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setHTTPData({ ...httpData, [name]: value });
    }

    //code to filter headers
    function filterHeaders() {
       let array = []
        let olddata = [...headerRow];
        for(let i=0;i<olddata.length;i++){
            let obj = {}
            if(olddata[i].key !== undefined && olddata[i].value !== undefined){
                obj['key'] = olddata[i].key
                obj['value'] = olddata[i].value
                array.push(obj)
            }
        }
       return array;
    }

    //Code to submit adding HTTP Integration Request
    const submitHTTP = (e) => {
        e.preventDefault();
        let data = {"integration": { ...httpData, headers: filterHeaders() }};
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}/integrations/http`, data)
                .then((response) => {
                    // console.log(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    PopUp("HTTP Integrated successfuly");
                    setTimeout(() => {
                        history.push(`/lora/applications/${props.match.params.id}`);     
                    }, 1000);
                   
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    ErrorPopUp("Request Failed. Try Again")
                    // history.push(`/lora/applications/${props.match.params.id}`);
                });

        }
    }

    return (
        <>
            <LoraLayout>
            <div style={{ color: 'red'}}>
                {error}
            </div>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Add HTTP integration</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Row>
                            <Col md={11} className="mapCol">
                                <Form onSubmit={submitHTTP}>
                                <Form.Group>
                                        <Form.Label>Payload marshaler</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={changeHandler}
                                            name="marshaler"
                                            required
                                            custom
                                        >
                                            <option value="">Select payload marshaler</option>
                                            <option value="JSON">JSON</option>
                                            <option value="PROTOBUF">Protocol Buffers</option>
                                            <option value="JSON_V3">JSON (legacy, will be deprecated)</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Headers</Form.Label>
                                    </Form.Group>
                                    <table>
                                        <tbody>
                                            {headerRow.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6">
                                                                <Form.Control type="text" name="key" placeholder="key" onChange={changeHeaderName(index)} autoComplete="off" />
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6">
                                                                <Form.Control type="text" name="value" placeholder="value" onChange={changeHeaderName(index)} autoComplete="off" />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <Button onClick={(e) => addRowHeader(e)} className="btnNormal">
                                        ADD HEADER
                                    </Button>
                                    <Form.Group controlId="projectName">
                                        <Form.Label>Endpoints</Form.Label>
                                        <Form.Control
                                            name="eventEndpointURL"
                                            onChange={changeHandler}
                                            type="text"
                                            placeholder="Endpoint URL(s) for events"
                                            autoComplete="off"
                                            required
                                        />
                                    </Form.Group>
                                    <div className="text-right">
                                        <Button disabled={!isValid} className="btnDefault" type="submit">
                                            SUBMIT
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default HTTPCreate;
