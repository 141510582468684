import React, { useState, useEffect, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import PopUp from '../../../components/UIElements/PopUp';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import ErrorPopUp from '../../../components/UIElements/ErrorPopup';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactPaginate from 'react-paginate';

function AdminOverrideRequest() {
    const [open, setOpen] = useState(false);
    const [pendingList, setPendingList] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [approvedId, setApproveId] = useState([]);
    const [apporved, setApproved] = useState({
        "severity": " ",
        "status": "APPROVED"
    });
    const axiosInstance = useAxios();


    const { state, actions } = useContext(Context);
    let data = "";


    useEffect(() => {
        fetchApprovalRequest();
    }, [axiosInstance])



    function denyNewRequest(e) {
        console.log(e.reqSeverity);

        let data = {
            "severity": e.reqSeverity,
            "status": 'REJECTED'
        }

        swal({

            title: "Are you sure?",
            text: "This Request Will be Denied",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"

        }).then((willRequest) => {
            if (willRequest) {
                actions({ type: 'setState', payload: { ...state, blocking: true } })
                axiosInstance
                    .put(`${process.env.REACT_APP_PROJECT_URL}/approval/severity/admin/${e.id}`, data)
                    .then((response) => {
                        // actions({type : 'setState', payload : {...state , blocking : false}});
                        let input = response.data.status;
                        PopUp("Rejected Sucessfully");


                        fetchApprovalRequest();

                    })
                    .catch((err) => {
                        actions({ type: 'setState', payload: { ...state, blocking: false } });
                        if (err.response) {
                            ErrorPopUp(err.response.data.detail)
                        } else {
                            ErrorPopUp("Request Failed. Try Again")
                        }
                        fetchApprovalRequest();
                    });
            }
        });

    }

    function fetchApprovalRequest() {
        if (axiosInstance) {
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/approval/view/all`)
                .then((response) => {
                    setPendingList(response.data.filter((item) => item.status === 'PENDING'));
                    setHistoryList(response.data.filter((item) => item.status !== 'PENDING'));
                })
                .catch((err) => {
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    if (err.response) {
                        ErrorPopUp(err.response.data.detail)
                    } else {
                        ErrorPopUp("Request Failed. Try Again")
                    }
                });
        }

    }
    function requestcall(e) {
        setOpen(true);
        console.log(e);
        setApproveId(e);
    }


    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setApproved({ ...apporved, [name]: value })
    };

    
    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 5;
    const pageCount = Math.ceil(historyList.length / recordsPerPage);
    const pageRangeDisplayed = 5;

    const onPageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const start = currentPage * recordsPerPage;
    const end = start + recordsPerPage;
    const currentData = historyList.slice(start, end);

    function submitRequest(e) {
        console.log(e);
        e.preventDefault();
        if (axiosInstance) {
            axiosInstance
                .put(`${process.env.REACT_APP_PROJECT_URL}/approval/severity/admin/${approvedId}`, apporved)
                .then((response) => {
                    setOpen(false);
                    PopUp("Approved Sucessfully");
                    fetchApprovalRequest();

                })
                .catch((err) => {
                    setOpen(false);

                    if (err.response) {
                        ErrorPopUp(err.response.data.detail)
                    } else {
                        ErrorPopUp("Request Failed. Try Again");
                    }
                    fetchApprovalRequest();
                });
        }
    }

    const [tabValue, setTabValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    return (
        <>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Pending Requests" value="1" />
                            <Tab label="History Requests" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Table responsive bordered className="projectTableData">
                            <thead>
                                <th>Project Id</th>
                                <th>File Path</th>
                                <th>Requested Severity</th>
                                <th>Reason</th>
                                <th>Created On</th>
                                <th>Owner Name</th>
                                <th>Test Bed Type</th>
                                <th>Status</th>
                                <th><center>Approval</center></th>
                            </thead>
                            <tbody>
                                {pendingList.map((value, index) => (
                                    <tr data-index={index}>
                                        <td>{value.projectId}</td>
                                        <td>{value.filePath}</td>
                                        <td>{value.reqSeverity}</td>
                                        <td>{value.reason}</td>
                                        <td>{value.createdOn}</td>
                                        <td>{value.ownerName}</td>
                                        <td>{value.testBedType}</td>
                                        <td>{value.status}</td>
                                        <td>
                                            {
                                                <>
                                                    <button type="button" className="btn btn-sm btnDefault btnSpace" onClick={e => requestcall(value.id)}>
                                                        Approve
                                                    </button>

                                                    <button type="button" className="btn btn-sm btnNormal" onClick={e => denyNewRequest(value)} >
                                                        Deny
                                                    </button>
                                                </>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel value="2">
                    <div lassName="col-md-6">
                        <Form.Row>
                            <Table responsive borderless className="table-striped">
                                <thead>
                                    <tr className="table-primary">
                                        <th>Project Id</th>
                                        <th>File Path</th>
                                        <th>Requested Severity</th>
                                        <th>Reason</th>
                                        <th>Created On</th>
                                        <th>Owner Name</th>
                                        <th>TestBed Type</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((item, index) => (
                                        <tr key={index} >
                                            <td>
                                                {
                                                    item.projectId
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.filePath
                                                }

                                            </td>
                                            <td>
                                                {
                                                    item.reqSeverity
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.reason
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.createdOn
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.ownerName
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.testBedType
                                                }
                                            </td>
                                            <td>
                                                {
                                                    item.status
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>                       
                        </Form.Row>
                        <ReactPaginate
                            initialPage={0}
                            className= 'pagination'
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={onPageChange}
                            containerClassName={"pagination"}
                            previousClassName={"previous"}
                            nextClassName={"next"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            pageRangeDisplayed={pageRangeDisplayed}
                            marginPagesDisplayed={1}
                        />
                    </div>
                    </TabPanel>
                </TabContext>
            </Box>


            <Modal
                show={open}
                onHide={() => setOpen(false)}
                animation={false}
                centered
                keyboard={false}
            >
                <Form onSubmit={(e) => submitRequest(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Approve</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="Container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-lg-12">
                                    <label style={{ marginBottom: '2rem' }}>
                                        <b>Sevrity</b>
                                    </label>
                                    <Form.Group>
                                        <Form.Label>Severity</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="severity"
                                            onChange={changeHandler}
                                            custom
                                        >
                                            <option value=''>Select</option>
                                            <option value='Critical'>Critical</option>
                                            <option value='High'>High</option>
                                            <option value='Medium'>Medium</option>
                                            <option value='low'>Low</option>

                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="btnColor btn-primary-outline"
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button className="btnDefault" type="submit">
                            Approve
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

        </>

    )

}

export default AdminOverrideRequest;

