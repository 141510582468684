import React, { useState, useEffect, useContext } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import PopUp from '../../../components/UIElements/PopUp';
import ErrorPopUp from '../../../components/UIElements/ErrorPopup';
import { useAxios } from '../../../utils/Axios';
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout';
import FilesTable from '../../../components/RoboticsNetworks/Table/FilesTable';
import AddUpdateUsers from '../../../components/RoboticsNetworks/AddUpdateUsers/AddUpdateUsers';
import ImageUploader from '../../../components/RoboticsNetworks/UploadManager/ImageUploader.js';
import ConfigUploader from '../../../components/RoboticsNetworks/UploadManager/ConfigUploader';
import BinaryUploader from '../../../components/RoboticsNetworks/UploadManager/BinaryUploader';
import ExperimentTable from '../../../components/RoboticsNetworks/ExperimentTable/ExperimentTable';
import ExperimentArchiveTable from '../../../components/RoboticsNetworks/ExperimentTable/ExperimentArchiveTable';
import CreateExperiment from '../../../components/RoboticsNetworks/Createexperiment/CreateExperiment';
import Context from '../../../store/Context'
import { ToastContainer, Zoom } from 'react-toastify';

import { emphasize, styled } from '@mui/material/styles';
import { Box, Breadcrumbs, Chip, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FolderIcon from '@mui/icons-material/Folder';
import { Link } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import { TabContext, TabList, TabPanel } from '@mui/lab';


const RoboticsProjects = ({ match }) => {
	const [projectInfo, setProjectInfo] = useState([]);
	const [getImages, setImages] = useState([]);
	const [imgFileSeverity, setimgFileSeverity] = useState([]);
	const [controllerImages, setControllerImages] = useState([]);
	const [radioSimImages, setRadioSimImages] = useState([]);
	const [configFiles, setConfigFiles] = useState([]);
	const [binaryArenaFiles, setBinaryArenaFiles] = useState([]);
	const [configWordFiles, setConfigWordFiles] = useState([]);
	const [getConfigs, setConfigs] = useState([]);
	const [experiments, setExperiments] = useState([]);
	const [archiveExperiments, setArchiveExperiments] = useState([]);
	const [expNodes, setExpNodes] = useState([1]); // state to store experiment nodes list
	const [robotArenas, setRobotArenas] = useState([]);
	const [imageManager, setImageManager] = useState(false);
	const [configManager, setConfigManager] = useState(false);
	const [BinaryManger, setBinaryManger] = useState(false);
	const [projectRole , setProjectRole] = useState(''); // code to store projectRole and use it for validation
	const [severity,setSeverity] = useState(['']);
	const axiosInstance = useAxios();
	const { state, actions } = useContext(Context);

	const [value, setValue] = React.useState('1');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};


	useEffect(() => {
		setTimeout(() => {
			fetchProject();
		}, 200);

	}, [axiosInstance, match]);

	useEffect(() => {
			getSeverityBackend();
	}, [axiosInstance]);


	function getSeverityBackend(){
        if(axiosInstance){
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/approval/severity/${match.params.id}`)
                .then((response)=>{
					setSeverity(response.data.message);
                    console.log(response.data.message);
                }).catch((err)=>{
                    // actions({type : 'setState', payload : {...state , blocking : false}});
                if (err.response) {
					ErrorPopUp(err.response.data.detail)
                }else {
                    ErrorPopUp("Request Failed. Try Again")
                }
                });
        }       
    }

	// useEffect(() => {

	// 	console.log(getImages.length)

	// }, [getImages]);

	//Code to fetch Individual Project Details
	const fetchProject = () => {
		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/projects/${match.params.id}`)
				.then((response) => {
					setProjectInfo(response.data);
					// console.log(response.data)
					getArenaDetails(response.data)
					setProjectRole(response.data.projectRole);
					// setProjectRole('user');
					localStorage.setItem('projectrole' , response.data.projectRole)
					// actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.then(
					setTimeout(() => {
						fetchImages()
					}, 200)

				)
				.catch((err) => {
					// console.log(err);
					// setProjectInfo({ "projectId": "123", "projectName": "dummyproject", "projectDescription": "default", "creationTime": "2020-11-04T12:48:26Z", "updateTime": "2020-11-04T12:48:26Z", "ownerName": "narev090", "arenaName": "default", "status": "active" })
					// fetchNodes()
					fetchImages();
				});

		}
	};

	//code to fetch Images and storing in State
	const fetchImages = () => {
		if (axiosInstance)
			// actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/projects/${match.params.id}/images`)
				.then((response) => {
					setImages(response.data)
					//checkImageSeverity(response.data);
					chkImageType(response.data);
				})
				.then(
					setTimeout(() => {
						fetchConfigs()
					}, 200)
				)
				.catch((err) => {
					// console.log(err);
					// setImages([{ "name": "docker-java/load", "artifacts": [{ "tag": "1.0", "size": 1912, "type": "jetson-nano" }], "scanOverview": { "reportId": "385b02cf-996c-46c6-bf9f-45161f180359", "startTime": "2020-11-17T10:08:38.796Z", "endTime": "2020-11-17T10:08:41.997Z", "duration": 3, "completePercent": 100, "scanStatus": "Success", "severity": "None" } }, { "name": "hello-world", "artifacts": [{ "tag": "latest", "size": 4564, "type": "raspberry-pi" }], "scanOverview": { "reportId": "95b13add-8cca-4153-800e-ac7721adf780", "startTime": "2020-12-10T08:47:27.832Z", "endTime": "2020-12-10T08:47:33.550Z", "duration": 6, "completePercent": 100, "scanStatus": "Success", "severity": "None" } }])
					// checkImageSeverity([{ "name": "docker-java/load", "artifacts": [{ "tag": "1.0", "size": 1912, "type": "jetson-nano" }], "scanOverview": { "reportId": "385b02cf-996c-46c6-bf9f-45161f180359", "startTime": "2020-11-17T10:08:38.796Z", "endTime": "2020-11-17T10:08:41.997Z", "duration": 3, "completePercent": 100, "scanStatus": "Success", "severity": "None" } }, { "name": "hello-world", "artifacts": [{ "tag": "latest", "size": 4564, "type": "raspberry-pi" }], "scanOverview": { "reportId": "95b13add-8cca-4153-800e-ac7721adf780", "startTime": "2020-12-10T08:47:27.832Z", "endTime": "2020-12-10T08:47:33.550Z", "duration": 6, "completePercent": 100, "scanStatus": "Success", "severity": "None" } }]);
					fetchConfigs();
				});

	}

	//Code to fetch Binaries
	const fetchConfigs = () => {
		// alert()
		if (axiosInstance)
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/projects/${match.params.id}/binaries`)
				.then((response) => {
					// setConfigs(response.data);
					let data = response.data;
					filterConfigfiles(data)
					// console.log(projectInfo)
					setConfigs(response.data)
					fetchRoboticExperiments()
				})
				.catch((err) => {
					// console.log(err);
					// let data = [{ "name": "dummy.txt", "size": 0, "validation": false, "path": "binaries/dummy.txt", "type": "nrf52" }]
					// setConfigs(data)
					// filterConfigfiles(data)
					fetchRoboticExperiments()
				});

	}

	//Code to sort config files based on 
	function filterConfigfiles(data) {
		let controllerFileArray = [];
		let worldFileArray = [];
		let binaryFileArray = [];
		data.map((item) => {
			// console.log(item);
			if (item.type == "world_file") {
				worldFileArray.push(item)
			} 
			if(item.type == "configuration"){
				controllerFileArray.push(item)
			}
			if(item.type == "nrf52" || item.type == "cc1310"){
				binaryFileArray.push(item)
			}
		})
		setConfigFiles(controllerFileArray);
		setConfigWordFiles(worldFileArray);
		setBinaryArenaFiles(binaryFileArray);
	}

	//Code to fetch Robotic Experiments List
	const fetchRoboticExperiments = () => {
		if (axiosInstance)
			axiosInstance
				.get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/${match.params.id}/experiments`)
				.then((response) => {
					// setExperiments(response.data);

					experimentListSort(response.data);
					// console.log(response.data)
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err);
					// let data = [{"experimentId":"4a76-80e4-a277d9bbf655","projectId":"123","name":"exp1","description":"sampleExp1","binaryFiles":[{"path":"binarytest1.txt","type":"configuration"}],"imageFiles":[{"repoPath":"image1","tag":"v1","type":"controller"}],"experimentMode":"simulator","useDefaultNames":true,"schedule":{"startTime":"2020-12-29","duration":20},"status":"running","archived":false},{"experimentId":"4000-80e4-a277d9bbf655","projectId":"456","name":"exp2","description":"sampleExp2","binaryFiles":[{"path":"binarytest2.txt","type":"configuration"}],"imageFiles":[{"repoPath":"image2","tag":"v1","type":"controller"}],"experimentMode":"robot_arena","useDefaultNames":true,"schedule":{"startTime":"2020-12-28","duration":30},"status":"running","archived":true},{"experimentId":"56ha-80e4-a277d9bbf655","projectId":"456","name":"exp3","description":"sampleExp3","binaryFiles":[{"path":"binarytest3.txt","type":"world_file"}],"imageFiles":[{"repoPath":"image2","tag":"v1","type":"controller"}],"experimentMode":"robot_arena","useDefaultNames":true,"schedule":{"startTime":"2020-12-27","duration":30},"status":"pending","archived":false}]
					// experimentListSort(data)
					actions({ type: 'setState', payload: { ...state, blocking: false } });
				});
	}

	//code to get all robotic arenas
	function getArenaDetails(input) {
		if (axiosInstance) {

			axiosInstance
				.get(`${process.env.REACT_APP_NODEMONITOR_URL}/robot-arenas/${input.testBedName}`)
				.then((response) => {
					// console.log(response);
					setRobotArenas(response.data.nodes)
					// console.log(response.data)
				})
				.catch((err) => {
					// console.log(err);
					// setNodeNetworks([{"name":"default"},{"name":"dummy Arena"}])
				});

		}
	}


	//Code to sort Experiment Data Completed / Archived
	function experimentListSort(data) {
		// console.log(data)
		let expCompleted = [];
		let expArchived = [];

		data.map((item) => {
			if (item.archived == true) {
				expArchived.push(item)
			} else {
				expCompleted.push(item)
			}
		})

		expCompleted.sort(function (a, b) {
			return a.creationTime - b.creationTime;
		});

		expArchived.sort(function (a, b) {
			return a.creationTime - b.creationTime;
		});
		setExperiments(expCompleted);
		setArchiveExperiments(expArchived);

	}

	//Code to check Image File Severity and add to state
	function checkImageSeverity(data) {
		console.log(data);
		if (data.length > 0) {
			let sortedimages = [];
			
			data.map((item) => {
				console.log("Image Name " + item.name , "severity " + item.scanOverview.severity);
				// if (item.scanOverview.severity == 'Low' || item.scanOverview.severity == 'None') {
				// 	sortedimages.push(item)
				// }
				let chks = item.scanOverview.severity;
				if(chks !== 'Critical' && chks !== 'High'){
					sortedimages.push(item)
				}

			})

			// console.log(sortedimages)
			setimgFileSeverity(sortedimages);
			chkImageType(sortedimages)
		}

	}

	//check image type is controller or radio simulator
	function chkImageType(data) {
		console.log('chk data', data);
		let controllerArray = [];
		let simulatorArray = [];
		data.map((item) => {
			item.artifacts.map((img) => {
				if (img.type == "controller") {
					controllerArray.push(item)
				} else {
					simulatorArray.push(item)
				}
			})
		})
		let controllerArrayWithoutDuplicate = controllerArray.filter((value,index)=>(
												controllerArray.indexOf(value) === index
											));
		let simulatorArrayWithoutDuplicate = simulatorArray.filter((value,index)=>(
												simulatorArray.indexOf(value) === index
											));
		setControllerImages(controllerArrayWithoutDuplicate);
		setRadioSimImages(simulatorArrayWithoutDuplicate);
	}

	//show Image Files upload 
	const onImageFileChange = (event) => {
		setConfigManager(false);
		setBinaryManger(false);
		setImageManager(true);
	};

	//show Binary Files Upload 
	const onConfigFileChange = (event) => {
		setImageManager(false);
		setBinaryManger(false)
		setConfigManager(true);
	}

	//show Binary Files Upload 
	const onBinaryFileChange = (event) => {
		setImageManager(false);
		setConfigManager(false);
		setBinaryManger(true);
	}

	//To fetch eperiments after creation
	function changeStatus() {
		// console.log("Change Status Function")
		setTimeout(() => {
			fetchProject();
		}, 200);

	}

	const StyledBreadcrumb = styled(Chip)(({ theme }) => {
		const backgroundColor =
			theme.palette.mode === 'light'
				? theme.palette.grey[100]
				: theme.palette.grey[800];
		return {
			backgroundColor,
			height: theme.spacing(3),
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightRegular,
			fontSize: '13px',
			'&:hover, &:focus': {
				backgroundColor: emphasize(backgroundColor, 0.06),
			},
			'&:active': {
				boxShadow: theme.shadows[1],
				backgroundColor: emphasize(backgroundColor, 0.12),
			},
		};
	});

	return (
		<RoboticsLayout>
			<Stack spacing={2}>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="medium" />}
					aria-label="breadcrumb"
				>
					<Link to={`/robotics`} underline="hover" key="1" color="text.primary">
						<StyledBreadcrumb
							component="a"
							href="/robotics"
							label="My Robotics Projects"
							icon={<FolderIcon fontSize="medium" style={{ color: "#1c7b8e" }} />}
						/>
					</Link>
					<Typography key="2" color="text.primary">
						<ListIcon style={{ color: "#1c7b8e" }} fontSize="medium" />{projectInfo.projectName}
					</Typography>
				</Breadcrumbs>
			</Stack>
			<div
				className=" col-md-12 col-sm-12 col-lg-12 text-left"
				style={{ margin: '2rem 2.5rem' }}
			>
				<Jumbotron
					className="bg-white"
					style={{ marginRight: '5rem', padding: '1rem 2rem', alignItems: 'left', alignContent: 'left' }}
				>
					<TableContainer>
						<Table aria-label="simple table">
							<TableBody>
								<TableRow >
									<TableCell>
										Project Name
									</TableCell>
									<TableCell align="left">{projectInfo.projectName}</TableCell>
								</TableRow>
								<TableRow >
									<TableCell >
										Project Id
									</TableCell>
									<TableCell align="left">{projectInfo.projectId}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										Project Description
									</TableCell>
									<TableCell align="left">{projectInfo.projectDescription}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										Arena
									</TableCell>
									<TableCell align="left">{projectInfo.testBedName}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>				
				</Jumbotron>
				

				<label className="create-proj-text-color">
					<b>Files</b>
				</label>
				<Jumbotron
					className="bg-white"
					style={{ marginRight: '5rem', padding: '1rem 2rem' }}
				>
					{getImages.length > 0 || getConfigs.length > 0 ? (
						<FilesTable 
							images={getImages} 
							configs={getConfigs} 
							parentProp={match} 
							changeCurState={(e) => changeStatus(e)} 
							projectrole={projectRole}
						/>
					) : (
						<>
							<h4 className="create-proj-jumbo" style={{ textAlign: 'center' }}>
								You don't have any files uploaded to this project.
							</h4>
						</>
					)}

					{
						projectRole == 'developer' || projectRole == 'projectAdmin' ? (
							<>
								<div>
									<div className="upload-btn-wrapper">
										<Button className="btnDefault" onClick={onImageFileChange}>+ Add Images</Button>
									</div>
									<br />
									{imageManager === true ? <ImageUploader projectid={match.params.id} changeCurState={(e) => changeStatus(e)} /> : null}

									<div className="upload-btn-wrapper" style={{ marginTop: '1%' }}>
										<Button className="btnDefault" onClick={onConfigFileChange}>+ Add Configurations</Button>
									</div>
									<br />
									{configManager === true ? <ConfigUploader projectid={match.params.id} changeCurState={(e) => changeStatus(e)} /> : null}

									<div className="upload-btn-wrapper" style={{ marginTop: '1%' }}>
										<Button className="btnDefault" onClick={onBinaryFileChange}>+ Add Binaries</Button>
									</div>
									<br />
									{BinaryManger === true ? <BinaryUploader projectid={match.params.id} changeCurState={(e) => changeStatus(e)} /> : null}
								</div>
							</>
						) : null
					}

				</Jumbotron>
				<br />
				<TabContext value={value}>
					<Box sx={{ borderBottom: 0, borderColor: 'white' }}>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							<Tab label="Experiments" value="1" />
							<Tab label="Archives" value="2" />
						</TabList>
					</Box>
					<TabPanel value="1">				
						<Jumbotron className="text-center bg-white " style={{ marginRight: '5rem' }}>
							{experiments.length > 0 ? (
								<div className="roboticExpTable">
									<ExperimentTable 
										data={experiments} 
										project={projectInfo} 
										changeCurState={(e) => changeStatus(e)} 
										projectrole={projectRole}
									/>
								</div>
							) : (
								<div>
									<h4 className="create-proj-jumbo">
										You don't have any experiments to this project.
									</h4>
								</div>
							)}
							<br />
							<div className="col-md-12 no-padding">
								{
									projectRole == 'developer' || projectRole == 'projectAdmin' ? (
										<>
											{Object.keys(expNodes).length > 0 ?
												<CreateExperiment
													project_id={match.params.id}
													robotArenas={robotArenas}
													controlerimg={controllerImages}
													radiosimimg={radioSimImages}
													configfiles={configFiles}
													binaryarenafiles={binaryArenaFiles}
													configwordfiles={configWordFiles}
													nodelist={expNodes}
													changeCurState={(e) => changeStatus(e)}
												/>
												:
												<Button className="btnDefault" disabled>
													Create Experiment
												</Button>
											}
										</>
									) : null
								}
							</div>
							<br />
							<div className="col-md-12 no-padding">
								<Button className="btnDefault btnSpace" href="#/robotics/simulationqueue">
									View Simulation Queue
								</Button>
								<Button className="btnDefault" href="#/robotics/arenaqueue">
									View Robot Arena Queue
								</Button>
							</div>
						</Jumbotron>
					</TabPanel>
					<TabPanel value="2">
						<Jumbotron className="text-center bg-white " style={{ marginRight: '5rem' }}>
							{archiveExperiments.length > 0 ? (
								<div className="">
									<ExperimentArchiveTable 
										data={archiveExperiments} 
										project={projectInfo} 
										changeCurState={(e) => changeStatus(e)} 
										projectrole={projectRole}
									/>
								</div>
							) : (
								<>
									<h4 className="create-proj-jumbo">
										You don't have any archived experiments to this project.
									</h4>
								</>
							)}
						</Jumbotron>
						</TabPanel>
				</TabContext>	
				<br />
					{
						projectRole == 'projectAdmin' ? (
							<>
								<Jumbotron
									className="bg-white"
									style={{ marginRight: '5rem', padding: '0' }}
								>
									<AddUpdateUsers projectid={match.params.id}/>
								</Jumbotron>
							</>
						) : null
					}
				<br />
			</div>
			<ToastContainer draggable={false} transition={Zoom} />
		</RoboticsLayout>
	)
}

export default RoboticsProjects;