import React, { useState, useEffect, useContext } from 'react';
import './CreateDeviceProfile.css';
import 'react-toastify/dist/ReactToastify.css';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/mode/javascript/javascript";
import 'codemirror/lib/codemirror.css';
import { FaTruckMonster } from 'react-icons/fa';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
function EditDeviceProfileTab(props) {

    const [checkClassB, setCheckClassB] = useState(false);
    const [checkClassC, setCheckClassC] = useState(false)
    const [nwServers, setNWServers] = useState([]);
    const [checkJOIN, setCheckJOIN] = useState(false);
    const [deviceProfile, setDeviceProfile] = useState({});
    const [lgModal, setLgModal] = useState('')
    const history = useHistory();
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [codeC, setcodeC] = useState({});
    const [uplink, setuplink] = useState('');
    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [activeTab, setActiveTab] = useState('Dashboard');

    const [value, setValue] = React.useState('Dashboard');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleTabSelect = ((tab) => {
        setActiveTab(tab);
    });


    let payloadDecoderScript;
    if (payloadDecoderScript == '' || payloadDecoderScript == 'undefined') {
        payloadDecoderScript = `// Decode decodes an array of bytes into an object.
//  - fPort contains the LoRaWAN fPort number
//  - bytes is an array of bytes, e.g. [225, 230, 255, 0]
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an object, e.g. {"temperature": 22.5}
function Decode(fPort, bytes, variables) {
    return {};
}`;
    }
    const [payloadDecoder, setPayloadDecoder] = useState(payloadDecoderScript);

    let payloadEncoderScript;
    if (payloadEncoderScript == '' || payloadEncoderScript == 'undefined') {
        payloadEncoderScript = `// Encode encodes the given object into an array of bytes.
//  - fPort contains the LoRaWAN fPort number
//  - obj is an object, e.g. {"temperature": 22.5}
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an array of bytes, e.g. [225, 230, 255, 0]
function Encode(fPort, obj, variables) {
  return [];
}`;
    }

    const [payloadEncoder, setPayloadEncoder] = useState(payloadEncoderScript);

    // useEffect(()=>{
    //     console.log(deviceProfile)
    // },[deviceProfile])

    // useEffect(() => {
    //     console.log(props.networks)
    // },[props])

    //code to close modal

    useEffect(() => {
        fetchDeviceProfile();
    }, [axiosInstance]);
    useEffect(() => {
        fetchNwServers();
    }, [axiosInstance]);


    function fetchDeviceProfile() {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/device-profiles/${props.match.params.id}`)
                .then((response) => {
                    let resp = response.data.deviceProfile;
                    fetchNwServers();
                    console.log(resp)
                    setDeviceProfile(resp);
                    setPayloadEncoder(resp.payloadEncoderScript);
                    setPayloadDecoder(resp.payloadDecoderScript);

                    console.log(resp.payloadDecoderScript);
                    setcodeC(resp.payloadDecoderScript);

                }).catch((err) => {
                    ErrorPopUp("Fetching Device Profile Failed")
                    fetchNwServers();
                })

        }
    }


    function fetchNwServers() {
        // let orgid = localStorage.getItem('orgid')
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/network-servers?limit=999`)
                .then((response) => {

                    setNWServers(response.data.result);

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }
    //code to check /uncheck LO
    function changeJOIN(e) {
        let check = e.target.checked;
        let name = e.target.getAttribute('name');
        if (check == true) {
            setDeviceProfile({ ...deviceProfile, [name]: true });
            setCheckJOIN(FaTruckMonster);
        }

        if (check == false) {
            setDeviceProfile({ ...deviceProfile, [name]: false });
            setCheckJOIN(false);
        }
    }

    //Code to check/Uncheck support Class B Checkbox
    function changeClassB(e) {
        let check = e.target.checked;
        let name = e.target.getAttribute('name');
        // console.log(check)
        if (check == true) {
            setCheckClassB(!checkClassB)
            setDeviceProfile({ ...deviceProfile, [name]: !checkClassB });

        } else {
            setCheckClassB(!checkClassB);
            setDeviceProfile({ ...deviceProfile, [name]: !checkClassB });
        }
    }

    //Code to check/Uncheck support Class C Checkbox
    function changeClassC(e) {
        let check = e.target.checked;
        let name = e.target.getAttribute('name');
        // console.log(check)
        if (check == true) {
            setCheckClassC(!checkClassC)
            setDeviceProfile({ ...deviceProfile, [name]: !checkClassC });
        } else {
            setCheckClassC(!checkClassC)
            setDeviceProfile({ ...deviceProfile, [name]: !checkClassC });
        }
    }

    //Code to store key input
    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setDeviceProfile({ ...deviceProfile, [name]: value });
    };

    const changeFactoryPreset = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setDeviceProfile({ ...deviceProfile, [name]: value, ['factoryPresetFreqs']: [value] });
        // setDeviceProfile({ ...deviceProfile, ['factoryPresetFreqs']: [value]});
    }

    //Code to store device name and codec
    const changeNameandCodec = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
        if (!textFieldRegex.test(value)) {
            setError(name + ' is invalid');
            setIsValid(false);
        } else {
            setError('');
            setIsValid(true);
        }
        if (value == 'CUSTOM_JS') {
            setLgModal('lg')
        } else {
            setLgModal('')
        }
        setDeviceProfile({ ...deviceProfile, [name]: value });
    };

    //Code to store device name and codec
    const changeUplinkInterval = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const maskedval = `${value}s`;

        setDeviceProfile({ ...deviceProfile, [name]: maskedval });
    };

    //Code to store device name and codec
    const changeMaxEIRP = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setDeviceProfile({ ...deviceProfile, [name]: value });
    };

    //code to update decoder
    const handleDecoder = (editor, event, value) => {
        // console.log(event.keyCode);
        // console.log(editor);

        setPayloadDecoder(value);
    };

    //code to update decoder
    const handleEncoder = (editor, event, value) => {
        // console.log(event.keyCode);
        // console.log(editor);
        setPayloadEncoder(value)
    };

    //Code to send request to createapi key
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(deviceProfile);
        let orgid = localStorage.getItem('orgid');
        // let data =  {"deviceProfile":{...deviceProfile, ['organizationID']: orgid}};
        let newdeviceData;

        if (deviceProfile.payloadCodec == 'CUSTOM_JS') {
            newdeviceData = {
                "deviceProfile": {
                    ...deviceProfile,
                    ['payloadDecoderScript']: payloadDecoder,
                    ['payloadEncoderScript']: payloadEncoder,
                    ['organizationID']: orgid
                }
            };
        } else {
            newdeviceData = { "deviceProfile": { ...deviceProfile, ['organizationID']: orgid } };
        }

        // console.log(data);
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/device-profiles/${props.match.params.id}`, newdeviceData)
                .then((response) => {

                    PopUp("Device Profile Updated Successfully");

                    setDeviceProfile({ ...deviceProfile, ['payloadCodec']: '' });

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    history.push('/lora/device-profiles');
                })
                .catch((err) => {

                    ErrorPopUp("Request Failed. Try Again");
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    };

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 2rem' }}>

                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-8 col-sm-8 col-lg-8"
                        >
                            <h5>Edit Device Profile : {props.match.params.id}</h5>
                        </div>
                    </div>

                    <div className="default-main-page">
                        <Form onSubmit={handleSubmit}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Dashboard" value="Dashboard" />
                                            <Tab label="Join (OTAA / ABP)" value="Join(OTAA/ABP)" />
                                            <Tab label="Class-B" value="Class-B" />
                                            <Tab label="Class-C" value="Class-C" />
                                            <Tab label="Codec" value="Codec" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="Dashboard">
                                        <div className="default-main-page" style={{ padding: '1%' }}>
                                            <div style={{ color: 'red' }}>
                                                {error}
                                            </div>
                                            <h5 className="profileTitle">General</h5>
                                            <Form.Group>
                                                <Form.Label>Device-Profile Name</Form.Label>
                                                <Form.Control
                                                    name="name"
                                                    onChange={changeNameandCodec}
                                                    value={deviceProfile.name || ''}
                                                    type="text"
                                                    placeholder="Enter Device-Profile name"
                                                    autoComplete="off"

                                                />
                                                <Form.Text className="text-muted">
                                                    A name to identify the device-profile.
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Network-Server</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="networkServerID"
                                                    value={deviceProfile.networkServerID}
                                                    onChange={changeHandler}

                                                    custom
                                                >
                                                    {
                                                        nwServers.length > 0 ? (
                                                            <>
                                                                <option value="">Select Network</option>
                                                                {nwServers.map((item, index) => {
                                                                    return (
                                                                        <option value={item.id} key={index}>{item.name}</option>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value="">No Networs Found</option>
                                                            </>
                                                        )
                                                    }
                                                </Form.Control>
                                                <Form.Text className="text-muted">
                                                    The network-server on which this device-profile will be provisioned. After creating the device-profile, this value can't be changed.
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>LoRaWAN Regional Parameters revision</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="regParamsRevision"
                                                    onChange={changeHandler}
                                                    value={deviceProfile.regParamsRevision}

                                                    custom
                                                >
                                                    <option value=''>Select</option>
                                                    <option value='A'>A</option>
                                                    <option value='B'>B</option>
                                                </Form.Control>
                                                <Form.Text className="text-muted">
                                                    Revision of the Regional Parameters specification supported by the device.
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Max EIRP</Form.Label>
                                                <Form.Control
                                                    name="maxEIRP"
                                                    onChange={changeMaxEIRP}
                                                    type="number"
                                                    placeholder="Enter Max EIRP number"
                                                    value={deviceProfile.maxEIRP || ''}
                                                    autoComplete="off"

                                                />
                                                <Form.Text className="text-muted">
                                                    Maximum EIRP supported by the device
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Uplink Interval (Seconds)</Form.Label>
                                                <Form.Control
                                                    name="uplinkInterval"
                                                    onChange={changeUplinkInterval}
                                                    type="number"
                                                    value={deviceProfile.uplinkInterval || ''}
                                                    placeholder="Enter Uplink Interval"
                                                    autoComplete="off"

                                                />
                                                <Form.Text className="text-muted">
                                                    The expected interval in seconds in which the device sends uplink messages. This is used to determine if a device is active or inactive.
                                                </Form.Text>
                                            </Form.Group>


                                            <Button disabled={!isValid} align="" className="btnDefault" type="submit">
                                                Update DEVICE-PROFILE
                                            </Button>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="Join(OTAA/ABP)"><div className="default-main-page" style={{ padding: '1%' }}>
                                        <h5 className="profileTitle">Join (OTAA / ABP)</h5>
                                        <Form.Group>
                                            <Form.Check type="checkbox" checked={deviceProfile.supportsJoin || ''} label="Device supports OTAA" name="supportsJoin" onChange={(e) => changeJOIN(e)} />
                                        </Form.Group>
                                        {checkJOIN == false ? (
                                            <>
                                                <Form.Group>
                                                    <Form.Label>RX1 delay</Form.Label>
                                                    <Form.Control
                                                        name="rxDelay1"
                                                        onChange={changeHandler}
                                                        type="number"
                                                        placeholder="Enter RX1 Delay"
                                                        autoComplete="off"

                                                        value={deviceProfile.rxDelay1 || ''}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        RX1 delay (valid values are 0 - 15).
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>RX1 data-rate offset</Form.Label>
                                                    <Form.Control
                                                        name="rxDROffset1"
                                                        onChange={changeHandler}
                                                        type="number"
                                                        placeholder="RX1 data-rate offset"
                                                        autoComplete="off"
                                                        value={deviceProfile.rxDROffset1 || ''}

                                                    />
                                                    <Form.Text className="text-muted">
                                                        Please refer the LoRaWAN Regional Parameters specification for valid values
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>RX2 data-rate</Form.Label>
                                                    <Form.Control
                                                        name="rxDataRate2"
                                                        onChange={changeHandler}
                                                        type="number"
                                                        placeholder="RX1 data-rate offset"
                                                        autoComplete="off"

                                                        value={deviceProfile.rxDataRate2 || ''}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Please refer the LoRaWAN Regional Parameters specification for valid values.
                                                    </Form.Text>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>RX2 channel frequency (Hz)</Form.Label>
                                                    <Form.Control
                                                        name="rxFreq2"
                                                        onChange={changeHandler}
                                                        type="number"
                                                        placeholder="RX2 channel frequency"
                                                        autoComplete="off"

                                                        value={deviceProfile.rxFreq2 || ''}
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Factory-preset frequencies (Hz)</Form.Label>
                                                    <Form.Control
                                                        name="factoryPresetFreqsStr"
                                                        onChange={changeFactoryPreset}
                                                        type="number"
                                                        placeholder="Factory-preset frequencies"
                                                        autoComplete="off"

                                                        value={deviceProfile.factoryPresetFreqsStr || ''}
                                                    />
                                                    <Form.Text className="text-muted">
                                                        List of factory-preset frequencies (Hz), comma separated.
                                                    </Form.Text>
                                                </Form.Group>
                                            </>
                                        ) : null}

                                        <Button className="btnDefault" type="submit">
                                            Update DEVICE-PROFILE
                                        </Button>
                                    </div>
                                    </TabPanel>
                                    <TabPanel value="Class-B">
                                        <div className="default-main-page" style={{ padding: '1%' }}>
                                            <Form.Group>
                                                <Form.Check type="checkbox" checked={deviceProfile.supportsClassB || ''} label="Device supports Class-B" name="supportsClassB" onChange={(e) => changeClassB(e)} />
                                            </Form.Group>
                                            {checkClassB == true ? (
                                                <>
                                                    <Form.Group>
                                                        <Form.Label>Class-B confirmed downlink timeout</Form.Label>
                                                        <Form.Control
                                                            name="classBTimeout"
                                                            onChange={changeHandler}
                                                            type="number"
                                                            placeholder="Enter downlink timeout"
                                                            autoComplete="off"

                                                            value={deviceProfile.classBTimeout || ''}
                                                        />
                                                        <Form.Text className="text-muted">
                                                            Class-B timeout (in seconds) for confirmed downlink transmissions.
                                                        </Form.Text>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Class-B ping-slot periodicity</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            name="pingSlotPeriod"
                                                            onChange={changeHandler}

                                                            value={deviceProfile.pingSlotPeriod || ''}
                                                            custom
                                                        >
                                                            <option value=''>Select Period</option>
                                                            <option value='1'>Every Second</option>
                                                            <option value='2'>Every 2 Second</option>
                                                            <option value='4'>Every 4 Second</option>
                                                            <option value='8'>Every 8 Second</option>
                                                            <option value='16'>Every 16 Second</option>
                                                            <option value='32'>Every 32 Second</option>
                                                            <option value='64'>Every 64 Second</option>
                                                            <option value='128'>Every 128 Second</option>
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Class-B ping-slot data-rate</Form.Label>
                                                        <Form.Control
                                                            name="pingSlotDR"
                                                            onChange={changeHandler}
                                                            type="number"
                                                            placeholder="Enter ping-slot data-rate"
                                                            autoComplete="off"

                                                            value={deviceProfile.pingSlotDR || ''}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Class-B ping-slot frequency (Hz)</Form.Label>
                                                        <Form.Control
                                                            name="pingSlotFreq"
                                                            onChange={changeHandler}
                                                            type="number"
                                                            placeholder="Enter ping-slot frequency"
                                                            autoComplete="off"

                                                            value={deviceProfile.pingSlotFreq || ''}
                                                        />
                                                    </Form.Group>
                                                </>
                                            ) : null}
                                            <Button className="btnDefault" type="submit">
                                                Update DEVICE-PROFILE
                                            </Button>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="Class-C">
                                        <div className="default-main-page" style={{ padding: '1%' }}>
                                            <h5 className="profileTitle">Class-C</h5>
                                            <Form.Group>
                                                <Form.Check type="checkbox" checked={deviceProfile.supportsClassC || ''}
                                                    label="Device supports Class-C" name="supportsClassC" onChange={(e) => changeClassC(e)} />
                                            </Form.Group>
                                            <Form.Text className="text-muted">
                                                Select this option when the device will operate as Class-C device immediately after activation. In case it sends a DeviceModeInd mac-command when it changes to Class-C, do not select this option
                                            </Form.Text>
                                            <Form.Group>
                                                <Form.Label>Class-C confirmed downlink timeout</Form.Label>
                                                <Form.Control
                                                    name="downlinkTimeout"

                                                    onChange={changeHandler}
                                                    type="number"
                                                    placeholder="Enter downlink timeout"
                                                    autoComplete="off"

                                                    value={deviceProfile.downlinkTimeout || ''}
                                                />
                                                <Form.Text className="text-muted">
                                                    Class-C timeout (in seconds) for confirmed downlink transmissions.
                                                </Form.Text>
                                            </Form.Group>
                                            <Button className="btnDefault" type="submit">
                                                Update DEVICE-PROFILE
                                            </Button>

                                        </div>
                                    </TabPanel>
                                    <TabPanel value="Codec">
                                        <div className="default-main-page" style={{ padding: '1%' }}>

                                            <h5 className="profileTitle">Codec</h5>
                                            <Form.Group>
                                                <Form.Label>Payload codec</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="payloadCodec"
                                                    onChange={changeNameandCodec}

                                                    value={deviceProfile.payloadCodec || ''}
                                                    custom
                                                >
                                                    <option>Select Payload codec</option>
                                                    <option value="none">None</option>
                                                    <option value="cayenneLLP">Cayenne LPP</option>
                                                    <option value="CUSTOM_JS">Custom JavaScript codec functions</option>
                                                </Form.Control>
                                                <Form.Text>
                                                    By defining a payload codec, ChirpStack Application Server can encode and decode the binary device payload for you.
                                                </Form.Text>
                                            </Form.Group>
                                            {deviceProfile.payloadCodec == 'CUSTOM_JS' ? (
                                                <>
                                                    <Form.Group>
                                                        <div style={{ border: '1px solid aquamarine' }}>
                                                            <CodeMirror
                                                                value={payloadDecoder}
                                                                options={{
                                                                    lineNumbers: true,
                                                                    foldGutter: false,
                                                                    styleActiveLine: false,
                                                                    autofocus: true,
                                                                    theme: 'default',
                                                                    mode: 'javascript',
                                                                }}
                                                                onBeforeChange={(editor, data, code) => setPayloadDecoder(code)}
                                                                onChange={(editor, metadata, value) => handleDecoder(editor, metadata, value)}
                                                            />
                                                        </div>
                                                        <Form.Text>
                                                            The function must have the signature function Decode(fPort, bytes) and must return an object. ChirpStack Application Server will convert this object to JSON.
                                                        </Form.Text>
                                                    </Form.Group>
                                                </>
                                            ) : null}

                                            {deviceProfile.payloadCodec == 'CUSTOM_JS' ? (
                                                <>
                                                    <Form.Group>
                                                        <div style={{ border: '1px solid aquamarine' }}>
                                                            <CodeMirror
                                                                value={payloadEncoder}
                                                                options={{
                                                                    lineNumbers: true,
                                                                    foldGutter: false,
                                                                    styleActiveLine: false,
                                                                    autofocus: true,
                                                                    theme: 'default',
                                                                    mode: 'javascript',
                                                                }}
                                                                onBeforeChange={(editor, data, code) => setPayloadEncoder(code)}
                                                                onChange={(editor, metadata, value) => handleEncoder(editor, metadata, value)}
                                                            />
                                                        </div>
                                                        <Form.Text>
                                                            The function must have the signature function Encode(fPort, obj) and must return an array of bytes.
                                                        </Form.Text>
                                                    </Form.Group>
                                                </>
                                            ) : null}

                                            <Button className="btnDefault" type="submit">
                                                Update DEVICE-PROFILE
                                            </Button>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Form>
                    </div>
                </div>
            </LoraLayout>
        </>
    )

}
export default EditDeviceProfileTab;
