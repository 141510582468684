import React, { useState, useEffect, useContext } from 'react';
import RoboticLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout';
import SimulatorMode from '../../../components/RoboticsNetworks/GzWeb/SimulatorMode'
import RobotMode from '../../../components/RoboticsNetworks/GzWeb/RobotMode'
import './Experiment.css';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../../utils/Axios';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Context from '../../../store/Context'
import ErrorPopUp from '../../../components/UIElements/ErrorPopup';
import { ToastContainer, Zoom } from 'react-toastify';

const RoboticExperiment = (props) => {
	// const [config , setConfig] = useState([]);

	const [experimentInfo, setExperimentInfo] = useState({});

	const { state, actions } = useContext(Context);

	let expName = localStorage.getItem('expName');
	let expDescription = localStorage.getItem('description');
	let expId = localStorage.getItem('expid');

	const axiosInstance = useAxios();

	useEffect(() => {
		fetchExperiment();
	}, [axiosInstance]);

	// useEffect(() => {
	// 	console.log(props)
	// }, [props]);

	useEffect(() => {
		console.log(experimentInfo)
	}, [experimentInfo]);
	// //code to get Configuration

	const fetchExperiment = () => {
		if (axiosInstance) {
			// console.log("WWWWorking");
			// actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${expId}`)
				.then((response) => {
					setExperimentInfo(response.data)
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err);
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}
	}

	//code to splice url path and extract url
	function getFullPath(n){
		let str = n;
		let res = str.slice(10, str.length);
		return res;
	}

	//code to download the file
	function downloadFile(e) {
		e.preventDefault();
		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/${getFullPath(props.match.url)}/log`, { responseType: 'arraybuffer' })
				// .get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/123/logs/download/123.zip`, {responseType: 'arraybuffer'})
				.then((response) => {
					console.log(response);
					console.log(response.data);
					const a = document.createElement("a");
					a.href = URL.createObjectURL(
						new Blob([response.data], { type: 'application/zip' })
					);
					a.setAttribute("download", `${experimentInfo.id}.zip`);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err.response);
					ErrorPopUp("Download Failed. Try Again")

					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}
	}

	//code to download the file
        function downloadVideoFeed(e) {
		e.preventDefault();
		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/${getFullPath(props.match.url)}/camera-feed`, { responseType: 'arraybuffer' })
				// .get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/123/camera-feed/download/123.flv`, {responseType: 'arraybuffer'})
				.then((response) => {
					console.log(response);
					console.log(response.data);
					console.log(response.name);
					const a = document.createElement("a");
					a.href = URL.createObjectURL(
						new Blob([response.data], { type: 'video/mp4' })
					);
					a.setAttribute("download", `${experimentInfo.id}.mp4`);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err.response);
					ErrorPopUp("Download Failed. Try Again")

					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}
	}

	return (
		<RoboticLayout>
			<div
				className=" col-md-12 col-sm-12 col-lg-12 text-left"
				style={{ margin: '2rem 2.5rem' }}
			>
				{
					Object.keys(experimentInfo).length > 0 ? (

						<>
							<h5 className="create-proj-text-color">
								Name : <b>{experimentInfo.name}</b>
							</h5>
							<p className="create-proj-text-color" style={{ width: '50%' }}>
								Description : {experimentInfo.description}
							</p>
							<p className="create-proj-text-color" style={{ width: '50%' }}>
								Duration : {experimentInfo.schedule.duration}
							</p>
							<p className="create-proj-text-color" style={{ width: '50%' }}>
								Images : {experimentInfo.imageFiles.map((a) => (
								<>{a.repoPath}</>
							))}
							</p>
							{/* <p className="create-proj-text-color" style={{ width: '50%' }}>
									Simulator Image Path : {item.imageFiles.map((a) => (
										<>{a.repoPath}</>
									))}
								</p> */}
							<p className="create-proj-text-color" style={{ width: '50%' }}>
								Configuration : {experimentInfo.binaryFiles.map((a) => (
								<>{a.path}</>
							))}
							</p>
						</>


					) : (
						<>
							<h5 className="create-proj-text-color">
								<b>No Experiment Data Found</b>
							</h5>
						</>
					)
				}
				<br />
				{/* <Jumbotron
					className="text-center bg-white "
					style={{ marginRight: '5rem' }}
				>
					{
						Object.keys(experimentInfo).length > 0 ? (
							<>
								{experimentInfo.experimentMode == 'simulation' ? <SimulatorMode expid={experimentInfo.id} mode={experimentInfo.experimentMode}/> : <RobotMode expid={experimentInfo.id} mode={experimentInfo.experimentMode}/>}
							</>
						) : (
							<>
								<p>No Mode Found</p>
							</>
						)
					}

				</Jumbotron> */}

				{Object.keys(experimentInfo).length > 0 ? (
					<>
						{experimentInfo.experimentInfo != 'Failed' ? (
							<>
								<Jumbotron
									className="text-center bg-white "
									style={{ marginRight: '5rem' }}
								>
									{experimentInfo.experimentMode == 'simulation' ?
										<SimulatorMode expid={experimentInfo.id} mode={experimentInfo.experimentMode} 
										status={experimentInfo.status}/>
										:
										<RobotMode expid={experimentInfo.id} mode={experimentInfo.experimentMode
										} status={experimentInfo.status}/>}
									{experimentInfo.status == 'Ended' ? (
										<div style={{ textAlign: 'left', marginTop: '2%' }}>
											<Button className="btnDefault" onClick={(e) => downloadFile(e)}>Download Logs</Button>
										</div>
									) : null}
									{experimentInfo.experimentMode != 'simulation' && experimentInfo.status == 'Ended' ? (
									<div style={{ textAlign: 'left', marginTop: '2%' }}>
									<Button className="btnDefault" onClick={(e) => downloadVideoFeed(e)}>Download Camera Feed</Button>
									</div>): null}
								</Jumbotron>
							</>
						) : (
							<>
								<p>No Mode Found</p>
							</>
						)}
						{experimentInfo.experimentInfo == 'Failed' ? (
							<>
								<h5 className="create-proj-text-color">Failure reason:</h5>
								<p>{experimentInfo.schedulingInfo.statusDetail}</p>
							</>
						) : null}
					</>
				) : null}
			</div>
			<ToastContainer draggable={false} transition={Zoom} />
		</RoboticLayout>
	);
};

export default RoboticExperiment;
