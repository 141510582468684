import React ,{useState, useEffect,useContext} from 'react';
import Layout from '../../components/Layout/Layout'
import Form from 'react-bootstrap/Form';
import { useAxios } from '../../utils/Axios';
import Nodes from '../../components/NetworksTab/Nodes';
import Geohash from 'latlon-geohash'
import Context from '../../store/Context'

const NetworkDashboard = () => {

	const [nodeNetworks , setNodeNetworks] = useState([]);
	const [nodeList, setNodeList] = useState([]);
	const {state , actions} = useContext(Context);
	let axiosInstance = useAxios();

	useEffect(() => {
		fetchNodeNetworks();
	}, [axiosInstance]);

	// useEffect(() => {
	// 	console.log(nodeList)
	// }, [nodeList]);

	//Code to fetch node networks
	function fetchNodeNetworks() {
		if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_NODEMONITOR_URL}/node-networks/all`)
				.then((response) => {
					// console.log(response);
					setNodeNetworks(response.data)
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
					// console.log(err);
					actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
	}

	//Code to get Nodes basedon network selection
	function selectNetwork(e){
		// console.log(e.target.value)
		setNodeList({});
		let networkId = e.target.value
		if (axiosInstance ){
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_NODEMONITOR_URL}/node-networks/${networkId}`)
				.then((response) => {
					convertGeohash(response)
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
					// console.log(err);
					actions({type : 'setState', payload : {...state , blocking : false}})
				});
			}
	}

	//Code to convert GPs Hash to location and adding required data to original json
	function convertGeohash (input) {
		let oldData = input
		// console.log(oldData.data.nodes)
		for (let i=0;i<oldData.data.nodes.length;i++){
			const locationSlice = oldData.data.nodes[i].gpsHash
			// const res = locationSlice.slice(0, 9)
			// const latlon = Geohash.decode(`${res}`)
			// let obj = oldData.data.nodes[i];
			// Object.assign(obj, {lat: parseFloat(latlon.lat),lng: parseFloat(latlon.lon), selected: false});
			if(locationSlice !== null && locationSlice !== ''){
				const res = locationSlice.slice(0, 9)
				const latlon = Geohash.decode(`${res}`)
				let obj = oldData.data.nodes[i];
				Object.assign(obj, {lat: parseFloat(latlon.lat),lng: parseFloat(latlon.lon), selected: false});
			}else{
				let obj = oldData.data.nodes[i];
				Object.assign(obj, { lat: 51.5074, lng: 0.1278 ,selected: false});
			}
		}

		setNodeList(oldData)
	}

	return (	
		<Layout>
			<div
				className=" col-md-12 col-sm-12 col-lg-12 text-left"
				style={{ margin: '2rem 2.5rem' }}
			>
				<div className=" col-md-5 col-sm-5 col-lg-5">
					<label className="create-proj-text-color">
						<b>Select Node Network</b>
					</label>
					{
						nodeNetworks.length > 0 ? (
							<Form.Group controlId="projectNetwork" >
								<Form.Control
									as="select"
									name="networkName"
									onChange={(e) => selectNetwork(e)}
									required
									custom
								>
									<option>Select Network</option>
									{nodeNetworks.map((item, index) => (
										<option value={item.name} key={index}>{item.name}</option>
									))}
								</Form.Control>
							</Form.Group>
						) : (
								<Form.Group controlId="projectNetwork" >
									<Form.Control
										as="select"
										name="networkName"
										required
										custom
									>
										<option>No Networks Found</option>
									</Form.Control>
								</Form.Group>
							)
					}
				</div>
				
				<br />
				
				<div className=" col-md-10 col-sm-10 col-lg-10">
					<label className="create-proj-text-color">
						<b>Network Map</b>
					</label>
					{
						Object.keys(nodeList).length > 0 ? (
							<>
								<Nodes nodelist = {nodeList}/>
							</>		
						) : (
							<>
								{/* <Nodes nodelist = {nodeList}/> */}
								<p>Select Network</p>
							</>
						)
					}
				</div>	
				

			</div>
		</Layout>
	);
};

export default NetworkDashboard;