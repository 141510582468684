import React ,{ useState, useEffect, useContext } from 'react';
import PopUp from '../../../../../components/UIElements/PopUp';
import LoraLayout from '../../../../../components/LoraNetworks/LoraLayout/LoraLayout';
import ErrorPopUp from '../../../../../components/UIElements/ErrorPopup'
import { useAxios } from '../../../../../utils/Axios';
import Context from '../../../../../store/Context';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, Zoom } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Table } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import editIcon from '../../../../../assests/icons/edit.svg';

function HTTPCardEdit(props) {
    const [httpData, setHTTPData] = useState({
         
    })
    const[existheaderRow,setexistheaderRow] = useState({})
    
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    
    const [headerRow, setHeaderRow] = useState([

    ]);
    const[keyvalheader,setkeyvalheader] = useState({
        key : '',
        value : '',
        id:'',

    });
    
   const[isedit,setisedit] = useState(false);

   const [error, setError] = useState('');
   const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        fetchHTTPCardDetails();
    }, [axiosInstance]);
    function changeHandler(e) {
        const name = e.target.name;
        const value = e.target.value;
        const textFieldRegex = /^[A-Za-z0-9://.\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setHTTPData({ ...httpData, [name]: value });
    }

    function fetchHTTPCardDetails(){
        if(axiosInstance){
            actions({type:'setState',payload:{state,blocking:false}})
            axiosInstance
            .get(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}/integrations/http`)
            .then((response)=>{
                 console.log(response.data.integration);
                 setHTTPData(response.data.integration);
                 setexistheaderRow(response.data.integration.headers);
                 console.log(response.data.integration.headers);
            }).catch((err)=>{
                ErrorPopUp("Fetching Device Profile Failed");
                 
            })

        }
    }
    // const changeHeaderName = (idx) => (e) => {
    //     const value = e.target.value;
    //     const name = e.target.name;
    //     let newArr = [...existheaderRow]; 
        
    //     console.log(idx);// copying the old datas array

    //     for (let index = 0; index < newArr.length; index++) {
    //         if (newArr[index] === idx) {
    //           newArr[index][name] =[value] ;
    //           break;
    //         }
    //       }
    //    setexistheaderRow(newArr);
    //     console.log(newArr);
    // } 
    function filterHeaders() {
        let array = []
         let olddata = [...headerRow];
         for(let i=0;i<olddata.length;i++){
             let obj = {}
             if(olddata[i].key !== undefined && olddata[i].value !== undefined){
                 obj['key'] = olddata[i].key
                 obj['value'] = olddata[i].value
                 array.push(obj)
             }
         }
        return array;
     }

    //Code to submit adding HTTP Integration Request
    const submitHTTP = (e) => {
        e.preventDefault();
        let data = {"integration": { ...httpData }};
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}/integrations/http`, data)
                .then((response) => {
                    // console.log(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    PopUp("HTTP Details Updated successfuly");
                  
                   
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    ErrorPopUp("Request Failed. Try Again")
                    // history.push(`/lora/applications/${props.match.params.id}`);
                });

        }
    }
   
    function updateModal(e){
        setisedit(true);
        setkeyvalheader({...keyvalheader,["key"]:e.getAttribute('ke'),["value"]:e.getAttribute('va'),["id"]:e.getAttribute('ind')})
        
    }
    const changeHeaderName = (idx) => (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const textFieldRegex = /^[A-Za-z0-9._ \-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        let newArr = [...keyvalheader]; // copying the old datas array
        newArr[idx][name] = value;
        setHeaderRow(newArr)
    } 
    
    return (
        <>
            <LoraLayout>
            <div style={{ color: 'red'}}>
                {error}
            </div>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Edit HTTP integration</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Row>
                            <Col md={11} className="mapCol">
                                <Form onSubmit={submitHTTP}>
                                    <Form.Group>
                                        <Form.Label>Payload marshaler</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={changeHandler}
                                            name="marshaler"
                                            value={httpData.marshaler || ''}
                                            required
                                            custom
                                        >
                                            <option value="">Select payload marshaler</option>
                                            <option value="JSON">JSON</option>
                                            <option value="PROTOBUF">Protocol Buffers</option>
                                            <option value="JSON_V3">JSON (legacy, will be deprecated)</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Headers</Form.Label>
                                    </Form.Group>
                                     {
                                        existheaderRow.length >0  ? (
                                            <>
                                                <table>
                                                   <thead>
                                                    <th>Key</th>
                                                    <th>Value</th>
                                                     <th>Edit</th>
                                                   </thead>
                                                    <tbody>
                                                       {existheaderRow.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                      
                                                                        <input
                                                                        name='key'
                                                                        value={item.key}
                                                                        
                                                                        />
                                                                           
                                                                    </td>
                                                                    <td>
                                                                    
                                                                        <input
                                                                        name='value'
                                                                            value={item.value}
                                                                            />
                                                                    </td>
                                                                     
                                                                    <td>
                                                                    <td><img src={editIcon} className="iconCustom" ind={index} ke={item.key} va={item.value} onClick={e => updateModal(e.target)}/></td>
                                                                    </td>
                                                                    
                                                                </tr>
                                                                
                                                                
                                                            )
                                                            
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        ):(<></> ) }
                                       
                                     
                                    <Form.Group controlId="projectName">
                                        <Form.Label>Endpoints</Form.Label>
                                        <Form.Control
                                            name="eventEndpointURL"
                                            onChange={changeHandler}
                                            type="text"
                                            value={httpData.eventEndpointURL || ''}
                                            placeholder="Endpoint URL(s) for events"
                                            autoComplete="off"
                                            required
                                        />
                                    </Form.Group>
                                    <div className="text-right">
                                        <Button disabled={!isValid} className="btnDefault" type="submit">
                                            SUBMIT
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                    {/* <TableDemo
                    data={existheaderRow}
                    /> */}
                </div>
            </LoraLayout>


            <Modal
				show={isedit}
				onHide={() => setisedit(false)}
				animation={false}
				centered
				keyboard={false}
			>
				 
					<Modal.Header closeButton>
						<Modal.Title>Update Header</Modal.Title>
					</Modal.Header>
					<Modal.Body>
                    <Form.Row>
                     
                    <input
                     name='key'
                          value={keyvalheader.key || ''}
                         
                          />
                        
                    </Form.Row>
                    
                    <br/>
                    <br/>
                    <br/>
                   
                    <input
                     name='value'
                        value={keyvalheader.value || ''}
                        
                    />
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="btnColor btn-primary-outline"
							onClick={() => setisedit(false)}
						>
							Cancel
						</Button>
						<Button className="btnDefault"  onClick={()=> setisedit(false)}>
							Update Headers
						</Button>
					</Modal.Footer>
				 
			</Modal>
             
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    );
}

export default HTTPCardEdit;
