import React, { useEffect, useState } from "react";
import { useAxios } from '../../../../utils/Axios';


function DeviceDataDashboard(props) {
  const axiosInstance = useAxios();
  const [message, setMessage] = useState('');
  const [resultURL, setResultURL] = useState('');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
    }, [windowDimensions])



  useEffect(() => {
    retrieveMeasurementsForDeviceId();
  }, [axiosInstance])

  function retrieveMeasurementsForDeviceId() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        let usertoken = localStorage.getItem('usertoken');
        let usernameArray = [];
        let panelArray = [];
        const name = localStorage.getItem('username');
        usernameArray.push(name);
        if (axiosInstance) {
            axiosInstance
                .get(`${process.env.REACT_APP_BASE_URL}/results/api/v1/internal/retrieveMeasurementsForDeviceId/${props.match.params.id}`)
                .then((response) => {
                    response.data.map((item) => {
                        panelArray.push(item);
                    })

                    if(panelArray == null || panelArray.length ==0){
                        setMessage("Device data not found");
                    } else{
                        let data = {
                            "dashboardName": props.match.params.id,
                            "users": usernameArray,
                            "panels": panelArray
                        };
    
                        
                        axiosInstance
                            .post(`${process.env.REACT_APP_BASE_URL}/results/api/v1/internal/create-multiple-panel-dashboard`, data)
                            .then((response) => {
                                if(response.data.url){                                
                                    let finalUrl = `${process.env.REACT_APP_BASE_URL}${response.data.url}?orgId=1&token=${usertoken}`;                         
                                    setResultURL(finalUrl);
                                }
                                if(response.data.uid){
                                    let uidArray = [];
                                    uidArray.push(response.data.uid);
                                    let dashboardListdata = {
                                        "uid": uidArray,
                                        "username": name
                                    };
                                    if (roles.includes('ln_admin') || roles.includes('org_admin')) {
                                        axiosInstance
                                            .post(`${process.env.REACT_APP_BASE_URL}/results/api/v1/internal/dashboard/users/add`, dashboardListdata)
                                            .then((response) => {
                                                console.log(response);                        
                                            }).catch((err) => {
                                                console.log(err);
                                            })
                                    }
                                }
                            }).catch((err) => {
                                setMessage("Requst Failed while creating multiple dashboard");
                            })
                    }

                    
                })
                .catch((err) => {
                    if (err.response) {
                        if(err.response.data.detail !== undefined || err.response.data.detail !== null){
                            setMessage(err.response.data.detail)
                        }else{
                            setMessage("Requst Failed while retriving data from influxdb")
                        }
                      }
                });
        }
        setTimeout(() => {
            setWindowDimensions(getWindowDimensions())
        }, 0);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

   if(message){
    return(
        <div className="alert alert-danger col-md-6" role="alert" style={{marginLeft: 15, marginTop: 15}}>
            {message}
        </div>
       )
   }else{
    return(
        <div className="col-md-12">
            <iframe id="grafanaChart" src={resultURL} width={`${windowDimensions.width - 380}px`} height={`${windowDimensions.height - 180}px`} frameBorder="0"></iframe>
        </div>
        ) 
   }
}

export default DeviceDataDashboard;
