import { EventEmitter } from "events";
import RobustWebSocket from "robust-websocket";

import { checkStatus, errorHandler, errorHandlerIgnoreNotFoundWithCallback } from "./helpers";
import dispatcher from "../dispatcher";
import { useAxios } from '../utils/Axios';
import ErrorPopUp from '../components/UIElements/ErrorPopup';

class DeviceStore extends EventEmitter { 

  constructor() {
    super();
    this.wsDataStatus = null;
    this.wsFramesStatus = null;   
  }

  getWSDataStatus() {
    return this.wsDataStatus;
  }

  getWSFramesStatus() {
    return this.wsFramesStatus;
  }


  getDataLogsConnection(devEUI, onData, chirpstackToken) {
    const loc = window.location;
    const wsURL = `wss://${process.env.REACT_APP_CHIRPSTACK_BASE_URL}/api/devices/${devEUI}/frames`;

    
    const conn = new RobustWebSocket(wsURL, ["Bearer", chirpstackToken], {});

    conn.addEventListener("open", () => {
      console.log("connected to", wsURL);
      this.wsDataStatus = "CONNECTED";
      this.emit("ws.status.change");
    });

    conn.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data);
      if (msg.error !== undefined) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: msg.error.message,
          },
        });
      } else if (msg.result !== undefined) {
        onData(msg.result);
      }
    });

    conn.addEventListener("close", () => {
      console.log("closing", wsURL);
      this.wsDataStatus = null;
      this.emit("ws.status.change");
    });

    conn.addEventListener("error", () => {
      console.log("error");
      this.wsDataStatus = "ERROR";
      this.emit("ws.status.change");
    });

    return conn;
  }

  getFrameLogsConnection(devEUI, onData, chirpstackToken) {
    const loc = window.location;
    const wsURL = `wss://${process.env.REACT_APP_CHIRPSTACK_BASE_URL}/api/devices/${devEUI}/frames`;

    const conn = new RobustWebSocket(wsURL, ["Bearer", chirpstackToken], {});

    conn.addEventListener("open", () => {
      console.log("connected to", wsURL);
      this.wsFramesStatus = "CONNECTED";
      this.emit("ws.status.change");
    });

    conn.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data);
      if (msg.error !== undefined) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: msg.error.message,
          },
        });
      } else if (msg.result !== undefined) {
        onData(msg.result);
      }
    });

    conn.addEventListener("close", () => {
      console.log("closing", wsURL);
      this.wsFramesStatus = null;
      this.emit("ws.status.change");
    });

    conn.addEventListener("error", (e) => {
      console.log("error", e);
      this.wsFramesStatus = "ERROR";
      this.emit("ws.status.change");
    });

    return conn;
  }




}

const deviceStore = new DeviceStore();
export default deviceStore;
