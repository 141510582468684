import React, { useEffect, useContext, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table'
// import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../../utils/Axios';
import Context from '../../../../store/Context';
function Details(props) {
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [deviceData, setDeviceData] = useState({});
    const [deviceProfileData, setDeviceProfileData] = useState({});

    useEffect(() => {
        fetchDevice();
    }, [axiosInstance]);

    //code o fetch individual device details
    function fetchDevice() {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}`)
                .then((response) => {
                    // actions({ type: 'setState', payload: { ...state, blocking: false } });
                    setDeviceData(response.data);
                    if(Object.keys(response.data).length !==0){
                        fetchDeviceProfile(response.data.device.deviceProfileID);
                    }
                    
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    // let response = { "device": { "devEUI": "19c7cd5ebc76e476", "name": "device4", "applicationID": "12", "description": "1", "deviceProfileID": "e48430bf-ac38-4815-a57e-5cda3e3f7d4c", "skipFCntCheck": true, "referenceAltitude": 0, "variables": {}, "tags": {}, "isDisabled": true }, "lastSeenAt": null, "deviceStatusBattery": 256, "deviceStatusMargin": 256, "location": null };
                    // setDeviceData(response)
                    // if(Object.keys(response).length !==0){
                    //     fetchDeviceProfile(response.device.deviceProfileID);
                    // }
                });

        }
    }

    //Code to get device profile
    function fetchDeviceProfile(input) {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/device-profiles/${input}`)
                .then((response) => {
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    setDeviceProfileData(response)
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    // setDeviceProfileData({ "deviceProfile": { "id": "e48430bf-ac38-4815-a57e-5cda3e3f7d4c", "name": "deviceprofile1", "organizationID": "21", "networkServerID": "4", "supportsClassB": true, "classBTimeout": 0, "pingSlotPeriod": 64, "pingSlotDR": 1, "pingSlotFreq": 1, "supportsClassC": true, "classCTimeout": 1, "macVersion": "1.1.0", "regParamsRevision": "A", "rxDelay1": 0, "rxDROffset1": 0, "rxDataRate2": 0, "rxFreq2": 0, "factoryPresetFreqs": [1], "maxEIRP": 1, "maxDutyCycle": 0, "supportsJoin": false, "rfRegion": "EU868", "supports32BitFCnt": false, "payloadCodec": "CAYENNE_LPP", "payloadEncoderScript": "", "payloadDecoderScript": "", "geolocBufferTTL": 0, "geolocMinBufferSize": 0, "tags": {}, "uplinkInterval": "1s" }, "createdAt": "2021-01-20T15:46:37.193352Z", "updatedAt": "2021-01-20T15:46:37.193352Z" })
                });

        }
    }


    return (
        <>
            <Row style={{ padding: '2% 0' }}>
                {Object.keys(deviceData).length > 0 ? (
                    <>
                        <Col md={6} lg={5} className="cardsCol">
                            <Card>
                                <h6 className="cardTitle">Details</h6>
                                <div className="cardBody">
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>{deviceData.device.name}</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td>{deviceData.device.description}</td>
                                            </tr>
                                            <tr>
                                                <td>Device-profile</td>
                                                <td>{Object.keys(deviceProfileData).length > 0 ? (
                                                    <>
                                                        {deviceProfileData.data.deviceProfile.name}
                                                    </>) : "-"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                        <Col md={6} lg={5} className="cardsCol">
                            <Card>
                                <h6 className="cardTitle">Status</h6>
                                <Table borderless>
                                    <tbody>
                                        <tr>
                                            <td>Last seen at</td>
                                            <td>{deviceData.lastSeenAt == null ? "never" : deviceData.lastSeenAt}</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>{deviceData.isDisabled == true ? "disabled" : "enabled"}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </>
                ) : (
                    <>
                     <Col md={6} lg={5} className="cardsCol">
                            <Card>
                                <h6 className="cardTitle">Details</h6>
                                <div className="cardBody">
                                    <Table borderless>
                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>Device-profile</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                        <Col md={6} lg={5} className="cardsCol">
                            <Card>
                                <h6 className="cardTitle">Status</h6>
                                <Table borderless>
                                    <tbody>
                                        <tr>
                                            <td>Last seen at</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>State</td>
                                            <td>-</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </>
                )}
            </Row>
        </>
    )
}

export default Details;
