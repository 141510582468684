import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';
import PopUp from '../../../components/UIElements/PopUp';
import ErrorPopUp from '../../../components/UIElements/ErrorPopup';
import { ToastContainer, Zoom } from 'react-toastify';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout';
import deleteIcon from '../../../assests/icons/delete.svg';
import swal from 'sweetalert';

function UsersOrgApprove() {
    const [users , setUsers] = useState([]);
    const [usersRequests, setUsersRequests] = useState([]);
    const [existUsers ,setExistUsers] = useState([]);
    const [existOrgUsers,setOrgUser] = useState('');
    const[isenable,setEnable]= useState(false);
    let OrgUserListdata = "";
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    useEffect(() => {
        fetchAllUsers();
    }, [axiosInstance]);
 
    // useEffect(() => {
    //     console.log(existUsers);
    // }, [existUsers]);

    //Code to get all Users
    function fetchAllUsers(){
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/users/all`)
                .then((response) => {
                    // console.log(response);
                    setUsers(response.data)
                    fetchUserRequests();
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    
                })
                .catch((err) => {
                    // console.log(err);
                    fetchUserRequests();
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    
                });

        }
    }

    //Code to fetch User Requests
    function fetchUserRequests() {
        let Orgid = localStorage.getItem('orgid')
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/organizations/${Orgid}/newusers`)
                .then((response) => {
                    // console.log(response);
                    setUsersRequests(response.data);
                    fetchExistingUsers();
                    // fetchNodeNetworks()

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    // fetchNodeNetworks()
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    //Code to fetch existing users
    function fetchExistingUsers() {
        let Orgid = localStorage.getItem('orgid')
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/users/all/${Orgid}`)
                .then((response) => {
                    console.log(response.data.result);
                    OrgUserListdata = response.data.result;
                    setOrgUser(response.data.result);
                    
                    // filterExistingUsers(response.data);

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    console.log(OrgUserListdata);
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    //code to filter current org existing users
    function filterExistingUsers(data){
        let orgname = localStorage.getItem('orgname');
        let list = [];
        for(let i=0; i<data.length;i++){
            if(data[i].organizationName == orgname){
                list.push(data[i])
            }
        }
        setFinalExistingUsers(list)
    }

    //code to set final data
    function setFinalExistingUsers(input){
        setExistUsers(input)
    }

    //code to store new user role
    const updateNewRole = (idx) => (e) => {
        const value = e.target.value;
        let newArr = [...usersRequests]; // copying the old data array
        usersRequests[idx]["role"] = value;
        setUsersRequests(newArr);
        setEnable(true);
    };

    //code to store existing user role
    const updateExistingUserRole = (idx) => (e) => {
        const value = e.target.value;
        let newArr = [...existUsers]; // copying the old data array
        existUsers[idx]["role"] = value;
        setExistUsers(newArr);
    };

    //Code to approve New Request
    const approveNewRequest = (idx) => (e) => {
        let obj = usersRequests[idx];
        
        let data = {
            "role" : obj.role,
            "status" : 'approved'
        }

        // console.log(obj);

        let uuid = function(input){
            for(let i=0;i<users.length;i++){
                if(users[i].emailId == input){
                    return users[i].id;
                }
            }
        }

        // console.log(uuid(obj.emailId));
        if(uuid(obj.emailId) != '' || uuid(obj.emailId) != undefined || uuid(obj.emailId) != null){
            approveDenyCall(uuid(obj.emailId),data);
        }
    };

    //Code to approve New Request
    const denyNewRequest = (idx) => (e) => {
        let obj = usersRequests[idx];
        
        let data = {
            "status" : 'denied'
        }

        // console.log(obj);

        let uuid = function(input){
            for(let i=0;i<users.length;i++){
                if(users[i].emailId == input){
                    return users[i].id;
                }
            }
        }

        // console.log(uuid(obj.emailId));
        if(uuid(obj.emailId) != '' || uuid(obj.emailId) != undefined || uuid(obj.emailId) != null){
            approveDenyCall(uuid(obj.emailId),data);
        }
    };

    //Code to update User
    const updateUser = (idx) => (e) => {
        let obj = existUsers[idx];
        // console.log(obj);
    };

    /*
    //Code to Delete User
    const deleteUser = (idx) => (e) => {
        let obj = existUsers[idx];
        // console.log(obj);
    };*/

    function deleteUser(id) {
        let orgId = localStorage.getItem('orgid');
        const userId = id.getAttribute('name');
        actions({type : 'setState', payload : {...state , blocking : true}})
        swal({
			title: 'Are you sure?',
			text: 'User will be deleted and removed from the organization',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		  })
		  .then((willDelete) => {
			if (willDelete) {
				axiosInstance
				.delete(`${process.env.REACT_APP_LORA_URL}/organizations/${orgId}/users/${userId}`)
				.then((response) => {
					PopUp("User Deleted Successfully");
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    fetchUserRequests();
				})
				.catch((err) => {
					ErrorPopUp("Deleting Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
				});
			}
		  });
    }

    //Code to make api call for approve / deny new user
    function approveDenyCall(uuid,data){
        let Orgid = localStorage.getItem('orgid');
        // console.log('UUID', uuid);
        // console.log('Input Data', data);
        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
            .put(`${process.env.REACT_APP_LORA_URL}/organizations/${Orgid}/newusers/${uuid}`, data)
            .then((response) => {

                actions({type : 'setState', payload : {...state , blocking : true}});
                if(data.status == 'approved'){
                    PopUp("Approved Successfully");
                    console.log("hi1");
                 
                }else if(data.status == 'denied'){
                    PopUp("Denied Successfully");
                    console.log("hi2");
                }else{
                    PopUp("Unknown");
                    
                }
                
                actions({ type: 'setState', payload: { ...state, blocking: false } })
                fetchUserRequests();
            })
            .catch((err) => {
                
               
                ErrorPopUp("Request Failed. Try Again");
                actions({ type: 'setState', payload: { ...state, blocking: false } })
            });
        }
    }

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>User Requests</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        {usersRequests.length > 0 ? (
                            <>
                                <div style={{ padding: '3%' }}>
                                    <Table responsive borderless className="projectTableData">
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Organization</th>
                                                <th>Role</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersRequests.map((item, index) => (
                                                <tr>
                                                    <td>{item.emailId}</td>
                                                    <td>{item.organizationName}</td>
                                                    <td>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={updateNewRole(index)}
                                                            name="type"
                                                            size="sm"
                                                            required
                                                            custom
                                                        >
                                                            <option value="">Select Role</option>
                                                            <option value="user">User</option>
                                                            <option value="organization_admin">Organization Admin</option>
                                                            <option value="device_admin">Device Admin</option>
                                                        </Form.Control>
                                                    </td>
                                                    <td>
                                                        
                                                       <button type="button" disabled={!isenable} className="btn btn-sm btnDefault btnSpace" onClick={approveNewRequest(index)}>
                                                            Approve
                                                        </button>
                                                        
                                                        <button type="button" className="btn btn-sm btnNormal" onClick={denyNewRequest(index)}>
                                                            Deny
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        ) : (
                                <>
                                    <div className="project-table-group">
                                        <Table responsive borderless className="projectTableData">
                                            <thead>
                                                <tr>
                                                    <th>User</th>
                                                    <th>Organization</th>
                                                    <th>Role</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td rowspan="4">No Users Requests found</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            )}
                    </div>

                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Existing Users</h5>
                        </div>
                    </div>

                    <div className="default-main-page">
                        <div style={{ padding: '3%' }}>
                            <Table responsive borderless className="projectTableData">
                                <thead>
                                    <tr>
                                        <th>User Email</th>
                                        <th><center>Organization Admin</center></th>
                                        <th><center>Device Admin</center></th>
                                        <th><center>Organization User</center></th>
                                        <th><center>Delete User</center></th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {existOrgUsers.length > 0 ? (
                                        <>
                                            {existOrgUsers.map((existOrgUser, index) => (
                                                <tr key={index}>
                                                    <td>{existOrgUser.email}</td>
                                                    <td>{existOrgUser.isAdmin == true ?(
                                                            
                                                            <>
                                                                <center>
                                                                <i class="fa fa-check fa-lg"></i>
                                                                </center>
                                                                       
                                                              
                                                            </>
                                                            ):    
                                                                <>
                                                                <center>
                                                                <i class="fa fa-times fa-lg" aria-hidden="true"></i>
                                                                </center>
                                                                   
                                                                 
                                                                </>
                                                                  
                                                            }</td>
                                                    <td>
                                                        {existOrgUser.isDeviceAdmin == true ?(
                                                            
                                                            <>
                                                                <center>
                                                                <i class="fa fa-check fa-lg"></i>
                                                                </center>
                                                                       
                                                              
                                                            </>
                                                            ):    
                                                                <>
                                                                 <center>
                                                                 <i class="fa fa-times fa-lg" aria-hidden="true"></i>
                                                                 </center>
                                                                 
                                                                 
                                                                </>
                                                                  
                                                            }
                                                    </td>
                                                    {/* Org User logic */}
                                                    <td align='center'>
                                                        {existOrgUser.isDeviceAdmin != true && existOrgUser.isAdmin != true?(                                                            
                                                            <>
                                                                <i class="fa fa-check fa-lg"></i>                                                                  
                                                            </>
                                                            ):    
                                                            <>
                                                                <i class="fa fa-times fa-lg" aria-hidden="true"></i>
                                                            </>     
                                                            }
                                                    </td>
                                                    <td align='center'>
                                                     {existOrgUser.isAdmin != true ?(
                                                        <>
                                                            <img src={deleteIcon} className="iconCustom" name={existOrgUser.userID} onClick={e => deleteUser(e.target)}/>
                                                        </>):
                                                        <>
                                                        -
                                                        </>}
                                                    </td>

                                                </tr>
                                            ))}
                                        </>
                                    ) :
                                        null
                                    }
                                </tbody>
                            </Table>
                        </div>

                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default UsersOrgApprove;
