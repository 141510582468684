import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import NodeTypeCheck from '../../UIElements/NodeTypeCheck';
// import Button from 'react-bootstrap/Button';
// import CheckBox from '../CheckBox/CheckBox';
import GoogleApiWrapper from '../NetworkMap/NetworkMap';
import CameraArenaNetworks from '../../UIElements/CameraArenaNetworks';
import ReactPlayer from 'react-player';
import CameraArenaFeed from '../../UIElements/CameraArenaFeed';
import VedioPlayer from '../../UIElements/VedioPlayer';
import RobotGroundTruth from '../../UIElements/RobotGroundTruth';


function RoboticArenaNodes(props) {
    const [arenaListView, setArenaListView] = useState(0)
    const [filters, setFilters] = useState([]);
    const [query, setQuery] = useState('');
    // const [data, setData] = useState(props.nodelist.data.nodes);
    const [originalData, setOriginalData] = useState([]);
    const [searchResult, setSearchResult] = useState('');
    const [searchText, setSearchText] = useState("");
    const [sort, setSort] = useState({
        column: null,
        direction: 'asc',
    });
    const [nodeTypeData, setNodeTypeData] = useState([
        { name: "RSE", isChecked: false },
        { name: "RSS", isChecked: false },
        { name: "5G", isChecked: false }
    ])
    const [networkNodes, setNetworkNodes] = useState([]);
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);
    useEffect(() => {
        // console.log(props.nodelist.data)

        if (Object.keys(props.nodelist).length > 0) {
            let input = props.nodelist.data.nodes

            setNetworkNodes(input)
            setOriginalData(input)
        }

    }, [props.nodelist]);


    /*const divStyle = {
  	display: 'flex';
  	flex-direction: 'row';
    };*/
    //Sorting the Table Columns Ascending and descending order
    const onSort = (column) => (e) => {

    };

    //Search table by node name based on user input 
    const doSearch = (event) => {

    }

    const search = () => {

    }

    // exclude column list from filter
    const excludeColumns = ["status"];

    // handle change event of search input
    const searchNodes = value => {
        setSearchText(value);
        filterNodes(value);
    };

    // filter records by search text
    const filterNodes = (value) => {
        const lowercasedValue = value.toLowerCase().trim();

        if (lowercasedValue === "") {
            setNetworkNodes(originalData);
        }
        else {
            const filteredData = originalData.filter(item => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
                );
            });
            // console.log(filteredData)
            setNetworkNodes(filteredData);

        }
    }


    //event to change type check
    function changeNodeType(e) {
        let indexval = e.target.getAttribute('indexid')
        let check = e.target.checked
        let inputval = e.target.value
        let newArr = [...nodeTypeData]; // copying the old datas array
        newArr[indexval]['isChecked'] = check;
        setNodeTypeData(newArr)
        filterNodesByType(originalData)
    }

    //code to filter the nodelist by type
    function filterNodesByType(a) {
        // console.log(data)
        // console.log(check)
        var data = a;

        var keys = [];

        nodeTypeData.map((val) => {
            if (val.isChecked == true) {
                keys.push(val.name)
            }
        })

        let array = []
        if (keys.length > 0) {

            keys.map((item) => {
                data.map((b) => {
                    if (b.nodeType == item) {
                        array.push(b)
                    }
                })
            })
        } else {
            array = data
        }

        setNetworkNodes(array)

    }

    //Code to open node table in mapview
    function openArenasList(e) {
        setArenaListView(1)
    }

    //Code to Close node table in mapview
    function closeArenasList(e){
        setArenaListView(0)
    }

    return (
        <>

            <div className="row create-exp-search">
                {/* Search Input for Node List */}
                <div className="col-md-4">
                    <div style={{ color: 'red'}}>
                        {error}
                    </div>
                    <input className="form-control serch-box" onChange={(e) => {
                        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                        if (!textFieldRegex.test(e.target.value)) {
                            setError(e.target.name + ' is invalid');
                            setIsValid(false);
                        }else{
                            setError('');
                            setIsValid(true);
                        }
                        searchNodes(e.target.value);
                    }} type="text" placeholder="Search" name="Search" aria-label="Search"></input>
                </div>
                {/* Filter Node List by RSS, RSE, 5G Networks */}
                <div className="offset-md-1 col-md-7 create-exp-filter">
                    <label style={{ paddingTop: "0.8rem" }}><b>Filter</b></label>

                    {nodeTypeData.map((item, index) => {
                        return (
                            <NodeTypeCheck key={index} id={index} event={(e) => changeNodeType(e)} {...item} />
                        )
                    })}
                </div>
	    </div>

            <div className="row" >
                {/* Node List in Google Mapview*/}
	    {/*<div style={{ paddingRight: "0.8rem" }}>
                     {networkNodes.length > 0 ?
                    <label style={{ paddingTop: "0.8rem" }}><b>Filter</b></label>
                <div className="row">
                    //    <CameraArenaNetworks/>
                        <VedioPlayer props={true}/>
                        :
                null}*/}
	    {/* </div>*/}
	          <div className="col-12 no-padding" >
                        <RobotGroundTruth props={true} mode= 'robot_arena'/> 
	    {/*<div style={{ paddingLeft: "0.8rem",  paddingRight: "0.8rem", border: '0.5px black'}} >*/}
                {/* {setArenaListView(1)} style={{ display:'inline-block'}*/}
                    {/* Arena List Table View */}
                    {arenaListView == 0 ? (
                        
                        <>
                            <i className="fas fa-bars nodeListTableIcon" onClick={(e) => openArenasList(e)}></i>
                        </>
                    ) : (
                        <div className="col-5 no-padding node-list nodesTable" >
                            <span className="create-exp-table-list">Arenas</span>
                            <span className="close" style={{cursor: 'pointer'}} onClick={() => closeArenasList()}>&times;</span> 	    
                            <div className="create-exp-table-wrapper">
                                <Table responsive borderless className="create-exp-table" >
                                    <thead>
                                        <tr >
                                            <th onClick={(e) => onSort('HostName')}>
                                                Name
                                                    <span className="sort-arrow" >
                                                    {/* <i onClick={(e) => setArrow('HostName')}></i> */}
                                                </span>
                                            </th>
                                            <th>Host</th>
                                            <th onClick={(e) => onSort('exp_type')}>
                                                Type
                                                    <span className="sort-arrow">
                                                    {/* <i className={(e) => setArrow('NodeType')}></i> */}
                                                </span>
                                            </th>
                                            <th onClick={(e) => onSort('exp_status')}>
                                                Status
                                                    <span className="sort-arrow">
                                                    {/* <i className={(e) => setArrow('HardwareStatus')}></i> */}
                                                </span>
                                            </th>
                                            {/* <th>View</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {networkNodes.length > 0 ? (
                                            <>
                                                {networkNodes.map((item, index) => {
                                                    return (
                                                        <tr key={item.hostName} id={item.hostName}>
                                                            <td>{item.hostName}</td>
                                                            <td>{item.friendlyName == null ? "-" : (<>{item.friendlyName}</>)}</td>
                                                            <td>{item.nodeType}</td>
                                                            <td>{item.status}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                <tr><td>No Nodes Found</td></tr>
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    )}
		</div>

	    {/*</div>
	          <div className="row" style={{  paddingLeft: "0.4rem", paddingRight: "0.1rem"}}>
                        <RobotGroundTruth props={true} mode= 'robot_arena'/> </div>*/}
            </div>
        </>
    )
}

export default RoboticArenaNodes;

