import React, {useState, useEffect, useContext} from 'react';
import Table from 'react-bootstrap/Table';
import { useAxios } from '../../utils/Axios';
import PopUp from '../../components/UIElements/PopUp';
import ErrorPopUp from '../../components/UIElements/ErrorPopup'
import Context from '../../store/Context';
import { Button } from 'bootstrap';

function SysAdminUserRequests() {
    const [allUsers , setAllUsers] = useState([]);
    const axiosInstance = useAxios();
    const {state , actions} = useContext(Context);

    useEffect(() => {
		getAllUsersLRequests();
    }, [axiosInstance]);

    // useEffect(() => {
	// 	console.log(allUsers);
    // }, [allUsers]);

    //Code to get all New Users Requests
    function getAllUsersLRequests(){
        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/un/users/adduser/all`)
				.then((response) => {
					// console.log(response);
                   pendingUsers(response.data)
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
                    // console.log(err);
                    // let resposne = [
                    //     {
                    //         "username":"narev090",
                    //         "affliation":"aff1",
                    //         "testbedPurpose":"testbed1",
                    //         "status":"pending", 
                    //     },
                    //     {
                    //         "username":"albert",
                    //         "affliation":"aff2",
                    //         "testbedPurpose":"testbed2",
                    //         "status":"pending", 
                    //     }
                    // ]

                    // pendingUsers(resposne);

                    actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
    }

    //Code to filter pending users
    function pendingUsers(data){
        let userarray = [];

        if(data.length > 0){
            data.forEach(element => {
                if(element.status == 'pending'){
                    userarray.push(element)
                }
            });

            setAllUsers(userarray)
        }
    }


    //Code to approve New Request
    const approveNewRequest = (idx) => (e) => {
        let obj = allUsers[idx];
        
        let data = {
            "username" : obj.username,
            "status" : 'approved'
        }

        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
            .put(`${process.env.REACT_APP_PROJECT_URL}/un/users/adduser`, data)
            .then((response) => {
                // console.log(response);
                actions({type : 'setState', payload : {...state , blocking : false}});
                let input = response.data.status;
                PopUp(input);
                getAllUsersLRequests();
            })
            .catch((err) => {
                actions({type : 'setState', payload : {...state , blocking : false}});
                if (err.response) {
					ErrorPopUp(err.response.data.detail)
                }else {
                    ErrorPopUp("Request Failed. Try Again")
                }

            });
        }
    };

    //Code to approve New Request
    const denyNewRequest = (idx) => (e) => {
        let obj = allUsers[idx];
        
        let data = {
            "username" : obj.username,
            "status" : 'denied'
        }

        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
            .put(`${process.env.REACT_APP_PROJECT_URL}/un/users/adduser`, data)
            .then((response) => {
                actions({type : 'setState', payload : {...state , blocking : false}});
                let input = response.data.status;
                PopUp(input);
                getAllUsersLRequests();
            })
            .catch((err) => {
                actions({type : 'setState', payload : {...state , blocking : false}});
                if (err.response) {
					ErrorPopUp(err.response.data.detail)
                }else {
                    ErrorPopUp("Request Failed. Try Again")
                }
            });
        }
    };

    return (
        <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '1rem 2.5rem' }}>
        <div className="bg-white jumbotron" style={{ marginRight: '5rem' }}>
        <h5>User Requests</h5>
            <Table responsive bordered className="userRequestTable">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Affiliation</th>
                        <th>Purpose of using testbed</th>
                        <th colSpan="2"></th>
                    </tr>
                </thead>
                <tbody>
                    {allUsers.length > 0 ? (
                        <>
                           {allUsers.map((item,index) => (
                                <tr key={index} >
                                    <td>
                                        {item.username}
                                    </td>
                                    <td>{item.email}</td>
                                    <td>{item.affiliation == null ? "-" : item.affiliation}</td>
                                    <td>{item.testbedPurpose == null ? "-" : item.testbedPurpose}</td>
                                    <td>
                                        <button type="button" className="btn btn-sm btnDefault btnSpace" onClick={approveNewRequest(index)}>
                                            Approve
                                        </button>
                                        <button type="button" className="btn btn-sm btnNormal" onClick={denyNewRequest(index)}>
                                            Deny
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </>
                    ) :null}
                </tbody>
            </Table>
        </div>
    </div>
    )
}

export default SysAdminUserRequests
