import React, { useState, useEffect, useContext } from 'react'
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { useAxios } from '../../../utils/Axios';
import ErrorPopup from '../../../components/UIElements/ErrorPopup'
import Context from '../../../store/Context'

function SearchRnProjects() {
    const [projects, setProjects] = useState([]);
    const [requestInfo, setRequestInfo] = useState({
        searchField: '',
        searchFieldType: ''
    });

    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        console.log(requestInfo);
    }, [requestInfo])

    //code to get projects list based on search input
    function getProjectsData(e) {
        e.preventDefault();

        actions({ type: 'setState', payload: { ...state, blocking: true } })

        axiosInstance
            .post(`${process.env.REACT_APP_PROJECT_URL}/rn/projects/search`, requestInfo)
            .then((response) => {
                console.log(response);
                setProjects(response.data);
                actions({ type: 'setState', payload: { ...state, blocking: false } })
            })
            .catch((err) => {
                console.log(err);
                ErrorPopup("Request Failed. Try Again");
                actions({ type: 'setState', payload: { ...state, blocking: false } })
            });
    }

    //code to store type
    function updateUserInput(e) {
        const value = e.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setRequestInfo({ ...requestInfo, [e.target.name]: e.target.value });
    }

    return (
        <RoboticsLayout>
            <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '2rem 2.5rem' }}>
                <div className="bg-white jumbotron" style={{ marginRight: '5rem' }}>
                    <h5>Search by</h5> <br />
                    <div style={{ color: 'red'}}>
                        {error}
                    </div>
                    <Form onSubmit={(e) => getProjectsData(e)}>
                        <Form.Row>
                            <div className="col-md-4">
                                <Form.Group controlId="projectType">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control as="select" defaultValue="Select" name="searchFieldType" onChange={(e) => updateUserInput(e)} required>
                                        <option value=''>Select</option>
                                        <option value='project_id'>Project Id</option>
                                        <option value='project_name'>Project Name</option>
                                        <option value='username'>Username</option>
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group controlId="formProjectId">
                                    <Form.Label style={{ visibility: 'hidden' }}>Project Id</Form.Label>
                                    <Form.Control name="searchField" onChange={(e) => updateUserInput(e)} autoComplete="off" required />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group>
                                    <Form.Label style={{ visibility: 'hidden' }}>Update</Form.Label>
                                    <Button disabled={!isValid}
                                        className="btnDefault"
                                        type="submit"
                                        style={{ display: 'block' }}
                                    >
                                        Submit
                            </Button>
                                </Form.Group>
                            </div>
                        </Form.Row>
                    </Form>
                    <br />
                    <div>
                        <h5>Projects</h5>
                        <Table responsive borderless className="projectTableData">
                            <thead>
                                <tr>
                                    <th>Project Id</th>
                                    <th>Project Name</th>
                                    <th>User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects.length > 0 ? (
                                    <>
                                        {projects.map((item) => (
                                            <tr key={item.projectId} >
                                                <td>
                                                    {/* <Link to={`/projects/${item.projectId}`}> */}

                                                    {
                                                        item.projectId
                                                    }

                                                    {/* </Link> */}
                                                </td>
                                                <td>
                                                    {
                                                        item.projectName.length < 21 ? (
                                                            <>{item.projectName.slice(0, 20)}</>
                                                        ) : (
                                                            <>{item.projectName.slice(0, 20) + '...'}</>
                                                        )
                                                    }

                                                </td>
                                                {/* <td>{item.experiment_count}</td> */}
                                                <td>
                                                    {
                                                        item.ownerName
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <tr><td colSpan='4' style={{textAlign : 'center'}}>No Projects</td></tr>
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </RoboticsLayout>
    )
}

export default SearchRnProjects;
