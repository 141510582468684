import React from 'react';
import './RadioButton.css';

const BinRadioButtons = ({key,binradiostate, name,binarytype, num, disabled, event}) => {
    // console.log(binradiostate);
    return (
        <div className="radioInput">
            <input 
                type="radio" 
                key={`${name}_${binarytype}`} 
                onChange={event} 
                value={`${name}_${binarytype}`} 
                checked={binradiostate === `${name}_${binarytype}_${num}`} 
                disabled={disabled} 
                name={name} 
                binarytype = {binarytype}
            /> {name}{`(${binarytype})`}
        </div>
    );
}
export default BinRadioButtons;