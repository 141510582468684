import React from 'react';
import Table from 'react-bootstrap/Table';
import { useAxios } from '../../utils/Axios';
import swal from 'sweetalert';
import PopUp from '../UIElements/PopUp';
import ErrorPopUp from '../UIElements/ErrorPopup'
import 'react-toastify/dist/ReactToastify.css';
import './Table.css';
import { FaCheck } from "react-icons/fa";
import deleteIcon from '../../assests/icons/delete.svg';

const FilesTable = ({ images, binaries, parentProp, changeCurState, projectrole }) => {
	const axiosInstance = useAxios();
	
	function deleteImage(n) {
		let imageName = n.getAttribute("imagename");
		let tagName = n.getAttribute("tagname");

		swal({
			title: 'Are you sure?',
			text: 'File will be permenantly deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_PROJECT_URL}/projects/${parentProp.params.id}/image`, {
							data: {
								"repoPath" : imageName + ":" +tagName,
								"imageType" : ""
							}
						})
						.then((response) => {
							PopUp("Image Deleted Successfully")
							changeCurState()
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});

	}

	function deleteBinary(n) {
		let binaryName = n.getAttribute("binaryname");

		swal({
			title: 'Are you sure?',
			text: 'File will be permenantly deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_PROJECT_URL}/projects/${parentProp.params.id}/binaries/${binaryName}`)
						.then((response) => {
							PopUp("File Deleted Successfully")
							changeCurState()
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});
	}

	// code to convert '/' to % for filenames
	function nameToPercent(name){
		// console.log(name);
		var myStr = name;
		var newStr = myStr.replace(/[/]/g, "%252F");
		return newStr
	}


	//Code to append tr dynamically for image files
	const GetFileName = ({keyid,name, scanOverview, version, imgsize,imagetype}) => {
		
		 return (
			<>
				<tr key={keyid}>
					<td>{name.name}_{version}{` (${imagetype})`}</td>
					<td>{imgsize} kb</td>
					<td>{scanOverview !== null ? <>{`${scanOverview.completePercent} %`}</> : '-'}</td>
					{/* <td>{`${name.scanOverview.completePercent} %`}</td> */}
					<td>{scanOverview !== null ? 
						<>
							{scanOverview.scanStatus == 'Success' ? <FaCheck /> : <>{scanOverview.scanStatus}</>}
						</> : '-'}
					</td>
					<td>{scanOverview !== null ? <>{scanOverview.severity}</> : '-'}</td>
					{
						projectrole == 'developer' || projectrole == 'projectAdmin' ? (
							<>
								<td><img src={deleteIcon} className="iconCustom" imagename={name.name} tagname={version} onClick={e => deleteImage(e.target)} /></td>
							</>
						) : null
					}
				</tr>
			</>
		 );
	 }

	return (
		<>
			<Table responsive borderless className="filesTable">
				<thead>
					<tr>
						<th>Name</th>
						<th>File size</th>
						<th>Scan Status</th>
						<th>Validation</th>
						<th>Severity</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					
					{images.map((item, index) => (
						<>
							{item.artifacts.map((version, i) => (								
								<GetFileName keyid={i} name={item} scanOverview={version.scanOverview} version={version.tag} imgsize={version.size} imagetype={version.type}/>
							))}
						</>
					))}
					{binaries.map((item) => (
						<tr key={item.name}>
							<td>{item.name}{` (${item.type})`}</td>
							<td>{item.size} kb</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							{
								projectrole == 'developer' || projectrole == 'projectAdmin' ? (
									<>
										<td><img src={deleteIcon} className="iconCustom" binaryname={item.name} onClick={e => deleteBinary(e.target)} /></td>
									</>
								) : null
							}
						</tr>
					))}
				</tbody>
			</Table>


		</>
	);
};

export default FilesTable;
