import React from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import './ConfigurationMap.css'
const exampleMapStyles = './MapStyles.json'

const __GAPI_KEY__ =  "AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo";


export class MapContainer extends React.Component {
  render() {
    // console.log(this.props.name)
    return (
      <Map google={this.props.google}
      className="map"
      initialCenter={{
        lat: 40.854885,
        lng: -88.081807
      }}
      zoom={15}
      defaultOptions={{ styles: exampleMapStyles }}
      >
 
        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
 
        {/* <InfoWindow onClose={this.onInfoWindowClose}>
            
        </InfoWindow> */}
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  // apiKey: (__GAPI_KEY__)
})(MapContainer)