import React, { useEffect, useState,useContext } from 'react'
import { Accordion, Card } from "react-bootstrap";
import { useAxios } from '../../../utils/Axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Context from '../../../store/Context'
import PopUp from '../../../components/UIElements/PopUp'
import ErrorPopup from '../../../components/UIElements/ErrorPopup'

function AddUpdateUsers(props) {
    const [projectUsers, setProjectUsers] = useState([]);
    const [userInfo , setUserInfo] = useState({}); // code to store dropdown username
    const [updateRole, setUpdateRole] = useState({projectRole : ''}); // code to store update role dropdown
    const [updateEmail , setUpdateEmail] = useState('');
    const [updateAddRole , setUpdateAddRole] = useState({projectRole : ''}); //code to store fresh user role for add user request
    const [reqstatus , setReqStatus] = useState({
        userDeregisterInfo : ''
    });
    const [reqAddstatus , setReqAddStatus] = useState({
        userInputsCheck : ''
    });
    const axiosInstance = useAxios();

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        getProjectUsers()
    }, [axiosInstance])

    //Code to fetch Individual Project Details
    const getProjectUsers = () => {
        if (axiosInstance) {
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/users`)
                .then((response) => {
                    // console.log(response.data);
                    setProjectUsers(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    };

    //Code to select user from list 
    function selectUserFromList(e){
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let name = e.target.name
        let val = optionElement.value;
        let role = optionElement.getAttribute('role');
        console.log(role);
        setUserInfo({ ...userInfo, [name]: val });
        setUpdateRole({ ...updateRole, ['projectRole']: role });
    }

    //code to store update user role
    const changeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setUpdateRole({ ...updateRole, [name]: value });
	};

    //code to send updated user role
    function updateUserRole(){
        if(Object.keys(userInfo).length == 0){
            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "Select User" });
            const timer = setTimeout(() => {
                setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }
        
        if(updateRole.projectRole == ''){
            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "Select Role" });
            const timer = setTimeout(() => {
                setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }

        let input = {
            "projectRole" : updateRole.projectRole
        }

        // console.log(input)
        if (axiosInstance) {
            axiosInstance
                .put(`${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/users/${userInfo.user_id}`, input)
                .then((response) => {
                    // console.log(response)
                    PopUp("Updated Successfully");
                    getProjectUsers();
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopup("Requst Failed")
                });

        }
    }

    //code to delete User
    function deleteUser(){
        if(Object.keys(userInfo).length == 0){
            setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "Select User" });
            const timer = setTimeout(() => {
                setReqStatus({ ...reqstatus, ["userDeregisterInfo"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }

        // console.log(input)
        if (axiosInstance) {
            axiosInstance
                .delete(`${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/users/${userInfo.user_id}`)
                .then((response) => {
                    // console.log(response)
                    PopUp("Deleted Successfully");
                    getProjectUsers();
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopup("Requst Failed")
                });

        }
    }

    //code to store email id
    function storeEmail(e){
        // console.log(e.target.value);
        const value = e.target.value;
		const textFieldRegex = /\S+@\S+\.\S+/;
		if (!textFieldRegex.test(value)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setUpdateEmail(e.target.value)
    }

    //code to store add user role
    function changeAddRole(e){
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let name = e.target.name
        let val = optionElement.value;
        // console.log(val);
        setUpdateAddRole({ ...updateAddRole, [name]: val });
    }

    //code to send add user request
    function addUser(event){
        if(updateEmail == ''){
            setReqAddStatus({ ...reqAddstatus, ["userInputsCheck"]: "Enter Email" });
            const timer = setTimeout(() => {
                setReqAddStatus({ ...reqstatus, ["userInputsCheck"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }

        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if(re.test(updateEmail) == false){
            setReqAddStatus({ ...reqAddstatus, ["userInputsCheck"]: "Email Incorrect" });
            const timer = setTimeout(() => {
                setReqAddStatus({ ...reqstatus, ["userInputsCheck"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }

        if(updateAddRole.projectRole == ''){
            setReqAddStatus({ ...reqAddstatus, ["userInputsCheck"]: "Select Role" });
            const timer = setTimeout(() => {
                setReqAddStatus({ ...reqstatus, ["userInputsCheck"]: "" });
            }, 3000);
            return () => clearTimeout(timer)

        }
        
        console.log('triggering');

        let input = {
            "emailId" : updateEmail,
            "projectRole" : updateAddRole.projectRole
        }

        // console.log(input)
        if (axiosInstance) {
            axiosInstance
                .post(`${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/users`, input)
                .then((response) => {
                    // console.log(response)
                    PopUp("Added Successfully");
                    getProjectUsers()
                })
                .catch((err) => {
                    if (err.response) {
                        console.log(err.response.data.detail);
                        if(err.response.data.detail !== undefined || err.response.data.detail !== null){
                            ErrorPopup(err.response.data.detail)
                        }else{
                            ErrorPopup("Requst Failed")
                        }
                      }
                });

        }
    }

    return (
        <div>
            <Accordion defaultActiveKey="">
                <Card style={{ 'border': '0.2px solid dimgrey' }}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" style={{ color: '#ffffff', backgroundColor: '#1c7b8e'}}>
                        Users
                        <i className="fa fa-plus" aria-hidden="true" style={{ float: 'right' }}></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="accBody">
                            <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '1rem' }}>
                                <div className="bg-white jumbotron" style={{ padding: 0 }}>
                                    <h6>Existing Users<span style={{ color: 'red' }}> {reqstatus.userDeregisterInfo}</span></h6>
                                    <Form>
                                        <Form.Row>
                                            <div className="col-md-4">
                                                <Form.Group controlId="formUsersList">
                                                    <Form.Label>Users List</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="user_id"
                                                        onChange={(e)=>selectUserFromList(e)}
                                                        custom
                                                    >
                                                        <option value='' role=''>Select User</option>
                                                        {projectUsers.map((item, index) => {
                                                            return (
                                                                <option value={item.userId} role={item.projectRole} key={index}>{item.userName}</option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4">
                                                <Form.Group>
                                                    <Form.Label>Select Role</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="projectRole"
                                                        onChange={changeHandler}
                                                        value={updateRole.projectRole}
                                                        required
                                                        custom
                                                    >
                                                        <option value=''>Select Role</option>
                                                        <option value='developer'>Developer</option>
                                                        <option value='user'>User</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-1" style={{marginRight : '20px'}}>
                                                <Form.Group>
                                                    <Form.Label style={{ visibility: 'hidden' }}>Deregister</Form.Label>
                                                    <Button className="btnDefault" style={{ display: 'block' }} onClick={() => updateUserRole()}>
                                                        Update
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-1">
                                                <Form.Group>
                                                    <Form.Label style={{ visibility: 'hidden' }}>Deregister</Form.Label>
                                                    <Button className="btnDefault" style={{ display: 'block' }} onClick={() => deleteUser()}>
                                                        Delete
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                        </Form.Row>
                                    </Form>
                                </div>
                            </div>
                            
                            <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '1rem' }}>
                                <div className="bg-white jumbotron" style={{ padding: 0 }}>
                                    <h6>Add User<span style={{ color: 'red' }}> {reqAddstatus.userInputsCheck}</span></h6>
                                    <div style={{ color: 'red'}}>
                                            {error}
                                    </div>
                                    <Form>
                                        <Form.Row>
                                            <div className="col-md-4">
                                                <Form.Group>
                                                    <Form.Label>Email id</Form.Label>
                                                    <Form.Control type="email" name="email" placeholder="Enter Email id" onChange={(e) => storeEmail(e)} required/>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-4">
                                                <Form.Group>
                                                    <Form.Label>Select Role</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        name="projectRole"
                                                        onChange={(e) => changeAddRole(e)}
                                                        required
                                                        custom
                                                    >
                                                        <option value=''>Select Role</option>
                                                        <option value='developer'>Developer</option>
                                                        <option value='user'>User</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-1" style={{marginRight : '20px'}}>
                                                <Form.Group>
                                                    <Form.Label style={{ visibility: 'hidden' }}>addusersubmit</Form.Label>
                                                    <Button disabled={!isValid} className="btnDefault" onClick={(e)=>addUser(e)}>
                                                        Submit
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                        </Form.Row>
                                    </Form>
                                </div>
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}

export default AddUpdateUsers
