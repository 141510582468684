import React, { useState, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import PopUp from '../UIElements/PopUp'
import ErrorPopUp from '../UIElements/ErrorPopup'
import { useAxios } from '../../utils/Axios';
import editIcon from '../../assests/icons/edit.svg';
import deleteIcon from '../../assests/icons/delete.svg';
import './ProjectTable.css';
import Context from '../../store/Context'


function ProjectTable ({ data, status, networks, editdeleteEnable }) {
	const axiosInstance = useAxios();
	const {state , actions} = useContext(Context);
	const [open, setOpen] = useState(false);
	const [newProject, setNewProject] = useState({
		projectName: '',
		projectDescription: '',
		testBedName: '',
	});;

	const [details, updateDetails] = useState({
		projectName: '',
		projectDescription: '',
		testBedName: '',
		projectId:''
	})

	const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	// console.log(data);
	//Code to update Project details
	const updateFormSubmit = (event) => {
		event.preventDefault();
		actions({type : 'setState', payload : {...state , blocking : true}})
		axiosInstance
			.put(`${process.env.REACT_APP_PROJECT_URL}/projects/${details.projectId}`, newProject)
			.then((response) => {
				setOpen(false);
				// status(true);
				actions({type : 'setState', payload : {...state , blocking : false}})
				PopUp("Project Updated Successfully")
				status(true)
			})
			.catch((err) => {
				// console.log(err);
				actions({type : 'setState', payload : {...state , blocking : false}})
				ErrorPopUp("Update Failed. Try Again")
			});
	};

	const changeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' text is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
		setNewProject({ ...newProject, [name]: value });
	};

	function changeTestBed(e) {
		let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let name = e.target.name
        let val = optionElement.value;
		setNewProject({ ...newProject, [name]: val });
	};


	//Code to get specific project details
	function updateProject(id) {
		// console.log(id.getAttribute('name'));
		const reqId = id.getAttribute('name');
		// console.log(reqId);
		actions({type : 'setState', payload : {...state , blocking : true}})
		axiosInstance
			.get(`${process.env.REACT_APP_PROJECT_URL}/projects/${reqId}`)
			.then((response) => {
				actions({type : 'setState', payload : {...state , blocking : false}})
				updateDetails({
					projectName:response.data.projectName,
					projectDescription:response.data.projectDescription,
					testBedName:response.data.testBedName,
					projectId: response.data.projectId
				})
				setNewProject({
					projectName:response.data.projectName,
					projectDescription:response.data.projectDescription,
					testBedName:response.data.testBedName,
				})
			})
			.then(
				setTimeout(() => {
					setOpen(true)
				}, 1000)
			)
			.catch((err) => {
				// console.log(err);
				actions({type : 'setState', payload : {...state , blocking : false}})
				ErrorPopUp("Fetching Project Details Failed. Try Again")
			});

	} 

	//Code to delete the project
	function deleteProject(id) {
		// console.log("Delete Project");
		
		const reqId = id.getAttribute('name');
		// console.log(reqId);

		swal({
			title: 'Are you sure?',
			text: 'All Project related files will be deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		  })
		  .then((willDelete) => {
			if (willDelete) {
				axiosInstance
				.delete(`${process.env.REACT_APP_PROJECT_URL}/projects/${reqId}`)
				.then((response) => {
					PopUp("Project Deleted Successfully")
					status(true)
				})
				.catch((err) => {
					// console.log(err);
					ErrorPopUp("Deleting Failed. Try Again")
				});
			}
		  });

		
	}

	return (
		<>
		<Table responsive borderless className="projectTableData">
			<thead>
				<tr>
					<th>Name</th>
					<th>Description</th>
					{/* <th>Experiments</th> */}
					<th>Network</th>
					<th>Owner Name</th>
					<th>Edit</th>
					<th>Delete</th>
				</tr>
			</thead>
			<tbody>
				{data.map((item) => (
					<tr key={item.projectId} >
						<td>
							<Link to={`/projects/${item.projectId}`}>

								{
									item.projectName.length < 21 ? (
										<>{item.projectName.slice(0, 20)}</>
									) : (
										<>{item.projectName.slice(0, 20) + '...'}</>
									)
								}
								
							</Link>
						</td>
						<td>
						{
							item.projectDescription.length < 21 ? (
								<>{item.projectDescription.slice(0, 20)}</>
							) : (
								<>{item.projectDescription.slice(0, 20) + '...'}</>
							)
						}
						
						</td>
						{/* <td>{item.experiment_count}</td> */}
						<td>{item.testBedName || ''}</td>
						<td>{item.ownerName || ''}	
						</td>
						<td>
							{item.currentUser == true ? (
								<>
									<img src={editIcon} className="iconCustom" name={item.projectId} onClick={e => updateProject(e.target)}/>
								</>
							) : null}
						</td>
						<td>
							{item.currentUser == true ? (
								<>
									<img src={deleteIcon} className="iconCustom" name={item.projectId} onClick={e => deleteProject(e.target)}/>
								</>
							) : null}							
						</td>							
					</tr>
				))}
			</tbody>
		</Table>

		<Modal
				show={open}
				onHide={() => setOpen(false)}
				animation={false}
				centered
				keyboard={false}
			>
				<Form onSubmit={updateFormSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Update Project</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="Container">
							<div className="row">
								<div className="col-md-12 col-sm-12 col-lg-12">
									<label style={{ marginBottom: '2rem' }}>
										<b>Details</b>
									</label>
									<div style={{ color: 'red'}}>
										{error}
									</div>
									<Form.Group controlId="projectName">
										<Form.Label>Project Name</Form.Label>
										<Form.Control
											name="projectName"
											onChange={changeHandler}
											type="text"
											placeholder="Enter a name for your Project"
											defaultValue={details.projectName}
											required
										/>
									</Form.Group>
									<Form.Group controlId="projectDesc">
										<Form.Label>Description</Form.Label>
										<Form.Control
											name="projectDescription"
											onChange={changeHandler}
											as="textarea"
											rows="2"
											placeholder="Enter a description for your Project"
											defaultValue={details.projectDescription}
											required
										/>
									</Form.Group>
									{/* {
										networks.length > 0 ? (
											<Form.Group controlId="projectNetwork">
												<Form.Label>Node Network</Form.Label>
												<Form.Control
													as="select"
													name="testBedName"
													onChange={(e) => changeTestBed(e)}
													value={newProject.testBedName}
													required
													custom
												>
													<option value="">Select Node Network</option>
													{networks.map((item , index) =>{
														return (
															<option value={item.name} key={index}>{item.name}</option>
														)
													})}
												</Form.Control>
											</Form.Group>
										) : (
											<Form.Group controlId="projectNetwork">
												<Form.Label>Node Network</Form.Label>
												<Form.Control>
													<option>No Networks Found</option>
												</Form.Control>
											</Form.Group>
										)
									} */}
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className = "btnNormal"
							variant="btnColor btn-primary-outline"
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button disabled={!isValid} className="btnDefault" type="submit">
							Update Project
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		
		</>
	);
};

export default ProjectTable;
