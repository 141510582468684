import React, {useState,useContext} from 'react';
import './LoraLayout.css';
import NewHeader from '../../Header/NewHeader';
import Context from '../../../store/Context'

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader, Types } from 'react-loaders';

import 'loaders.css/loaders.min.css';
import LoraNewSideBar from '../LoraSidebar/LoraNewSideBar';

function LoraLayout ({children}, props){

	const [apiLoader , setApiLoader] = useState({loaderType: 'ball-triangle-path'})
	
	const {state , actions} = useContext(Context);

	return(
		<BlockUi tag="div" message="Loading" blocking={state.blocking}>
			<LoraNewSideBar children={children} />
		</BlockUi>
	)
}

export default LoraLayout; 