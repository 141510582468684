import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useAxios } from '../../../utils/Axios';
//import './ProjectTable.css';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CheckBox from '../../CheckBox/CheckBox';
import Button from 'react-bootstrap/Button';
import { DatePicker, TimePicker } from 'react-tempusdominus-bootstrap';
import editIcon from '../../../assests/icons/edit.svg';
import archiveIcon from '../../../assests/icons/archive.svg'
import deleteIcon from '../../../assests/icons/delete.svg';

const ExperimentTable = ({ data, status, project , changeCurState, projectrole }) => {

	const axiosInstance = useAxios();
	const [open, setOpen] = useState(false);
	const [oldExp, setOldExp] = useState({
		name: '',
		description: '',
		id : ''
	});

	const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	// useEffect(()=>{
	// 	console.log(project);
	// },[project])

	//Code to fetch Experiment Info and adding in state for inputs
	function updateExpRequest(id) {
		const reqId = id.getAttribute('name');
		axiosInstance
			.get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${reqId}`)
			.then((response) => {
				setOldExp({
					name: response.data.name,
					description: response.data.description,
					id : response.data.id
				})
				setOpen(true)

			})
			.catch((err) => {
				// console.log(err);
				ErrorPopUp("Request Failed. Try Again")
			});
	}

	//Code to Update Experiment 
	function updateExperiment() {
		let expData = {
			"name" : oldExp.name,
			"description" : oldExp.description
		}
		axiosInstance({
            method: 'put',
            url: `${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${oldExp.id}`,
            data: expData,
        })
        .then((response) => {
            // console.log(response);
			PopUp("Experiment Updated Successfully");
			setOpen(false);
        })
        .then(
            changeCurState()
        )
        .catch((err) => {
            // console.log(err);
            ErrorPopUp("Request Failed. Try Again")
        })
	}

	//Code to delete Experiment
	function deleteExperiment(id) {
		const reqId = id.getAttribute('name');
		const projectId = project.projectId;
		console.log('project id ', projectId);
		console.log('experiment id', reqId);
		// alert(reqId);
		swal({
			title: "Are you sure?",
			text: "All Experiment Data will be deleted",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/${projectId}/experiments/${reqId}`)
						.then((response) => {
							PopUp("Experiment Deleted Successfully")
							sendUpdateState()
						})
						// .then(
						// 	sendUpdateState()
						// )
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});

	}

	//Code to move experiment to archive
	function archiveExperiment(id){
		const reqId = id.getAttribute('name');
		// alert(reqId);
		const setArchive = {
			"archive" : true
		}

		swal({
			title: "Are you sure?",
			text: "Experiment Will be Archived",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.put(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${reqId}`, setArchive)
						.then((response) => {
							PopUp("Experiment Archived Successfully")
							sendUpdateState()
						})
						// .then(
						// 	sendUpdateState()
						// )
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Archiving Failed. Try Again")
						});
				}
			});
	}

	let projectname = project.projectName;
	// let projectid = project.projectId

	function storeProject(e){
		let projectName = e.getAttribute('projectname');
		let expName = e.getAttribute('name')
		let description = e.getAttribute('desc')
		let expId = e.getAttribute('expid')
		localStorage.removeItem('projectId');
		localStorage.removeItem('expName')
		localStorage.removeItem('description')
		localStorage.removeItem('expid')
		localStorage.setItem('projectId', project.projectId);
		localStorage.setItem('expid', expId);
		localStorage.setItem('expName', expName)
		localStorage.setItem('description', description)

	}

	//Code to extract date
	function getDate(a){
		// console.log(a)
		// console.log(a.robotSimulatorDetails)
		// let input = a;
		// let date = new Date(input * 1000).toDateString()
		if(a.robotSimulatorDetails == null){
			return '-'
		}else{
			// let date = a.creationTime.split('T').join(' ')
			let input = new Date(a.schedule.startTime*1000);
			let date = input.toLocaleString();
			return date;
		}
	}

	//function to convert minutes to hours and Minutes
	function time_convert(num){
		let hours = Math.floor(num / 60);  
		let minutes = num % 60;
		return hours + ' Hrs ' + ' : ' + minutes + ' Min ';  
	}

	//Code to update name
	function changeName(e){
		// console.log(e.target.value)
		let input = e.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(input)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
		setOldExp((prevState) => {
            prevState.name = input;
            return {
                ...prevState
            };
        });
	}

	//Code to update Description
	function changeDescription(e){
		let input = e.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(input)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
		setOldExp((prevState) => {
            prevState.description = input;
            return {
                ...prevState
            };
        });
	}

	//Function to change state for CRUD operations
	const sendUpdateState = () => {
		changeCurState() 
	}

	// console.log(data);
	return (
		<>
			<Modal show={open} animation={false} centered backdrop="static" keyboard={false} >
				<Modal.Header>
					<Modal.Title id="create-exp-title">Update Experiment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="Container">
						<div className="row">
							<div style={{ color: 'red'}}>
								{error}
							</div>
							<div className="col-md-12 col-sm-12 col-lg-12 create-exp-first">
								<label ><b>Details</b></label>
								<div>
									<Form id="formPage">
										<Form.Group>
											<Form.Label>Experiment Name</Form.Label>
											<Form.Control id="proj-name" type="text" value={oldExp.name} onChange={(e)=>changeName(e)}/>
										</Form.Group>
									</Form>
									<label >Description</label>
									<textarea className="form-control" type="text" value={oldExp.description} rows="3" onChange={(e)=>changeDescription(e)}></textarea>
								</div>
								<div className="create-exp-footer">
									<Button className="btnNormal btnSpace" onClick={() => setOpen(false)}>
										Cancel
                                    </Button>
									<Button disabled={!isValid} type="submit" className="btnDefault" onClick={(e) => updateExperiment(e)}>
										Submit
                                    </Button>
								</div>
							</div>

						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Table responsive borderless className="filesTable">
				<thead>
					<tr>
						{/* <th>Id</th> */}
						<th>Name</th>
						{/* <th>Description</th> */}
						<th>Start Time</th>
						{/* <th>Start</th> */}
						<th>Duration</th>
						<th>Status</th>
						{
							projectrole == 'developer' || projectrole == 'projectAdmin' ? (
								<>
									<th>Edit</th>
									<th>Archive</th>
									<th>Delete</th>
								</>
							) : null
						}
					</tr>
				</thead>
				<tbody>
					{data.map((item) => (
						<tr key={item.id}>
							{/* <td>
								<Link to={`/robotics/projects/${item.id}/experiments/${item.id}`} name={item.name} projectname = {projectname} desc={item.description} expid={item.id} onClick={e => storeProject(e.target)}>
								
									{
										item.id.length < 15 ? (
											<>{item.id.slice(0, 15)}</>
										) : (
											<>{item.id.slice(0, 15) + ' ...'}</>
										)
									}
								</Link>
							</td> */}
							<td>
								<Link to={`/robotics/projects/${project.projectId}/experiments/${item.id}`} name={item.name} projectname = {projectname} desc={item.description} expid={item.id} onClick={e => storeProject(e.target)}>
									
									{
										item.name.length < 15 ? (
											<>{item.name.slice(0, 15)}</>
										) : (
											<>{item.name.slice(0, 15) + ' ...'}</>
										)
									}
								</Link>
							</td>
							{/* <td>
								{
									item.description.length < 21 ? (
										<>{item.description.slice(0, 20)}</>
									) : (
										<>{item.description.slice(0, 20) + ' ...'}</>
									)
								}
							</td> */}
							<td>
								{getDate(item)}
							</td>
							<td>{time_convert(item.schedule.duration)}</td>
							<td>{item.status}</td>
							{/* <td><img src={editIcon} className="iconCustom" name={item.id} onClick={e => updateExpRequest(e.target)} /></td> */}
							{
								projectrole == 'developer' || projectrole == 'projectAdmin' ? (
									<>
										<td>
											{
											item.status == 'Running' || item.status == 'cancelled' || item.status == 'Ended' ? (
													<></>
												) : (
													<>
														<img src={editIcon} className="iconCustom" name={item.id} onClick={e => updateExpRequest(e.target)} />
													</>
												)
											}
										</td>
										<td><img src={archiveIcon} className="iconCustom" name={item.id} onClick={e => archiveExperiment(e.target)} /></td>
										<td><img src={deleteIcon} className="iconCustom" name={item.id} onClick={e => deleteExperiment(e.target)} /></td>
									</>
								) : null
							}							
						</tr>
					))}
				</tbody>
			</Table>

		</>
	);
};

export default ExperimentTable;
