import React, { useEffect, useState, useContext } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import { useAxios } from '../../utils/Axios';
import Context from '../../store/Context'
import VedioPlayer from '../UIElements/VedioPlayer';
import axios from 'axios';

/*
import React, { useEffect, useState, useContext } from 'react';
import { useVideojs } from 'react-videojs-hook';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../utils/Axios';
import axios from 'axios';
import Context from '../../store/Context'
import PopUp from '../UIElements/PopUp';
import ErrorPopUp from '../UIElements/ErrorPopup';
*/
function RobotGroundTruth(props) {
    // const [url , setUrl] = useState(props.url)
     const [HMurl , setHMUrl] = useState('');
    const [experimentResult, setExperimentResult] = useState('');
    const [heatMap, setHeatMap] = useState('');	
    const [groundTruth, setGroundTruth] = useState('');	
    const [overlayData, setOverlayData] = useState('');	
    const [mapOverlayToken, setMapOverlayToken] = useState('');	
    const [getMapOverlay , setMapOverlay] = useState(false);
    const [getMapOverlayOption , setMapOverlayOption] = useState("Groundtruth");
    const { state, actions } = useContext(Context);
    const axiosInstance = useAxios();
    const [geoserverURL, setGeoserverURL] = useState('');	
	var fetchedToken = "";
	var imgsrc = "";
	var currentMapOverlayOption = "Groundtruth";
    	
	/*useEffect(() => {
        // convertUrl(url)
        fetchExperimentResult()
    }, [axiosInstance])
*/
	useEffect(() => {
        fetchMapOverlayDetails()
    }, [axiosInstance])
   

    const fetchGroundTruthData = () => {
        if (axiosInstance) {
			let token = fetchedToken;
			if(token == '' || token == null){
				token = mapOverlayToken;
			}
		let AuthStr = 'Bearer '+ token;
	    	axiosInstance
	    	.get(`${process.env.REACT_APP_BASE_URL}/device/1.0.0/map`,  { 'headers': { 'Authorization': AuthStr } })
                .then((response) => {
         	    
		    var data = response.data
			var replaceData = "xmlhttp.setRequestHeader('Authorization', 'Bearer "+token+"');"
			var origTxt= " xmlhttp.send();"
			var replaceExpIdData = "experimentid="+`${props.expid}`+"\"";
			var origExpidTxt= "experimentid=\"+Math.random()";
			var newTxt = data.replace("xmlhttp.send();",replaceData+origTxt)
			data = newTxt.replace(origExpidTxt,replaceExpIdData)
		        //data = response.data
          
			if( currentMapOverlayOption == "Groundtruth" ){
			    setGroundTruth(data)
			    setOverlayData(data)
			}
			   })
                .then(
                    setTimeout(() => {
                        // fetchImages()
                    }, 2000)
                )
                .catch((err) => {
                     console.log(err);            
                });

        }
    }
    const fetchHeatmapData = async () => {
         
        if (axiosInstance ) {        
		        let token = fetchedToken;
                        if(token == '' || token == null){
                                token = mapOverlayToken;
                        }
			
			let AuthStr = 'Bearer '+ token;
		axiosInstance
	    	.get(`${process.env.REACT_APP_BASE_URL}/device/1.0.0/map?maptype=heatmap`,  { 'headers': { 'Authorization': AuthStr } })
                .then(async (response) => {
         	    console.log("HeatmapData=" +response.data)
		    var data = response.data
			var replaceData = "xmlhttp.setRequestHeader('Authorization', 'Bearer "+token+"');"
			var origTxt= " xmlhttp.send();"
			var newTxt = data.replace("xmlhttp.send();",replaceData+origTxt)

			var subString = newTxt.substring( newTxt.indexOf("/geoserver") ,     newTxt.indexOf("2.5\"/>")+3);
			
			if((imgsrc == '' || imgsrc == null) && (geoserverURL == '' || geoserverURL == null))
			   await fetchHeatmapImageData(subString);	
			
			var replaceDataGeo = geoserverURL; 
			if(replaceDataGeo == '' || replaceDataGeo == null){
                 replaceDataGeo = imgsrc;
             }
             data = newTxt.replace(subString,replaceDataGeo+"\" loading=\"lazy")
			 
			if(currentMapOverlayOption == "Heatmap" ){
		            setHeatMap(data)
			    setOverlayData(data)
			}
                })
                .then(
                    setTimeout(() => {
                    }, 2000)
                )
                .catch((err) => {
                     console.log(err);
                });

        }
    }
	
	async function fetchHeatmapImageData (url)  {
         //console.log("fetchHeatmapImageData url= "+url)
        if (axiosInstance ) {         
         //console.log("fetchHeatmapImageData inside axiosInstance")
			let token = fetchedToken;
                        if(token == '' || token == null){
                                token = mapOverlayToken;
                        }	
			let AuthStr = 'Bearer '+ token;
			//Robot heatmap endpoint sends the url with &amp, so removing that before request send
			var unencoded = url.replaceAll("&amp;","&");
			url = unencoded;
         	
			await axiosInstance
	    	.get(url,  { 'headers': { 'Authorization': AuthStr },  'responseType': 'arraybuffer' })
                .then((response) => {
         	
	   	    imgsrc = URL.createObjectURL(
                                                new Blob([response.data], { type: 'image/png' }))
         	
		    setGeoserverURL(imgsrc);
		    var data = response.data
			 var iframe = document.getElementById('heatmapframe');
			if(null != iframe &&  null != iframe.contentWindow && currentMapOverlayOption == "Heatmap")
				iframe.contentWindow.location.reload();
			else
				 console.log("HeatmapImageData iframe is null");
                })
                .then(
                    setTimeout(() => {
                        // fetchImages()
                    }, 2000)
                )
                .catch((err) => {
                     console.log(err);
                });

        }
    }

	const fetchMapOverlayDetails = () => {
        
        if (axiosInstance) {
        
            axiosInstance
                .get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/arena/mapoverlay`)
		.then((response) => {
                    setMapOverlayToken(response.data.token)
        
			fetchedToken = response.data.token;
                })
                .then(
                    setTimeout(() => {
                        // fetchImages()
			fetchGroundTruthData()
        		fetchHeatmapData()    
                    }, 2000)
                )
                .catch((err) => {
                });

        }
    }
    const fetchExperimentResult = () => {
        // console.log(props.expid)
        if (axiosInstance) {
            // actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${props.expid}/result`)
                .then((response) => {
                    setExperimentResult(response.data)
                })
                .then(
                    setTimeout(() => {
                        // fetchImages()
                    }, 2000)
                )
                .catch((err) => {
                    // console.log(err);
                });

        }
    }

    //code to get map overlay data from api and store it
    function loadMapOverlay(e){
        
    	//console.log("loadMapOverlay ="+e);
        if(e == "mapOverlay"){
            setMapOverlay(true);
	    fetchGroundTruthData();
	    }else {
            setMapOverlay(false);
        }
    }

      const onValueChange= (e) => {

    	//console.log(e.target.value);
	currentMapOverlayOption = e.target.value;
	setMapOverlayOption(e.target.value);
	if(e.target.value == "Groundtruth"){
	  fetchGroundTruthData();
	   	
	}else {
	   fetchHeatmapData();		
	}
    };
    return (
        <>
	     {props.mode == 'robot_arena' ? (
                <>
             <h5 className="create-proj-text-color"></h5>
		     <div className="row arenadivVerticalStyle" >
				    <div align="left">
        				<input type="radio" style={{textAlign: 'left', marginTop: "1rem", marginBottom: "1rem", marginLeft: "2.2rem", marginRight: "0.5rem"}} value="Groundtruth" name="Overlay type" defaultChecked="true"    onChange={onValueChange} /> Groundtruth
				        <input type="radio"  style={{textAlign: 'left', marginTop: "1rem", marginBottom: "1rem", marginLeft: "2rem", marginRight: "0.5rem"}} value="Heatmap" name="Overlay type"  onChange={onValueChange} /> Heatmap
				    </div>
                    <div align="left"  style={{ marginLeft: "2.2rem" }}>
                    { <iframe width="850" height="700" srcDoc={overlayData} id="arenaheatmapframe"  >
				         </iframe>}
				    </div>
				    </div>
                </>
                
	     ): (
                <>
                    <h5 className="create-proj-text-color">No Robot Arena Found</h5>
                </>
            )}

        </>
    )
}

export default RobotGroundTruth;

// {Object.keys(experimentResult).length > 0 ? (
//     <>
//         <h5 className="create-proj-text-color">Robot Arena</h5>
//         <Tabs defaultActiveKey="camera">
//             <Tab eventKey="camera" title="Camera">
//                 {/* <iframe src={`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/${experimentResult.robotArena.resultVideo1Url}`} width="100%" height="500" frameBorder="0"></iframe> */}
//                 <iframe src={`${experimentResult.resultUrl1}`} width="100%" height="500"></iframe>
//             </Tab>
//             <Tab eventKey="mapOverlay" title="MapOverlay">
//                 {/* <iframe src={`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/${experimentResult.robotArena.resultVideo2Url}`} width="100%" height="500" frameBorder="0"></iframe> */}
//                 <iframe src={`${experimentResult.resultUrl2}`} width="100%" height="500"></iframe>
//             </Tab>
//         </Tabs>
//     </>
// ) : (
//     <>
//         <h5 className="create-proj-text-color">No Data Found</h5>
//     </>
// )}
