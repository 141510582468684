import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import swal from 'sweetalert';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import logo from '../../../assests/icons/umbrella-logo3.png';
import { Image } from 'react-bootstrap';
import PopUp from '../../../components/UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';

import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesIcon from '@mui/icons-material/Devices';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import AppsIcon from '@mui/icons-material/Apps';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FilterListIcon from '@mui/icons-material/FilterList';

import './LoraNewSideBar.css';
import { FormControl, Grid, InputLabel, Menu, MenuItem, Paper, Select, TextField, Tooltip } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { AccountCircle } from '@mui/icons-material';

import CopyrightIcon from '@mui/icons-material/Copyright';
import InfoIcon from '@mui/icons-material/Info';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AssignmentIcon from '@mui/icons-material/Assignment';

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function LoraNewSideBar(props) {
    let children = props.children;
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { state, actions } = useContext(Context);
    const axiosInstance = useAxios();
    const { keycloak } = useKeycloak();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentUsername, setCurrentUsername] = useState('');
    const [currentUserid, setCurrentUserid] = useState('');


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTimeout(() => {
            getUserid();
            getUsername()
        }, 2000);
    }, [])

    useEffect(() => {
        if (currentUserid == null || currentUserid == '') {
            getUserid();
        }
    }, [currentUserid])

    useEffect(() => {
        if (currentUsername == null || currentUsername == '') {
            getUsername();
        }
    }, [currentUsername])

    //code to get data from localstorage
    function getUsername() {
        const username = localStorage.getItem('name');
        setCurrentUsername(username);
    }

    //code to get data from localstorage
    function getUserid() {
        const userid = localStorage.getItem('userid');
        setCurrentUserid(userid);
    }

    //Code to self deregister user
    function deregister(e) {
        swal({
            title: 'Are you sure?',
            text: 'You will be deregistered Permanently',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (axiosInstance) {
                        axiosInstance
                            .post(`${process.env.REACT_APP_PROJECT_URL}/users/deregistration/${currentUserid}`)
                            .then((response) => {
                                if (response.response) {
                                    PopUp(response.response.data.detail)
                                } else {
                                    PopUp("Success")
                                }

                                const timer = setTimeout(() => {
                                    clearLocalstorage()
                                }, 500);
                                return () => clearTimeout(timer)

                            })
                            .catch((err) => {
                                if (err.response) {
                                    ErrorPopUp(err.response.data.detail)
                                } else {
                                    ErrorPopUp("RequestFailed")
                                }
                            });
                    }
                }
            });
    }

    function profile(e) {
        history.push('/profile');
    }

    //code to clear localstorage when user logged out
    function clearLocalstorage() {
        history.push('/home');
        localStorage.clear();
        keycloak.logout();
    }


    const goToHome = () => history.push('/home');
    const goToUsers = () => history.push('/lora/orguserrequests');

    const loraOrgListDropDown = <LoraOrgListDropDown />;
    const [value, setValue] = useState(localStorage.getItem("orgid"));
    const [orgList, setOrgList] = useState([]);
    const [orgname, setOrgName] = useState('');
    const [optionsEnabled, setOptionsEnabled] = useState({
        usersOption: false,
        orgKeysOption: false,
        serviceProfileOption: false,
        deviceProfileOption: false,
        applicationsOption: false,
        adminOption: false
    })


    useEffect(() => {
        validateOptions();
    }, [axiosInstance])

    // code to check options avaliable for current user
    function validateOptions() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ["ln_user"]

        // console.log(roles.includes('ln_admin'));
        if (roles.includes('ln_admin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    usersOption: false,
                    orgKeysOption: true,
                    serviceProfileOption: true,
                    deviceProfileOption: true,
                    applicationsOption: true,
                    adminOption: true
                })
            )
        }
        if (roles.includes('ln_orgadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    usersOption: true,
                    orgKeysOption: true,
                    serviceProfileOption: true,
                    deviceProfileOption: true,
                    applicationsOption: true,
                    adminOption: false
                })
            )
        }
        if (roles.includes('ln_user')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    usersOption: false,
                    orgKeysOption: false,
                    serviceProfileOption: true,
                    deviceProfileOption: true,
                    applicationsOption: true,
                    adminOption: false
                })
            )
        }
        if (roles.includes('ln_deviceadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    usersOption: false,
                    orgKeysOption: false,
                    serviceProfileOption: true,
                    deviceProfileOption: true,
                    applicationsOption: true,
                    adminOption: false
                })
            )
        }

        checksystemadmin();
    }

    const handleChange = (e) => {
        setValue(e.target.value);
        localStorage.setItem('orgid', e.target.value);
        history.push('/lora');
    };


    //code to check the user type
    function checksystemadmin() {
        var roles = JSON.parse(localStorage.getItem("roles"));

        if (roles.includes('ln_admin')) {
            // console.log('system Admin')
            if (axiosInstance) {
                axiosInstance
                    .get(`${process.env.REACT_APP_LORA_URL}/organizations?limit=5000`)
                    .then((response) => {
                        setOrgList(response.data.result);
                        let data = response.data.result[0];
                        console.log(data);
                        if (localStorage.getItem("orgid") == null ||
                            localStorage.getItem("orgid") == undefined ||
                            localStorage.getItem("orgid").length == 0) {
                            localStorage.setItem('orgid', data.id);
                            setValue(data.id);
                        }
                        setOrgName(data.name);
                    })
                    .catch((err) => {

                    });
            }
        } else {
            let localorgname = localStorage.getItem('orgname');
            setOrgName(localorgname)
        }
    }

    function LoraOrgListDropDown() {
        return (
            <>
                <Tooltip title="Organization" placement="right">
                    <ListItemButton >
                        <ListItemIcon>
                            <FilterListIcon style={{ color: "#1c7b8e" }} />
                        </ListItemIcon>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Organization</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={value}
                                label="Organization"
                                onChange={handleChange}
                            >
                                {orgList.map(item => (
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ListItemButton>
                </Tooltip>
            </>
        )
    }


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} color='inherit'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                        <a href="#/home"><Image src={logo} className="custom-icon" /></a>
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 4 }}>
                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            {currentUsername} <AccountCircle style={{ color: "#1c7b8e" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={(e) => profile(e)}>Profile</MenuItem>
                            <MenuItem onClick={(e) => deregister(e)}>Deregister</MenuItem>
                            <MenuItem onClick={() => clearLocalstorage()}>Logout</MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 6 }} >
                        <Tooltip title="Report Issue" placement="bottom-start">
                            <a href="https://report.umbrellaiot.com" target="_blank">
                                <ReportProblemIcon style={{ color: "#1c7b8e" }} />
                            </a>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open} color='inherit'>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />


                <List >
                    <Tooltip title="Home" placement="right">
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={goToHome}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <HomeIcon style={{ color: "#1c7b8e" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Home"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </Tooltip>

                    {optionsEnabled.usersOption == true ? (
                        <Tooltip title="Users" placement="right">
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                onClick={goToUsers}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <GroupsIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary={"Users"} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Tooltip>
                    ) : null}

                    {optionsEnabled.adminOption == true ? (
                        <>
                            {loraOrgListDropDown}
                        </>
                    ) :
                        <>
                            <Divider />
                            <ListItemButton onClick={() => history.push('/lora')}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary={orgname} sx={{color: 'black'}}/>
                         </ListItemButton>

                        </>
                    }

                </List>
                <Divider />
                <List>
                    <Tooltip title="Dashboard" placement="right">
                        <ListItemButton onClick={() => history.push('/lora')}>
                            <ListItemIcon>
                                <DashboardIcon style={{ color: "#1c7b8e" }} />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItemButton>
                    </Tooltip>
                    {optionsEnabled.serviceProfileOption == true ? (
                        <Tooltip title="Service Profiles" placement="right">
                            <ListItemButton onClick={() => history.push('/lora/service-profiles')}>
                                <ListItemIcon>
                                    <MiscellaneousServicesIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Service Profiles" />
                            </ListItemButton>
                        </Tooltip>
                    ) : null}
                    {optionsEnabled.deviceProfileOption == true ? (
                        <Tooltip title="Device Profiles" placement="right">
                            <ListItemButton onClick={() => history.push('/lora/device-profiles')}>
                                <ListItemIcon>
                                    <DevicesIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Device Profiles" />
                            </ListItemButton>
                        </Tooltip>
                    ) : null}
                    {optionsEnabled.applicationsOption == true ? (
                        <Tooltip title="Applications" placement="right">
                            <ListItemButton onClick={() => history.push('/lora/applications')}>
                                <ListItemIcon>
                                    <AppsIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Applications" />
                            </ListItemButton>
                        </Tooltip>
                    ) : null}
                </List>
                <Divider />
                <List>
                    {optionsEnabled.adminOption == true ? (
                        <Tooltip title="Admin" placement="right">
                            <ListItemButton onClick={() => history.push('/lora/admin/userrequests')}>
                                <ListItemIcon>
                                    <AdminPanelSettingsIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Admin" />
                            </ListItemButton>
                        </Tooltip>
                    ) : null}
                </List>
                <Divider />


                <List sx={{ marginTop: 'auto', fontSize: '0.5rem' }}>
                    <Divider />
                    <a href="https://wiki.umbrellaiot.com" target="_blank">
                        <Tooltip title="Wiki" placement="right">
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Wiki" />
                            </ListItem>
                        </Tooltip>
                    </a>
                    <a href="https://www.umbrellaiot.com/about" target="_blank">
                        <Tooltip title="About Us" placement="right">
                            <ListItem>
                                <ListItemIcon>
                                    <InfoIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="About Us" />
                            </ListItem>
                        </Tooltip>
                    </a>
                    <a href="https://www.umbrellaiot.com/contact" target="_blank">
                        <Tooltip title="Contact Us" placement="right">
                            <ListItem>
                                <ListItemIcon>
                                    <PermContactCalendarIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Contact Us" />
                            </ListItem>
                        </Tooltip>
                    </a>
                </List>
                <Divider />
                <Tooltip title="&copy; Toshiba Europe Ltd." placement="right">
                    <ListItem>
                        <ListItemIcon>
                            <CopyrightIcon style={{ color: "#1c7b8e" }} />
                        </ListItemIcon>
                        <ListItemText primary="Toshiba Europe Ltd." />
                    </ListItem>
                </Tooltip>
                <Tooltip title="&copy; South Gloucestershire council." placement="right">
                    <ListItem>
                        <ListItemIcon>
                            <CopyrightIcon style={{ color: "#1c7b8e" }} />
                        </ListItemIcon>
                        <ListItemText primary="South Gloucestershire council." />
                    </ListItem>
                </Tooltip>
                <Divider />
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
}