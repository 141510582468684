import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import moment from "moment";

import JSONTree from "./JSONTree";
import theme from "../../../../theme";
class LoRaWANFrameLog extends Component {
  constructor() {
    super();

    this.state = {
      expanded: false,
    };

  }

  onExpandChange = (e, expanded) => {
    this.setState({
      expanded: expanded,
    });
  }

  render() {
    const receivedAt = moment(this.props.frame.publishedAt).format("MMM DD LTS");

    let chips = [
      <Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={this.props.frame.phyPayload.mhdr.mType} />,
      <Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`${this.props.frame.txInfo.frequency / 1000000} MHz`} />,
    ];

    if (this.props.frame.txInfo !== undefined && this.props.frame.txInfo.loRaModulationInfo !== undefined) {
      chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`SF${this.props.frame.txInfo.loRaModulationInfo.spreadingFactor}`} />);
      chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`BW${this.props.frame.txInfo.loRaModulationInfo.bandwidth}`} />);
    }

    if (this.props.frame.phyPayload.macPayload !== undefined) {
      if (this.props.frame.phyPayload.macPayload.devEUI !== undefined) {
        chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`DevEUI: ${this.props.frame.phyPayload.macPayload.devEUI}`} />);
      }

      if (this.props.frame.phyPayload.macPayload.fPort !== undefined && this.props.frame.phyPayload.macPayload.fPort !== null) {
        chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`FPort: ${this.props.frame.phyPayload.macPayload.fPort}`} />);
      }

      if (this.props.frame.phyPayload.macPayload.fhdr !== undefined) {
        chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`FCnt: ${this.props.frame.phyPayload.macPayload.fhdr.fCnt}`} />);
        chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`DevAddr: ${this.props.frame.phyPayload.macPayload.fhdr.devAddr}`} />);
      }
    }

    if (this.props.frame.gatewayID !== undefined) {
      chips.push(<Chip sx={{marginRight: theme.spacing.unit}} size="small" variant="outlined" label={`GW: ${this.props.frame.gatewayID}`} />);
    }

    return (
      <Accordion onChange={this.onExpandChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header">
          <div sx={{width: 145}}><Typography variant="body2">{receivedAt}</Typography></div>
          {chips}
        </AccordionSummary>
        <AccordionDetails>
          {this.state.expanded && <Grid container spacing={4}>
            <Grid item xs sx={{paddingTop: 0, paddingBottom:0, fontSize:12, lineHeight:1.5}}>
              {this.props.frame.txInfo && <JSONTree data={{ gatewayID: this.props.frame.gatewayID, txInfo: this.props.frame.txInfo }} />}
              {this.props.frame.rxInfo && <JSONTree data={{ rxInfo: this.props.frame.rxInfo }} />}
            </Grid>
            <Grid item xs sx={{paddingTop: 0, paddingBottom:0, fontSize:12, lineHeight:1.5}}>
              <JSONTree data={{ phyPayload: this.props.frame.phyPayload }} />
            </Grid>
          </Grid>}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default LoRaWANFrameLog;
