import React from 'react'

const NodeTypeCheck = props => {
    return (
        <>
            {/* <input value={props.name} type='checkbox' checked={props.isChecked} onChange={props.event} indexid={props.id} className="custom-control-input"/>{props.name} */}
            <div className="nodeTypeCheckbox custom-checkbox create-exp-checkBox">
                <input type="checkbox" value={props.name} checked={props.isChecked} onChange={props.event} indexid={props.id} />
                <label>{props.name}</label>
            </div>
        </>
    )
}

export default NodeTypeCheck;
