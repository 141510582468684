import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import CrateExpNodeChecks from '../UIElements/CrateExpNodeChecks'
// import { sampleCreateExpList } from '../../utils/testdata';
import { useAxios } from '../../utils/Axios';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CheckBox from '../CheckBox/CheckBox';
import CreateExpMap from '../../components/UIElements/Map/CreateExpMap';
import './CreateExpTable.css';


function CreateExpTable(props) {

    const [filters, setFilters] = useState([]);
    const [query, setQuery] = useState('');
    const [originalData, setOriginalData] = useState(props.nodelist.data.nodes);
    // const [data, setData] = useState(props.nodelist.data.nodes);
    const [searchResult, setSearchResult] = useState('');
    const [searchText, setSearchText] = useState("");
    const [sort, setSort] = useState({
        column: null,
        direction: 'asc',
    });
    const [ nodeTypeData , setNodeTypeData]  = useState([
        {name : "RSE", isChecked : false},
        {name : "RSS", isChecked : false},
        {name : "5G", isChecked : false},
    ])
    const [ nodeFilterChecks , setNodeFilterChecks]  = useState([
        {name : "ALL", displayName: 'All' ,isChecked : false},
        // {name : "ACTIVE", displayName: 'Active', isChecked : false},
        {name : "AVAILABLE",displayName: 'Available', isChecked : false}
    ])
    const [nodeData, setNodeData] = useState([]);
    const [expNodeListView, setExpNodeListView] = useState(0);
    const [nodedetails, setNodeDetails] = useState({})
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);
    const axiosInstance = useAxios();

    useEffect(()=> {
        // console.log(props.nodelist.data.nodes);
        setNodeData(props.nodelist.data.nodes)
    },[props.nodelist.data.nodes])

    //Sorting the Table Columns Ascending and descending order
    const onSort = (column) => (e) => {

    };

    //Based on sorting setting the arrow to right and down
    const setArrow = (column) => {

    };

    //Selecting the Nodes based on all/active/available checkboxes
    function selectNodesByName(e){
        let index = e.target.getAttribute('index');
        let name = e.target.getAttribute('name');
        // console.log(index);
        // console.log(name);
        props.selectall(e, name)
        let oldarray = [...nodeFilterChecks];
        for (let i = 0; i < oldarray.length; i++) {
            if(i == index){
                oldarray[i].isChecked = !oldarray[i].isChecked
            }else{
                oldarray[i].isChecked = false
            }
        }
        // console.log(oldarray);
        setNodeFilterChecks(oldarray);
    }

    // Checkcking / unchecking the checkboxes and sending props back to parent
    const selectOneNode = (e) => {
        
        props.selectednodes(e)

        let {checked} = e.target
		// let nodeid = e.target.getAttribute('index');
        let hostname = e.target.getAttribute('hostname');  
		// console.log(nodeid)
		let items = nodeData.map((row,index) => {
            if (row["hostName"] == hostname) {
				row['selected'] = checked;
                // row['disabled'] = !row.disabled;   
			}
			return row
		});
		
		setNodeData(items);
    }

    //Toggle the node list based on select button click
    const nodeSelectedCheck = (e) => {
        // console.log(e)
        props.selectednodesbybtn(e);

        let hostname = e.target.getAttribute('nodename');   
		// console.log(nodeid)
		let items = nodeData.map((row,index) => {
            if (row["hostName"] == hostname) {
				row['selected'] = !row['selected'];
                // row['disabled'] = !row.disabled;   
			}
			return row
		});
		
		setNodeData(items);
    }
    
   // exclude column list from filter
   const excludeColumns = ["status"];

   // handle change event of search input
   const searchNodes = value => {
       setSearchText(value);
       filterNodes(value);
   };
   
    // filter records by search text
   const filterNodes = (value) => {
       const lowercasedValue = value.toLowerCase().trim();
       
       if (lowercasedValue === ""){ 
           setNodeData(originalData);
       }
       else {
           const filteredData = originalData.filter(item => {
               return Object.keys(item).some(key =>
                   excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
               );
           });
        //    console.log(filteredData)
           setNodeData(filteredData);
           
       }
   }

    //event to change type check
    function changeNodeType (e) {
        let indexval = e.target.getAttribute('indexid')
        let check = e.target.checked
        let inputval = e.target.value
        let newArr = [...nodeTypeData]; // copying the old datas array
        newArr[indexval]['isChecked'] = check;
        setNodeTypeData(newArr)
        filterNodesByType(originalData)
    }

    //code to filter the nodelist by type
    function filterNodesByType (a) {
        // console.log(data)
        // console.log(check)
        var data = a;

        var keys = [];

        nodeTypeData.map((val) => {
            if(val.isChecked == true){
                keys.push(val.name)
            }
        })

        let array = []
        if(keys.length > 0){
            
            keys.map((item) => {
                data.map((b) => {
                    if(b.nodeType == item){
                        array.push(b)
                    }
                })
            })
        }else {
            array = data
        }
        
        setNodeData(array)
        
    }

    //Search table by node name based on user input 
    const doSearch = (event) => {

    }

    const search = () => {

    }

    //Code to open node table in mapview
    function openExpNodeTable(e) {
        setExpNodeListView(1)
    }

    //Code to Close node table in mapview
    function closeExpNodeTable(e) {
        setExpNodeListView(0)
    }
    // var newdata = data[0];
    // console.log(props.nodelist.data.nodes)
    return (
        <>
            <div className="row create-exp-search">
                {/* Search Input for Node List */}
                <div className="col-md-5">
                <div style={{ color: 'red'}}>
                    {error}
                </div>
                    <input style={{marginLeft : 0}} name="searchbox" className="form-control serch-box" onChange={(e) => 
                        {
                            const value = e.target.value;
                            const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                            if (!textFieldRegex.test(value)) {
                                setError(e.target.name + ' is invalid');
                                setIsValid(false);
                            }else{
                                setError('');
                                setIsValid(true);
                            }
                            searchNodes(e.target.value)
                        }
                        } type="text" placeholder="Search" aria-label="Search"></input>
                </div>
                {/* Filter Node List by RSS, RSE, 5G Networks and others*/}
                <div className="col-md-7 createExpNodeChecks">
                    <label><b>Filter</b></label>
                    {nodeTypeData.map((item, index) => {
                        return (
                            <CrateExpNodeChecks key={index} id={index} event={(e) => changeNodeType(e)} {...item}/>
                        )
                    })}
                </div>
            </div>

            <div className="row">
                {/* Node List in Google Mapview*/}
                <div className="col-12 no-padding">
                    <CreateExpMap 
                        nodelist={props.nodelist} 
                        selectbtncheck={(e)=>nodeSelectedCheck(e)}
                    />

                    {/* Node List Table View */}
                    {expNodeListView == 0 ? (
                        <>
                            <i className="fas fa-bars nodeListTableIcon" onClick={(e) => openExpNodeTable(e)}></i>
                        </>
                    ) : (
                            <>
                                <div className="col-5 no-padding node-list expNodesTable">
                                    <span className="create-exp-table-list">Node List</span>
                                    <span className="close" onClick={(e) => { closeExpNodeTable(e) }} style={{ cursor: 'pointer' }}>&times;</span>
                                    <div className="create-exp-table-wrapper">
                                        <div className="selectAllandActive">
                                            {nodeFilterChecks.map((item, index) => {
                                                return (
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        name={item.name} 
                                                        index={index}
                                                        label={item.displayName} 
                                                        checked={item.isChecked}
                                                        onChange={(e)=>selectNodesByName(e)}
                                                    />
                                                )
                                            })}
                                        </div>
                                        <Table responsive borderless className="create-exp-table" >
                                            <thead>
                                                <tr>
                                                    <th className="th-no-margin">
                                                        {/* <CheckBox
                                                            event={(e) => selectAll(e)}
                                                        /> */}
                                                    </th>
                                                    <th onClick={(e) => onSort('HostName')}>
                                                        Name
                                                        <span className="sort-arrow" >
                                                            <i onClick={(e) => setArrow('HostName')}></i>
                                                        </span>
                                                    </th>
                                                    <th>Host</th>
                                                    <th onClick={(e) => onSort('exp_type')}>
                                                        Type
                                                        <span className="sort-arrow">
                                                            <i onClick={(e) => setArrow('NodeType')}></i>
                                                        </span>
                                                    </th>
                                                    <th onClick={(e) => onSort('exp_status')}>
                                                        Status
                                                        <span className="sort-arrow">
                                                            <i onClick={(e) => setArrow('HardwareStatus')}></i>
                                                        </span>
                                                    </th>
                                                    {/* <th>View</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {nodeData.length > 0 ? (
                                                    <>
                                                        {nodeData.map((item, index) => {
                                                            return (
                                                                <tr key={item.hostName}>
                                                                    {item.status.toLowerCase() == 'active' ? (
                                                                        <>
                                                                            <td>
                                                                                <CheckBox 
                                                                                    event={(e) => selectOneNode(e)} 
                                                                                    checked={item.selected} 
                                                                                    name={item.hostName} 
                                                                                    id={index} 
                                                                                    disabled={item.disabled}    
                                                                                />
                                                                            </td>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <td></td>
                                                                        </>
                                                                    )}
                                                                    <td>{item.hostName}</td>
                                                                    <td>{item.friendlyName == null ? "-" : (<>{item.friendlyName}</>)}</td>
                                                                    <td>{item.nodeType}</td>
                                                                    <td>{item.status}</td>
                                                                    {/* <td>{item.exp_view}</td> */}
                                                                </tr>
                                                            );
                                                        })}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr><td>No Nodes Found</td></tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </>
    )
}

export default CreateExpTable;

