import React, { useEffect, useState,useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PopUp from '../../../UIElements/PopUp';
import ErrorPopUp from '../../../UIElements/ErrorPopup';
import { useAxios } from '../../../../utils/Axios';
import Context from '../../../../store/Context';
import { useHistory } from 'react-router-dom';

function KeysOTAA(props) {

    const [MSB, setMSB] = useState(true);
    const [nwkeyvalue, setNwKeyValue] = useState([
         
    ]);
    const[keyvalue,setKeyvalue] = useState([]);
    const[keysOtta,setKeysOtta]= useState([
       
    ]);
    const [genMSB, genSetMSB] = useState(true);
    const [genNwkeyvalue, setGenNwKeyValue] = useState('');

    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const history = useHistory();
    
    // useEffect(() => {
    //     console.log(genNwkeyvalue)
    // }, [genNwkeyvalue])

    // useEffect(() => {
    //     console.log(props);
    // }, [props])

    //Code to store nwKey user input change
    function changeNwkKey(e) {
        let input = e.target.value;
        console.log(input);
        if (input == '') {
            setNwKeyValue('');
            return
        }
        if (input.length <= 32) {
            const re = /^[A-Fa-f0-9]+$/;
            if (re.test(input)) {
                setNwKeyValue(input)
            }
        }
    }

    //code to set MSB/LSB for nwKey
    function changeNwKeyMSB(e) {
        const bytes = nwkeyvalue.match(/[A-Fa-f0-9]{2}/g);
        if (bytes !== null) {
            let data = bytes.reverse().join("")
            setNwKeyValue(data)
        }
        setMSB(!MSB);
    }

    //code to generate NwKey and storing in state 
    function generateNwKey(e) {
        let key = "";
        const possible = 'abcdef0123456789';

        for (let i = 0; i < 32; i++) {
            key += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        setNwKeyValue(key)
    }

    //Code to store nwKey user input change
    function changeGenNwkKey(e) {
        let input = e.target.value;
        console.log(input);
        if (input == '') {
            setGenNwKeyValue('');
            return
        }
        if (input.length <= 32) {
            const re = /^[A-Fa-f0-9]+$/;
            if (re.test(input)) {
                setGenNwKeyValue(input)
            }
        }
    }

    //code to set MSB/LSB for nwKey
    function changeGenNwKeyMSB(e) {
        const bytes = genNwkeyvalue.match(/[A-Fa-f0-9]{2}/g);
        if (bytes !== null) {
            let data = bytes.reverse().join("")
            setGenNwKeyValue(data)
        }
        genSetMSB(!MSB);
    }

    //code to generate NwKey and storing in state 
    function generateGenNwKey(e) {
        let key = "";
        const possible = 'abcdef0123456789';

        for (let i = 0; i < 32; i++) {
            key += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        setGenNwKeyValue(key)
    }

    useEffect(() => {
        fetchKeysOtta();
    }, [axiosInstance])

    //code to set keys
    function submitKeysOTAA(e){
        e.preventDefault();
        let data = {
            "deviceKeys":{
            "appKey": "00000000000000000000000000000000",
            "nwkKey": nwkeyvalue,
            "genAppKey":genNwkeyvalue,
            "devEUI":props.match.params.id
        }}
        // console.log(data);
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}/keys`, data)
                .then((response) => {
                    // console.log(response);
                    PopUp("Keys Added Successfully");
                    actions({ type: 'setState', payload: { ...state, blocking: false }});
                    setTimeout(() => {
                        history.push(`/lora/applications`);     
                    }, 200);
                })
                .catch((err) => {
                    console.log(err);
                    ErrorPopUp("Request Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false }})
                });

        }   
    }


    function updateKeysOTTA(e){
        e.preventDefault();
        let data = {
            "deviceKeys":{
            "appKey": "00000000000000000000000000000000",
            "nwkKey": nwkeyvalue,
            "genAppKey":genNwkeyvalue,
            "devEUI":props.match.params.id
        }}
        // console.log(data);
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}/keys`, data)
                .then((response) => {
                    // console.log(response);
                    PopUp("Keys Added Successfully");
                    actions({ type: 'setState', payload: { ...state, blocking: false }});
                    setTimeout(() => {
                        history.push(`/lora/applications`);     
                    }, 200);
                })
                .catch((err) => {
                    console.log(err);
                    ErrorPopUp("Request Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false }})
                });

        }   
    }

    function fetchKeysOtta(){
        if(axiosInstance){
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
            .get(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}/keys`)
            .then((response)=>{
                setNwKeyValue(response.data.deviceKeys.nwkKey);
                setKeyvalue(response.data.deviceKeys.nwkKey);
                actions({ type: 'setState', payload: { ...state, blocking: false } });
            }).catch((err) => {
                 
                actions({ type: 'setState', payload: { ...state, blocking: false } })
                 
            });
        }
    }

    const onSubmit =e=>{
        e.preventDefault();
        if (state.button === 1) {
            console.log("HI");
         submitKeysOTAA(e);
        }
        if (state.button === 2) {
            updateKeysOTTA(e);
        }
    }

    return (
        <>
        <Form onSubmit={(e) => onSubmit(e)}>
            <Row style={{ margin: '2%' }}>
                <div className="col-md-12">
                    <Form.Row>
                        <Form.Group as={Col} md="10">
                            <Form.Label>Application key</Form.Label>
                            <Form.Control type="text" value={nwkeyvalue} name="nwkKey" placeholder="Enter Application Key" onChange={(e) => changeNwkKey(e)} autoComplete="off" required/>
                            <Form.Text className="text-muted">
                                For LoRaWAN 1.0 devices. In case your device supports LoRaWAN 1.1, update the device-profile first.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="1" style={{ lineHeight: '90px' }}>
                            <Button className="btnNormal" onClick={(e) => changeNwKeyMSB(e)}>{MSB == true ? 'MSB' : 'LSB'}</Button>
                        </Form.Group>
                        <Form.Group as={Col} md="1" style={{ lineHeight: '90px' }}>
                            <i className="fas fa-redo" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={(e) => generateNwKey(e)}></i>
                        </Form.Group>
                    </Form.Row>
                </div>
            </Row>
            {/* <Row style={{ margin: '2%' }}>
                <div className="col-md-12">
                    <Form.Row>
                        <Form.Group as={Col} md="10">
                            <Form.Label>Gen Application key</Form.Label>
                            <Form.Control type="text" value={genNwkeyvalue} name="genAppKey" placeholder="Gen Application Key" onChange={(e) => changeGenNwkKey(e)} autoComplete="off" />
                            <Form.Text className="text-muted">
                                For LoRaWAN 1.0 devices. This key must only be set when the device implements the remote multicast setup specification / firmware updates over the air (FUOTA). Else leave this field blank.
                        </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="1" style={{ lineHeight: '90px' }}>
                            <Button className="btnNormal" onClick={(e) => changeGenNwKeyMSB(e)}>{genMSB == true ? 'MSB' : 'LSB'}</Button>
                        </Form.Group>
                        <Form.Group as={Col} md="1" style={{ lineHeight: '90px' }}>
                            <i className="fas fa-redo" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={(e) => generateGenNwKey(e)}></i>
                        </Form.Group>
                    </Form.Row>
                </div>
            </Row> */}
            <Row>
                <div className="col-md-12 text-right no-padding" >
                    <>{
                        (keyvalue == '')?(<Button className="btnDefault" type="submit" style={{ margin: '2%' }} onClick={()=>(state.button=1)}>
                        CREATE DEVICE KEYS
                    </Button>):

                            <Button className="btnDefault" type="submit" style={{ margin: '2%' }} onClick={()=>(state.button=2)}>
                            UPDATE DEVICE KEYS
                            </Button>   
                    }
                    
                    </>
                    

                </div>
            </Row>
            </Form>
        </>
    )
}

export default KeysOTAA;
