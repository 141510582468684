import React, { useEffect, useState, useContext } from 'react';
import { useVideojs } from 'react-videojs-hook';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../utils/Axios';
import axios from 'axios';
import Context from '../../store/Context'
import PopUp from '../UIElements/PopUp';
import ErrorPopUp from '../UIElements/ErrorPopup';
import 'video.js/dist/video-js.css';

function VedioPlayer(props) {
    const [startRecordingBtn, SetStartRecordingBtn] = useState(true);
    const [stopRecordingBtn, SetStopRecordingBtn] = useState(false);
    const [downloadFiles, setDownloadFiles] = useState(false);

    const { state, actions } = useContext(Context);
    const axiosInstance = useAxios();

    useEffect(() => {

    }, []);

    const onPlay = (currentTime) => {
        // console.log("Video played at: ", currentTime);
    };

    const onPause = (currentTime) => {
        // console.log("Video paused at: ", currentTime);
    };

    const onEnd = (currentTime) => {
        // console.log(`Video ended at ${currentTime}`);
    };

    const onTimeUpdate = (currentTime) => {
        // console.log(`Video current time is ${currentTime}`)
    };

    const onReady = (currentTime) => {
        // console.log(`Video current time is ${currentTime}`)
        let playBT = document.getElementsByClassName(".vjs-big-play-button");
        // console.log(playBT);
        // console.log(playBT[0]);
        // playBT.css({
        //     left:( (playBT.parent().outerWidth()-playBT.outerWidth())/2 )+"px",
        //     top:( (playBT.parent().outerHeight()-playBT.outerHeight())/2 )+"px"
        // });
    };
	
	//src:"http://10.200.100.110/hls/cam1.m3u8"	
    const { vjsId, vjsRef, vjsClassName } = useVideojs({
        src: `${process.env.REACT_APP_BASE_URL}/hls/cam1.m3u8`,
        controls: true,
        autoplay: false,
        responsive: false,
        width: '850',
        height: '600',
        bigPlayButtonCentered: true,
        onPlay,
        onPause,
        onEnd,
        onTimeUpdate,
        onReady
    });

    //code to start video recording
    function startVideoRecord() {
         
        axios.get(`${process.env.REACT_APP_BASE_URL}/control/record/start?app=live&name=cam1&rec=rec1`)
        .then(response =>{
                     console.log(response);
                    PopUp("Recording Started");
                    setDownloadFiles(false);
                    SetStartRecordingBtn(false);
                    SetStopRecordingBtn(true);
        }).catch(err=>{
            console.log(err);
            setDownloadFiles(false);
            SetStartRecordingBtn(false);
            SetStopRecordingBtn(true);
        })



        // if (axiosInstance) {
        //     // console.log("WWWWorking");
        //     actions({ type: 'setState', payload: { ...state, blocking: true } })
        //     axiosInstance
        //         .get("http://10.200.100.110/control/record/start?app=live&name=cam1&rec=rec1")
        //         .then((response) => {
                    // console.log(response);
                    // PopUp("Recording Started");
                    // SetStartRecordingBtn(false);
                    // SetStopRecordingBtn(true);
        //             actions({ type: 'setState', payload: { ...state, blocking: false } })
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //             ErrorPopUp("Start Recording Failed");
        //             // SetStartRecordingBtn(false);
        //             // SetStopRecordingBtn(true);
        //             actions({ type: 'setState', payload: { ...state, blocking: false } })
        //         });
        // }
    }
    //code to stop video recording
    function stopVideoRecord() {
        axios.get(`${process.env.REACT_APP_BASE_URL}/control/record/stop?app=live&name=cam1&rec=rec1`)
        .then(response =>{
                     console.log(response);
                    PopUp("Recording Started");
                    SetStartRecordingBtn(true);
                    SetStopRecordingBtn(false);
                    setDownloadFiles(true);
        }).catch(err=>{
            SetStartRecordingBtn(true);
            SetStopRecordingBtn(false);
            setDownloadFiles(true);
        })
        // if (axiosInstance) {
        //     // console.log("WWWWorking");
        //     actions({ type: 'setState', payload: { ...state, blocking: true } })
        //     axiosInstance
        //         .get('http://10.200.100.110/control/record/stop?app=live&name=cam1&rec=rec1')
        //         .then((response) => {
        //             console.log(response);
        //             PopUp("Recording Stopped");
        //             SetStopRecordingBtn(false);
        //             SetStartRecordingBtn(true);
        //             actions({ type: 'setState', payload: { ...state, blocking: false } })
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //             ErrorPopUp("Stop Recording Failed");
        //             // SetStopRecordingBtn(false);
        //             // SetStartRecordingBtn(true);
        //             setDownloadFiles(true);
        //             actions({ type: 'setState', payload: { ...state, blocking: false } })
        //         });
        // }
    }
    console.log(props);

    //code to download flv video file
    function downloadFLV(){
        if (axiosInstance) {
            // console.log("WWWWorking");
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_BASE_URL}/rec/cam1.flv`)
                .then((response) => {
                    console.log(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    console.log(err);
                    ErrorPopUp("Download Failed");
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    }

    //code to download mp4 video file
    function downloadMP4(){
        if (axiosInstance) {
            // console.log("WWWWorking");
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_BASE_URL}/rec/cam1.mp4`)
                .then((response) => {
                    console.log(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    console.log(err);
                    ErrorPopUp("Download Failed");
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    }
    return (
        <>
            <div data-vjs-player>
                <video ref={vjsRef} id={vjsId} className={vjsClassName}></video>
            </div>
            {
                props == true ?(<>

<div style={{  marginTop: '2%' }} >

{startRecordingBtn == true ? (
    <>
        <Button className="btnDefault" onClick={(e) => startVideoRecord(e)}>Start Recording</Button>
    </>
) : null}

{stopRecordingBtn == true ? (
    <>
        <Button className="btnDanger" onClick={(e) => stopVideoRecord(e)}>Stop Recording</Button>
    </>
) : null}
</div>
                
                </>):null            }
          

            {downloadFiles == true ? (
                <>
                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                        {/* <Button className="btnNormal" onClick={(e) => downloadFLV(e)}>Download Recording (flv)</Button> */}
                        <Button className="btnNormal" href={`${process.env.REACT_APP_BASE_URL}/rec/cam1.flv`}>Download Recording (flv)</Button>
                    </div>
                    <div className="col-md-4">
                        {/* <Button className="btnNormal" onClick={(e) => downloadMP4(e)}>Download Recording (mp4)</Button> */}
                        <Button className="btnNormal" href={`${process.env.REACT_APP_BASE_URL}/rec/cam1.mp4`}>Download Recording (mp4)</Button>
                    </div>
                    <div className="col-md-2"></div>
                </div>
                </>
            ) : null}
        </>
    )
}

export default VedioPlayer;