import React, { useState, useEffect, useContext } from 'react';
import LoraLayout from '../../../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import PopUp from '../../../../../components/UIElements/PopUp';
import ErrorPopUp from '../../../../../components/UIElements/ErrorPopup';
import Col from 'react-bootstrap/Col';
import { useAxios } from '../../../../../utils/Axios';
import Context from '../../../../../store/Context';

function FuotaCreate(props) {
    const [FUOTAData, SetFUOTAData] = useState({});
    const [file , setFile] = useState({});
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const history = useHistory();
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);
    // useEffect(() => {
    //     console.log(FUOTAData)
    // }, [FUOTAData])

    //Code to update Org Name and Disaply Name
    function changeHandler(e) {
        const name = e.target.name;
        const value = e.target.value;

        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}

        switch (name) {
            case 'name':
                SetFUOTAData({ ...FUOTAData, [name]: value });
            break;
            case 'groupType':
                SetFUOTAData({ ...FUOTAData, [name]: value });
            break;
            case 'dr':
                SetFUOTAData({ ...FUOTAData, [name]: parseInt(value) });
            break;  
            case 'frequency':
                SetFUOTAData({ ...FUOTAData, [name]: parseInt(value) });
            break;
            case 'redundancy':
                SetFUOTAData({ ...FUOTAData, [name]: parseInt(value) });
            break;    
            case 'multicastTimeout':
                SetFUOTAData({ ...FUOTAData, [name]: parseInt(value) });
            break; 
            case 'unicastTimeout':
                SetFUOTAData({ ...FUOTAData, [name]: value+'s' });
                break;                
            default:
                break;
        }
    }

    //code to store file input
    function changeFile(e){
        // console.log(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = () => {
            const encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
            SetFUOTAData({ ...FUOTAData, ["payload"]: encoded })
            // console.log(encoded)
        };
        reader.readAsDataURL(e.target.files[0]);
        // console.log(reader)
    }

    //Code to submit adding HTTP Integration Request
    const submitFUOTA = (e) => {
        e.preventDefault();
        let data = { "fuotaDeployment": { ...FUOTAData } };
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}/fuota-deployments`, data)
                .then((response) => {
                    // console.log(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    PopUp("FUOTA Created successfuly");
                    setTimeout(() => {
                        history.goBack();     
                    }, 1000);

                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    ErrorPopUp("Request Failed. Try Again")
                    // history.push(`/lora/applications/${props.match.params.id}`);
                });

        }
    }

    return (
        <>
            <LoraLayout>
            <div style={{ color: 'red'}}>
                {error}
            </div>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Create update job</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Row>
                            <Col md={11} className="mapCol">
                                <Form onSubmit={submitFUOTA}>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Firmware update job-name" onChange={changeHandler} autoComplete="off" required />
                                        <Form.Text className="text-muted">
                                            A descriptive name for this firmware update job.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Select Firmware File</Form.Label>
                                        <Form.Control type="file" name="payload" onChange={changeFile} />
                                        <Form.Text className="text-muted">
                                            This file will fragmented and sent to the device(s). Please note that the format of this file is vendor dependent.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Redundant Frames</Form.Label>
                                        <Form.Control type="number" name="redundancy" placeholder="Redundant frames in number" onChange={changeHandler} autoComplete="off" required />
                                        <Form.Text className="text-muted">
                                            The given number represents the extra redundant frames that will be sent so that a device can recover from packet-loss.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Unicast timeout (seconds)</Form.Label>
                                        <Form.Control type="number" name="unicastTimeout" placeholder="Unicast timeout in seconds" onChange={changeHandler} autoComplete="off" required />
                                        <Form.Text className="text-muted">
                                            Set this to the minimum interval in which the device(s) are sending uplink messages.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Data-rate</Form.Label>
                                        <Form.Control type="number" name="dr" placeholder="Data-rate" onChange={changeHandler} autoComplete="off" required />
                                        <Form.Text className="text-muted">
                                            The data-rate to use when transmitting the multicast frames. Please refer to the LoRaWAN Regional Parameters specification for valid values.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Frequency (Hz)</Form.Label>
                                        <Form.Control type="number" name="frequency" placeholder="Enter Frequency" onChange={changeHandler} autoComplete="off" required />
                                        <Form.Text className="text-muted">
                                            The frequency to use when transmitting the multicast frames. Please refer to the LoRaWAN Regional Parameters specification for valid values.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Multicast-group type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="groupType"
                                            onChange={changeHandler}
                                            required
                                            custom
                                        >
                                            <option value=''>Select Multicast-group type</option>
                                            <option value='CLASS_C'>Class-C</option>
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            The multicast-group type defines the way how multicast frames are scheduled by the network-server.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>Multicast timeout</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="multicastTimeout"
                                            onChange={changeHandler}
                                            required
                                            custom
                                        >
                                            <option value=''>Select Multicast Timeout</option>
                                            <option value='1'>1 Seconds</option>
                                            <option value='2'>2 Seconds</option>
                                            <option value='4'>4 Seconds</option>
                                            <option value='8'>8 Seconds</option>
                                            <option value='16'>16 seconds</option>
                                            <option value='32'>32 Seconds</option>
                                            <option value='64'>64 Seconds</option>
                                            <option value='128'>128 Seconds</option>
                                            <option value='256'>256 Seconds</option>
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            The multicast-group type defines the way how multicast frames are scheduled by the network-server.
                                        </Form.Text>
                                    </Form.Group>
                                    <div className="text-right">
                                        <Button disabled={!isValid} className="btnDefault" type="submit">
                                            CREATE FUOTA DEPLOYMENT
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default FuotaCreate;
