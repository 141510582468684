import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import swal from 'sweetalert';
import { useAxios } from '../../utils/Axios';
import Context from '../../store/Context';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListIcon from '@mui/icons-material/List';
import logo from '../../assests/icons/umbrella-logo3.png';
import { Image } from 'react-bootstrap';
import PopUp from '../../components/UIElements/PopUp';
import ErrorPopUp from '../UIElements/ErrorPopup';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderIcon from '@mui/icons-material/Folder';

import HomeIcon from '@mui/icons-material/Home';
import RssFeedIcon from '@mui/icons-material/RssFeed';

import './NewSideBar.css';
import { Collapse, Menu, MenuItem, Paper, Tooltip } from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { AccountCircle, ExpandLess, ExpandMore, Search } from '@mui/icons-material';
import SourceIcon from '@mui/icons-material/Source';
import SensorsIcon from '@mui/icons-material/Sensors';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import CopyrightIcon from '@mui/icons-material/Copyright';
import InfoIcon from '@mui/icons-material/Info';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 270;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function NewSideBar(props) {
    let children = props.children;
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const [projectList, setProjectList] = useState([]);
    const [sysAdmin, setSysAdmin] = useState(false);
    const { state, actions } = useContext(Context);
    const axiosInstance = useAxios();
    const { keycloak } = useKeycloak();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [currentUsername, setCurrentUsername] = useState('');
    const [currentUserid, setCurrentUserid] = useState('');
    const [openProject, setOpenProject] = React.useState(false);
    const [openAdmin, setOpenAdmin] = React.useState(false);

    const handleClick = () => {
        setOpenProject(!openProject);
        setOpenAdmin(false);
    };

    const handleAdminClick = () => {
        setOpenAdmin(!openAdmin);
        setOpenProject(false);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setTimeout(() => {
            getUserid();
            getUsername()
        }, 2000);
    }, [])

    useEffect(() => {
        if (currentUserid == null || currentUserid == '') {
            getUserid();
        }
    }, [currentUserid])

    useEffect(() => {
        if (currentUsername == null || currentUsername == '') {
            getUsername();
        }
    }, [currentUsername])

    //code to get data from localstorage
    function getUsername() {
        const username = localStorage.getItem('name');
        setCurrentUsername(username);
    }

    //code to get data from localstorage
    function getUserid() {
        const userid = localStorage.getItem('userid');
        setCurrentUserid(userid);
    }

    //Code to self deregister user
    function deregister(e) {
        swal({
            title: 'Are you sure?',
            text: 'You will be deregistered Permanently',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (axiosInstance) {
                        axiosInstance
                            .post(`${process.env.REACT_APP_PROJECT_URL}/users/deregistration/${currentUserid}`)
                            .then((response) => {
                                if (response.response) {
                                    PopUp(response.response.data.detail)
                                } else {
                                    PopUp("Success")
                                }

                                const timer = setTimeout(() => {
                                    clearLocalstorage()
                                }, 500);
                                return () => clearTimeout(timer)

                            })
                            .catch((err) => {
                                if (err.response) {
                                    ErrorPopUp(err.response.data.detail)
                                } else {
                                    ErrorPopUp("RequestFailed")
                                }
                            });
                    }
                }
            });
    }

    function profile(e) {
        history.push('/profile');
    }

    //code to clear localstorage when user logged out
    function clearLocalstorage() {
        history.push('/home');
        localStorage.clear();
        keycloak.logout();
    }

    useEffect(() => {
        fetchList();
    }, [axiosInstance]);

    useEffect(() => {
        if (state.projectChange == true) {
            fetchList()
        }
    }, [state.projectChange]);

    const fetchList = () => {
        if (axiosInstance) {

            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/un/projects`)
                .then((response) => {
                    setProjectList(response.data);
                    getUserStatus();
                })
                .catch((err) => {
                    getUserStatus();
                });

        }
    };

    //Code to fetch All Users
    function getUserStatus() {
        if (axiosInstance) {
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/un/users/adduser`)
                .then((response) => {
                    var roles = JSON.parse(localStorage.getItem("roles"));
                    let check = response;
                    if (roles.includes('un_admin')) {
                        fetchCurrentUser()
                    } else {
                        if (check.data.status == 'pending' || check.data.status == 'denied' || check.data == '') {
                            // ErrorPopUp("Your Requesting is Pending")
                            ErrorPopUp("Your are not a part of Umbrella Network")
                            setTimeout(() => {
                                goToHome();
                            }, 1500);
                        } else {
                            fetchCurrentUser()
                        }
                    }
                })
                .catch((err) => {
                    ErrorPopUp("User Validation Failed.")
                    setTimeout(() => {
                        goToHome();
                    }, 1500);
                });

        }
    }


    //code to check uer role
    function fetchCurrentUser() {
        var roles = JSON.parse(localStorage.getItem("roles"));
        if (roles.includes('un_admin')) {
            setSysAdmin(true)
        } else if (roles.includes('un_pradmin')) {
            setSysAdmin(false)
        } else {
            setSysAdmin(false)
        }
    }

    const goToHome = () => history.push('/home');
    const goToNetworks = () => history.push('/networks');

    function handleProjectIdClick(e) {
        console.log(e.currentTarget);
        const projectName = e.currentTarget.getAttribute('project-name');
        const projectId = e.currentTarget.getAttribute('project-id');
        console.log(projectName);
        console.log(projectId);
        history.push('/projects/' + projectId);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} color='inherit'>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                        <a href="#/home"><Image src={logo} className="custom-icon" /></a>
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 4 }}>
                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            {currentUsername} <AccountCircle style={{ color: "#1c7b8e" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={(e) => profile(e)}>Profile</MenuItem>
                            <MenuItem onClick={(e) => deregister(e)}>Deregister</MenuItem>
                            <MenuItem onClick={() => clearLocalstorage()}>Logout</MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 6 }} >
                        <Tooltip title="Report Issue" placement="bottom-start">
                            <a href="https://report.umbrellaiot.com" target="_blank">
                                <ReportProblemIcon style={{ color: "#1c7b8e" }} />
                            </a>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer variant="permanent" open={open} color='inherit'>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />


                <List >
                    <Tooltip title="Home" placement="right">
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={goToHome}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <HomeIcon style={{ color: "#1c7b8e" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Home"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </Tooltip>


                    <Tooltip title="Networks" placement="right">
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={goToNetworks}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <RssFeedIcon style={{ color: "#1c7b8e" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Networks"} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </Tooltip>

                </List>
                <Divider />

                <List>
                    <Tooltip title="My Projects" placement="right">
                        <ListItemButton onClick={handleClick}>
                            <ListItemIcon>
                                <FolderCopyIcon style={{ color: "#1c7b8e" }} />
                            </ListItemIcon>
                            <ListItemText primary="My Projects" />
                            {openProject ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </Tooltip>
                </List>
                <Paper style={{ maxHeight: 250, overflow: 'auto', overflowX: 'hidden' }}>
                    <Collapse in={openProject} timeout="auto" unmountOnExit>
                        <List>
                            <Tooltip title="ALL" placement="right">
                                <ListItem key={"ALL"} color='inherit' disablePadding sx={{ display: 'block' }}>
                                    <ListItemButton
                                        onClick={(e) => history.push('/projects')}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <FolderIcon style={{ color: "#1c7b8e" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="ALL" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </ListItem>
                            </Tooltip>


                            {projectList.length > 0 ? (
                                <>
                                    {projectList.map((item, index) => (
                                        <>
                                            <Tooltip title={item.projectName} placement="right">
                                                <ListItem key={item.projectName} color='inherit' disablePadding sx={{ display: 'block' }}>
                                                    <ListItemButton
                                                        project-name={item.projectName}
                                                        project-id={item.projectId}
                                                        onClick={(e) => handleProjectIdClick(e)}
                                                        sx={{
                                                            minHeight: 48,
                                                            justifyContent: open ? 'initial' : 'center',
                                                            px: 2.5,
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                                mr: open ? 3 : 'auto',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <ListIcon style={{ color: "#1c7b8e" }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={item.projectName} sx={{ opacity: open ? 1 : 0 }} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Tooltip>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </List>
                    </Collapse>
                </Paper>
                <Divider />

                {/* Admin Section  */}
                <List>
                    <Tooltip title="Admin" placement="right">
                        <ListItemButton onClick={handleAdminClick}>
                            <ListItemIcon>
                                <AdminPanelSettingsIcon style={{ color: "#1c7b8e" }} />
                            </ListItemIcon>
                            <ListItemText primary="Admin" />
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </Tooltip>
                </List>
                <Paper style={{ maxHeight: 250, overflow: 'auto', overflowX: 'hidden' }}>
                    <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem color='inherit' disablePadding sx={{ display: 'block' }}>
                                <Tooltip title="Quota" placement="right">
                                    <ListItemButton
                                        onClick={(e) => history.push('/admin/quota')}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <SourceIcon style={{ color: "#1c7b8e" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Quota" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                            <ListItem key={"ALL"} color='inherit' disablePadding sx={{ display: 'block' }}>
                                <Tooltip title="Node Inventory" placement="right">
                                    <ListItemButton
                                        onClick={(e) => history.push('/admin/nodeinventory')}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <SensorsIcon style={{ color: "#1c7b8e" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Node Inventory" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                            <ListItem key={"ALL"} color='inherit' disablePadding sx={{ display: 'block' }}>
                                <Tooltip title="Manage Users" placement="right">
                                    <ListItemButton
                                        onClick={(e) => history.push('/admin/manageusers')}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <GroupIcon style={{ color: "#1c7b8e" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Manage Users" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                            <ListItem key={"ALL"} color='inherit' disablePadding sx={{ display: 'block' }}>
                                <Tooltip title="Search" placement="right">
                                    <ListItemButton
                                        onClick={(e) => history.push('/admin/searchun')}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <SearchIcon style={{ color: "#1c7b8e" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Search" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                            <ListItem key={"ALL"} color='inherit' disablePadding sx={{ display: 'block' }}>
                                <Tooltip title="Experiment History" placement="right">
                                    <ListItemButton
                                        onClick={(e) => history.push('/admin/umbexperimenthistory')}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <HistoryIcon style={{ color: "#1c7b8e" }} />
                                        </ListItemIcon>
                                        <ListItemText primary="Experiment History" sx={{ opacity: open ? 1 : 0 }} />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        </List>
                    </Collapse>
                </Paper>
                <Divider />

                <List sx={{ marginTop: 'auto', fontSize: '0.5rem' }}>
                    <Divider />
                    <a href="https://wiki.umbrellaiot.com" target="_blank">
                        <Tooltip title="Wiki" placement="right">
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Wiki" />
                            </ListItem>
                        </Tooltip>
                    </a>
                    <a href="https://www.umbrellaiot.com/about" target="_blank">
                        <Tooltip title="About Us" placement="right">
                            <ListItem>
                                <ListItemIcon>
                                    <InfoIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="About Us" />
                            </ListItem>
                        </Tooltip>
                    </a>
                    <a href="https://www.umbrellaiot.com/contact" target="_blank">
                        <Tooltip title="Contact Us" placement="right">
                            <ListItem>
                                <ListItemIcon>
                                    <PermContactCalendarIcon style={{ color: "#1c7b8e" }} />
                                </ListItemIcon>
                                <ListItemText primary="Contact Us" />
                            </ListItem>
                        </Tooltip>
                    </a>
                </List>
                <Divider />
                <Tooltip title="&copy; Toshiba Europe Ltd." placement="right">
                    <ListItem>
                        <ListItemIcon>
                            <CopyrightIcon style={{ color: "#1c7b8e" }} />
                        </ListItemIcon>
                        <ListItemText primary="Toshiba Europe Ltd." />
                    </ListItem>
                </Tooltip>
                <Tooltip title="&copy; South Gloucestershire council." placement="right">
                    <ListItem>
                        <ListItemIcon>
                            <CopyrightIcon style={{ color: "#1c7b8e" }} />
                        </ListItemIcon>
                        <ListItemText primary="South Gloucestershire council." />
                    </ListItem>
                </Tooltip>
                <Divider />
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
}