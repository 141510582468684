import React, { useState, useContext, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import PopUp from '../../../components/UIElements/PopUp';
import ErrorPopUp from '../../../components/UIElements/ErrorPopup';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';
import { useHistory } from 'react-router-dom';
import BlockUi from 'react-block-ui';
// import './Home.css'
import './UsersOrgRequest.css'
import NewHeader from '../../../components/Header/NewHeader';

function UsersOrgRequest() {
    const [reqStatus, setReqStatus] = useState('');
    const[createAgain, setCreateAgain] = useState('');
    const [showExOrgForm, setShowExOrgForm] = useState('');
    const [orgData, setOrgData] = useState({});
    const [existOrg, setExistOrg] = useState({})
    const [orgList, setOrgList] = useState({});
    const [userObjInfo , setUserObjInfo] = useState({});
    const [userExOrgInfo , setuserExOrgInfo] = useState({});
    const [isOrgNameValid, setIsOrgNameValid] = useState(false);
    const [orgNameMessage, setOrgNameMessage] = useState('');

    const history = useHistory();
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    const localUserEmail = localStorage.getItem('useremail')
    const userId = localStorage.getItem('userId')
    useEffect(() => {
        checkLnAdmin()
    }, [axiosInstance]);

    //code to check user is ln admin
    function checkLnAdmin(){
        var roles = JSON.parse(localStorage.getItem("roles"));

        if(roles.includes('ln_admin')){
            gotoLoraDashboard();
        }else{
            fetchAllUsers();
            fetchOrgList();
        }  
    }

    //Code to fetch All Users
    function fetchAllUsers(){
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/users/all`)
                .then((response) => {
                    // console.log(response.data);
                    checkUserPresent(response.data);
                    // actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    setReqStatus('enable');
                    ErrorPopUp("Request Failed.")
                });

        }
    }

    //Code to check the current login user is present in all users list or not
    function checkUserPresent(input){
        let currentuser = localStorage.getItem('username');
        let userObj = {};
        let check = function (data) {
            for(let i=0;i<input.length;i++){
                if(data[i].name == currentuser){
                    userObj = data[i];
                    return true
                }
            }
            return false
        }

        if(check(input) == true){
            // console.log(userObj)
            if(userObj.status == null){
                setReqStatus('enable');    
            }else{
                checkDefaultRole(userObj);
            }
            
        }else{
            setReqStatus('enable');
        }
    }

    //code to check user is system admin or not
    function checkDefaultRole(input){
        var roles = JSON.parse(localStorage.getItem("roles"));

        // console.log(roles);
        if(roles.includes('default')){
            // console.log('system Admin')
            getOrgId(input);
        }else{
            setReqStatus('enable');
        }
    }
   
    //Code to get Lora Org Id
    function getOrgId(userinfo){
        if (axiosInstance) {
            // actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/users/organizations`)
                .then((response) => {
                    if(userinfo.status == 'approved' && response.data.status == 'approved'){
                        storeDataLocally(response.data); 
                        setTimeout(() => {
                            gotoLoraDashboard();
                        }, 1000);
                    }else{
                        checkTypeofRequest(response.data);                   
                    }
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    setReqStatus('enable');
                    ErrorPopUp("Request Failed.")
                });

        }
    }

    //code to type of request user done previously
    function checkTypeofRequest(input){
        let email = localStorage.getItem('useremail');
        // console.log(email);
        // let input = {
        //     id: "e31c90c1-33ae-4e83-8b38-e4c69db814df",
        //     loraOrgId: 3,
        //     name: "testorg1",
        //     orgAdmin: "demo@gmail.com",
        //     status: "approved"
        // }

        if(input.status == 'denied'){
            console.log('Access Denied');
            setReqStatus('disable');
            setCreateAgain('enable');
            setUserObjInfo(input);
        }

        if(input.status == 'pending' && input.orgAdmin == email){
            console.log('pending ' , "same Email")
            setReqStatus('disable');
            setCreateAgain('');
            setUserObjInfo(input);
        }

        if(input.status == 'pending' && input.orgAdmin !== email){
            // user requested for add user to organization 
            // call /organizations/{Orgid}/newusers api to check status
            console.log('pending ' , "different Email")
            storeDataLocally(input); 
            getAddToOrgStatus(input)
        }

        if(input.status == 'approved' && input.orgAdmin == email){
            // user requested for create organization (redirect to dasboard)
            console.log('approved ' , "same Email")
            storeDataLocally(input); 
            gotoLoraDashboard()
        }

        if(input.status == 'approved' && input.orgAdmin !== email){
            // user requested for add user to organization 
            // call /organizations/{Orgid}/newusers api to check status
            console.log('approved ' , "different Email") 
            storeDataLocally(input); 
            getAddToOrgStatus(input);
        }
    }

    //code to check status of add user organization request
    function getAddToOrgStatus(input){
        if (axiosInstance) {
            // actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/organizations/${input.loraOrgId}/newusers`)
                .then((response) => {
                //    console.log(response.data);
                   let currentuser = localStorage.getItem('username');
                   let currentObj;
                   async function check(data) {
                       for(let i=0;i<data.length;i++){
                           if(data[i].name == currentuser){
                            currentObj = data[i];
                            return true
                           }
                       }
                       return false
                   }

                   check(response.data).then((item)=>{
                       if(item == true){
                            console.log(currentObj.status)
                            
                            if(currentObj.status == 'deny'){
                                setReqStatus('');
                                setuserExOrgInfo(currentObj);
                                setShowExOrgForm(true);
                            }

                            if(currentObj.status == 'pending'){
                                setReqStatus('');
                                console.log("pending status")
                                setuserExOrgInfo(currentObj);
                                setShowExOrgForm(true);
                            }

                            if(currentObj.status == 'approved'){
                                storeDataLocally(currentObj);
                                gotoLoraDashboard()
                            }
                       }else{
                            console.log('user not found')
                            ErrorPopUp("Request Failed.")
                            setShowExOrgForm(false);
                            setReqStatus('enable')    
                       }
                   })
                   actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    setReqStatus('enable');
                    ErrorPopUp("Request Failed.")
                });
        }
    }

    //Code to store data locally
    function storeDataLocally(input){
        // console.log(input);
        localStorage.removeItem('orgid');
        localStorage.removeItem('orgname');
        let orgid = input.loraOrgId;
        let orgname = input.name;
        localStorage.setItem('orgid', orgid);
        localStorage.setItem('orgname', orgname);

    }
    
    //Code to redirect the user to dashboard
    const gotoLoraDashboard = () => {
        // console.log('Dashboard Hits')
        history.push('/lora');
    }

    //Code to fetch Organizations list and storing in state
    function fetchOrgList(){
        if (axiosInstance) {
            // actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/organizations?limit=500`)
                .then((response) => {
                    // console.log(response);
                    // setOrgList(response.data)
                    // let data = [{"organization":{"id":1,"name":"org1","displayName":"org1","canHaveGateways":1,"maxGatewayCount":5,"maxDeviceCount":1},"createdAt":"","updatedAt":""},{"organization":{"id":2,"name":"org2","displayName":"org2","canHaveGateways":2,"maxGatewayCount":6,"maxDeviceCount":2},"createdAt":"","updatedAt":""}]
                    setOrgList(response.data)
                    actions({ type: 'setState', payload: { ...state, blocking: false }})
                })
                .catch((err) => {
                    // console.log(err);
                    // let data = [{"organization":{"id":1,"name":"org1","displayName":"org1","canHaveGateways":1,"maxGatewayCount":5,"maxDeviceCount":1},"createdAt":"","updatedAt":""},{"organization":{"id":2,"name":"org2","displayName":"org2","canHaveGateways":2,"maxGatewayCount":6,"maxDeviceCount":2},"createdAt":"","updatedAt":""}]
                    // setOrgList(data)
                    actions({ type: 'setState', payload: { ...state, blocking: false }})
                });

        }
    }

    //Code to store new org name 
    function storeOrgName(e){
        const name = e.target.name;
		const value = e.target.value;
        const orgNameRegex = /^[A-Za-z0-9\-]+$/;
        if(value == ''){
            setIsOrgNameValid(false);
            setOrgNameMessage('Organization name cannot be empty');
        }
        else if(value.length > 30){
            setIsOrgNameValid(false);
            setOrgNameMessage('Organization name length should be less than or equal to 30');
        }
        else if (orgNameRegex.test(value)) {
            setIsOrgNameValid(true);
            setOrgNameMessage('Organization name is valid');
        }else{
            setIsOrgNameValid(false);
            setOrgNameMessage('Organization name may only contain words, numbers and dashes');
        }

		setOrgData({ ...orgData, [name]: value });
    }

    //Code to store Existing Selected Organization
    function storeExistOrg(e){
        let orgname = e.target.selectedOptions[0].text
        let id = e.target.value
        setExistOrg({...existOrg, "orgId" : id, "name" : orgname})
    }

    //Code to submit new organization request
    const submitNewOrg = (e) => {
        e.preventDefault();
        let neworg = {
            name : orgData.newOrgName
        }
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/users/organizations`, neworg)
                .then((response) => {
                    // console.log(response);
                    PopUp("Organization Created Successfully");
                    actions({ type: 'setState', payload: { ...state, blocking: false }});
                    setTimeout(() => {
                        fetchAllUsers();
                        fetchOrgList();
                    }, 1000);
                })
                .catch((err) => {
                    //console.log(err.response.data.detail);
                    if(err.response){
                        if(err.response.data.detail){
                            ErrorPopUp(err.response.data.detail)
                            actions({ type: 'setState', payload: { ...state, blocking: false }})
                        }
                    }else{
                        ErrorPopUp("Request Failed. Try Again")
                        actions({ type: 'setState', payload: { ...state, blocking: false }})
                    }
                    
                });

        }
    }

    //Code to submit add existing organization request
    const submitExistingOrg = (e) => {
        e.preventDefault();
        let Orgid = existOrg.orgId
        let currentuser = localStorage.getItem('username');
        let existorg = {
            organizationName : existOrg.name
        }
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/organizations/${Orgid}/newusers`, existorg)
                .then((response) => {
                    // console.log(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    PopUp("Success");
                    setTimeout(() => {
                        checkLnAdmin()
                    }, 2000);
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    ErrorPopUp("Request Failed. Try Again")
                });

        }
    }


    return (
        <>
        <BlockUi tag="div" message="Loading" blocking={state.blocking}>
            <div className="container-fluid">
                <NewHeader />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <br />
                        <Jumbotron className="text-center bg-white nwrkBody">
                            <div className="row d-flex justify-content-center">
                                {reqStatus == 'enable' ? (
                                    <>
                                        <div className="offset-md-2">

                                        </div>
                                        <div className="col-md-8">
                                            <h6>You are not registered on the Lora Network. </h6>
                                            <div>
                                                <h7>Note: Organisation name cannot be changed after creation</h7><br/>
                                            </div>
                                            <Table responsive bordered className="projectTableData">
                                                <tbody>
                                                        <tr>                                                            
                                                            <td style={{lineHeight: '60px'}}>
                                                                Create Org
                                                            </td>
                                                            <td>
                                                                By default you will be added to this organization as org admin
                                                                <Form.Control type="text" placeholder="Enter Org Name" onChange={(e)=>storeOrgName(e)} name="newOrgName" required />
                                                                <div className={`${isOrgNameValid ? 'success' : 'error'}`}>
                                                                    {orgNameMessage}
                                                                </div>
                                                            </td>
                                                            <td style={{lineHeight: '60px'}}>
                                                                <Button disabled={!isOrgNameValid} className="btnDefault create-exp-btn" onClick={submitNewOrg}>
                                                                    Submit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Add to Org
                                                            </td>
                                                            <td>
                                                                {Object.keys(orgList).length > 0 ? (
                                                                    <>
                                                                        <Form.Control as="select" custom onChange={(e)=>storeExistOrg(e)} name="orgName">
                                                                            <option value="">Select Organization</option>
                                                                            {orgList.result.map((item , index) => (
                                                                                <option value={item.id} name={item.name} key={index}>{item.name}</option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Form.Control as="select" custom onChange={(e)=>storeExistOrg(e)} name="orgName">
                                                                            <option value="" name="">No Organizations found</option>
                                                                        </Form.Control>    
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Button className="btnDefault create-exp-btn" onClick={submitExistingOrg}>
                                                                    Submit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="offset-md-2">

                                        </div>
                                    </>
                                ) : null}

                                {reqStatus == 'disable' ? (
                                    <>
                                        <div className="offset-md-2">

                                        </div>
                                        <div className="col-md-8">
                                            <Table responsive borderless className="projectTableData">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>User</th>
                                                        <th>Organization</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            {localUserEmail}
                                                        </td>
                                                        <td>
                                                            {userObjInfo.name}
                                                        </td>
                                                        <td>
                                                            {userObjInfo.status}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            
                                        </div>
                                        <div className="offset-md-2">

                                        </div>
                                    </>
                                ) : null}
                                {createAgain == 'enable'?(
                                    <>
                                    <div className="offset-md-2">

                                    </div>
                                      
                                  
                                    <div className="col-md-8">
                                          <h6>Register Again to Lora Network. </h6>
                                            <div>
                                                <h7>Note: Organisation name cannot be changed after creation</h7><br/>
                                            </div>
                                            <Table responsive bordered className="projectTableData">
                                                <tbody>
                                                        <tr>
                                                            <td style={{lineHeight: '60px'}}>
                                                                Create Org
                                                            </td>
                                                            <td>
                                                                By default you will be added to this organization as org admin
                                                                <Form.Control type="text" placeholder="Enter Org Name" onChange={(e)=>storeOrgName(e)} name="newOrgName" required />
                                                                <div className={`${isOrgNameValid ? 'success' : 'error'}`}>
                                                                    {orgNameMessage}
                                                                </div>
                                                            </td>
                                                            <td style={{lineHeight: '60px'}}>
                                                                <Button disabled={!isOrgNameValid} className="btnDefault create-exp-btn" onClick={submitNewOrg}>
                                                                    Submit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Add to Org
                                                            </td>
                                                            <td>
                                                                {Object.keys(orgList).length > 0 ? (
                                                                    <>
                                                                        <Form.Control as="select" custom onChange={(e)=>storeExistOrg(e)} name="orgName">
                                                                            <option value="">Select Organization</option>
                                                                            {orgList.result.map((item , index) => (
                                                                                <option value={item.id} name={item.name} key={index}>{item.name}</option>
                                                                            ))}
                                                                        </Form.Control>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Form.Control as="select" custom onChange={(e)=>storeExistOrg(e)} name="orgName">
                                                                            <option value="" name="">No Organiations found</option>
                                                                        </Form.Control>    
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <Button  className="btnDefault create-exp-btn" onClick={submitExistingOrg}>
                                                                    Submit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                </tbody>
                                            </Table>

                                    </div>
                                    <div className="offset-md-2">

                                        </div>
                                    </>
                                ) : null}

                                {showExOrgForm == true ? (
                                    <>
                                        <div className="offset-md-2">

                                        </div>
                                        <div className="col-md-8">
                                            <Table responsive borderless className="projectTableData">
                                                <thead>
                                                    <tr>
                                                        <th>Sl.No</th>
                                                        <th>User</th>
                                                        <th>Requested Organization</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            1
                                                        </td>
                                                        <td>
                                                            {localUserEmail}
                                                        </td>
                                                        <td>
                                                            {userExOrgInfo.organizationName}
                                                        </td>
                                                        <td>
                                                            {userExOrgInfo.status}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="offset-md-2">

                                        </div>
                                    </>
                                ) : null}

                            </div>

                        </Jumbotron>
                    </div>
                </div>
            </div>
            </BlockUi>
            <ToastContainer draggable={false} transition={Zoom}/>
        </>
    )

}

export default UsersOrgRequest;

