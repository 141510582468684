import React, { useState, useContext }  from 'react';
import './SMEForm.css';
import axios from 'axios';
import PopUp from '../../components/UIElements/PopUp';
import ErrorPopup from '../../components/UIElements/ErrorPopup';
import { ToastContainer, Zoom } from 'react-toastify';
import BlockUi from 'react-block-ui';
import Context from '../../store/Context';
import Banner from '../../assests/sme-competition-banner.png';
import {Container, Row, Col, Jumbotron, Form, Table} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import NewHeader from '../../components/Header/NewHeader';


const SMEForm = () => {
  const history = useHistory();
  const initialValues = {
    legalNameEnterprise: "",
    tradeNameEnterprise: "",
    applicantName: "",
    applicantJobFunction: "",
    applicantEmailAddress: "",
    telephoneNumber: "",
    countryCheckbox: "",
    country: "",
    city: "",
    enterpriseEmployeeCount: "",
    registerdAsSME: "",
    levelOfAutonomy: "",
  };

  const { state, actions } = useContext(Context);

  const [formValues, setFormValues] = useState(initialValues);
  const [attachments0, setAttachments0] = useState([]);
  const [attachments1, setAttachments1] = useState([]);
  const [attachments2, setAttachments2] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === "applicantEmailAddress"){
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }  
    setFormValues({ ...formValues, [name]: value });    
    
  };


  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);

  const handleConditionChange1 = (event) => {
    setCondition1(event.target.checked);
  };
  const handleConditionChange2 = (event) => {
    setCondition2(event.target.checked);
  };
  const handleConditionChange3 = (event) => {
    setCondition3(event.target.checked);
  };

  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(false);
  const [mobileNumberMessage, setMobileNumberMessage] = useState('');

  const [validationResult, setvalidationResult] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;
  const validateEmail = (event) => {
    const email = event.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      setMessage('Your email looks good!');
      setFormValues({ ...formValues, [event.target.name]: email });
      setvalidationResult(true);
    } else {
      setIsValid(false);
      setMessage('Please enter a valid email!');
      setvalidationResult(false);
    }
  };

  const mobileRegex = /^[0-9\b]+$/;
  const validateMobileNumber = (event) => {
    const mobileNumber = event.target.value;
    if (mobileRegex.test(mobileNumber)) {
      setIsMobileNumberValid(true);
      setMobileNumberMessage('Your mobile number looks good!');
      setFormValues({ ...formValues, [event.target.name]: mobileNumber });
      setvalidationResult(true);
    } else {
      setIsMobileNumberValid(false);
      setMobileNumberMessage('Please enter a valid mobile number!');
      setvalidationResult(false);
    }
  };


  const submitData = (e) => {
    e.preventDefault();
    let key=0;
    actions({ type: 'setState', payload: { ...state, blocking: true }})
    const formData = new FormData();
    formData.append('legalNameEnterprise', formValues.legalNameEnterprise);
    formData.append('tradeNameEnterprise', formValues.tradeNameEnterprise);
    formData.append('applicantName', formValues.applicantName);
    formData.append('applicantJobFunction', formValues.applicantJobFunction);
    formData.append('applicantEmailAddress', formValues.applicantEmailAddress);
    formData.append('telephoneNumber', formValues.telephoneNumber);
    if(formValues.countryCheckbox === "Other"){
      formData.append('country', formValues.country);
    }else{
      formData.append('country', formValues.countryCheckbox);
    }
   
    formData.append('city', formValues.city);
    formData.append('enterpriseEmployeeCount', formValues.enterpriseEmployeeCount);
    formData.append('registerdAsSME', formValues.registerdAsSME);
    formData.append('levelOfAutonomy', formValues.levelOfAutonomy);

    formData.append('attachments0', attachments0);
    formData.append('attachments1', attachments1);
    formData.append('attachments2', attachments2);

    axios.post(`${process.env.REACT_APP_SME_FORM_URL}/sme-form-submit`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        //console.log(response);
        PopUp('Form submitted successfully');
        actions({ type: 'setState', payload: { ...state, blocking: false } });   
        //redirect to success page
        history.push('/smeformresult');			
      }).catch((err) => {
        //console.log(response);
        if (err.response) {
          //console.log(err.response);
          ErrorPopup(err.response.data.message);			
          actions({ type: 'setState', payload: { ...state, blocking: false } })			
        } else {
          //console.log("Request failed");
          ErrorPopup('Request failed. Please check with support team');	
          actions({ type: 'setState', payload: { ...state, blocking: false } })			
        }		
      });
      //reset
      //e.target.reset();
      setMessage('');
      setMobileNumberMessage(''); 
  }


  return (
    <>
    <BlockUi tag="div" message="Loading" blocking={state.blocking}>
    <NewHeader />
    <div className="container-fluid outer-block">      
      <section className="row justify-content-center inside-scroll" > 
      <Container>   
      <Jumbotron className="bg-white">  
          <Row>
            <Col>
              <img className=".img-fluid" src={Banner} alt="Banner"/>
            </Col>            
          </Row> 
          <Row>
            <Col className="justify-content-center">
                <h3>UMBRELLA Competition: Application Form</h3>
            </Col>                        
          </Row>
          <Row>
            <Col>
              <span className="mandate">* All fields are mandatory</span>
            </Col>
          </Row>
          <Form onSubmit={submitData}  style={{ display: "flex" }}>
            <Table className="table table-borderless responsive">
              <thead>
                <tr className="table-primary">
                  <th>
                    SECTION 1: COMPANY INFORMATION
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Legal name of your enterprise<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="legalNameEnterprise"
                            id="exampleInputName"
                            aria-describedby="emailHelp"
                            placeholder="Enter Legal Name"
                            required
                            onChange={handleChange}>
                        </Form.Control>  
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Trade name of your enterprise :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="tradeNameEnterprise"
                            id="exampleInputTradeName"
                            aria-describedby="emailHelp"
                            placeholder="Enter Trade Name"                            
                            onChange={handleChange}>
                        </Form.Control>  
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Applicant full name<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="applicantName"
                            id="exampleInputAppName"
                            aria-describedby="emailHelp"
                            placeholder="Enter Applicant Full Name"
                            required
                            onChange={handleChange}>
                        </Form.Control>  
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label> Applicant job function in the enterprise<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="applicantJobFunction"
                            id="exampleInputAppJob"
                            aria-describedby="emailHelp"
                            placeholder="Enter Applicant Job Function"
                            required
                            onChange={handleChange}>
                        </Form.Control>  
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Applicant email address<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="applicantEmailAddress"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Applicant Email Address"
                            required
                            onChange={validateEmail}>
                        </Form.Control> 
                        <small id="emailHelp" class="form-text text-muted">
                          We'll never share your email with anyone else.
                        </small>
                        <div className={`message ${isValid ? 'success' : 'error'}`}>
                          {message}
                        </div> 
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Applicant telephone number<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="telephoneNumber"
                            id="exampleInputMob"
                            placeholder="Enter Telephone Number"
                            required
                            onChange={validateMobileNumber}>
                        </Form.Control>  
                        <div className={`message ${isMobileNumberValid ? 'success' : 'error'}`}>
                          {mobileNumberMessage}
                        </div>
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Country<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="countryCheckbox"
                            id="inlineCheckbox1"
                            value="UK"
                            onChange={handleChange}
                            required
                          />
                          <label class="form-check-label" for="inlineCheckbox1">
                            UK
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="countryCheckbox"
                            id="inlineCheckbox2"
                            value="Other"
                            onChange={handleChange}
                            required
                          />
                          <label class="form-check-label" for="inlineCheckbox2">
                            Other
                          </label>
                        </div>
                        {formValues.countryCheckbox.toLowerCase() === "other" && (
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputother"
                            name="country"
                            placeholder="Enter Country Name"
                            onChange={handleChange}
                            required
                          />
                        )}
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>City<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="city"
                            id="exampleInputCity"
                            aria-describedby="emailHelp"
                            placeholder="Enter City"
                            required
                            onChange={handleChange}>
                        </Form.Control>                        
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>How many people (employees) does your enterprise employ?<span className="mandate">*</span> :</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control type="text" 
                            name="enterpriseEmployeeCount"
                            id="exampleInputEmpNo"
                            aria-describedby="emailHelp"
                            placeholder="Enter Number of Employees"
                            required
                            onChange={handleChange}>
                        </Form.Control>  
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>
                          Is your enterprise registered as an SME at the time of submitting the application? 
                          <br/>
                          Note that in order for your enterprise to be eligible to receive funding, 
                          it must be registered in the UK before the start of the engagement programme.
                          <span className="mandate">*</span>
                        </Form.Label>
                        </Col>
                        <Col>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="registerdAsSME"
                                id="inlineYes"
                                value="yes"
                                onChange={handleChange}
                                required
                              />
                              <label class="form-check-label" for="inlineYes">
                                Yes
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="radio"
                                name="registerdAsSME"
                                id="inlineNo"
                                value="no"
                                onChange={handleChange}
                                required
                              />
                              <label class="form-check-label" for="inlineCheckbox2">
                                No
                              </label>
                            </div>
                            {formValues.registerdAsSME.toLowerCase() === "yes" && (
                              <div className="form-group">
                                <label>Please select the level of autonomy that best describes your enterprise. To self-assess your status, please consult the 
                                  <a target="_blank" href="https://ec.europa.eu/docsroom/documents/42921/attachments/1/translations/en/renditions/native"> EC guidance </a>.</label><br></br>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="levelOfAutonomy"
                                    id="inlineAutonomy1"
                                    value="I am representing an autonomous enterprise "
                                    onChange={handleChange}
                                    required
                                  />
                                  <label class="form-check-label" for="inlineAutonomy1">
                                    I am representing <span className="autonomy">an autonomous enterprise</span>
                                  </label>
                                </div>
                                <br/>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="levelOfAutonomy"
                                    id="inlineAutonomy2"
                                    value="I am representing a partner enterprise "
                                    onChange={handleChange}
                                    required
                                  />
                                  <label class="form-check-label" for="inlineAutonomy2">
                                    I am representing <span className="autonomy">a partner enterprise</span>
                                  </label>
                                </div>
                                <br/>
                                <div class="form-check form-check-inline">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="levelOfAutonomy"
                                    id="inlineAutonomy3"
                                    value="I am representing a linked enterprise "
                                    onChange={handleChange}
                                    required
                                  />
                                  <label class="form-check-label" for="inlineAutonomy3">
                                    I am representing <span className="autonomy">a linked enterprise</span>
                                  </label>
                                </div>
                              </div>
                            )}
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr className="table-primary">
                  <th>
                    SECTION 2: PROJECT PROPOSAL
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Please upload your application proposal template<span className="mandate">*</span> :</Form.Label>
                        <br/>
                        <span><code>Formats accepted: .doc, .docx, .odt, .pdf</code></span>
                        </Col>
                        <Col>
                         <input className="form-control" type="file" id="formFile" required onChange={(e)=> {
                           setAttachments0(e.target.files[0]);
                         }}/>
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Please upload your project finance form<span className="mandate">*</span> :</Form.Label>
                        <br/>
                        <span><code>Formats accepted: .xls, .xlsx, .csv</code></span>
                        </Col>
                        <Col>
                          <input className="form-control" type="file" id="formFile" required onChange={(e)=> {
                           setAttachments1(e.target.files[0]);
                         }}/>
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Form.Group>
                      <Row>
                        <Col>
                        <Form.Label>Please upload your project plan<span className="mandate">*</span> :</Form.Label>
                        <br/>
                        <span><code>Formats accepted: .doc, .docx, .odt, .xls, .xlsx, .csv, .pdf</code></span>
                        </Col>
                        <Col>
                          <input className="form-control" type="file" id="formFile" required onChange={(e)=> {
                           setAttachments2(e.target.files[0]);
                         }}/>
                        </Col>
                      </Row>          
                    </Form.Group>
                  </td>
                </tr>
              </tbody>
              <thead>
                <tr className="table-primary">
                  <th>
                    SECTION 3: DECLARATIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  <div className="">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        name="check1"
                        // value={true}
                        onChange={handleConditionChange1}
                        required
                      />
                      <label className="form-check-label" for="exampleCheck1">
                      I declare there are no conflicts of interest of any nature which would prevent me or my enterprise from entering the competition.
                      If any actual, potential or perceived conflict of interest arises in the future, I will inform the awarding organisation immediately.
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck2"
                        name="check2"
                        // value={true}
                        onChange={handleConditionChange2}
                        required
                      />
                      <label className="form-check-label" for="exampleCheck2">
                      I declare that the information provided in this application is true and correct. I also understand that any willful dishonesty may render for refusal of this application and any grant funding requested.
                      </label>
                    </div>
                  </div>
                  <div className="">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck3"
                        name="check3"
                        value={true}
                        onChange={handleConditionChange3}
                        required
                      />
                      <label className="form-check-label" for="exampleCheck3">
                      I hereby confirm that the entity I enter the competition with is not insolvent nor under liquidation and it is not excluded from the possibility of obtaining public funding under the provisions of national law.
                      </label>
                    </div>
                  </div>
                  <div className="">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck4"
                          name="check4"
                          required
                        />
                        <label className="form-check-label" for="exampleCheck4">
                        I confirm I have read and agree with the terms presented in the <a href="https://www.umbrellaiot.com/umbrella-competition-privacy-notice" target="_blank">Competition Privacy Notice </a>
                        </label>
                      </div>
                    </div>
                    <Row>
                      <Col>
                        <input type="submit" value="Submit" disabled={!validationResult}
                            className="btnDefault"/>  
                      </Col>
                      <Col></Col>
                      <Col></Col>
                    </Row> 
                  </td>
                </tr>                
              </tbody>   
                        
            </Table>
          </Form>
        </Jumbotron>
      </Container>
      </section>    
      </div>
    </BlockUi>
    <ToastContainer draggable={false} transition={Zoom} />
    </>
  );
};

export default SMEForm;
