import React from 'react';
import RoboticsProjectTable from '../../../components/RoboticsNetworks/RoboticsProjectTable/RoboticsProjectTable';
import CreateProject from '../../../components/RoboticsNetworks/Createproject/CreateProject';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab'
import { useAxios } from '../../../utils/Axios';
import { useEffect , useState, useContext } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import Context from '../../../store/Context';
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout';

import { emphasize, styled } from '@mui/material/styles';
import { Breadcrumbs, Chip, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FolderIcon from '@mui/icons-material/Folder';

const RoboticsProjectDashboard = (props) => {

	const [projectList, setProjectList] = useState([]);
	const [nodeNetworks , setNodeNetworks] = useState([]);
	const [projectBtnEnable , setProjectBtnEnable] = useState(false);
	const axiosInstance = useAxios();
	const {state , actions} = useContext(Context);

	useEffect(() => {
		fetchList();
	}, [axiosInstance]);

	const fetchList = () => {
		let roles = JSON.parse(localStorage.getItem("roles"));
		// let roles = ["un_dev"]

		// console.log(roles.includes('ln_admin'));
		if (roles.includes('rn_admin')) {
			setProjectBtnEnable(true)
		}

		if (roles.includes('rn_pradmin')) {
			setProjectBtnEnable(true)
		}

		if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/ra/projects`)
				.then((response) => {
					// console.log(response);
					checkOwnerName(response.data);
					fetchNodeNetworks()

					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
					// console.log(err);
					// setProjectList([{"projectId":"123","projectName":"roboticproject1","projectDescription":"1","creationTime":"2020-11-02T06:53:37Z","updateTime":"2020-11-02T06:53:37Z","ownerName":"narev090","testBedName":"dummy robotic Node Network","status":"active"},{"projectId":"456","projectName":"roboticproject2","projectDescription":"default robotic","creationTime":"2020-11-04T12:48:26Z","updateTime":"2020-11-04T12:48:26Z","ownerName":"narev090","testBedName":"default robotic Nw","status":"active"}])
					fetchNodeNetworks()
					actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
	};

	//code to check owner name in projects
	function checkOwnerName(input){
		let currentuser = localStorage.getItem('username');
		input.map((item)=>{
			if(item.ownerName == currentuser){
				item['currentUser'] = true
			}else{
				item['currentUser'] = false
			}	
		})

		setProjectList(input);
	}
	// const sampleList = sampleProjectsList;
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchList();
				actions({type : 'setState', payload : {...state , projectChange : true}})
			}, 1000);

		};
	};

	//Code to fetch node networks
	function fetchNodeNetworks() {
		if (axiosInstance) {

			axiosInstance
				.get(`${process.env.REACT_APP_NODEMONITOR_URL}/robot-arenas/all`)
				.then((response) => {
					// console.log(response);
					// setNodeNetworks(response.data)
					setNodeNetworks(response.data)
				})
				.catch((err) => {
					// console.log(err);
					// setNodeNetworks([{"name":"default"},{"name":"dummy Arena"}])
				});

		}
	}

	const StyledBreadcrumb = styled(Chip)(({ theme }) => {
		const backgroundColor =
			theme.palette.mode === 'light'
				? theme.palette.grey[100]
				: theme.palette.grey[800];
		return {
			backgroundColor,
			height: theme.spacing(3),
			color: theme.palette.text.primary,
			fontWeight: theme.typography.fontWeightRegular,
			fontSize: '13px',
			'&:hover, &:focus': {
				backgroundColor: emphasize(backgroundColor, 0.06),
			},
			'&:active': {
				boxShadow: theme.shadows[1],
				backgroundColor: emphasize(backgroundColor, 0.12),
			},
		};
	});


	return (
		<>
			<RoboticsLayout>
			<Stack spacing={2}>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Typography key="1" color="text.primary">
						<StyledBreadcrumb							
							label="My Robotics Projects"
							icon={<FolderIcon fontSize="medium" style={{ color: "#1c7b8e" }} />}
						/>
						</Typography>
				</Breadcrumbs>
			</Stack>
			<div className="col-md-12 col-sm-12 col-lg-12 no-padding">
					<div className="default-main-page">					 	
 								<>
 								{projectList.length > 0 ? (
									<>
									<div className="project-table-group">
										<RoboticsProjectTable  
											data={projectList} 
											status={handleSubmit} 
											networks={nodeNetworks}	
										/>
										
									</div>
									<br />
									<div className="col-md-12 text-right no-padding" >
										{projectBtnEnable == true ? (
											<>
												<CreateProject status={handleSubmit} networks={nodeNetworks}/>
											</>
										) : null}
									</div>
									</>
									) : (
										<div className="default-content-center" style={{margin: '3%',textAlign: 'center'}}>
											You don't have any running Projects right now
											<br />
											<br />
											<div>
												{projectBtnEnable == true ? (
													<>
														<CreateProject status={handleSubmit} networks={nodeNetworks}/>
													</>
												) : null}
											</div>
										</div>
									)}
								</>													
					</div>
				</div>
			</RoboticsLayout>
			<ToastContainer draggable={false} transition={Zoom}/>
		</>
	)
}

export default RoboticsProjectDashboard