import React, { useState, useEffect, useContext } from 'react';
import './CreateServiceProfile.css';
import Button from 'react-bootstrap/Button';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
import { useHistory } from 'react-router-dom';
import Context from '../../../store/Context'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';

function EditServiceProfile(props) {
    const { state, actions } = useContext(Context);
    const [checkDevStatusReq, setCheckDevStatusReq] = useState(0)
    const [serviceProfile, setServiceProfile] = useState({});
    const [nwServers, setNWServers] = useState([]);
    const [optionsEnabled, setOptionsEnabled] = useState({
        updateSPBtn: false
    })
    const axiosInstance = useAxios();
    const history = useHistory();
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        checkCurrentUser()
    }, [axiosInstance])

    useEffect(() => {
        fetchServiceProfile();
    }, [axiosInstance]);

    // useEffect(() => {
    //     console.log(props);
    // }, [props])

    //code to check current user role
    function checkCurrentUser() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ["ln_orgadmin"]

        console.log(roles.includes('ln_admin'));
        if (roles.includes('ln_admin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    updateSPBtn : true
                })
            )
        }
        if (roles.includes('ln_orgadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    updateSPBtn : true
                })
            )
        }
        if (roles.includes('ln_user')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    updateSPBtn : false
                })
            )
        }
        if (roles.includes('ln_deviceadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    updateSPBtn : false
                })
            )
        }

    }

    //code to fetch service profile
    function fetchServiceProfile() {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/service-profiles/${props.match.params.id}`)
                .then((response) => {
                    // console.log(response);
                    let resp = response.data.serviceProfile;
                    fetchNwServers();
                    if (response.devStatusReqFreq > 0) {
                        setCheckDevStatusReq(1);
                    } else {
                        setCheckDevStatusReq(0);
                    }
                    setServiceProfile(resp);
                    fetchNwServers();
                    // actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    // let response = {"id":"6842939b-10d2-4cdf-a08d-1e6959756e1e","name":"sample3","organizationID":"21","networkServerID":"4","ulRate":0,"ulBucketSize":0,"ulRatePolicy":"DROP","dlRate":0,"dlBucketSize":0,"dlRatePolicy":"DROP","addGWMetaData":true,"devStatusReqFreq":1,"reportDevStatusBattery":true,"reportDevStatusMargin":true,"drMin":1,"drMax":1,"channelMask":null,"prAllowed":false,"hrAllowed":false,"raAllowed":false,"nwkGeoLoc":false,"targetPER":0,"minGWDiversity":0}
                    // setServiceProfile(response)
                    fetchNwServers();
                    // if(response.devStatusReqFreq > 0){
                    //     setCheckDevStatusReq(1);
                    // }else{
                    //     setCheckDevStatusReq(0);
                    // }
                    ErrorPopUp("Fetching Service Profile Failed")
                    // actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    //Code to fetch Network-servers store in state
    function fetchNwServers() {
        // let orgid = localStorage.getItem('orgid')
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/network-servers?limit=999`)
                .then((response) => {
                    // console.log(response);
                    setNWServers(response.data.result);

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    //Code to store user inputs
    const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setServiceProfile({ ...serviceProfile, [name]: value });
    };

    //Code to store device status request input and enable and disable checkboxes
    const changeDeviceStatusReq = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (value > 0) {
            setCheckDevStatusReq(1)
        } else {
            setCheckDevStatusReq(0)
        }
        setServiceProfile({ ...serviceProfile, [name]: value });
    }

    //Code to check/Uncheck and store checkbox data
    function changeCheckbox(e) {
        let check = e.target.checked;
        let name = e.target.getAttribute('name');
        // console.log(check)
        // setServiceProfile({ ...serviceProfile, [name]: !serviceProfile.addGWMetaData });
        switch (name) {
            case 'addGWMetaData':
                setServiceProfile({ ...serviceProfile, [name]: !serviceProfile.addGWMetaData });
                break;
            case 'nwkGeoLoc':
                setServiceProfile({ ...serviceProfile, [name]: !serviceProfile.nwGeoLoc });
                break;
            case 'reportDevStatusBattery':
                setServiceProfile({ ...serviceProfile, [name]: !serviceProfile.reportDevStatusBattery });
                break;
            case 'reportDevStatusMargin':
                setServiceProfile({ ...serviceProfile, [name]: !serviceProfile.reportDevStatusMargin });
                break;
        }
    }

    //Code to send request to create app
    const handleSubmit = (event) => {
        event.preventDefault();
        let orgid = localStorage.getItem('orgid');
        let newserviceData = { "serviceProfile": { ...serviceProfile, "organizationID": orgid } }
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/service-profiles/${props.match.params.id}`, newserviceData)
                .then((response) => {
                    PopUp("Service Profile Updated Successfully")
                    // props.status(true)
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    history.push('/lora/service-profiles');
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopUp("Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    };

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>                        
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-12 col-sm-12 col-lg-12"
                        >
                            <h5>Service Profile : {props.match.params.id}</h5>
                        </div>
                </div>
                    <div className="default-main-page" style={{ padding: '3%' }}>
                        <div style={{ color: 'red'}}>
                            {error}
                        </div>
                        <Form.Group>                       
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                onChange={changeHandler}
                                type="text"
                                placeholder="service profile name"
                                value={serviceProfile.name || ''}
                                autoComplete="off"
                                required
                            />
                            <Form.Text className="text-muted">
                                A name to identify the service-profile.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Network-Server</Form.Label>
                            <Form.Control
                                as="select"
                                name="networkServerID"
                                value={serviceProfile.networkServerID}
                                onChange={changeHandler}
                                required
                                custom
                            >
                                {
                                    nwServers.length > 0 ? (
                                        <>
                                            <option value="">Select Network</option>
                                            {nwServers.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <option value="">No Networks Found</option>
                                        </>
                                    )
                                }
                            </Form.Control>
                            <Form.Text className="text-muted">
                                The network-server on which this service-profile will be provisioned. After creating the service-profile, this value can't be changed.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" checked={serviceProfile.addGWMetaData || ''} label="Add gateway meta-data" name="addGWMetaData" onChange={(e) => changeCheckbox(e)} />
                            <Form.Text className="text-muted">
                                GW metadata (RSSI, SNR, GW geoloc., etc.) are added to the packet sent to the application-server.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="checkbox" checked={serviceProfile.nwkGeoLoc || ''} label="Enable network geolocation" name="nwkGeoLoc" onChange={(e) => changeCheckbox(e)} />
                            <Form.Text className="text-muted">
                                When enabled, the network-server will try to resolve the location of the devices under this service-profile. Please note that you need to have gateways supporting the fine-timestamp feature and that the network-server needs to be configured in order to provide geolocation support.
                        </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Device-status request frequency</Form.Label>
                            <Form.Control
                                name="devStatusReqFreq"
                                onChange={changeDeviceStatusReq}
                                value={serviceProfile.devStatusReqFreq || ''}
                                type="number"
                                placeholder="Device-status request frequency"
                                autoComplete="off"
                            />
                            <Form.Text className="text-muted">
                                Frequency to initiate an End-Device status request (request/day). Set to 0 to disable.
                            </Form.Text>
                        </Form.Group>
                        {checkDevStatusReq > 0 ? (
                            <>
                                <Form.Group>
                                    <Form.Check type="checkbox" checked={serviceProfile.reportDevStatusBattery || ''} label="Report device battery level to application-server" name="reportDevStatusBattery" onChange={(e) => changeCheckbox(e)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check type="checkbox" checked={serviceProfile.reportDevStatusMargin || ''} label="Report device link margin to application-server" name="reportDevStatusMargin" onChange={(e) => changeCheckbox(e)} />
                                </Form.Group>
                            </>
                        ) : null}
                        <Form.Group>
                            <Form.Label>Minimum allowed data-rate</Form.Label>
                            <Form.Control
                                name="drMin"
                                onChange={changeHandler}
                                type="number"
                                placeholder="Minimum allowed data-rate"
                                value={serviceProfile.drMin || ''}
                                autoComplete="off"
                                required
                            />
                            <Form.Text className="text-muted">
                                Minimum allowed data rate. Used for ADR.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Maximum allowed data-rate</Form.Label>
                            <Form.Control
                                name="drMax"
                                onChange={changeHandler}
                                type="number"
                                placeholder="Maximum allowed data-rate"
                                value={serviceProfile.drMax || ''}
                                autoComplete="off"
                                required
                            />
                            <Form.Text className="text-muted">
                                Maximum allowed data rate. Used for ADR.
                            </Form.Text>
                        </Form.Group>
                        {optionsEnabled.updateSPBtn == true ? (
                            <>
                                <div style={{ textAlign: 'right' }}>
                                    <Button disabled={!isValid} className="btnDefault" onClick={handleSubmit}>
                                        Update Service-profile
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    );
}

export default EditServiceProfile;
