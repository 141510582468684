import React, {useState,useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import GoogleApiWrapper from './Map'
// import ErrorPopUp from '../UIElements/ErrorPopup'
import deleteIcon from '../../assests/icons/delete.svg';
import './NetworkNodes.css'
import { useAxios } from '../../utils/Axios';

function NetworkNodes (props) {

    const [mapData, setMapData] = useState(props.nodelist);
    const [nodeData, setNodeData] = useState([]);
    const [nodeListView, setNodeListView] = useState(0)
    const axiosInstance = useAxios();
    let history = useHistory();

    useEffect(() => {
		console.log(props.nodelist)
	},[props.nodelist])

    const modalShow = {
        display : 'block'
    } 

    const modalHide = {
        display: 'none'
    }

    // Checking / unchecking the checkboxes and sending props back to parent
    function selectOne (e) {
        props.nodeselect(e)        
    }

    //Code to open node table in mapview
    function openNodeTable(e) {
        setNodeListView(1)
    }

    //Code to Close node table in mapview
    function closeNodeTable(e){
        setNodeListView(0)
    }

    //code to add node to node list
    function addtoList(input){
        // console.log(input)
        let olddata = [...mapData];
        olddata.map((item) => {
            // console.log(item);
            if (item.friendlyName == input) {
                // console.log("found")
                item['selected'] = !item.selected;
            }
        })

        setMapData(olddata);

        let nodesarray = [];
        for(let i=0;i<olddata.length;i++){
            if (olddata[i].selected == true) {
                // console.log("found")
                nodesarray.push(olddata[i])
            }
        }
        setNodeData(nodesarray);
    }

    //code to delete node from list
    function deleteNode(input){
        let friendlyName = input.getAttribute('friendlyName');
        // console.log(nodename)
        let olddata = [...mapData];
        olddata.map((item) => {
            // console.log(item);
            if (item.friendlyName == friendlyName) {
                // console.log("found")
                item['selected'] = !item.selected;
            }
        })

        setMapData(olddata);

        let nodesarray = [];
        for(let i=0;i<olddata.length;i++){
            if (olddata[i].selected == true) {
                // console.log("found")
                nodesarray.push(olddata[i])
            }
        }
        setNodeData(nodesarray);
    }

    //code to redirect user to sensor dashboard
    function loadSensorDashboard() {
        const storedata = new Promise((resolve, reject) => {
            localStorage.removeItem('sensornodes');
            setTimeout(() => {
                localStorage.setItem('sensornodes', JSON.stringify(nodeData));
                resolve('');
            }, 0);
          });
          
          storedata
            .then( data => {
                history.push('/sensordashboard')
            })
    }

    return (
        <>
            <div className="text-center bg-white  jumbotron">

                <div className="row">
                    {/* Node List in Google Mapview*/}
                    <div className="col-md-12 no-padding">
                        <GoogleApiWrapper nodelist={mapData} addtolist={(input)=>addtoList(input)}/>

                        {/* Node List Table View */}
                        {nodeListView == 0 ? (
                            <>
                                <i className="fas fa-bars nodeListTableIcon" onClick={(e) => openNodeTable(e)}></i>
                            </>
                        ) : (
                                <>
                                    <div className="col-5 no-padding node-list" className="nodesTable">
                                        <span className="create-exp-table-list">Node List</span>
                                        <span className="close" onClick={(e) => {closeNodeTable(e)}} style={{cursor: 'pointer'}}>&times;</span>
                                        <div className="create-exp-table-wrapper">
                                            <Table responsive borderless className="create-exp-table" >
                                                <thead>
                                                    <tr>
                                                        <th>Node Name</th>
                                                        <th>Friendly Name</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {nodeData.length > 0 ? (
                                                        <>
                                                            {nodeData.map((item, index) => {
                                                                return (
                                                                    <tr key={item.hostName} id={item.hostName}>
                                                                        <td>{item.hostName}</td>
                                                                        <td>{item.friendlyName}</td>
                                                                        <td><img src={deleteIcon} className="iconCustom" nodename={item.hostName} friendlyname={item.friendlyName} onClick={e => deleteNode(e.target)} /></td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <tr><td>select atleast one node</td><td></td></tr>
                                                        </>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </div>
                                </>
                            )}
                    </div>

                </div>
                {nodeData.length > 0 ? (
                    <div style={{textAlign : 'left', margin : '4% 0 0 0'}}>
                        <Button className="btnNormal" onClick={() => loadSensorDashboard()}>
                            Sensor Dashboard
                        </Button>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default NetworkNodes;
