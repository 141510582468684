import React from 'react';
import Layout from '../../components/Layout/Layout';
import './ProjectDashboard.css';
import ProjectTable from '../../components/ProjectTable/ProjectTable';
import CreateProject from '../../components/Createproject/CreateProject';
import { useAxios } from '../../utils/Axios';
import { useEffect, useState, useContext } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import Context from '../../store/Context'
import { Breadcrumbs, Link, Stack, Typography, Chip } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FolderIcon from '@mui/icons-material/Folder';
import { emphasize, styled } from '@mui/material/styles';

const ProjectDashboard = (props) => {
	const [projectList, setProjectList] = useState([]);
	const [nodeNetworks, setNodeNetworks] = useState([]);
	const [projectBtnEnable, setProjectBtnEnable] = useState(false);
	const axiosInstance = useAxios();
	const { state, actions } = useContext(Context);

	useEffect(() => {
		fetchList();
	}, [axiosInstance]);

	const fetchList = () => {
		let roles = JSON.parse(localStorage.getItem("roles"));
		// let roles = ["un_dev"]

		// console.log(roles.includes('ln_admin'));
		if (roles.includes('un_admin')) {
			setProjectBtnEnable(true)
		}

		if (roles.includes('un_pradmin')) {
			setProjectBtnEnable(true)
		}

		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/un/projects`)
				.then((response) => {
					// console.log(response);
					checkOwnerName(response.data);
					// setProjectList(response.data);
					fetchNodeNetworks()

					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err);
					fetchNodeNetworks()
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}
	};

	//code to check owner name in projects
	function checkOwnerName(input) {
		let currentuser = localStorage.getItem('username');
		input.map((item) => {
			if (item.ownerName == currentuser) {
				item['currentUser'] = true
			} else {
				item['currentUser'] = false
			}
		})

		setProjectList(input);
	}

	// const sampleList = sampleProjectsList;
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchList();
				actions({ type: 'setState', payload: { ...state, projectChange: true } })
			}, 2000);

		};
	};

	//Code to fetch node networks
	function fetchNodeNetworks() {
		if (axiosInstance) {

			axiosInstance
				.get(`${process.env.REACT_APP_NODEMONITOR_URL}/node-networks/all`)
				.then((response) => {
					// console.log(response);
					setNodeNetworks(response.data)
				})
				.catch((err) => {
					// console.log(err);
				});

		}
	}

	const StyledBreadcrumb = styled(Chip)(({ theme }) => {
		const backgroundColor =
		  theme.palette.mode === 'light'
			? theme.palette.grey[100]
			: theme.palette.grey[800];
		return {
		  backgroundColor,
		  height: theme.spacing(3),
		  color: theme.palette.text.primary,
		  fontWeight: theme.typography.fontWeightRegular,
		  fontSize: '13px',
		  '&:hover, &:focus': {
			backgroundColor: emphasize(backgroundColor, 0.06),
		  },
		  '&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(backgroundColor, 0.12),
		  },
		};
	  });

	return (
		<>
			<Layout projDetails="Data">
			<Stack spacing={2}>
				<Breadcrumbs
					separator={<NavigateNextIcon fontSize="small" />}
					aria-label="breadcrumb"
				>
					<Typography key="1" color="text.primary">
						<StyledBreadcrumb							
							label="My Projects"
							icon={<FolderIcon fontSize="medium" style={{ color: "#1c7b8e" }} />}
						/>
						</Typography>
				</Breadcrumbs>
			</Stack>
				<div className="col-md-12 col-sm-12 col-lg-12 no-padding">
					<div style={{ marginLeft: '0rem' }} className="row">
						
						<div className="col-md-10 col-sm-10 col-lg-10"></div>
					</div>
					<div className="default-main-page">
								<>
									{projectList.length > 0 ? (
										<>
											<div className="project-table-group">
												<ProjectTable data={projectList} status={handleSubmit} networks={nodeNetworks} editdeleteEnable={projectBtnEnable} />
											</div>
											<br />
											<div className="col-md-12 text-right no-padding" >
												{projectBtnEnable == true ? (
													<>
														<CreateProject status={handleSubmit} networks={nodeNetworks} />
													</>
												) : null}
											</div>
										</>
									) : (
										<div className="default-content-center" style={{ margin: '3%', textAlign: 'center' }}>
											You don't have any running Projects right now
											<br />
											<br />
											<div>
												{projectBtnEnable == true ? (
													<>
														<CreateProject status={handleSubmit} networks={nodeNetworks} />
													</>
												) : null}
											</div>
										</div>
									)}
								</>							
					</div>
				</div>
				<ToastContainer draggable={false} transition={Zoom} />
			</Layout>
		</>
	);
};

export default ProjectDashboard;
