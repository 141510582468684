import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import './Map.css'
import activenode from '../../assests/bluemarker.png';
import inactivenode from '../../assests/redmarker.png'
import nodemodel from '../../assests/nodemodel.png'

// We will use these things from the lib
// https://react-google-maps-api-docs.netlify.com/
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";

function MapContainer(props) {
  const [locations, setLocations] = useState(props.nodelist)

  const [mapRef, setMapRef] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [center, setCenter] = useState({ lat: 44.076613, lng: -98.362239833 });
  const [zoom, setZoom] = useState(5);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const { isLoaded } = useLoadScript({
    // Google Maps API key
    // googleMapsApiKey: "AIzaSyAq-P3UaB54VwXF7vlvaNsOOjoSKvCpBr0"
    googleMapsApiKey : process.env.REACT_APP_GOOGLEMAP_URL
  });

  // useEffect(()=>{
  //   console.log(props)
  // },[props])

  // Map Night Version Styling code
  const styles = [
    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#263c3f" }]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#6b9a76" }]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#38414e" }]
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }]
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9ca5b3" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#746855" }]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#1f2835" }]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [{ color: "#2f3948" }]
    },
    {
      featureType: "transit.station",
      elementType: "labels.text.fill",
      stylers: [{ color: "#d59563" }]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#17263c" }]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#515c6d" }]
    },
    {
      featureType: "water",
      elementType: "labels.text.stroke",
      stylers: [{ color: "#17263c" }]
    }
  ]

  const activeMarker = {
    // path: "M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z",
    url: activenode,
  }

  const inactiveMarker = {
    url: inactivenode,
  }

  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds();
    locations.map(place => {
      bounds.extend({ lat: place.lat, lng: place.lng });
      return place.friendlyName;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = map => {
    // Store a reference to the google map instance in state
    setMapRef(map);
    // Fit map bounds to contain all markers
    fitBounds(map);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap(prevState => {
      return { ...prevState, [place.friendlyName]: marker };
    });
  };

  const onCenterChanged = mapRef => {
    if (mapRef && mapRef.getCenter()) {
      const ccenter = mapRef.getCenter().toJSON();
      if (ccenter.lat !== center.lat && ccenter.lng !== center.lng) {
        setCenter(mapRef.getCenter().toJSON());
      }
    }
  };

  const markerClickHandler = (event, place) => {
    // console.log(place);
    // Remember which place was clicked
    setSelectedPlace(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    if (zoom < 14) {
      setZoom(14);
    }

    // if you want to center the selected Marker
    // setCenter(place.pos);

    // console.log(place)
  };

  // Code to send selected node to prop
  const selectNode = (e) => {
    let nodename = e.target.getAttribute('nodename');
    // console.log(nodename)
    props.addtolist(nodename)

  }

    // Code to send un selected node to prop
    const unSelectNode = (e) => {
      let nodename = e.target.getAttribute('nodename');
      // console.log(nodename)
      props.addtolist(nodename)
  
    }
  
  function getMarker(data) {
      if(data.status !== null){
        if (data.status === 'Active'){
          return activeMarker;
        }else if(data.status === 'InActive'){
          return inactiveMarker;
        }else {
          // console.log("No status found")
        }
      }else{
        return inactiveMarker;
      }
  }

  // console.log(props)
  const renderMap = () => {
    return (
      <>
        <GoogleMap
          id="maps"
          onLoad={loadHandler}
          onUnmount={() => console.log()}
          onCenterChanged={() => onCenterChanged(mapRef)}
          onClick={e => setClickedLatLng(e.latLng.toJSON())}
          center={center}
          zoom={zoom}
          options={{
            styles: styles,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            panControl: true
          }}
        >
          {/* <MarkerClusterer>
            {clusterer =>
              locations.map(location => (
                <Marker
                  key={location.friendlyName}
                  position = {{
                      lng: location.lng,
                      lat: location.lat
                  }}
                  clusterer={clusterer}
                  onLoad={marker => markerLoadHandler(marker, location)}
                  onMouseOver={event => markerClickHandler(event, location)}
                  icon = {
                    getMarker(location)
                  }
                />
              ))
            }
          </MarkerClusterer> */}
          {locations.map(location => (
                <Marker
                  key={location.friendlyName}
                  position = {{
                      lng: location.lng,
                      lat: location.lat
                  }}
                  onLoad={marker => markerLoadHandler(marker, location)}
                  onMouseOver={event => markerClickHandler(event, location)}
                  icon = {
                    getMarker(location)
                  }
                />
              ))
          }
          {infoOpen && selectedPlace && (
            <InfoWindow
              anchor={markerMap[selectedPlace.friendlyName]}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div>
                <h5>{selectedPlace.friendlyName}</h5>
                <br />
                <div className="miniNode">
                  <p><b>Host Name : </b></p>
                  <span>{selectedPlace.hostName}</span>
                  <p><b>Friendly Name : </b></p>
                  <span>{selectedPlace.friendlyName}</span>
                  
                  <p><b>Type : </b></p>
                  <span>{selectedPlace.nodeType}</span>
                  
                  <p><b>Status : </b></p>
                  <span>{selectedPlace.status == null ? 'unknown' : selectedPlace.status}</span>
                </div>
                <div>
                  <Image src={nodemodel} width="40%" />
                </div>
                <br />
                <div className="upload-btn-wrapper">
                  {selectedPlace.selected == false ? (
                    <>
                    <button className="btn" nodename={selectedPlace.friendlyName} onClick={(event) => selectNode(event)}>Select</button>
                    </>
                  ) : (
                    <>
                    <button className="btn" nodename={selectedPlace.friendlyName} onClick={(event) => unSelectNode(event)}>Deselect</button>
                    </>
                  )}

                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </>
    );
  };

  return (
    <div>{isLoaded ? renderMap() : null}</div>
  );
}

export default MapContainer;