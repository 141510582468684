import React, {useState,useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import CheckBox from '../CheckBox/CheckBox';
import GoogleApiWrapper from './NetworkMap'
import Image from 'react-bootstrap/Image';
import NodeTypeCheck from '../UIElements/NodeTypeCheck'
import nodemodel from '../../assests/nodemodel.png'
import ErrorPopUp from '../UIElements/ErrorPopup'
import './NetworkNodes.css'
import { useAxios } from '../../utils/Axios';

function NetworkNodes (props) {

    // const [filters, setFileters] = useState([]);
    // const [query, setQuery] = useState('');
    const [mapData, setMapData] = useState(props.nodelist);
    const [originalData, setOriginalData] = useState(props.nodelist.data.nodes);
    const [searchText, setSearchText] = useState("");
    // const [data, setData] = useState(props.nodelist);
    // const [searchResult, setSearchResult] = useState('');
    // const [sort, setSort] = useState({
    //     column: null,
    //     direction: 'asc',
    // });
    const [ nodeTypeData , setNodeTypeData]  = useState([
        {name : "RSE", isChecked : false},
        {name : "RSS", isChecked : false},
        {name : "5G", isChecked : false}
    ])
    const [nodeData, setNodeData] = useState(originalData);
    const [open, setOpen] = useState(false);
    // const [newdata, setNewData] = useState(originalData)
    const [nodedetails, setNodeDetails] = useState({})
    const [nodeListView, setNodeListView] = useState(0)
    const axiosInstance = useAxios();
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    // useEffect(() => {
	// 	console.log(props.nodelist.data.nodes)
	// },[props])


    const modalShow = {
        display : 'block'
    } 

    const modalHide = {
        display: 'none'
    }

    //Sorting the Table Columns Ascending and descending order
    const onSort = (e) => {
        // const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        // const sortedData = this.state.data.sort((a, b) => {
        //     if (a[column] < b[column]) {
        //         return -1;
        //     }
        //     if (a[column] > b[column]) {
        //         return 1;
        //     }
        //     return 0;
        // });

        // if (direction === 'desc') {
        //     sortedData.reverse();
        // }

        // this.setState({
        //     data: sortedData,
        //     sort: {
        //         column,
        //         direction,
        //     }
        // });
    };

    //Based on sorting setting the arrow to right and down
    const setArrow = (e) => {
        // let className = 'fa fa-angle-';
        // if (this.state.sort.column === column) {
        //     className += this.state.sort.direction === 'asc' ? 'right' : 'down';
        // }
        // className = className.split("-")[2] == "" ? "fa fa-angle-right" : className;
        // return className;
    };

    //Selecting all Nodes by Checkbox
    const selectAll = (e) => {
        // let cBox = document.querySelectorAll('[createexptable="exp-inp-checked"]');
        // let checkedAllState = e.target.checked == true ? true : false;
        // for (var i = 0; i <= cBox.length - 1; i++) {
        //     cBox[i].checked = checkedAllState;
        // }
    }

    //event to change type check
    function changeNodeType (e) {
        let indexval = e.target.getAttribute('indexid')
        let check = e.target.checked
        let inputval = e.target.value
        let newArr = [...nodeTypeData]; // copying the old datas array
        newArr[indexval]['isChecked'] = check;
        setNodeTypeData(newArr)
        filterNodesByType(originalData)
    }

    //code to filter the nodelist by type
    function filterNodesByType (a) {
        // console.log(data)
        // console.log(check)
        var data = a;

        var keys = [];

        nodeTypeData.map((val) => {
            if(val.isChecked == true){
                keys.push(val.name)
            }
        })

        let array = []
        if(keys.length > 0){
            
            keys.map((item) => {
                data.map((b) => {
                    if(b.nodeType == item){
                        array.push(b)
                    }
                })
            })
        }else {
            array = data
        }
        
        setNodeData(array)
        
    }
    
    // exclude column list from filter
    const excludeColumns = ["status"];

    // handle change event of search input
    const searchNodes = value => {
        setSearchText(value);
        filterNodes(value);
    };
    
   // filter records by search text
    const filterNodes = (value) => {
        const lowercasedValue = value.toLowerCase().trim();
        
        if (lowercasedValue === ""){ 
            setNodeData(originalData);
        }
        else {
            const filteredData = originalData.filter(item => {
                return Object.keys(item).some(key =>
                    excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
                );
            });
            // console.log(filteredData)
            setNodeData(filteredData);
            
        }
    }


    // Checkcking / unchecking the checkboxes and sending props back to parent
    function selectOne (e) {
        // document.querySelector('[createexptable="exp-checkbox-header"]').checked = false;
        // this.setState({nodesData : {"Alpha" : "A"}})
        props.nodeselect(e)
        // let nodeId = e.target.getAttribute("index")
        // console.log(nodeId)
        // console.log(e.target.checked)

        
    }

    //Getting Node detailed view
    const detailedView = (data) => {
        // console.log(data)
        axiosInstance
            .get(`${process.env.REACT_APP_NODEMONITOR_URL}/nodes/${data}`)
            .then((response) => {
            //    console.log(response.data)
            //    setNodeDetails(response.data.data)
                let convertNodeData = Object.fromEntries(
                    // convert to array, map, and then fromEntries gives back the object
                    Object.entries(response.data).map(([key, value]) => [key, value])
                );

                setNodeDetails(convertNodeData)
               setOpen(true)
            })
            .catch((err) => {
                // console.log(err);
                ErrorPopUp("Request Failed. Try Again")
            });
    }

    //code to close detailed view modal
    function closeModal(e) {
        setOpen(false)
    }

    //Code to open node table in mapview
    function openNodeTable(e) {
        setNodeListView(1)
    }

    //Code to Close node table in mapview
    function closeNodeTable(e){
        setNodeListView(0)
    }

    return (
        <>
            <div className="text-center bg-white  jumbotron" style={{ marginRight: '5rem' }}>

                <div className="row create-exp-search">
                    {/* Search Input for Node List */}
                    <div style={{ color: 'red'}}>
                        {error}
                    </div>
                    <div className="col-md-4">
                        {/* <input className="form-control serch-box" onKeyUp={doSearch()} ref={input => (search(input))} type="text" placeholder="Search" aria-label="Search"></input> */}
                        <input className="form-control serch-box" onChange={(e) => {
                            const value = e.target.value;
                            const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                            if (!textFieldRegex.test(value)) {
                                setError(e.target.name + ' is invalid');
                                setIsValid(false);
                            }else{
                                setError('');
                                setIsValid(true);
                            }
                            searchNodes(e.target.value)
                        }} type="text" name="Searchbox" placeholder="Search" aria-label="Search"></input>
                    </div>
                    {/* Filter Node List by RSS, RSE, 5G Networks */}
                    <div className="offset-md-1 col-md-7 create-exp-filter">
                        <label style={{ paddingTop: "0.8rem" }}><b>Filter</b></label>

                        {nodeTypeData.map((item, index) => {
                            return (
                                <NodeTypeCheck key={index} id={index} event={(e) => changeNodeType(e)} {...item}/>
                            )
                        })}
                    </div>
                </div>

                <div className="row">
                    {/* Node List in Google Mapview*/}
                    <div className="col-md-12 no-padding">
                        <GoogleApiWrapper nodelist={mapData} detailedview={detailedView}/>

                        {/* Node List Table View */}
                        {nodeListView == 0 ? (
                            <>
                                <i className="fas fa-bars nodeListTableIcon" onClick={(e) => openNodeTable(e)}></i>
                            </>
                        ) : (
                                <>
                                    <div className="col-5 no-padding node-list nodesTable">
                                        <span className="create-exp-table-list">Node List</span>
                                        <span className="close" onClick={(e) => {closeNodeTable(e)}} style={{cursor: 'pointer'}}>&times;</span>
                                        <div className="create-exp-table-wrapper">
                                            <Table responsive borderless className="create-exp-table" >
                                                <thead>
                                                    <tr >
                                                        <th className="th-no-margin">
                                                            {/* <CheckBox
                                                                event={selectAll()}
                                                                createExpTable={"exp-checkbox-header"}
                                                            /> */}
                                                        </th>
                                                        <th onClick={onSort('exp_name')}>
                                                            Name
                                                            <span className="sort-arrow" >
                                                                <i className={setArrow('exp_name')}></i>
                                                            </span>
                                                        </th>
                                                        <th>Host Name</th>
                                                        <th onClick={onSort('exp_type')}>
                                                            Type
                                                            <span className="sort-arrow">
                                                                <i className={setArrow('exp_type')}></i>
                                                            </span>
                                                        </th>
                                                        <th onClick={onSort('exp_status')}>
                                                            Status
                                                            <span className="sort-arrow">
                                                                <i className={setArrow('exp_status')}></i>
                                                            </span>
                                                        </th>
                                                        {/* <th>View</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {nodeData.length > 0 ? (
                                                        <>
                                                            {nodeData.map((item, index) => {
                                                                return (
                                                                    <tr key={index} id={item.hostName}>
                                                                        <td>

                                                                            <CheckBox event={(e) => selectOne(e)} name={item.hostName} id={index} />
                                                                        </td>
                                                                        <td>{item.hostName}</td>
                                                                        {/* <td>{item.friendlyName}</td> */}
                                                                        <td>{item.friendlyName == null ? "-" : (<>{item.friendlyName}</>)}</td>
                                                                        <td>{item.nodeType}</td>
                                                                        <td>{item.status}</td>
                                                                        {/* <td>{item.exp_view}</td> */}
                                                                    </tr>
                                                                );
                                                            })}
                                                        </>
                                                    ) : (
                                                            <>
                                                                <tr><td>No Nodes found</td><td></td><td></td><td></td><td></td></tr>
                                                            </>
                                                        )}
                                                </tbody>
                                            </Table>
                                        </div>

                                    </div>
                                </>
                            )}
                        
                        
                        {/* Node Detailed View */}
                        <div className="container-fluid nodeDetailedView" style={open === true ? modalShow : modalHide}>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-lg-12 modalTitle">
                                    <div>{nodedetails.HostName} - Detailed view</div>
                                    <div onClick={(e) => closeModal(e)}>&times;</div>
                                </div>
                                <div className="create-exp-table-wrapper" style={{padding: '2%'}}>
                                        <div className="miniNode detailedNode" style={{width: '50%'}}>
                                            <p><b>Description</b></p>
                                            <br />
                                            <p>The Road-Side Standard node type is a fixed position installation equipped for environmental data collection and radio based experimentation with a range of communication technologies on board. </p>
                                            <div>
                                                <br />
                                                <p><b>Host Name : </b></p>
                                                <span>{nodedetails.HostName == null ? "-" : (<>{nodedetails.HostName}</>)}</span>
                                                
                                                <p><b>Name : </b></p>
                                                <span>{nodedetails.FriendlyName == null ? "-" : (<>{nodedetails.FriendlyName}</>)}</span>
                                                
                                                <p><b>Type : </b></p>
                                                <span>{nodedetails.NodeType == null ? "-" : (<>{nodedetails.NodeType}</>)}</span>
                                                
                                                <p><b>Status : </b></p>
                                                <span>{nodedetails.HardwareStatus == null ? '-' : nodedetails.HardwareStatus}</span>
                                                
                                                <p><b>Ip Address : </b></p>
                                                <span>-</span>
                                            </div>
                                            <div>
                                                <Image src={nodemodel} width="100%" />
                                            </div>
                                            
                                        </div>
                                    
                                    <div>
                                    <Table className="nodeDetailsTable table-bordered" style={{width: '50%'}}>
                                        <thead>
                                            <tr>
                                                <th><b>Type</b></th>
                                                <th><b>Items</b></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           <tr>
                                               <td>Processing Core(s)</td>
                                               <td>Raspberyy Pi CM+3<br />Jetson Nano</td>
                                           </tr>
                                           <tr>
                                               <td>Sensors</td>
                                               <td>Pi Camera (Sky-facing)<br />MP503 Gas Sensor<br />6-Axis IMU<br />GPS</td>
                                           </tr>
                                           <tr>
                                               <td>Actuator(s)</td>
                                               <td>None</td>
                                           </tr>
                                           <tr>
                                               <td>Communication Devices (s)</td>
                                               <td>868MHz CC1310<br />868MHz LoraWAN<br />2.4GHz BL654<br />2.4GHz Wifi Mesh</td>
                                           </tr>
                                        </tbody>	
                                    </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default NetworkNodes;
