import React, { useState, useEffect, useContext } from 'react';
import DevicesList from './Devices/DevicesList';
import IntegrationsList from './Integrations/IntegrationsList'
import LoraLayout from '../LoraLayout/LoraLayout';
import Button from 'react-bootstrap/Button';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
import { useHistory } from 'react-router-dom';
import Context from '../../../store/Context';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

function AppDetails(props) {
    const [optionsEnabled, setOptionsEnabled] = useState({
        createLoraDevice: false,
        deleteDevice: false,
        deleteApp: false,
        integrations: false
    })
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const history = useHistory();

    const [value, setValue] = React.useState('Devices');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    useEffect(() => {
        checkCurrentUser()
    }, [axiosInstance]);

    //code to check current user role
    function checkCurrentUser() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ["ln_user"]

        // console.log(roles.includes('ln_admin'));
        if (roles.includes('ln_admin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraDevice: true,
                    deleteDevice: true,
                    deleteApp: true,
                    integrations: true
                })
            )
        }
        if (roles.includes('ln_orgadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraDevice: true,
                    deleteDevice: true,
                    deleteApp: true,
                    integrations: true
                })
            )
        }
        if (roles.includes('ln_user')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraDevice: false,
                    deleteDevice: false,
                    deleteApp: false,
                    integrations: false
                })
            )
        }
        if (roles.includes('ln_deviceadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraDevice: true,
                    deleteDevice: true,
                    deleteApp: false,
                    integrations: true
                })
            )
        }

    }

    //Code to delete Organization
    function deleteApplication(e) {
        swal({
            title: "Are you sure?",
            text: "Application will be deleted",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    axiosInstance
                        .delete(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}`)
                        .then((response) => {
                            PopUp("Application Deleted Successfully");
                            history.push('/lora/applications');
                            // sendUpdateState()
                        })
                        .catch((err) => {
                            // console.log(err);
                            ErrorPopUp("Deleting Failed. Try Again");
                        });
                }
            });
    }

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Applications</h5>
                        </div>
                        <div
                            style={{ marginTop: '1rem' }}
                            className="col-md-6 col-sm-6 col-lg-6 text-right"
                        >
                            {optionsEnabled.deleteApp == true ? (
                                <Button className="btnNormal" type="submit" onClick={(e) => deleteApplication(e)}>
                                    DELETE
                                </Button>
                            ) : null}
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="Devices" value="Devices" />
                                        <Tab label="Integrations" value="Integrations" />
                                    </TabList>
                                </Box>
                                <TabPanel value="Devices"><DevicesList {...props} options={optionsEnabled} /></TabPanel>
                                <TabPanel value="Integrations"><IntegrationsList {...props} options={optionsEnabled} /></TabPanel>
                            </TabContext>
                        </Box>                       
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default AppDetails
