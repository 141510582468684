import React  from 'react';
import './SMEForm.css';
import Banner from '../../assests/sme-competition-banner.png';
import {Container, Row, Col, Jumbotron, Form, Table} from 'react-bootstrap';
import NewHeader from '../../components/Header/NewHeader';


const SMEFormResult = () => {

    return (
        <>
       <NewHeader />
        <div className="container-fluid outer-block">      
        <section className="row justify-content-center inside-scroll" > 
        <Container>   
        <Jumbotron className="bg-white">  
            <Row>
                <Col>
                <img className=".img-fluid" src={Banner} alt="Banner"/>
                </Col>            
            </Row> 
            <br/>
            <br/>
            <br/>
            <Row>
                <Col className="justify-content-center">
                    <h6>Thank you, your submission has been recorded. Follow this <span><a href="https://www.umbrellaiot.com/umbrella-competition/" target='_blank'>link</a></span> to return to the competition page.</h6>
                </Col>                        
            </Row>
        </Jumbotron>
        </Container>
        </section>
        </div>
        </>
    );
};

export default SMEFormResult;