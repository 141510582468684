import React from 'react';
import './ProgressBar.css'

const ProgressBar = (props) => {
    const { completed, name, id } = props;
  
    const containerStyles = {
    //   height: 20,
      width: '80%',
      height: '10px',
      backgroundColor: "#e0e0de",
    }
  
    const fillerStyles = {
      height: '10px',
      width: `${completed}%`,
    //   width: '100%',
      backgroundColor: "#00695c",
      transition: 'width 1s ease-in-out'
    }
  
    const labelStyles = {
      padding: '0px 10px 0px 0px',
      color: 'black',
      fontWeight: 'bold',
      fontSize: '12px'
    }
    return (
        <div style={{display:'flex', marginTop: '8%'}} name={name} id={`${id}`}>
            <span style={labelStyles}>{`${completed}%`}</span>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    
                </div>
            </div>
        </div>
    );
  };
  
  export default ProgressBar;