import React, { useState, useEffect } from 'react';
import { useAxios } from '../../../utils/Axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ProgressBar from '../../../components/UIElements/ProgressBar'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';
import './ImageUpload.css'


function ConfigUploader(props) {
    const [rows, setRows] = useState([]);
    const [files, setFiles] = useState([]);
    const [percentage, setUploadPerecent] = useState([]);
    const [objPercent, setObjPercent] = useState([])

    useEffect(() => {
        handleAddRow()
    }, [])

    const axiosInstance = useAxios();

    const handleFileChange = (idx) => (e) => {
        // console.log(e.target.files[0])
        const value = e.target.files[0];
        const name = e.target.name

        let items = rows.map(row => {
            if (row.id === idx.id) {
                row[name] = value;
            }
            return row;
        });

        setRows(items);
        
      }
    
    const handleTypeChange = (idx) => (e) => {
        const { name, value } = e.target;
        // console.log(idx.id);
        let items = rows.map((row,index) => {
            if (index === idx) {
                row[name] = value;
            }
            return row;
        });
        setRows(items);

    };


    const handleAddRow = () => {
        let item = {
            type: ""
        };

        let fileItem = {
            binary : "",
        }

        setRows([...rows, item]);
        setFiles([...files, fileItem]);
    };

    const handleRemoveSpecificRow = item => () => {

        let data = [...rows]
        let items = data.map((row,index) => {
            if (index === item) {
                var col = rows.splice(index,1);
                // console.log("correct")
                return col;
            }
            return row;
            
        });
        setRows(items);
    };

    const handleSpecificRowUpload = item => () => {
        //Find the current file upload
		let fileArray = files
		var fileObj;
		for (var i = 0, len = fileArray.length; i < len; i++) {
			fileObj = fileArray[item];
		}

		//Find the current type upload
		let typeArray = rows
        var typeObj;
        // var percentageObj;
		for (var j = 0, typelen = typeArray.length; j < typelen; j++) {
            typeObj = typeArray[item];
            // percentageObj = typeArray[item];
		}


		let totalReq = {
			...fileObj,
            ...typeObj,
		};

		if (Object.keys(totalReq).length === 0 && totalReq.constructor === Object) {
            // console.log("Select File and type")
            ErrorPopUp("Select File and type")
		} else if (totalReq['binary'] === undefined || totalReq['binary'] === '') {
            // console.log("Select File")
            ErrorPopUp("Select File")
		} else if (totalReq.type === undefined || totalReq.type === '') {
            // console.log("Select Type")
            ErrorPopUp("Select Type")
		} else {
			binaryUpload(totalReq,item)
		}
    }


	//Code to send Binary to server
	function binaryUpload(data,item) {
 
        // console.log(data)

        const formData = new FormData();

        // let files = data.image.File;
        
        formData.append('binary', data.binary);

        formData.append('type', data.type);

        formData.append('overwrite', true);

		axiosInstance({
			method: 'post',
			url: `${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/binaries`,
			data: formData,
			headers: {
				'Content-Type': 'application/octet-stream',
				'Content-Disposition': 'file'
			},
			onUploadProgress : (progressEvent) => {
				const {loaded , total} = progressEvent;
				let percent = Math.floor(loaded*100/total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                const oldArray = [...objPercent]
                oldArray[item] = percent
                setObjPercent(oldArray)
                
			}
		})
		.then((response) => {
			// console.log(response);
            PopUp("Uploaded Successfully")
            sendUpdateState()

		})
		.catch((err) => {
			// console.log(err);
            ErrorPopUp("Upload Failed. Try Again")
			setObjPercent([]);
		})
    }
    

    const sendUpdateState = () => {
        setTimeout(() => {
            props.changeCurState()    
        }, 2000);
    }
    // console.log(progressId)

    return (
        <div style={{padding : '1%', border : '1px solid lightgrey'}}>
            <Table responsive className="table-bordered imgUploader">
                <thead className="thead-light">
                    {/* <tr>
                        <th colSpan={4}>Binaries Upload</th>
                    </tr> */}
                    <tr>
                        <th className="text-center"> Select File </th>
                        <th className="text-center"> Progress</th>
                        <th className="text-center"> Type</th>
                        <th />
                        {/* <th /> */}
                    </tr>
                </thead>
                <tbody>

                    {rows.map((item, index) => (
                        <tr key={index}>
                            {/* <td>{item.id}</td> */}
                            <td>
                                <input
                                type="file"
                                name="binary"
                                // value={this.state.rows[idx].name}
                                onChange={handleFileChange(index)}
                                />
                            </td>
                            <td>
                            <ProgressBar completed={objPercent[index] === undefined ? 0 : objPercent[index]} />
                            </td>
                            <td>
                                <select
                                // value={rows[item].type}
                                onChange={handleTypeChange(index)}
                                name="type"
                                className="form-control form-control-sm"
                                    >
                                    <option value="">Select Type</option>
                                    <option value="configuration">Configuration</option>
                                    <option value="world_file">World</option>
                                </select>
                            </td>
                            <td>
                                <Button
                                // className="btn btn-outline-danger btn-sm"
                                className="btnDefault btn-sm"
                                onClick={handleSpecificRowUpload(index)}
                                >
                                Upload
                                </Button>
                            </td>
                            {/* <td>
                                <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={handleRemoveSpecificRow(index)}
                                >
                                Remove
                                </button>
                            </td> */}
                        </tr>
                    ))}
                   
                </tbody>
            </Table>

            <Button onClick={handleAddRow} className="btnDefault">
                New
            </Button>
        </div>
    );
}

export default ConfigUploader;