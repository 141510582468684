import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../components/Layout/Layout';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useAxios } from '../../utils/Axios';
import { ToastContainer, Zoom } from 'react-toastify';
import PopUp from '../../components/UIElements/PopUp'
import ErrorPopup from '../../components/UIElements/ErrorPopup'
import Context from '../../store/Context'
import ReactPaginate from 'react-paginate';

function NodeInventory() {
    const [open, setOpen] = useState(false);
    const [allNodes, setAllNodes] = useState([]);
    const [nodeInfo, setNodeInfo] = useState({});

    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    const [currentPage, setCurrentPage] = useState(0);
    const recordsPerPage = 10;
    const pageCount = Math.ceil(allNodes.length / recordsPerPage);
    const pageRangeDisplayed = 5;
    const onPageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    const start = currentPage * recordsPerPage;
    const end = start + recordsPerPage;
    const currentData = allNodes.slice(start, end);

    useEffect(() => {
        fetchNodes();
    }, [axiosInstance]);

    // useEffect(() => {
    //     console.log(nodeInfo);
    // }, [nodeInfo]);

    //code to fetch all nodes
    const fetchNodes = () => {

        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_NODEMONITOR_URL}/nodes`)
                .then((response) => {
                    // console.log(response);
                    setAllNodes(response.data);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    };

    //Code to get individual node hardware and software info
    function fetchNodeInfo(e) {
        e.preventDefault();
        setOpen(false);
        // console.log(e.target.getAttribute('hostname'))
        let host = e.target.getAttribute('hostname')

        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_NODEMONITOR_URL}/nodes/details/${host}`)
                .then((response) => {
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    // PopUp("success")
                    setNodeInfo(response.data);
                    setOpen(true)

                })
                .catch((err) => {
                    console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    if (err.response) {
                        console.log(err.response.data.detail);
                        if(err.response.data.detail !== undefined || err.response.data.detail !== null){
                            ErrorPopup(err.response.data.detail)
                        }else{
                            ErrorPopup("Request Failed or Unknown Node")
                        }
                    }
                    
                });

        }


    }

    return (
        <Layout>
                <div className="bg-white jumbotron" style={{ marginRight: '5rem' }}>
                    <h5>All Nodes</h5>
                    <Table responsive borderless className="projectTableData">
                        <thead>
                            <tr>
                                <th>Host Name</th>
                                <th>Friendly Name</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.map((item, index) => (
                                <tr key={index} >
                                    <td>
                                        <Link to={`#`} hostname={item.HostName} onClick={(e) => fetchNodeInfo(e)}>{item.HostName}</Link>
                                    </td>
                                    <td>
                                        {
                                            item.FriendlyName
                                        }

                                    </td>
                                    <td>
                                        {
                                            item.Type
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <ReactPaginate
                            initialPage={0}
                            className= 'pagination'
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={onPageChange}
                            containerClassName={"pagination"}
                            previousClassName={"previous"}
                            nextClassName={"next"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            pageRangeDisplayed={pageRangeDisplayed}
                            marginPagesDisplayed={1}
                        />

                    {/* Code to show node detailed view*/}
                    <Modal
                        show={open}
                        onHide={() => setOpen(false)}
                        animation={false}
                        centered
                        keyboard={false}
                        className="node-modal-dialog"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Node Info</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                                    {Object.keys(nodeInfo).length > 0 ?
                                        <>
                                                <Table responsive bordered className="projectTableData nodeInfoTable">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="2">NODE SUMMARY</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p>Last Communication: {nodeInfo.nodeSummary.lastCommunication}</p>
                                                                <p>Host Name: {nodeInfo.nodeSummary.HostName}</p>
                                                                <p>Type: {nodeInfo.nodeSummary.Type}</p>
                                                                <p>Friendly Name: {nodeInfo.nodeSummary.FriendlyName}</p>
                                                                <p>Hardware Status: {nodeInfo.nodeSummary.HardwareStatus}</p>
                                                                <p>GPSHash: {nodeInfo.nodeSummary.GPSHash}</p>
                                                                <p><b>Build:</b></p>
                                                                <p>Branch: {nodeInfo.nodeSummary.Build.Branch}</p>
                                                                <p>Commit: {nodeInfo.nodeSummary.Build.Branch}</p>
                                                                <p>State: {nodeInfo.nodeSummary.Build.State}</p>
                                                                <p>Build Date: {nodeInfo.nodeSummary.Build.BuildDate}</p>
                                                                <p><b>Edge Module Status :</b></p>
                                                                <p>Connected: {nodeInfo.nodeSummary.EdgeModuleStatus.Connected == true ? <span>Yes</span> : <span>No</span>}</p>
                                                                <p>Serial Number: {nodeInfo.nodeSummary.EdgeModuleStatus.SerialNumber}</p>
                                                            </td>
                                                            <td>
                                                                <p>Node start time : {nodeInfo.NodeStartTime}</p>
                                                                <p>Node Runtime : {nodeInfo.NodeRuntime}</p>
                                                                <p>Network Interfaces : </p>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </Table>

                                                <Table responsive bordered className="projectTableData nodeInfoTable">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="2">GPS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <p><b>TPV</b></p>
                                                                <p>Class: {nodeInfo.GPS.TPV.Class}</p>
                                                                <p>Tag: {nodeInfo.GPS.TPV.Tag}</p>
                                                                <p>Device: {nodeInfo.GPS.TPV.Device}</p>
                                                                <p>TimeUTC: {nodeInfo.GPS.TPV.TimeUTC}</p>
                                                                <p>Lat: {nodeInfo.GPS.TPV.Lat}</p>
                                                                <p>Lon: {nodeInfo.GPS.TPV.Lon}</p>
                                                                <p>Alt : {nodeInfo.GPS.TPV.Alt}</p>
                                                                <p>Mode: {nodeInfo.GPS.TPV.Mode}</p>
                                                                <p>Mode Verbose :{nodeInfo.GPS.TPV.ModeVerbose}</p>
                                                                <p>Satellites: {nodeInfo.GPS.Satellites != null ? nodeInfo.GPS.Satellites.length : 0}</p>
                                                                <p>Server Packet Loss:{nodeInfo.ServerPktLoss}</p>
                                                            </td>
                                                            <td>
                                                                <p>Partitions : {nodeInfo.Partitions.length}</p>
                                                            </td>
                                                        </tr>
                                                    </tbody>

                                                </Table>
                                            
                                                <Table responsive bordered className="projectTableData nodeInfoTable">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan="3">Current Sensors</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Host Serial</th>
                                                            <th>Sensor Info</th>
                                                            <th>Reading</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {nodeInfo.CurrentSensors !== null ? <>
                                                            {nodeInfo.CurrentSensors.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <p>{item.HostSerial}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>Sensor ID - {item.SensorInfo.SensorID}</p>
                                                                        <p>Sensor Type - {item.SensorInfo.SensorType}</p>
                                                                        <p>Meta Data - {item.SensorInfo.MetaData}</p>
                                                                        <p>Sample Period MilliSec - {item.SensorInfo.SamplePeriodMilliSec}</p>
                                                                    </td>
                                                                    <td>
                                                                        <p>Sensor Id - {item.Reading.Sensor_id}</p>
                                                                        <p>Voltage(mv) - {item.Reading.Voltage_mv}</p>
                                                                        <p>Power(mw) - {item.Reading.Power_mw}</p>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </> 
                                                        : <>
                                                            <tr>
                                                                <td colSpan="3">
                                                                    <p>Sensors Data Not Found</p>
                                                                </td>
                                                            </tr>
                                                        </>
                                                        }
                                                    </tbody>
                                                </Table>
                                            
                                        </>
                                        :
                                        <p>Node Data Not found</p>
                                    }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                className= "btnDefault"
                                onClick={() => setOpen(false)}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            <ToastContainer draggable={false} transition={Zoom}/>
        </Layout>
    )
}

export default NodeInventory;



