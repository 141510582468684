import React, { useState } from 'react';
import './CreateProject.css';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import { useAxios } from '../../utils/Axios';
import PopUp from '../UIElements/PopUp'
import ErrorPopUp from '../UIElements/ErrorPopup'

function CreateProject({ status , networks }) {
	const axiosInstance = useAxios();
	const [open, setOpen] = useState(false);
	const [newProject, setNewProject] = useState({
		projectName: '',
		projectDescription: '',
		testbedType : 'umbrella_network',
		testbedName: '',
	});
	const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	const handleSubmit = (event) => {
		event.preventDefault();
		axiosInstance
			.post(`${process.env.REACT_APP_PROJECT_URL}/projects`, newProject)
			.then((response) => {
				setOpen(false);
				if (response.response) {
					PopUp(response.response.data.detail)
                }else {
                    PopUp("Project Added Successfully")
                }
				status(true);
			})
			.catch((err) => {
				// console.log(err);
				if (err.response) {
					ErrorPopUp(err.response.data.detail)
                }else {
                    ErrorPopUp("Adding Project Failed. Try Again")
                }
			});
	};

	const changeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
            setError(name + ' is invalid');
			setIsValid(false);
        }else{
			setError('');
			setIsValid(true);
		}
		setNewProject({ ...newProject, [name]: value });
	};

	return (
		<div>
			<Button className="btnDefault" style={{margin : '2%'}} onClick={() => setOpen(true)}>
				+ New Project
			</Button>

			<Modal
				show={open}
				onHide={() => setOpen(false)}
				animation={false}
				centered
				keyboard={false}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>New Project</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="Container">
							<div className="row">
								<div className="col-md-12 col-sm-12 col-lg-12">
									<label style={{ marginBottom: '2rem' }}>
										<b>Details</b>
									</label>
									<div style={{ color: 'red'}}>
                                        {error}
                                    </div>
									<Form.Group controlId="projectName">
										<Form.Label>Project Name</Form.Label>
										<Form.Control
											name="projectName"
											onChange={changeHandler}
											type="text"
											placeholder="Enter a name for your Project"
											required
										/>
									</Form.Group>
									<Form.Group controlId="projectDesc">
										<Form.Label>Description</Form.Label>
										<Form.Control
											name="projectDescription"
											onChange={changeHandler}
											as="textarea"
											rows="2"
											placeholder="Enter a description for your Project"
											required
										/>
									</Form.Group>
									<Form.Group controlId="projectNetwork">
										<Form.Label>Node Network</Form.Label>
										<Form.Control
											as="select"
											name="testbedName"
											onChange={changeHandler}
											required
											custom
										>
											<option value=''>Select Network</option>
											{networks.length > 0 ? (
												<>
													{networks.map((item, index) => (
														<option value={item.name} key={index}>{item.name}</option>
													))}
												</>
												) : null
											}

										</Form.Control>
									</Form.Group>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btnNormal"
							variant="btnColor btn-primary-outline"
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
							<Button disabled={!isValid} className="btnDefault" type="submit">
							Create Project
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			
		</div>
	);
}

export default CreateProject;
