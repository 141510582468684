import React, { useState, useEffect, useContext } from 'react';
import LoraLayout from '../../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import PopUp from '../../../../components/UIElements/PopUp';
import ErrorPopUp from '../../../../components/UIElements/ErrorPopup';
import Col from 'react-bootstrap/Col';
import { useAxios } from '../../../../utils/Axios';
import Context from '../../../../store/Context';
import { useHistory } from 'react-router-dom';

function CreateDevice(props) {
    const [deviceData, setDeviceData] = useState([]);
    const [deviceProfiles, setDeviceProfiles] = useState([]);
    const [MSB, setMSB] = useState(true);
    const [EUIValue , setEUIValue] = useState('');
    const [fcvCheck , setFcvCheck] = useState(false);
    const [deviceCheck , setDeviceCheck] = useState(false   );
    const [rowData , setRowData] = useState([]);
    const [rowTags , setRowTags] = useState([]);
    const [supportsJoin , setSupportsJoin] = useState({});

    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const history = useHistory();
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        console.log(props);
    }, [props])

    useEffect(() => {
        fetchDeviceProfile();
    }, [axiosInstance])
    
    //Code to fetch device profiles adn store in state
    function fetchDeviceProfile() {
        let reqid = localStorage.getItem('orgid');
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/device-profiles?limit=100&organizationID=${reqid}`)
                .then((response) => {
                    // console.log(response);
                    setDeviceProfiles(response.data.result)
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                })
                .catch((err) => {
                    // console.log(err);
                    setDeviceProfiles([{ "id": "e48430bf-ac38-4815-a57e-5cda3e3f7d4c", "name": "deviceprofile1", "organizationID": "21", "networkServerID": "4", "createdAt": "2021-01-20T15:46:37.193352Z", "updatedAt": "2021-01-20T15:46:37.193352Z", "networkServerName": "network-server-test" }])
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }


    //code to store input data
    function inputHandler(e) {
        const name = e.target.name;
        const value = e.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        setDeviceData({ ...deviceData, [name]: value });
    }

    //Code to store selected device profiles
    function changeDeviceProfile(e) {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let name = e.target.name
        let val = optionElement.value;
        setDeviceData({ ...deviceData, [name]: val });
        fetchDeviceProfileWithId(val)
    }

    //code to set MSB/LSB
    function changeMSB(e){
        const bytes = EUIValue.match(/[A-Fa-f0-9]{2}/g);
        if (bytes !== null) {
            let data = bytes.reverse().join("")
            setEUIValue(data)
        }
        setMSB(!MSB);
        setError('');
		setIsValid(true);
    }

    //Code to store EUI user input change
    function changeEUI(e){
        let input = e.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(input)) {
			setError('Device EUI is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        // if (input >= 12) input = 12;
        setEUIValue(input)
    }

    //code to generate 12digit EUI Id and save in state
    function generateEUI(e){
        let key = "";
        const possible = 'abcdef0123456789';

        for(let i = 0; i < 16; i++){
            key += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        setEUIValue(key)
        setError('');
		setIsValid(true);
    }

    //code to store checkbox data
    function setCheckbox(e){
        let name = e.target.getAttribute('name');
        if(name == 'skipFCntCheck'){
            setFcvCheck(!fcvCheck)
        } 

        if(name == 'isDisabled'){
            setDeviceCheck(!deviceCheck)
        }
    }

    //code to add new variable row
    function addRowVariable(input) {
        if(input == 'variable'){
            let newRow = {

            }
    
            setRowData([...rowData, newRow])
        }
        if(input == 'tag'){
            let newRow = {

            }
    
            setRowTags([...rowTags, newRow])
        }
    }

    //Code to store variable name
    const changeName = (idx) => (e) => {
        const value = e.target.value;
        // console.log(value);
        const name = e.target.name
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        let newArr = [...rowData]; // copying the old datas array
        newArr[idx][name] = value;
        setRowData(newArr)
    } 

    //Code to store variable name
    const changeTagName = (idx) => (e) => {
        const value = e.target.value;
        // console.log(value);
        const name = e.target.name
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
        let newArr = [...rowTags]; // copying the old datas array
        newArr[idx][name] = value;
        setRowTags(newArr)
    } 

    //code to filter variable data
    function filterVariableData (){
        let obj = {}
        let olddata = [...rowData];

        for(let i=0;i<olddata.length;i++){
            if(olddata[i].name !== undefined && olddata[i].value !== undefined){
                obj[olddata[i].name] = olddata[i].value
            }
        }
       return obj;
    }

     //code to filter Tag data
     function filterTagsData (){
        let obj = {}
        let olddata = [...rowTags];

        for(let i=0;i<olddata.length;i++){
            if(olddata[i].name !== undefined && olddata[i].value !== undefined){
                obj[olddata[i].name] = olddata[i].value
            }
        }
       return obj;
    }

    //code to fetch device profile with id and check it has OTT supported
    function fetchDeviceProfileWithId(valueid){
        console.log(valueid);
        if (axiosInstance) {
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/device-profiles/${valueid}`)
                .then((response) => {
                    // console.log(response);
                    console.log(response);
                    setSupportsJoin(response.data.deviceProfile)
                })
                .catch((err) => {
                    console.log(err);
                    // let obj = {
                    //     "deviceProfile": {
                    //         "id": "fbdcc80a-caa0-4277-a9fc-40739adca0d3",
                    //         "name": "a4",
                    //         "organizationID": "21",
                    //         "networkServerID": "4",
                    //         "supportsJoin": true,
                    //         "rfRegion": "EU868",
                    //     }
                    // }
                    // setSupportsJoin(obj.deviceProfile)
                });
        }
    }

    //code to submit create new device
    function submitNewDevice(){
        let data = {"device": {...deviceData, 
            "devEUI": EUIValue, 
            "skipFCntCheck": fcvCheck, 
            "isDisabled": deviceCheck, 
            "applicationID": props.match.params.id,
            "variables" : filterVariableData(), 
            "tags" : filterTagsData()
        }};
        // console.log(data);
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true }})
            axiosInstance
                .post(`${process.env.REACT_APP_LORA_URL}/devices`, data)
                .then((response) => {
                    // console.log(response);
                    PopUp("Device Created Successfully");
                    actions({ type: 'setState', payload: { ...state, blocking: false, keysOTAA : false }});
                    if(supportsJoin.supportsJoin == undefined){
                        setTimeout(() => {
                            history.push(`/lora/applications/${props.match.params.id}`);     
                        }, 200);
                    }
                    if(supportsJoin.supportsJoin == true){
                        setTimeout(() => {
                            history.push(`/lora/applications/${props.match.params.id}/deviceinfo/${EUIValue}`);     
                        }, 500);
                    }
                    if(supportsJoin.supportsJoin == false){
                        setTimeout(() => {
                            history.push(`/lora/applications/${props.match.params.id}`);     
                        }, 200);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopUp("Request Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false }})
                });

        }
    }

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Create Device</h5>
                        </div>
                        <div style={{ color: 'red'}}>
                            {error}
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Tabs defaultActiveKey="General" transition={false} className="experimentTabs">
                            <Tab eventKey="General" title="GENERAL">
                                <Form style={{ margin: '3%' }}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Enter Device Name" onChange={(e) => inputHandler(e)} autoComplete="off"/>
                                        <Form.Text className="text-muted">
                                            The name may only contain words, numbers and dashes.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control type="text" name="description" placeholder="Enter Description" onChange={(e) => inputHandler(e)} autoComplete="off"/>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col} md="10">
                                            <Form.Label>Device EUI </Form.Label>
                                            <Form.Control type="text" value={EUIValue} name="devEUI" placeholder="Enter Device EUI" onChange={(e) => changeEUI(e)} autoComplete="off"/>
                                            <Form.Text className="text-muted">
                                            The Device EUI must be 12 characters length
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group as={Col} md="1" style={{lineHeight:'90px'}}>
                                            <Button className="btnNormal" onClick={(e) => changeMSB(e)}>{MSB == true ? 'MSB' : 'LSB'}</Button>
                                        </Form.Group>
                                        <Form.Group as={Col} md="1" style={{lineHeight:'90px'}}>
                                            <i className="fas fa-redo" aria-hidden="true" style={{cursor: 'pointer'}} onClick={(e)=> generateEUI(e)}></i>                                            
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group>
                                        <Form.Label>Select Device Profile</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => changeDeviceProfile(e)}
                                            name="deviceProfileID"
                                            required
                                            custom
                                        >
                                            <option value="">Select Device Profile</option>
                                            {deviceProfiles.length > 0 ? (
                                                <>
                                                    {deviceProfiles.map((item, index) => {
                                                        return (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        );
                                                    })}
                                                </>
                                            ) : null}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check type="checkbox" name="skipFCntCheck" label="Disable frame-counter validation" onChange={(e)=>setCheckbox(e)}/>
                                        <Form.Text className="text-muted">
                                            Note that disabling the frame-counter validation will compromise security as it enables people to perform replay-attacks.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check type="checkbox" name="isDisabled" label="Device is disabled" onChange={(e)=>setCheckbox(e)}/>
                                        <Form.Text className="text-muted">
                                            ChirpStack Network Server will ignore received uplink frames and join-requests from disabled devices.
                                        </Form.Text>
                                    </Form.Group>
                                </Form>
                            </Tab>
                            <Tab eventKey="Variables" title="VARIABLES">
                                <div style={{ margin: '3%' }}>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                            Variables can be used to substitute placeholders in for example integrations, e.g. in case an integration requires the configuration of a device specific token.
                                        </Form.Text>
                                    </Form.Group>
                                    <table>
                                        <tbody>
                                            {rowData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6">
                                                                <Form.Control type="text" name="name" placeholder="name" onChange={changeName(index)} autoComplete="off"/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6">
                                                                <Form.Control type="text" name="value" placeholder="value" onChange={changeName(index)} autoComplete="off"/>
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button onClick={() => addRowVariable('variable')} className="btnDefault">
                                        Add Variable
                                    </Button>
                                </div>
                            </Tab>
                            <Tab eventKey="Tags" title="TAGS">
                            <div style={{ margin: '3%' }}>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                        Tags can be used as device filters and are exposed on events as additional meta-data for aggregation.
                                        </Form.Text>
                                    </Form.Group>
                                    <table>
                                        <tbody>
                                            {rowTags.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <Form.Row>
                                                            <Form.Group as={Col} md="6">
                                                                <Form.Control type="text" name="name" placeholder="name" onChange={changeTagName(index)} autoComplete="off"/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} md="6">
                                                                <Form.Control type="text" name="value" placeholder="value" onChange={changeTagName(index)} autoComplete="off"/>
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button onClick={(e) => addRowVariable('tag')} className="btnDefault">
                                        Add Tag
                                    </Button>
                                </div>
                            </Tab>
                        </Tabs>

                        <div className="col-md-12 text-right no-padding" >
                            <Button disabled={!isValid} className="btnDefault" style={{ margin: '2%' }} onClick={() => submitNewDevice()}>
                                + CREATE DEVICE
                            </Button>
                        </div>
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default CreateDevice;
