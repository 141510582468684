import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useAxios } from '../../../utils/Axios';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import 'react-toastify/dist/ReactToastify.css';
import './Table.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { FaCheck, FaDownload, FaTimes } from "react-icons/fa";
import deleteIcon from '../../../assests/icons/delete.svg';
import downloadIcon from '../../../assests/icons/download.svg';
import editIcon from '../../../assests/icons/edit.svg';
import {Buffer} from 'buffer';

const FilesTable = ({ images, configs, parentProp, changeCurState, projectrole }) => {
	const axiosInstance = useAxios();
    const [severity,setSeverity] = useState(['']);
    const [open, setOpen] = useState(false);
 
    const [approval, setApproval] = useState({
		 filePath : "",
		 reqSeverity:"",
		 reason:"",
		 testBedType:"robot_arena"
	});
	useEffect(()=>{
        getSeverityBackend() ;
    },[axiosInstance, parentProp.params.id])

     

	function getSeverityBackend(){
        if(axiosInstance){
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/approval/severity/${parentProp.params.id}`)
                .then((response)=>{
					setSeverity(response.data.message);
                    console.log(response.data.message);
                }).catch((err)=>{
                    // actions({type : 'setState', payload : {...state , blocking : false}});
                if (err.response) {
					ErrorPopUp(err.response.data.detail)
                }else {
                    ErrorPopUp("Request Failed. Try Again")
                }
                });
        }       
    }

	//Code to delete specific Image
	function deleteImage(n) {
		let imageName = n.getAttribute("imagename");
		let tagName = n.getAttribute("tagname");

		swal({
			title: 'Are you sure?',
			text: 'File will be permenantly deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_PROJECT_URL}/projects/${parentProp.params.id}/image`, {
							data: {
								"repoPath" : imageName + ":" +tagName,
								"imageType" : ""
							}
						})	
						.then((response) => {
							PopUp("Image Deleted Successfully")
							changeCurState()
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});

	}
	 


	
   
	//Code to delete specific Configuration
	function deleteConfig(n) {
		 
		let configName = n.getAttribute("configname");
             
		swal({
			title: 'Are you sure?',
			text: 'File will be permenantly deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_PROJECT_URL}/projects/${parentProp.params.id}/binaries/${configName}`)
						.then((response) => {
							PopUp("File Deleted Successfully")
							changeCurState()
						})
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});
	}
	console.log(severity);
	// code to convert '/' to % for filenames
	function nameToPercent(name) {
		// console.log(name);
		var myStr = name;
		var newStr = myStr.replace(/[/]/g, "%252F");
		return newStr
	}
	
	function downloadScanLogs(n) {
		console.log(n);
		let imageName = n.getAttribute("imagename");
		let tagName = n.getAttribute("tagname");			
		if (axiosInstance) {	
			let input = {
				"tag": tagName,
				"imageName": imageName
			}	
			axiosInstance
				.post(`${process.env.REACT_APP_PROJECT_URL}/projects/${parentProp.params.id}/vulnerabilities`, input, { responseType: 'arraybuffer' })
				.then((response) => {
					const a = document.createElement("a");
					a.href = URL.createObjectURL(
						new Blob([response.data], { type: 'application/octet-stream' })
					);
					a.setAttribute("download", `Vulnerabilities.xlsx`);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					
				})
				.catch((err) => {
					const parsedJson = JSON.parse(Buffer.from(err.response.data).toString('utf8'));
					console.log(parsedJson);
					if(parsedJson.detail !== undefined || parsedJson.detail !== null){
						ErrorPopUp(parsedJson.detail)
					}
					else{
						ErrorPopUp("Download Failed. Try Again")
					}

				});
		}
	}

	function createapprovalrequest(e){
		let filepathname = e.getAttribute("imagename");
		console.log(filepathname);
		 setOpen(true);
		 console.log(e);
		 setApproval({ ...approval, ["filePath"]: filepathname })
	}
	const changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setApproval({...approval,[name]:value})
    };

	const changeHandlername = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setApproval({...approval,[name]:value});
	};

	function submitRequest(e){
        e.preventDefault();
         console.log(approval);
        // console.log(data);
        if (axiosInstance) {
             axiosInstance
                .post(`${process.env.REACT_APP_PROJECT_URL}/approval/${parentProp.params.id}`, approval)
                .then((response) => {
					setOpen(false);
                    // console.log(response);
                    PopUp("Request Added Successfully");
                      
                })
                .catch((err) => {
					setOpen(false);
                    console.log(err);
				    ErrorPopUp("Request Failed");
                 });

        }   
    }


	
	 
	return (
		<>
			<Table responsive borderless className="filesTable">
				<thead>
					<tr>
						<th>Name</th>
						<th>File size</th>
						<th>Scan Status</th>
						<th>Severity</th>
						<th>Validation</th>
						<th>Download scan logs</th>
						<th>Delete</th>
						<th>Admin Override</th>
					</tr>
				</thead>
				<tbody>
					{images.map((item, index) => (
						<>
							{item.artifacts.map((version, i) => (
								<tr key={i}>
									<td>{item.name}_{version.tag}{` (${version.type})`}</td>
									<td>{version.size} kb</td>
									{/* <td>{`${item.scanOverview.completePercent} %`}</td> */}
									<td>{version.scanOverview !== null ? <>{`${version.scanOverview.completePercent} %`}</> : '-'}</td>
									<td>{version.scanOverview !== null ? <>{version.scanOverview.severity}</> : '-'}</td>
									{/* <td>{item.scanOverview.scanStatus == 'Success' ? <FaCheck /> : <>{item.scanOverview.scanStatus}</>}</td> */}
									
									<td>{version.validated !== null ? 
										<>											
											{version.validated == true ? <FaCheck /> : <><FaTimes/></>}
										</> : <> {<FaTimes/>} </>}									
									</td>
									{
										version.scanOverview !== null ? 
										<>
											<td><img src={downloadIcon} className="iconCustom" imagename={nameToPercent(item.name)} tagname={version.tag} onClick={e => downloadScanLogs(e.target)} /></td>
										</> : '-'}																
					
									{
										projectrole == 'developer' || projectrole == 'projectAdmin' ? (
											<>
												<td><img src={deleteIcon} className="iconCustom" imagename={nameToPercent(item.name)} tagname={version.tag} onClick={e => deleteImage(e.target)} /></td>
											</>
										) : null
									}
									{
										 
										 ((version.scanOverview.severity =='Critical'&& (severity == 'High' ||severity == 'Medium'||severity == 'low' || severity == 'None' ))
										 	||((version.scanOverview.severity =='High'&& ( severity == 'High' ||severity == 'Medium'||severity == 'low' || severity == 'None' ))) ||
											 ((version.scanOverview.severity=='Medium'&& (severity == 'Medium'||severity == 'low' || severity == 'None' ))) 
											 ||((version.scanOverview.severity=='low'&& (severity == 'low' || severity == 'None' ))) 
											 ||((version.scanOverview.severity=='None'&& (severity == 'None' )))
										 ) ?(
											<>
												<td><img src={editIcon} className="iconCustom" imagename={item.name} onClick={e =>  createapprovalrequest(e.target)}/></td>
											</>
										): <>
											<center>
											-
												</center>
										</>
									}
								</tr>
							))}
						</>
					))}
					{configs.map((item,i) => (
						<tr key={i}>
							<td>{item.name}{` (${item.type})`}</td>
							<td>{item.size} kb</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							<td>-</td>
							{
								projectrole == 'developer' || projectrole == 'projectAdmin' ? (
									<>
										<td><img src={deleteIcon} className="iconCustom" configname={item.name} onClick={e => deleteConfig(e.target)} /></td>
									</>
								) : null
							}
							
						</tr>
					))}
				</tbody>
			</Table>
			<Modal
				show={open}
				onHide={() => setOpen(false)}
				animation={false}
				centered
				keyboard={false}
			>
				<Form onSubmit={(e) => submitRequest(e)}>
					<Modal.Header closeButton>
						<Modal.Title>Admin Approval Request</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="Container">
							<div className="row">
								<div className="col-md-12 col-sm-12 col-lg-12">
									<label style={{ marginBottom: '2rem' }}>
										<b>Change Sevrity</b>
									</label>
									<Form.Group>
                                    <Form.Label>Change Severity</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="reqSeverity"
                                        onChange={changeHandler}
                                        custom
										required
                                    >
                                        <option value=''>Select</option>
										<option value='Critical'>Critical</option>
                                        <option value='High'>High</option>
                                        <option value='Medium'>Medium</option>
										<option value='low'>Low</option>
										
                                    </Form.Control>
                                    <Form.Text className="text-muted">
                                     </Form.Text>
                                </Form.Group>
								<Form.Group controlId="projectDesc">
										<Form.Label>Reason</Form.Label>
										<Form.Control
											name="reason"
											onChange={changeHandlername}
											as="textarea"
											rows="2"
											placeholder="Enter the reason for override"
 											required
										/>
									</Form.Group>	 
									 
									 
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="btnColor btn-primary-outline"
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button className="btnDefault" type="submit">
							Create Request
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>


		</>
	);
};

export default FilesTable;
