import React, { useState, useContext, useEffect } from 'react';
import NewHeader from '../../components/Header/NewHeader';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import PopUp from '../../components/UIElements/PopUp';
import ErrorPopUp from '../../components/UIElements/ErrorPopup';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../utils/Axios';
import Context from '../../store/Context';
import { useHistory } from 'react-router-dom';
import BlockUi from 'react-block-ui';

function UsersRequest() {
    const [userStatus, setUserStatus] = useState({});
    const [userDetails , setUserDetails] = useState([]);

    const history = useHistory();
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        getUserStatus()
    }, [axiosInstance]);

    // useEffect(() => {
    //     console.log(userDetails);
    // }, [userDetails]);

    //Code to fetch All Users
    function getUserStatus() {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/un/users/adduser`)
                .then((response) => {
                    // console.log(response);
                    // let data = [{"id":123,"organizationName":'org1',"name":"albert","role":"user","status":"pending"},{"id":456,"organizationName":'org2',"name":"Narendra V","role":"user","status":"pending"}]
                    // console.log(check(data))

                    // checkUserPresent(data);
                    checkSysAdmin(response);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    // setUserStatus({"status" : "no user exist in UN"});
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    //code to check user us system admin or not
    function checkSysAdmin(response){
        var roles = JSON.parse(localStorage.getItem("roles"));

        // console.log(roles);

        if(roles.includes('un_admin')){
            // console.log('system Admin')
            gotoHome();
        }
        
        if(roles.includes('un_dev')){
            gotoHome();
        }
        
        if(roles.includes('un_pradmin')){
            if(response.data.status == 'approved'){
                gotoHome();
                // console.log('approved user')
            }else{
                setUserStatus(response);
                // console.log('Un approved user')
            }
        }

        if(roles.includes('default')){
            setUserStatus({data: {status : ''}});
        }else{
            setUserStatus({data: {status : 'nouserrole'}});
        }
    }

    //Code to store user details
    function storeUserDetails(e){
        const name = e.target.name;
		const value = e.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
		setUserDetails({ ...userDetails, [name]: value });
    }

    //Code to send user request
    function submitUserRequest(e){
        e.preventDefault();
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_PROJECT_URL}/un/users/adduser`, userDetails)
                .then((response) => {
                    // console.log(response);
                    if (response.response) {
                        PopUp(response.response.data.detail)
                    }else {
                        PopUp("Request Success")
                    }

                    setTimeout(() => {
                        getUserStatus();
                    }, 500);

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    if (err.response) {
                        ErrorPopUp(err.response.data.detail)
                    }else {
                        ErrorPopUp("Request Failed")
                    }
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    //Code to redirect the user to umbrella dashboard
    const gotoHome = () => history.push('/projects');

    return (
        <>
            <BlockUi tag="div" message="Loading" blocking={state.blocking}>
            <NewHeader />
                <div className="container-fluid">                 
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12">
                            <br />
                            <Jumbotron className="text-center bg-white nwrkBody">
                                <div className="row d-flex justify-content-center">
                                    {Object.keys(userStatus).length > 0 ? (
                                        <>
                                            {userStatus.data.status == '' || userStatus.data.status == null ? (
                                                <>
                                                    <div className="offset-md-2">

                                                    </div>
                                                    <div className="col-md-8">
                                                        <h6>You are not a part of Umbrella Network. Provide your details to access Umbrella Network</h6>
                                                        <div style={{ color: 'red'}}>
                                                            {error}
                                                        </div>
                                                        <form onSubmit={submitUserRequest}>
                                                            <Table responsive bordered className="projectTableData">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            Affiliation
                                                                    </td>
                                                                        <td>
                                                                            <Form.Control type="text" placeholder="Enter Affiliation info" onChange={(e) => storeUserDetails(e)} name="affiliation" autoComplete="off" required />
                                                                        </td>
                                                                        <td style={{ lineHeight: '120px', textAlign: 'center' }} rowSpan="2">
                                                                            <Button disabled={!isValid} className="btnDefault create-exp-btn" type="submit">
                                                                                Submit
                                                                        </Button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Purpose of using testbed
                                                                    </td>
                                                                        <td>
                                                                            <Form.Control type="text" placeholder="Enter Testbed Purpose" onChange={(e) => storeUserDetails(e)} name="testbedPurpose" autoComplete="off" required />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </form>
                                                    </div>
                                                    <div className="offset-md-2">

                                                    </div>

                                                </>
                                            ) : null}

                                            {userStatus.data.status == 'pending' ? (
                                                <>
                                                    <div className="offset-md-2">

                                                    </div>
                                                    <div className="col-md-8">
                                                    <h6>You are not a part of Umbrella Network. You request to access Umbrella Network is in pending state.</h6>
                                                    </div>
                                                    <div className="offset-md-2">

                                                    </div>
                                                </>
                                            ) : null}

                                            {userStatus.data.status == 'denied' || userStatus.data.status == 'deny' ? (
                                                <>
                                                    <div className="offset-md-2">

                                                    </div>
                                                    <div className="col-md-8">
                                                    <h6>You request to access Umbrella Network is Denied. Please contact System Admin</h6>
                                                    </div>
                                                    <div className="offset-md-2">

                                                    </div>
                                                </>
                                            ) : null}

                                            {userStatus.data.status == 'nouserrole' ? (
                                                <>
                                                    <div className="offset-md-2">

                                                    </div>
                                                    <div className="col-md-8">
                                                    <h6>You are not a part of Umbrella Network.</h6>
                                                    </div>
                                                    <div className="offset-md-2">

                                                    </div>
                                                </>
                                            ) : null}

                                        </>
                                    ) : null}
                                </div>

                            </Jumbotron>
                        </div>
                    </div>
                </div>
            </BlockUi>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )

}

export default UsersRequest;