import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import useGlobalState from './store/UseGlobalState';
import Context from './store/Context'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css';

const Index = () => {

  const store = useGlobalState ();

  return (
    <Context.Provider value={store}>
        <App />
    </Context.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
