import React from 'react';
import './RadioButton.css';

const ImgRadioButtons = ({key,value, name,imagetype, version,disabled ,event}) => {
    return (
        <div className="radioInput">
            <input 
                type="radio" 
                key={`${name}_${imagetype}`} 
                onChange={event} 
                value={`${name}_${version}_${imagetype}`} 
                checked={value === `${name}_${version}_${imagetype}`} 
                disabled={disabled} 
                name={name} 
                imagetype = {imagetype} 
                version={version}
            /> {name}_{version}{`(${imagetype})`}
        </div>
    );
}
export default ImgRadioButtons;