import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import { useAxios } from '../../../utils/Axios';
import editIcon from '../../../assests/icons/edit.svg';
import deleteIcon from '../../../assests/icons/delete.svg';
import './ServiceProfilesTable.css';

function ServiceProfilesTable({ data, status, networks,deletebtn }) {
	const axiosInstance = useAxios();


	//Code to edit service Profile
	function editServiceProfile(e) {
		// console.log(e.getAttribute('name'));
	}

	//Code to delete the Org Key
	function deleteServiceProfile(e) {
		const reqId = e.getAttribute('name');
		// console.log(reqId);
		swal({
			title: 'Are you sure?',
			text: 'Service Profile will be deleted',
			icon: 'warning',
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_LORA_URL}/service-profiles/${reqId}`)
						.then((response) => {
							PopUp("Service Profile Deleted Successfully")
							status(true)
						})
						.catch((err) => {
							if (err.response) {
								if(err.response.data.detail !== undefined || err.response.data.detail !== null){
									ErrorPopUp(err.response.data.detail)
								}else{
									ErrorPopUp("Deleting Failed. Please check with support team")
								}
							}							
						});
				}
			});


	}

	return (
		<>
			<Table responsive borderless className="deviceProfileTable">
				<thead>
					<tr>
						<th>Name</th>
						<th>Network Server </th>
						{deletebtn == true ? (
							<>
								<th>Delete</th>
							</>
						) : null}
					</tr>
				</thead>
				<tbody>
					{data.map((item) => (
						<tr key={item.id} >
							<td key={item.id} >
								{/* <Link to={`/lora/service-profiles/${item.id}/edit`}> */}
								<Link to = {{
										pathname: `/lora/service-profiles/${item.id}/edit`, 
										query:{deletebtn: deletebtn}
									}}
								>
									{item.name}

								</Link>
							</td>
							<td>{item.networkServerName}</td>
							{deletebtn == true ? (
								<>
									<td>
										<img src={deleteIcon} className="iconCustom" name={item.id} onClick={(e) => deleteServiceProfile(e.target)} />
									</td>
								</>
							) : null}

						</tr>
					))}
				</tbody>
			</Table>
		</>
	);
};

export default ServiceProfilesTable;
