import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useAxios } from '../../utils/Axios';
//import './ProjectTable.css';
import PopUp from '../UIElements/PopUp'
import ErrorPopUp from '../UIElements/ErrorPopup'
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CheckBox from '../CheckBox/CheckBox';
import Button from 'react-bootstrap/Button';
import { DatePicker, TimePicker } from 'react-tempusdominus-bootstrap';
import editIcon from '../../assests/icons/edit.svg';
import archiveIcon from '../../assests/icons/archive.svg';
import deleteIcon from '../../assests/icons/delete.svg';

const ExperimentTable = ({ data, status, project , changeCurState, projectrole }) => {

	const axiosInstance = useAxios();
	const [open, setOpen] = useState(false);
	const [oldExp, changeOldExp] = useState({
		name: '',
		description: '',
		start_time: '',
		start_date: '',
		nodes_available: true,
		duration:'',
		no_time_limit: true
	});

	const [state, changeState] = useState(0);
	const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	//Code to fetch Experiment Info and adding in state for inputs
	function updateExpRequest(id) {
		const reqId = id.getAttribute('name');
		axiosInstance
			.get(`/projects/experiments/${reqId}`)
			.then((response) => {

				setOpen(true)

			})
			.catch((err) => {
				// console.log(err);
				let response = {
					"experiment_id": 1,
					"project_id": "100",
					"name": "Air Quality 1",
					"description": "Sample Airquality 1",
					"SchedulingInfoDataModel": {
						"start_time": "13:00",
						"start_date": "YYYY-MM-DD",
						"run_on_node_available": true,
						"duration": "2960",
						"run_indefinitely": true
					},
					"status": "pending",
					"configurations": [{
						"configuration_id": 5,
						"name": "Configuration 1",
						"binary_files": [{
							"name": "binary 1"
						}],
						"image_file": {
							"name": "img 1",
							"tag": "v1"
						},
						"nodes": [{
							"hostname": "host 1"
						}]
					}]
				}
				changeOldExp({
					name: response.name,
					description: response.description,
					start_time: response.SchedulingInfoDataModel.start_time,
					start_date: response.SchedulingInfoDataModel.start_date,
					nodes_available: response.SchedulingInfoDataModel.run_on_node_available,
					duration:response.SchedulingInfoDataModel.duration,
					no_time_limit: response.SchedulingInfoDataModel.run_indefinitely
				})

				setOpen(true)
			});
	}

	//Code to Update Experiment 
	function updateExperiment() {

	}

	//Code to delete Experiment
	function deleteExperiment(id) {
		const reqId = id.getAttribute('name');
		// alert(reqId);
		swal({
			title: "Are you sure?",
			text: "All Experiment Data will be deleted",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/${reqId}`)
						.then((response) => {
							PopUp("Experiment Deleted Successfully")
							sendUpdateState()
						})
						// .then(
						// 	sendUpdateState()
						// )
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Deleting Failed. Try Again")
						});
				}
			});

	}

	function archiveExperiment(id){
		const reqId = id.getAttribute('name');
		// alert(reqId);
		const setArchive = {
			"archive" : true
		}

		swal({
			title: "Are you sure?",
			text: "Experiment Will be Archived",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.put(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/${reqId}`, setArchive)
						.then((response) => {
							PopUp("Experiment Archived Successfully")
							sendUpdateState()
						})
						// .then(
						// 	sendUpdateState()
						// )
						.catch((err) => {
							// console.log(err);
							ErrorPopUp("Archiving Failed. Try Again")
						});
				}
			});
	}

	let projectname = project.projectName;
	// let projectid = project.projectId

	function storeProject(e){
		let projectName = e.getAttribute('projectname');
		let expName = e.getAttribute('name')
		let description = e.getAttribute('desc')
		let expId = e.getAttribute('expid')
		localStorage.removeItem('projectId');
		localStorage.removeItem('expName')
		localStorage.removeItem('description')
		localStorage.removeItem('expid')
		localStorage.setItem('projectId', project.projectId);
		localStorage.setItem('expid', expId);
		localStorage.setItem('expName', expName)
		localStorage.setItem('description', description)

	}

	//Code to extract 
	// function getDate(a , b , c){
	// 	// console.log(a)
	// 	let input = a;
	// 	let date = new Date(input * 1000).toDateString()
	// 	return date;
	// }

	//function to convert minutes to hours and Minutes
	function time_convert(num){
		let hours = Math.floor(num / 60);  
		let minutes = num % 60;
		return hours + ' Hrs ' + ' : ' + minutes + ' Min ';  
	}

	function getStartTime(num){
		var myDate = new Date(num * 1000);
		// console.log(myDate.toGMTString()+"<br>"+myDate.toLocaleString());
		let time = myDate.toLocaleString();
		return time
	}

	//Function to change state for CRUD operations
	const sendUpdateState = () => {
		changeCurState() 
	}

	// console.log(data);
	return (
		<>
			<Modal show={open} animation={false} centered backdrop="static" keyboard={false} >
				<Modal.Header>
					<Modal.Title id="create-exp-title">Update Experiment</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="Container">
						<div className="row">
							<div className="col-md-12 col-sm-12 col-lg-12 create-exp-first">
								<label ><b>Details</b></label>
								<div style={{ color: 'red'}}>
									{error}
								</div>
								<div style={{ width: "90%" }}>
									<Form id="formPage">
										<Form.Group>
											<Form.Label>Experiment Name</Form.Label>
											<Form.Control id="proj-name" type="text" placeholder="Enter a name for your Experiment" value={oldExp.name} onChange={(e) => {
												const value = e.target.value;
												const textFieldRegex = /^[A-Za-z0-9\-]+$/;
												if (!textFieldRegex.test(value)) {
													setError(e.target.name + ' is invalid');
													setIsValid(false);
												}else{
													setError('');
													setIsValid(true);
												}
											}}/>

										</Form.Group>
									</Form>
									<label >Description</label>
									<textarea className="form-control" name="text" placeholder="Enter a description for your Experiment" value={oldExp.description} rows="5" onChange={(e) => {
												const value = e.target.value;
												const textFieldRegex = /^[A-Za-z0-9\-]+$/;
												if (!textFieldRegex.test(value)) {
													setError(e.target.name + ' is invalid');
													setIsValid(false);
												}else{
													setError('');
													setIsValid(true);
												}
											}}></textarea>
									<div className="date-time-picker ">
										<div>
											<label >Next Available Start Time</label>
											<TimePicker iconClassName="far fa-clock" />
										</div>
										<div className="date-picker">
											<label >Date</label>
											<DatePicker />
										</div>
									</div>
									<CheckBox name={"Run as soon as nodes available"} timeZone />

									<label ><b>Duration</b></label>
									<div className="create-exp-no-inp">
										<Form style={{ display: "flex" }}>
											<div>
												<label >Days</label>
												<input type="number" className="form-control" min="0" value={oldExp.duration}></input>
											</div>
											<div>
												<label >Hours</label>
												<input type="number" className="form-control" min="0"  ></input>
											</div>
											<div>
												<label >Min</label>
												<input type="number" className="form-control" min="0"  ></input>
											</div>
										</Form>
									</div>
									<div className="custom-control custom-checkbox create-exp-checkBox">
										<input type="checkbox" className="custom-control-input" id="noTimeLimit" ></input>
										<label className="custom-control-label" >No time limit(∞)</label>
									</div>
								</div>
								<div className="create-exp-footer">
									<Button variant=" create-exp-btn btn-transparent-text" onClick={() => setOpen(false)}>
										Cancel
                                        </Button>
									<Button disabled={!isValid} type="submit" variant=" button-color create-exp-btn">
										Submit
                                        </Button>
								</div>
							</div>

						</div>
					</div>
				</Modal.Body>
			</Modal>

			<Table responsive borderless className="filesTable">
				<thead>
					<tr>
						<th>Name</th>
						{/* <th>Date</th> */}
						<th>Start Time</th>
						<th>Duration</th>
						<th>Configurations</th>
						<th>Status</th>
						{
							projectrole == 'developer' || projectrole == 'projectAdmin' ? (
								<>
									<th>Archive</th>
									<th>Delete</th>
								</>
							) : null
						}
					</tr>
				</thead>
				<tbody>
					{data.map((item) => (
						<tr key={item.id}>
							<td>
								<Link to={`/projects/${item.id}/experiments/${item.id}`} name={item.name} projectname = {projectname} desc={item.description} expid={item.id} onClick={e => storeProject(e.target)}>
									{item.name}
								</Link>
							</td>
							{/* <td>{getStartTime(item.schedulingInfo.startTime)}</td> */}
							<td>
								{item.status == 'Scheduled' ? (
									<>
										{item.schedulingInfo.runOnNodeAvailability ? "On Node Available" : (
											<>
												{getStartTime(item.schedulingInfo.startTime)}
											</>
										)}
									</>
									) : (
										<>
											{getStartTime(item.schedulingInfo.startTime)}
										</>
									)
								}
							</td>
							<td>{time_convert(item.schedulingInfo.duration)}</td>
							<td>{item.configurations.length}</td>
							<td>{item.status}</td>
							{
								projectrole == 'developer' || projectrole == 'projectAdmin' ? (
									<>
										<td>
											{item.status == 'Scheduled' ? null : (
												<>
													<img src={archiveIcon} className="iconCustom" name={item.id} onClick={e => archiveExperiment(e.target)} />
												</>
											)
											}
										</td>
										<td><img src={deleteIcon} className="iconCustom" name={item.id} onClick={e => deleteExperiment(e.target)} /></td>
									</>
								) : null
							}
						</tr>
					))}
				</tbody>
			</Table>

		</>
	);
};

export default ExperimentTable;
