import React, { useContext } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context'
import '../RoboticsProjectTable/RoboticsProjectTable.css';

function SimulationQueueTable(props) {
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    //code to send Experiment cancel request
    const cancelExp = (input) => {
        let expId = input.getAttribute('expid');
        swal({
            title: 'Are you sure?',
            text: 'The Experiment Will be cancelled',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    actions({ type: 'setState', payload: { ...state, blocking: true } })
                    axiosInstance
                        .put(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${expId}/cancel`)
                        .then((response) => {
                            PopUp("Experiment Cancelled Successfully")
                            props.status(true)
                            actions({ type: 'setState', payload: { ...state, blocking: false } })
                        })
                        .catch((err) => {
                            // console.log(err);
                            ErrorPopUp("Cancellation Failed. Try Again")
                            actions({ type: 'setState', payload: { ...state, blocking: false } })
                        });
                }
            });
    }

    return (
        <div>
            {props.data.length > 0 ? (
                <>
                    <Table responsive bordered className="simulationQueueTable">
                        <thead>
                            <tr>
                                <th colSpan="4" style={{ textAlign: 'center' }}>Simulation Experiment Queue</th>
                            </tr>
                            <tr>
                                <th>Experiment Id</th>
                                <th>Duration</th>
                                <th>Status</th>
                                <th>Cancel</th>
                            </tr>
                        </thead>
                        <tbody>

                            {props.data.map((item) => (
                                <tr key={item.id} >
                                    <td>

                                        {
                                            item.id.length < 31 ? (
                                                <>{item.id.slice(0, 30)}</>
                                            ) : (
                                                    <>{item.id.slice(0, 30) + '...'}</>
                                                )
                                        }
                                    </td>
                                    <td>
                                        {item.duration}
                                    </td>
                                    <td>
                                        {item.status}
                                    </td>
                                    <td>
                                        {
                                            item.status == 'ended' || item.status == 'failed' ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {item.cancel == true ? (
                                                        <>
                                                            <i className="fa fa-times" aria-hidden="true" style={{ color: "#A3A6B4" }} expid={item.id} onClick={e => cancelExp(e.target)}></i>                                                        
                                                        </>
                                                    ) : '-'}
                                                </>
                                            )
                                        }

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </>
            ) : (
                    <>
                        <h4>No Simulation Experiments Queue Found</h4>

                    </>
                )
            }

        </div>
    )
}

export default SimulationQueueTable
