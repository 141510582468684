import React ,{useState, useEffect,useContext} from 'react';
import RoboticLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout';
import Form from 'react-bootstrap/Form';
import { useAxios } from '../../../utils/Axios';
import RoboticArenaNodes from '../../../components/RoboticsNetworks/RoboticsNetworksTab/RoboticArenaNodes';
import Geohash from 'latlon-geohash'
import Context from '../../../store/Context';
import { ToastContainer, Zoom } from 'react-toastify';

const ArenaNetworkDashboard = () => {

	const [arenaNetworks , setArenaNetworks] = useState([]);
	const [nodeList, setNodeList] = useState([]);
	const [mapOpen,setMapOpen] = useState(false);
	const {state , actions} = useContext(Context);
	let axiosInstance = useAxios();

	useEffect(() => {
		fetchArenas();
	}, [axiosInstance]);

	// useEffect(() => {
	// 	console.log(nodeList)
	// }, [nodeList]);

	//Code to fetch node networks
	function fetchArenas() {
		if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_NODEMONITOR_URL}/robot-arenas/all`)
				.then((response) => {
					// console.log(response);
					setArenaNetworks(response.data)
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
					// console.log(err);
					actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
	}
	console.log(arenaNetworks);
	//Code to get Nodes basedon network selection
	function selectNetwork(e){
		// console.log(e.target.value)
		let networkId = e.target.value;
		console.log(networkId);
		if(networkId !== '' ){
			console.log(networkId);
			setMapOpen(true);
			if (axiosInstance ){
				actions({type : 'setState', payload : {...state , blocking : true}})
				axiosInstance
					.get(`${process.env.REACT_APP_NODEMONITOR_URL}/robot-arenas/${networkId}`)
					.then((response) => {
						console.log(response)
						convertGeohash(response)
						actions({type : 'setState', payload : {...state , blocking : false}})
					})
					.catch((err) => {
						// console.log(err);
						actions({type : 'setState', payload : {...state , blocking : false}})
					});
				}
		}else{
			setMapOpen(false)
		}
		
	}

	//Code to convert GPs Hash to location and adding required data to original json
	function convertGeohash (input) {
		let oldData = input
		// console.log(oldData.data.nodes)
		for (let i=0;i<oldData.data.nodes.length;i++){
			const locationSlice = oldData.data.nodes[i].gpsHash
			// const res = locationSlice.slice(0, 9)
			// const latlon = Geohash.decode(`${res}`)
			// let obj = oldData.data.nodes[i];
			// Object.assign(obj, {lat: parseFloat(latlon.lat),lng: parseFloat(latlon.lon), selected: false});
			if(locationSlice !== null && locationSlice !== ''){
				const res = locationSlice.slice(0, 9)
				const latlon = Geohash.decode(`${res}`)
				let obj = oldData.data.nodes[i];
				Object.assign(obj, {lat: parseFloat(latlon.lat),lng: parseFloat(latlon.lon), selected: false});
			}else{
				let obj = oldData.data.nodes[i];
				Object.assign(obj, { lat: 51.5074, lng: 0.1278 ,selected: false});
			}
		}

		setNodeList(oldData)
	}
	console.log(nodeList);

	return (	
		<RoboticLayout>
			<div
				className=" col-md-12 col-sm-12 col-lg-12 text-left"
				style={{ margin: '2rem 2.5rem' }}
			>
				<div className=" col-md-5 col-sm-5 col-lg-5">
					<label className="create-proj-text-color">
						<b>Select Arena</b>
					</label>
					{
						arenaNetworks.length > 0 ? (
							<Form.Group controlId="projectNetwork" >
								<Form.Control
									as="select"
									name="networkName"
									onChange={(e) => selectNetwork(e)}
									required
									custom
								>
									<option value=''>Select Arena</option>
									{arenaNetworks.map((item, index) => (
										<option value={item.name} key={index}>{item.name}</option>
									))}
								</Form.Control>
							</Form.Group>
						) : (
								<Form.Group controlId="projectNetwork" >
									<Form.Control
										as="select"
										name="networkName"
										required
										custom
									>
										<option>No Arenas Found</option>
									</Form.Control>
								</Form.Group>
							)
					}
				</div>
				
				<br />
				
				<div className=" col-md-10 col-sm-10 col-lg-10">
					<label className="create-proj-text-color">
						<b>Arenas</b>
					</label>
					{mapOpen == true ? (
						<>
							{
								Object.keys(nodeList).length > 0 ? (
									<>
										<RoboticArenaNodes nodelist={nodeList} />
									</>
								) : (
									<>
										{/* <Nodes nodelist = {nodeList}/> */}
										<p>Select Arena</p>
									</>
								)
							}
						</>
					) : (
						<>
							<p>Select Arena</p>
						</>
					)}
					
				</div>	
			</div>
			<ToastContainer draggable={false} transition={Zoom} />
		</RoboticLayout>
	);
};

export default ArenaNetworkDashboard;