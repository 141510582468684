import React, {useState, useEffect, useContext} from 'react'
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { useAxios } from '../../../utils/Axios';
import ErrorPopup from '../../../components/UIElements/ErrorPopup'
import Context from '../../../store/Context'


function QuotaRn() {
    const [projectLimit , setProjectLimit] = useState([{}])
    const [projectQuota , setProjectQuota] = useState({
        projectId : '',
        projectStorage : ''
    })
    const [allUsers , setAllUsers] = useState([])
    const [reqstatus , setReqStatus] = useState({
        projectLimit : '',
        projectQuotaLimit : ''
    })

    const axiosInstance = useAxios();
    const {state , actions} = useContext(Context);

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		getUsersList();
    }, [axiosInstance]);
    
    //Color Customization for users dropdown
    const colourStyles = {

        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            padding: 0,
            "&:hover": {
                // Overwrittes the different states of border
                background: state.isSelected ? "black" : "black"
            }
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'black' : 'black',
            background: 'white'
        }),
    };

	//Code to fetch node networks
	function getUsersList() {
		if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/users/project-config`)
				.then((response) => {
					// console.log(response);
					addLabelToUsers(response.data)
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
                    // console.log(err);
                    actions({type : 'setState', payload : {...state , blocking : false}})
                    ErrorPopup("Userlist Not Found")
				});

		}
	}

    //code to add labels for js objects for dropdown
    function addLabelToUsers(data) {
        let oldArray = data;

        oldArray.map((item, index) => {
            let obj = item
            Object.assign(obj, {label : item.username})
        })

        setAllUsers(oldArray)
    }

    //code to update project count limit
    function updateProLimit(e){
        const validate = /^[0-9\b]+$/;
        if (e.target.value === '' || validate.test(e.target.value)) {
            setProjectLimit( { ...projectLimit, ["maxRNProjectLimit"]: e.target.value });
        }
    }

    //code to update project id and storage info
    function updateProQuota(e){
        const value = e.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
       setProjectQuota({ ...projectQuota, [e.target.name]: e.target.value });
    }

    //Code to select user from list 
    function selectUserFromList(e){
        setProjectLimit(e);
    }

    //Code to send project count limit
    function submitProjectLimit(e) {
        e.preventDefault();

        let data = [{
            "maxRNProjectLimit" : projectLimit.maxRNProjectLimit,
            "maxUNProjectLimit" : projectLimit.maxUNProjectLimit,
            "userId" : projectLimit.userId
        }]

        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
            .put(`${process.env.REACT_APP_PROJECT_URL}/users/project-config`, data)
            .then((response) => {
                if (response.response) {
                    setReqStatus({ ...reqstatus, ["projectLimit"]: response.response.data.detail });
                }else {
                    setReqStatus({ ...reqstatus, ["projectLimit"]: "Project Limit Update Failed." }); 
                }
                actions({type : 'setState', payload : {...state , blocking : false}})
                setReqStatus({ ...reqstatus, ["projectLimit"]: "Project Limit Updated Successfully" });

                const timer = setTimeout(() => {
                    setReqStatus({ ...reqstatus, ["projectLimit"]: "" });
                }, 3000);
                return () => clearTimeout(timer)

            })
            .catch((err) => {
                actions({type : 'setState', payload : {...state , blocking : false}})
                if (err.response) {
                    setReqStatus({ ...reqstatus, ["projectLimit"]: err.response.data.detail });
                }else {
                    setReqStatus({ ...reqstatus, ["projectLimit"]: "Project Limit Update Failed." }); 
                }
                
                const timer = setTimeout(() => {
                    setReqStatus({ ...reqstatus, ["projectLimit"]: "" });
                }, 3000);
                return () => clearTimeout(timer)
            });
        }
    }

    //Code to send project storage quota
    function submitQuota(e) {
        e.preventDefault();
        let quota = projectQuota.projectStorage;
        let quotaInBytes;

        switch(quota) {
            case "500MB":
                quotaInBytes = 524288000
                break;
            case "1GB":
                quotaInBytes = 1073741824
                break;
            case "1.5GB":
                quotaInBytes = 1572864000
                break;
            case "2GB":
                quotaInBytes = 2147483648
                break;
            case "2.5GB":
                quotaInBytes = 2621440000
                break;
            case "3GB":
                quotaInBytes = 3221225472
                break;
            default:
                quotaInBytes = 1073741824     
        }
        
        let data = {
            "value" : quotaInBytes
        }

        actions({type : 'setState', payload : {...state , blocking : true}})

        axiosInstance
            .put(`${process.env.REACT_APP_PROJECT_URL}/projects/${projectQuota.projectId}/quota`, data)
            .then((response) => {
                actions({type : 'setState', payload : {...state , blocking : false}})
                if (response.response) {
                    setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: response.response.data.detail });
                }else {
                    setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: "Project Quota Updated Successfully" }); 
                }
                setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: "Project Quota Updated Successfully" });
                const timer = setTimeout(() => {
                    setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: "" });
                }, 3000);
                return () => clearTimeout(timer)
            })
            .catch((err) => {
                actions({type : 'setState', payload : {...state , blocking : false}})
                if (err.response) {
                    setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: err.response.data.detail });
                }else {
                    setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: "Request Failed.Try agian !" }); 
                }
                const timer = setTimeout(() => {
                    setReqStatus({ ...reqstatus, ["projectQuotaLimit"]: "" });
                }, 3000);
                return () => clearTimeout(timer)
            });
    }
    
    return (
        <RoboticsLayout>
            <div className=" col-md-12 col-sm-12 col-lg-12 text-left" style={{ margin: '2rem 2.5rem' }}>
                <div className="bg-white jumbotron" style={{ marginRight: '5rem' }}>
                <h5>Project Limit (No. of Projects)  <span style={{color: 'red'}}> {reqstatus.projectLimit}</span></h5>
                    <Form onSubmit={(e) => submitProjectLimit(e)}>
                        <Form.Row>
                        <div className="col-md-4">
                            <Form.Group controlId="formUsersList">
                                    <Form.Label>Users List</Form.Label>
                                    {allUsers.length > 0 ?
                                        <Select
                                            label="Single select"
                                            options={allUsers}
                                            onChange={(opt) => selectUserFromList(opt)}
                                            styles={colourStyles}
                                            // theme={customTheme}
                                        />
                                        :

                                        <Select
                                            label="Single select"
                                            onChange={(opt) => selectUserFromList(opt)}
                                            styles={colourStyles}
                                            // theme={customTheme}
                                        />
                                    }
                                    
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formProjectLimit">
                                    <Form.Label>Projects Count</Form.Label>
                                    <Form.Control type="number" value={projectLimit.maxRNProjectLimit} onChange={(e) => updateProLimit(e)} required/>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                        <Form.Group>
                            <Form.Label style={{visibility : 'hidden'}}>Update</Form.Label>
                            <Button className="btnDefault" type="submit" style={{display: 'block'}}>
                                Submit
                            </Button>
                            </Form.Group>
                        </div>
                        </Form.Row>
                    </Form>

                    <br />
                    <div style={{ color: 'red'}}>
                        {error}
                    </div>

                    <h5>Project Storage Limit (Files) <span style={{color: 'red'}}> {reqstatus.projectQuotaLimit}</span></h5>
                    <Form onSubmit={(e) => submitQuota(e)}>
                        <Form.Row>
                        <div className="col-md-4">
                            <Form.Group controlId="formProjectId">
                                    <Form.Label>Project Id</Form.Label>
                                    <Form.Control name="projectId" onChange={(e) => updateProQuota(e)} autoComplete="off" required/>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group controlId="formProjectSize">
                                    <Form.Label>Storage</Form.Label>
                                    <Form.Control as="select" defaultValue="Select" name="projectStorage" onChange={(e) => updateProQuota(e)} required>
                                        <option value=''>Select</option>
                                        <option value='500MB'>500MB</option>
                                        <option value='1GB'>1GB</option>
                                        <option value='1.5GB'>1.5GB</option>
                                        <option value='2GB'>2GB</option>
                                        <option value='2.5GB'>2.5GB</option>
                                        <option value='3GB'>3GB</option>
                                    </Form.Control>
                                </Form.Group>
                        </div>
                        <div className="col-md-4">
                        <Form.Group>
                            <Form.Label style={{visibility : 'hidden'}}>Update</Form.Label>
                            <Button disabled={!isValid}
                                className="btnDefault"
                                type="submit" 
                                style={{display: 'block'}}
                            >
                                Submit
                            </Button>
                            </Form.Group>
                        </div>
                        </Form.Row>
                    </Form>
                </div>
            </div>
        </RoboticsLayout>
    )
}

export default QuotaRn;