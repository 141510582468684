import React, { useState, useContext, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import {Form, Button} from 'react-bootstrap';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import PopUp from '../../components/UIElements/PopUp'
import ErrorPopup from '../../components/UIElements/ErrorPopup';
import { useAxios } from '../../utils/Axios';
import './Profile.css';
import Context from '../../store/Context';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import profileIcon from '../../assests/icons/profile.svg';
import NewHeader from '../../components/Header/NewHeader';

function Profile(props) {
    const { state, actions } = useContext(Context);

    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState('');

    const axiosInstance = useAxios();

    const roles = JSON.parse(localStorage.getItem("roles"));
    console.log(roles);
    const rolesPlaintext = roles.map(modifyRoles);

    function modifyRoles(item){
        if(item.startsWith("un_")){
            return "umbrella " + item.slice(3);
        }else if(item.startsWith("rn_")){
            return "robotics " + item.slice(3);
        }else if(item.startsWith("ln_")){
            return "lora " + item.slice(3);
        }else{
            return item;
        }
    }

    console.log('rolesPlaintext ', rolesPlaintext);

    removeUnwantedRoles("default");
    removeUnwantedRoles("uma_authorization");
    removeUnwantedRoles("offline_access");

    function removeUnwantedRoles(value){
        const itemIndex = rolesPlaintext.indexOf(value);
        if(itemIndex !== -1) {
            rolesPlaintext.splice(itemIndex, 1);
        }
    }
    
    const midPoint = Math.ceil(rolesPlaintext.length/2);
    const firstColumn = rolesPlaintext.slice(0, midPoint).map(item => item.replace(/_+/g, ' '));
    const lastColumn = rolesPlaintext.slice(midPoint).map(item => item.replace(/_+/g, ' '));

    useEffect(() => {
            fetchProfile();
	}, [axiosInstance]);

    const fetchProfile = () => {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_IAM_SERVICE_URL}/getProfile`)
                .then((response) => {
                    if(response.data){
                        setUserName(response.data.userName);
                        setUserEmail(response.data.userEmail);
                        setFirstName(response.data.firstName);
                        setLastName(response.data.lastName);
                    }
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });
        }
    };

    const handleProfileSubmit = (e) => {
        e.preventDefault();
        var nameRegex = /^[a-zA-Z _-]+$/;
        if(firstName == null || firstName.length == 0){
            ErrorPopup("Firstname cannot be null or empty");
        }else if(lastName == null || lastName.length == 0){
            ErrorPopup("Lastname cannot be null or empty");
        }else if(!nameRegex.test(firstName)){
            ErrorPopup("Firstname should be alphabets");
        }else if(!nameRegex.test(lastName)){
            ErrorPopup("LastName should be alphabets");
        }else if(firstName.length >20){
            ErrorPopup("Firstname length should be 20 characters");
        }else if(lastName.length >20){
            ErrorPopup("Lastname length should be 20 characters");
        }else {
            let input = {
                "firstName" : firstName,
                "lastName" : lastName
            }
            handleSubmit(input);
        }
    }

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if(newPassword == null || newPassword.length == 0){
            ErrorPopup("New Password cannot be null or empty");
        }else if(confirmPassword == null || confirmPassword.length == 0){
            ErrorPopup("Confirm Password cannot be null or empty");
        }    
        var pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
        if(newPassword != null  && newPassword.length >0 ){
            if(newPassword != confirmPassword){
                ErrorPopup("Passwords doesn't match");
            }else if(newPassword == confirmPassword){
                if(!pattern.test(newPassword)){
                    ErrorPopup("Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character.");
                }else {
                    let input = {
                        "password" : newPassword
                    }        
                    handleSubmit(input);
                }
            }
        }
    }


    const handleSubmit = (input) => {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .post(`${process.env.REACT_APP_IAM_SERVICE_URL}/updateProfile`, input)
                .then((response) => {
                    PopUp("Profile Updated Successfully");
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    if (err.response) {
                        if(err.response.data.detail !== undefined || err.response.data.detail !== null){
                            ErrorPopup(err.response.data.detail)
                        }else{
                            ErrorPopup("Requst Failed")
                        }
                        actions({ type: 'setState', payload: { ...state, blocking: false } })
                    }
                });
        }
    }   

    return (
        <BlockUi tag="div" message="Loading" blocking={state.blocking}>
           <div className="container-fluid">
                <NewHeader />
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-lg-12" >
                            <h5 className="nwrkText">Update Profile</h5>
                        </div>
                    </div>                        
                        <div className = "row">
                            <div className = "col">
                                <div className = "card firstCard ">
                                        <img src={profileIcon} height={200} width={200} className="card-img-top"/>
                                        
                                </div>
                            </div>
                            
                            <div className = "col">
                                <div className = "card">                                        
                                        <div className="card-body">
                                           <Form className="form-horizontal" onSubmit={handleProfileSubmit}>
                                            <div className= "row">
                                                <div class="col">
                                                    <p className="profileLetter">Firstname:<h7>*</h7></p>
                                                </div>
                                                <div class="col">
                                                    <input type="text" class="form-control" id="firstName" value={firstName} aria-label="First name" onChange={(e) => setFirstName(e.target.value)}	/>
                                                </div> 
                                            </div> 
                                            <br/>
                                            <div className= "row">
                                                <div class="col">
                                                    <p className="profileLetter">Lastname:<h7>*</h7></p>
                                                </div>
                                                <div class="col">
                                                    <input type="text" class="form-control" id="lastName" value={lastName}  aria-label="Last name" onChange={(e) => setLastName(e.target.value)}	/>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className= "row">
                                                <div class="col">
                                                    <Button className="btnDefault btnDefaultHome btnProfile" type='submit'>Update Profile</Button>
                                                </div>
                                            </div>  
                                            </Form>                                                                             
                                        </div>
                                </div>
                            </div>    
                                                
                        </div>      
                        <div className = "row">
                        <div className = "col">
                                <div className = "card firstCard ">
                                        <div className="card-body firstCardBody">
                                            <div className = "row">
                                            <p class="card-title col-sm-3 prof"></p>
                                            <p class="card-title col-sm-3 prof">Username:</p>
                                            <p class="card-title col-sm-6 prof">{userName}</p>
                                            </div>
                                            <div className = "row">
                                            <p class="card-title col-sm-3 prof"></p>
                                            <p class="card-text col-sm-3 prof">Email:</p>  
                                            <p class="card-text col-sm-6 prof">{userEmail}</p>  
                                            </div>
                                             <div className = "row">
                                             <p class="card-title col-sm-3 prof"></p>
                                             <p class="card-text col-sm-3 prof">Roles:</p>  
                                             <p class="card-text col-sm-3">
                                                <ul class="ulColumn prof">
                                                    {firstColumn.map((item, index) => (
                                                        <li className="prof" key={index}>{item}</li>
                                                    ))}
                                                </ul> 
                                             </p>
                                             <p class="card-text col-sm-3">
                                                <ul class="ulColumn prof">
                                                    {lastColumn.map((item, index) => (
                                                        <li className="prof" key={index}>{item}</li>
                                                    ))}
                                                </ul> 
                                             </p>
                                             </div>
                                                                                     
                                        </div>
                                </div>
                            </div>     
                            
                            <div className = "col">
                                <div className = "card">
                                        <div className="card-body">
                                        <Form className="form-horizontal" onSubmit={handlePasswordSubmit}>
                                            <div className= "row">
                                                <div className = "col">
                                                     <p className="profileLetter">New password:<h7>*</h7></p>
                                                </div>
                                                <div className = "col">
                                                     <input type="password" class="form-control" id="newPassword" value={newPassword} aria-label="New Password" onChange={(e) => setNewPassword(e.target.value)} />
                                                </div>
                                            </div>   
                                            <br/>
                                             <div className= "row">
                                                <div className = "col">
                                                     <p className="profileLetter">Confirm password:<h7>*</h7></p>
                                                </div>
                                                <div className = "col">
                                                    <input type="password" class="form-control" id="confirmPassword" value={confirmPassword}  aria-label="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)}/>
                                                </div>
                                            </div>  
                                            <br/>
                                            <div className= "row">
                                                <div className = "col">
                                                    <Button className="btnDefault btnDefaultHome btnProfile" type='submit'>Update Password</Button>
                                                </div>
                                            </div>  
                                            </Form>                                                                                
                                        </div>
                                </div>
                            </div>   
                                                 
                        </div>
            </div>  
            <ToastContainer draggable={false} transition={Zoom}/>          
        </BlockUi>
    )

}

export default Profile;

