import React, { useState,useEffect } from "react";
import { useAxios } from '../../../utils/Axios';
import Table from 'react-bootstrap/Table';
import ProgressBar from '../../../components/UIElements/ProgressBar'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';
import Button from 'react-bootstrap/Button';
import './ImageUpload.css'

function ImageUploader(props) {
    const [rowData , setRowData] = useState([]);
    const [objPercent, setObjPercent] = useState([])

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);


    useEffect(() => {
        handleAddRow()
    }, [])

    // useEffect(() => {
    //     console.log(rowData)
    // },[rowData])

    const axiosInstance = useAxios();

    //Add New Row when dynamically
    const handleAddRow = () => {
        let newRow = {
            image : '',
            url : '',
            progress : 0,
            type : '',
            fileVisible : false,
            urlVisible : false,
            disableprop : false
        }

        setRowData([...rowData , newRow])

    };

    //Code to update selected file in state
    const handleFileChange = (idx) => (e) => {
        // console.log(e.target.files[0])
        const value = e.target.files[0];
        const name = e.target.name

        let newArr = [...rowData]; // copying the old datas array
        newArr[idx][name] = value;
        newArr[idx]['url'] = '';
        setRowData(newArr)

    }

     //Code to show / hide file input and url
     const handleFileType = (idx) => (e) => {
        const value = e.target.value;
        const name = e.target.name

        if (value == 'localfile') {
            let newArr = [...rowData]; // copying the old datas array
            newArr[idx]['fileVisible'] = true;
            newArr[idx]['urlVisible'] = false;
            newArr[idx]['url'] = ''
            newArr[idx]['image'] = ''
            setRowData(newArr)
        }else if (value == 'repo') {
            let newArr = [...rowData]; // copying the old datas array
            newArr[idx]['fileVisible'] = false;
            newArr[idx]['urlVisible'] = true;
            newArr[idx]['url'] = ''
            newArr[idx]['image'] = ''
            setRowData(newArr)
        }else {
            let newArr = [...rowData]; // copying the old datas array
            newArr[idx]['fileVisible'] = false;
            newArr[idx]['urlVisible'] = false;
            newArr[idx]['url'] = ''
            newArr[idx]['image'] = ''
            setRowData(newArr)
        }
    }

     //Code to store repo path url
     const handleUrlChange = (idx) => (e) => {
        const value = e.target.value;
        const name = e.target.name;

        const textFieldRegex = /^[A-Za-z0-9/:.\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}

        let newArr = [...rowData]; // copying the old datas array
        newArr[idx][name] = value;
        newArr[idx]['image'] = '';
        setRowData(newArr)
    }


    //code to store file hardware type
    const handleTypeChange = (idx) => (e) => {
       const value = e.target.value;
        const name = e.target.name

        let newArr = [...rowData]; // copying the old datas array
        newArr[idx][name] = value;
        setRowData(newArr)

    };
    
    const handleSpecificRowUpload = item => () => {
        let newArr = [...rowData]; // copying the old datas array
        let row = newArr[item]
        // imageUpload(row)

        if(row.image == '' && row.url == '') {
            ErrorPopUp("Select Local File or Repo Path")
        }else if(row.type == '') {
            ErrorPopUp("Select Type")
        }else if(row.image == '' && row.url !== '') {
            imageUpload(row, item)
        }else if(row.image !== '' && row.url == '') {
            imageUpload(row, item)
        }

    }


    //Preparing Image Data to send to server
	function imageUpload(data, index) {
        // console.log(data.url)
        let checkData = data
        if(checkData.image !== '') {
            const formData = new FormData();
            formData.append('image', data.image);

            formData.append('type', data.type);

            sendImageFile(formData, index)

        }else if (checkData.url !== '') {
             let inputdata = {
                 "repoPath" : checkData.url,
                 "type" : checkData.type
             }
             sendImageRepoPath(inputdata, index)
        }
		
	}

    //Code to send Local Image file to server
    const sendImageFile  = (input, index) => {
        const oldDataArray = [...rowData]
        oldDataArray[index].disableprop = true

        axiosInstance ({
			method: 'post',
			url: `${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/images`,
			data: input,
			headers: {
				'Content-Type': 'application/octet-stream',
				'Content-Disposition': 'file'
			},
			onUploadProgress : (progressEvent) => {
				const {loaded , total} = progressEvent;
				let percentage = Math.floor(loaded*100/total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                
                const oldArray = [...rowData]
                oldArray[index].percent = percentage
                setRowData(oldArray)
                
                
				// console.log({ progressEvent });
			}
		})
		.then((response) => {
            // console.log(response);
            if (response.response) {
                PopUp(response.response.data.detail)
            }else {
                PopUp("Uploaded Successfully")
            }
            sendUpdateState()
        })
		.catch((err) => {
            // console.log(err);
            if (err.response) {
                ErrorPopUp(err.response.data.detail)
            }else {
                ErrorPopUp("Upload Failed. Try Again")
            }
            
		})
    }

    //Code to send Image Repo Path to server

    const sendImageRepoPath = (input, index) => {
        const oldDataArray = [...rowData]
        oldDataArray[index].disableprop = true

        axiosInstance ({
			method: 'post',
			url: `${process.env.REACT_APP_PROJECT_URL}/projects/${props.projectid}/images/public`,
			data: input,
			onUploadProgress : (progressEvent) => {
				const {loaded , total} = progressEvent;
				let percentage = Math.floor(loaded*100/total)
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                
                const oldArray = [...rowData]
                oldArray[index].percent = percentage
                setRowData(oldArray)
			}
		})
		.then((response) => {
            // console.log(response);
            if (response.response) {
                PopUp(response.response.data.detail)
            }else {
                PopUp("Uploaded Successfully")
            }

            sendUpdateState()
        })
		.catch((err) => {
            // console.log(err);
            if (err.response.status == 500) {
                ErrorPopUp("Request Failed")
            }
            if (err.response) {
                ErrorPopUp(err.response.data.detail)
            }else {
                ErrorPopUp("Upload Failed. Try Again")
            }
		})
    }

    const sendUpdateState = () => {
        setTimeout(() => {
            props.changeCurState()    
        }, 2000);
    }
    // console.log(progressId)

    return (
        <div style={{padding : '1%', border : '1px solid lightgrey'}}>
         <div style={{ color: 'red'}}>
            {error}
        </div>
            <Table responsive className="table-bordered imgUploader">
                <thead className="thead-light">
                    <tr>
                        <th className="text-center"> Select File </th>
                        <th className="text-center"> Progress</th>
                        <th className="text-center"> Type</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                {rowData.length !== 0 && (
                    <>
                    {rowData.map((item, index) => (
                        <tr key={index}>
                            <td>
                            <form className="form-inline">
                                <div className="form-group mb-2">
                                    <select
                                    onChange={handleFileType(index)}
                                    name="type"
                                    className="form-control form-control-sm"
                                        >
                                        <option value="">Select File</option>
                                        <option value="localfile">Local file</option>
                                        <option value="repo">Public repo path </option>
                                    </select>
                                </div>
                                <div className="form-group mx-sm-3 mb-2">
                                    {rowData[index].fileVisible == true ? (
                                        <input type="file" className="form-control-file" name="image" onChange={handleFileChange(index)}/> 
                                    ) : null}

                                    {rowData[index].urlVisible == true ? (
                                        <input type="text" className="form-control form-control-sm" name="url" value= {`${rowData[index].url}`} onChange={handleUrlChange(index)}/>
                                    ) : null}
                                </div>
                            </form>
                                
                            </td>
                            <td>
                                <ProgressBar completed={rowData[index].percent === undefined ? 0 : rowData[index].percent} />
                            </td>
                            <td>
                                <select
                                onChange={handleTypeChange(index)}
                                name="type"
                                className="form-control form-control-sm"
                                    >
                                    <option value="">Select Type</option>
                                    <option value="controller">Controller</option>
                                    <option value="radio-simulator">Radio Simulator</option>
                                </select>
                            </td>
                            <td>
                                <Button
                                className="btnDefault btn-sm"
                                onClick={handleSpecificRowUpload(index)}
                                disabled={rowData[index].disableprop || !isValid}
                                >
                                Upload
                                
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </>
                )}
                </tbody>
            </Table>

            <Button onClick={handleAddRow} className="btnDefault">
                New
              </Button>
        </div>
        
    );
}

export default ImageUploader;