import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import CheckBox from '../CheckBox/CheckBox';
// import {useTableSearch} from './UserTableSearch'
import GoogleApiWrapper from '../Configuration/ConfigurationMap'
// import '../../components/CreateExpTable/CreateExpTable.css';
import axios from 'axios';


class ConfigurationNodes extends React.Component {
    
    constructor(props) {
       
        super(props);
        // console.log(this.props.projectId)
        this.filters = [];
        this.state = {
            query: '',
            originalData: [],
            data: [],
            searchResult: '',
            filters: [],
            sort: {
                column: null,
                direction: 'asc',
            },
            nodeData: []
        };
        //  this.onSort = this.onSort.bind(this)
    }

    componentDidMount(){
        axios.get(`/projects/${this.props.projectId}`)
        .then((response) => {
            // console.log(response);
            this.setState({nodeData: response, data:response, originalData:response})
        })
        .catch((err) => {
            // console.log(err);
        });
       
    }

    //Sorting the Table Columns Ascending and descending order
    onSort = (column) => (e) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = this.state.data.sort((a, b) => {
            if (a[column] < b[column]) {
                return -1;
            }
            if (a[column] > b[column]) {
                return 1;
            }
            return 0;
        });

        if (direction === 'desc') {
            sortedData.reverse();
        }

        this.setState({
            data: sortedData,
            sort: {
                column,
                direction,
            }
        });
    };

    //Based on sorting setting the arrow to right and down
    setArrow = (column) => {
        let className = 'fa fa-angle-';
        if (this.state.sort.column === column) {
            className += this.state.sort.direction === 'asc' ? 'right' : 'down';
        }
        className = className.split('-')[2] == '' ? 'fa fa-angle-right' : className;
        return className;
    };

    //Selecting all Nodes by Checkbox
    selectAll = () => (e) => {
        let cBox = document.querySelectorAll('[createexptable="exp-inp-checked"]');
        let checkedAllState = e.target.checked == true ? true : false;
        for (var i = 0; i <= cBox.length - 1; i++) {
            cBox[i].checked = checkedAllState;
        }
    }


    selectOne = () => (e) => {
        document.querySelector('[createexptable="exp-checkbox-header"]').checked = false;
    }


    tableFilter = () => (e) => {
        let filterData = [];
        let searchedData = [];
        let filterDataInp = '';
        let index = this.filters.indexOf(e.target.id);
        index = e.target.checked == true ? this.filters = [...this.filters, e.target.id] : [...this.filters.splice(index, 1)];
        let searchedvalue = document.querySelector('.serch-box').value;
        let searchData = searchedvalue.trim() !== '';
        if (searchData) {
            const searchedArr = this.state.data.map((item) => {
                if (item.exp_name.startsWith(searchedvalue)) {
                    return searchedData.push(item);
                }
            });
            filterDataInp = searchedData;
        } else {
            filterDataInp = this.state.originalData;
        }

        const filterArr = filterDataInp.map((item) => {
            if (this.filters.includes(item.exp_type)) {
                return filterData.push(item);
            }
        });

        if (filterData.length > 0) {
            this.setState({ data: filterData });
        } else {
            if (this.filters.length === 0 && searchedData.length === 0) {
                this.setState({ data: this.state.originalData });
            } else if (this.filters.length > 0 && filterData.length === 0) {
                this.setState({ data: [] });
            } else {
                this.setState({ data: searchedData });
            }
        }
    }

    // Calling function from Search Table function
    getSearchResults = query => {
        const allData = this.state.originalData;
        const dataToShow = [];
        allData.forEach(item => {
            const searchString = this.state.query.toUpperCase();
            if (item.exp_name.toUpperCase().includes(searchString)) {
                dataToShow.push(item);
            }

        });

        this.setState({
            data: dataToShow
        });
    };

    //Search table by node name based on user input 
    doSearch = (event) => {

        this.setState(
            {
                query: event.target.value
            },
            () => {
                if (this.state.query && this.state.query.length > 0) {
                    this.getSearchResults(this.state.query);
                } else {
                    this.setState({
                        data: this.state.originalData
                    });
                }
            }
        );

    }

    render() {
        var newdata = this.state.data;
        // console.log(newdata);
        return (
            <>
                <div className="bg-white  jumbotron">

                    <div className="row create-exp-search">
                        {/* Search Input for Node List */}
                        <div className="col-md-4">
                            <input className="form-control serch-box" onKeyUp={this.doSearch} ref={input => (this.search = input)} type="text" placeholder="Search" aria-label="Search"></input>
                        </div>
                        {/* Filter Node List by RSS, RSE, 5G Networks */}
                        <div className="offset-md-1 col-md-7 create-exp-filter">
                            <label style={{ paddingTop: "0.8rem" }}><b>Filter</b></label>
                            <CheckBox
                                name={"RSS"}
                                event={this.tableFilter(this)}
                            />
                            <CheckBox
                                name={"RSE"}
                                event={this.tableFilter(this)}
                            />
                            <CheckBox
                                name={"5G"}
                                event={this.tableFilter(this)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        {/* Node List in Google Mapview*/}
                        <div className="col-md-12 no-padding">
                            <GoogleApiWrapper name={this.state} />

                            {/* Node List Table View */}
                            <div className="col-5 no-padding node-list">
                                <span className="create-exp-table-list">Node List</span>
                                <span className="close">&times;</span>
                                <div className="create-exp-table-wrapper">
                                    <Table responsive borderless className="create-exp-table" >
                                        <thead>
                                            <tr >
                                                <th className="th-no-margin">
                                                    <CheckBox
                                                        event={this.selectAll(this)}
                                                        createexptable={"exp-checkbox-header"}
                                                    />
                                                </th>
                                                <th onClick={this.onSort('exp_name')}>
                                                    Name
                                                <span className="sort-arrow" >
                                                        <i className={this.setArrow('exp_name')}></i>
                                                    </span>
                                                </th>
                                                <th>Host</th>
                                                <th onClick={this.onSort('exp_type')}>
                                                    Type
                                                <span className="sort-arrow">
                                                        <i className={this.setArrow('exp_type')}></i>
                                                    </span>
                                                </th>
                                                <th onClick={this.onSort('exp_status')}>
                                                    Status
                                                <span className="sort-arrow">
                                                        <i className={this.setArrow('exp_status')}></i>
                                                    </span>
                                                </th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {newdata.map((item, index) => {
                                                return (
                                                    <tr key={item.exp_id} id={item.exp_id}>
                                                        <td>
                                                            <CheckBox
                                                                createexptable={"exp-inp-checked"}
                                                                event={this.selectOne(this)}
                                                            />
                                                        </td>
                                                        <td>{item.exp_name}</td>
                                                        <td>{item.exp_host}</td>
                                                        <td>{item.exp_type}</td>
                                                        <td>{item.exp_status}</td>
                                                        <td>{item.exp_view}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
};

export default ConfigurationNodes;
