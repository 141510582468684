import React, { useState, useEffect, useContext } from 'react';
import LoraLayout from '../../LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PopUp from '../../../UIElements/PopUp';
import ErrorPopUp from '../../../UIElements/ErrorPopup';
import Col from 'react-bootstrap/Col';
import { useAxios } from '../../../../utils/Axios';
import Context from '../../../../store/Context';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

function EditDevice(props) {
    const [singleDevice, setSingleDevice] = useState({})
    const [deviceProfiles, setDeviceProfiles] = useState([]);
    const [MSB, setMSB] = useState(true);
    const [devE, setdevE] = useState([]);
    const [fcvCheck, setFcvCheck] = useState(false);
    const [deviceCheck, setDeviceCheck] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [rowTags, setRowTags] = useState([]);

    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(true);

    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    const [value, setValue] = React.useState('General');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // useEffect(() => {
    //     console.log(rowTags);
    // }, [rowTags])

    useEffect(() => {
        fetchDeviceDetails();
    }, [axiosInstance])

    //code to fetch single device data
    function fetchDeviceDetails() {
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}`)
                .then((response) => {
                    // console.log(response);
                    setSingleDevice(response.data.device);
                    setdevE(response.data.devEUI);
                    setFcvCheck(response.data.device.skipFCntCheck);
                    setDeviceCheck(response.data.device.isDisabled);
                    // actions({ type: 'setState', payload: { ...state, blocking: false } });
                    extractVariables(response.data.device.variables, response.data.device.tags);
                    fetchDeviceProfile();
                })
                .catch((err) => {
                    // console.log(err);
                    // let response = {
                    //     "devEUI":"18c7dd5ebc76e476",
                    //     "name":"device100",
                    //     "applicationID":"12",
                    //     "description":"100",
                    //     "deviceProfileID":"e48430bf-ac38-4815-a57e-5cda3e3f7d4c",
                    //     "skipFCntCheck":true,
                    //     "referenceAltitude":0,
                    //     "variables":{"hi":"1","hi2":"hello"},
                    //     "tags":{"hi1":"1","hi2":"hello"},
                    //     "isDisabled":true}
                    // setSingleDevice(response);
                    // // actions({ type: 'setState', payload: { ...state, blocking: false } }
                    // setFcvCheck(response.skipFCntCheck);
                    // setDeviceCheck(response.isDisabled);
                    fetchDeviceProfile();
                });

        }
    };

    //Code to fetch device profiles adn store in state
    function fetchDeviceProfile() {
        let reqid = localStorage.getItem('orgid');
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/device-profiles?limit=100&organizationID=${reqid}`)
                .then((response) => {
                    // console.log(response);
                    setDeviceProfiles(response.data.result)
                    actions({ type: 'setState', payload: { ...state, blocking: false } });

                })
                .catch((err) => {
                    // console.log(err);
                    // setDeviceProfiles([
                    //     { "id": "e48430bf-ac38-4815-a57e-5cda3e3f7d4c", "name": "deviceprofile1", "organizationID": "21", "networkServerID": "4", "createdAt": "2021-01-20T15:46:37.193352Z", "updatedAt": "2021-01-20T15:46:37.193352Z", "networkServerName": "network-server-test" },
                    //     { "id": "123330bf-ac38-4815-a57e-5cda3e355555", "name": "deviceprofile2", "organizationID": "100", "networkServerID": "4", "createdAt": "2021-01-20T15:46:37.193352Z", "updatedAt": "2021-01-20T15:46:37.193352Z", "networkServerName": "network-server-test" }
                    // ])
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                    extractVariables();
                });

        }
    }

    //code to seperate variables from key values
    function extractVariables(variabledata, rowdata) {
        // console.log(singleDevice);
        let variables = variabledata;
        let arr = [];
        if (Object.keys(variables).length > 0) {
            for (const property in variables) {
                let obj = {};
                obj['name'] = property;
                obj['value'] = variables[property];
                arr.push(obj);
            }

        }
        setRowData(arr);
        setTimeout(() => {
            extractTags(rowdata);
        }, 1000);
    }

    //code to seperate variables from key values
    function extractTags(data) {
        let tags = data;
        let arr = [];
        if (Object.keys(tags).length > 0) {
            for (const property in tags) {
                let obj = {};
                obj['name'] = property;
                obj['value'] = tags[property];
                arr.push(obj);
            }

        }
        setRowTags(arr);
    }
    //code to store input data
    function inputHandler(e) {
        const name = e.target.name;
        const value = e.target.value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
        if (!textFieldRegex.test(value)) {
            setError(name + ' is invalid');
            setIsValid(false);
        } else {
            setError('');
            setIsValid(true);
        }
        setSingleDevice({ ...singleDevice, [name]: value });
    }

    //Code to store selected device profiles
    function changeDeviceProfile(e) {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let name = e.target.name
        let val = optionElement.value;
        setSingleDevice({ ...singleDevice, [name]: val });
    }

    //code to set MSB/LSB
    function changeMSB(e) {
        const bytes = singleDevice.devEUI.match(/[A-Fa-f0-9]{2}/g);
        if (bytes !== null) {
            let data = bytes.reverse().join("")
            setSingleDevice({ ...singleDevice, ['devEUI']: data });
        }
        setMSB(!MSB);
    }

    //Code to store EUI user input change
    function changeEUI(e) {
        const name = e.target.name;
        let input = e.target.value;
        // if (input >= 12) input = 12;
        // setEUIValue(input)
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
        if (!textFieldRegex.test(input)) {
            setError(name + ' is invalid');
            setIsValid(false);
        } else {
            setError('');
            setIsValid(true);
        }
        setSingleDevice({ ...singleDevice, ["devEUI"]: input });
    }

    //code to generate 12digit EUI Id and save in state
    function generateEUI(e) {

        let key = "";
        const possible = 'abcdef0123456789';

        for (let i = 0; i < 16; i++) {
            key += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        //console.log(key);
        setdevE(key);
        setSingleDevice({ ...singleDevice, ['devEUI']: key });
    }

    //code to store checkbox data
    function setCheckbox(e) {
        let name = e.target.getAttribute('name');
        if (name == 'skipFCntCheck') {
            setFcvCheck(!fcvCheck)
        }

        if (name == 'isDisabled') {
            setDeviceCheck(!deviceCheck)
        }
    }

    //code to add new variable row
    function addRowVariable(input) {
        if (input == 'variable') {
            let newRow = {

            }

            setRowData([...rowData, newRow])
        }
        if (input == 'tag') {
            let newRow = {

            }

            setRowTags([...rowTags, newRow])
        }
    }

    //Code to store variable name
    const changeName = (idx) => (e) => {
        const value = e.target.value;
        // console.log(value);
        const name = e.target.name
        let newArr = [...rowData]; // copying the old datas array
        newArr[idx][name] = value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
        if (!textFieldRegex.test(value)) {
            setError(name + ' is invalid');
            setIsValid(false);
        } else {
            setError('');
            setIsValid(true);
        }
        setRowData(newArr)
    }

    //Code to store variable name
    const changeTagName = (idx) => (e) => {
        const value = e.target.value;
        // console.log(value);
        const name = e.target.name
        let newArr = [...rowTags]; // copying the old datas array
        newArr[idx][name] = value;
        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
        if (!textFieldRegex.test(value)) {
            setError(name + ' is invalid');
            setIsValid(false);
        } else {
            setError('');
            setIsValid(true);
        }
        setRowTags(newArr)
    }

    //code to filter variable data
    function filterVariableData() {
        let obj = {}
        let olddata = [...rowData];

        for (let i = 0; i < olddata.length; i++) {
            if (olddata[i].name !== undefined && olddata[i].value !== undefined) {
                obj[olddata[i].name] = olddata[i].value
            }
        }
        return obj;
    }

    //code to filter Tag data
    function filterTagsData() {
        let obj = {}
        let olddata = [...rowTags];

        for (let i = 0; i < olddata.length; i++) {
            if (olddata[i].name !== undefined && olddata[i].value !== undefined) {
                obj[olddata[i].name] = olddata[i].value
            }
        }
        return obj;
    }

    //code to update the device
    function updateDevice() {
        let data = {
            "device": {
                ...singleDevice,
                "skipFCntCheck": fcvCheck,
                "isDisabled": deviceCheck,
                "variables": filterVariableData(),
                "tags": filterTagsData()
            }
        };

        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            //console.log(data);
            axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/devices/${props.match.params.id}`, data)
                .then((response) => {
                    // console.log(response);
                    PopUp("Device Updated Successfully");
                    fetchDeviceDetails();
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopUp("Request Failed. Try Again")
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    }

    return (
        <>{Object.keys(singleDevice).length > 0 ? (
            <>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="default-main-page">
                        <div style={{ color: 'red' }}>
                            {error}
                        </div>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="General" value="General" />
                                        <Tab label="Variables" value="Variables" />
                                        <Tab label="Tags" value="Tags" />
                                    </TabList>
                                </Box>
                                <TabPanel value="General"> <Form style={{ margin: '3%' }}>
                                    <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" name="name" placeholder="Enter Device Name" value={singleDevice.name} onChange={(e) => inputHandler(e)} autoComplete="off" />
                                        <Form.Text className="text-muted">
                                            The name may only contain words, numbers and dashes.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control type="text" name="description" value={singleDevice.description} placeholder="Enter Description" onChange={(e) => inputHandler(e)} autoComplete="off" />
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col} md="10">
                                            <Form.Label>Device EUI </Form.Label>
                                            <Form.Control type="text" value={singleDevice.devEUI} name="devEUI" placeholder="Enter Device EUI" readOnly onChange={(e) => changeEUI(e)} autoComplete="off" />
                                            <Form.Text className="text-muted">
                                                The Device EUI must be 12 characters length
                                            </Form.Text>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md="1" style={{lineHeight:'90px'}}>
                                        <Button className="btnNormal" readOnly onClick={(e) => changeMSB(e)}>{MSB == true ? 'MSB' : 'LSB'}</Button>
                                    </Form.Group>
                                    <Form.Group as={Col} md="1" style={{lineHeight:'90px'}}>
                                        <i className="fas fa-redo"  readOnly aria-hidden="true" style={{cursor: 'pointer'}} onClick={(e)=> generateEUI(e)}></i>                                            
                                    </Form.Group> */}
                                    </Form.Row>
                                    <Form.Group>
                                        <Form.Label>Select Device Profile</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => changeDeviceProfile(e)}
                                            name="deviceProfileID"
                                            value={singleDevice.deviceProfileID}
                                            required
                                            custom
                                        >
                                            <option value="">Select Device Profile</option>
                                            {deviceProfiles.length > 0 ? (
                                                <>
                                                    {deviceProfiles.map((item, index) => {
                                                        return (
                                                            <option value={item.id} key={index}>{item.name}</option>
                                                        );
                                                    })}
                                                </>
                                            ) : null}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check type="checkbox" checked={fcvCheck || ''} name="skipFCntCheck" label="Disable frame-counter validation" onChange={(e) => setCheckbox(e)} />
                                        <Form.Text className="text-muted">
                                            Note that disabling the frame-counter validation will compromise security as it enables people to perform replay-attacks.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Check type="checkbox" checked={deviceCheck || ''} name="isDisabled" label="Device is disabled" onChange={(e) => setCheckbox(e)} />
                                        <Form.Text className="text-muted">
                                            ChirpStack Network Server will ignore received uplink frames and join-requests from disabled devices.
                                        </Form.Text>
                                    </Form.Group>
                                </Form></TabPanel>
                                <TabPanel value="Variables"><div style={{ margin: '3%' }}>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                            Variables can be used to substitute placeholders in for example integrations, e.g. in case an integration requires the configuration of a device specific token.
                                        </Form.Text>
                                    </Form.Group>
                                    <table>
                                        <tbody>
                                            {rowData.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Control type="text" value={item.name || ''} name="name" placeholder="name" onChange={changeName(index)} autoComplete="off" />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Control type="text" value={item.value || ''} name="value" placeholder="value" onChange={changeName(index)} autoComplete="off" />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button onClick={() => addRowVariable('variable')} className="btnDefault">
                                        Add Variable
                                    </Button>
                                </div></TabPanel>
                                <TabPanel value="Tags"> <div style={{ margin: '3%' }}>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                            Tags can be used as device filters and are exposed on events as additional meta-data for aggregation.
                                        </Form.Text>
                                    </Form.Group>
                                    <table>
                                        <tbody>
                                            {rowTags.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Form.Row>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Control type="text" value={item.name || ''} name="name" placeholder="name" onChange={changeTagName(index)} autoComplete="off" />
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="6">
                                                                    <Form.Control type="text" value={item.value || ''} name="value" placeholder="value" onChange={changeTagName(index)} autoComplete="off" />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <br />
                                    <Button onClick={(e) => addRowVariable('tag')} className="btnDefault">
                                        Add Tag
                                    </Button>
                                </div></TabPanel>
                            </TabContext>
                        </Box>
                        <div className="col-md-12 text-right no-padding" >
                            <Button disabled={!isValid} className="btnDefault" style={{ margin: '2%' }} onClick={() => updateDevice()}>
                                + UPDATE DEVICE
                            </Button>
                        </div>
                    </div>
                </div>

            </>
        ) : (
            <div className="default-content-center" style={{ margin: '3%', textAlign: 'center' }}>
                Device Data Not Found
            </div>
        )}
        </>
    )
}

export default EditDevice;
