import React, {  } from 'react';
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout';
import AdminOverrideRequest from './AdminOverrideRequest';


function AdminOverride(){
 

    return(
        <RoboticsLayout>
                    <div className="bg-white jumbotron" style={{ marginRight: '5rem' }}>
                        <h5>Admin Override Requests</h5>
                        <AdminOverrideRequest />                    
                </div>
        </RoboticsLayout>
    )

}

export default AdminOverride;


