import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup'
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context'
import '../RoboticsProjectTable/RoboticsProjectTable.css';

function AdminArenaQueueTable(props) {
    const [isRnOperator, setIsRnOperator] = useState(false);
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    useEffect(() => {
        checkIsRnOperator();
    }, [props])

    //code to check user is rn_admin or not
    function checkIsRnOperator() {
        let roles = JSON.parse(localStorage.getItem("roles"));

        if(roles.includes('rn_admin')){
            setIsRnOperator(true);
        }

        if(roles.includes('rn_pradmin')){
            setIsRnOperator(true)
        }

        if (roles.includes('rn_operator')) {
            // console.log('system Admin')
            setIsRnOperator(false);
        }

        
    }

    //code to Start Experiment request
    const startExp = (input) => {
        let expId = input.getAttribute('expid');
        swal({
            title: 'Are you sure?',
            text: 'The Experiment Will Start Immediately',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    actions({ type: 'setState', payload: { ...state, blocking: true } })
                    axiosInstance
                        .put(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${expId}/start`)
                        .then((response) => {
                            PopUp("Experiment Started Successfully")
                            props.status(true)
                            actions({ type: 'setState', payload: { ...state, blocking: false } })
                        })
                        .catch((err) => {
                            // console.log(err);
                            ErrorPopUp("Request Failed. Try Again")
                            actions({ type: 'setState', payload: { ...state, blocking: false } })
                        });
                }
            });
    }

    //code to send Experiment cancel request
    const cancelExp = (input) => {
        let expId = input.getAttribute('expid');
        swal({
            title: 'Are you sure?',
            text: 'The Experiment Will be cancelled',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    actions({ type: 'setState', payload: { ...state, blocking: true } })
                    axiosInstance
                        .put(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/${expId}/cancel`)
                        .then((response) => {
                            PopUp("Experiment Cancelled Successfully")
                            props.status(true)
                            actions({ type: 'setState', payload: { ...state, blocking: false } })
                        })
                        .catch((err) => {
                            // console.log(err);
                            ErrorPopUp("Cancellation Failed. Try Again")
                            actions({ type: 'setState', payload: { ...state, blocking: false } })
                        });
                }
            });
    }

    //code to parse the input and return the length
    function parseRobotCount(input) {
        // console.log(input.robotDetails.stringProp);
        let parse = JSON.parse(input.robotDetails.stringProp)
        return parse.robots.length;
    }

    return (
        <div>
            {isRnOperator == true ? (
                <>                                
                    <Table responsive bordered className="adminQueueTable">
                        <thead>
                            <tr>
                                <th colSpan="6" style={{ textAlign: 'center' }}>Robot Arena Experiment Queue</th>
                            </tr>
                            <tr>
                                <th>Experiment Id</th>
                                <th>Robot Count</th>
                                <th>Duration</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((item) => (
                                <tr key={item.id} >
                                    <td>

                                        {
                                            item.id.length < 30 ? (
                                                <>{item.id.slice(0, 29)}</>
                                            ) : (
                                                <>{item.id.slice(0, 29) + '...'}</>
                                            )
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.details !== null ? (
                                                <>
                                                    {parseRobotCount(item.details)}
                                                </>
                                            ) : '-'
                                        }
                                    </td>
                                    <td>
                                        {item.duration}
                                    </td>
                                    <td>
                                        {item.status}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </>
            ) : (
                <>   
                    <h5>Robot Arena Experiment Queue</h5> 
                    <Table responsive bordered className="adminQueueTable">
                        <thead>
                            <tr>
                                <th>Experiment Id</th>
                                <th>Robot Count</th>
                                <th>Duration</th>
                                <th>Status</th>
                                <th>Control</th>
                                <th>Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((item) => (
                                <tr key={item.id} >
                                    <td>
                                        {item.id}                                       
                                    </td>
                                    <td>
                                        {
                                            item.details !== null ? (
                                                <>
                                                    {parseRobotCount(item.details)}
                                                </>
                                            ) : '-'
                                        }
                                    </td>
                                    <td>
                                        {item.duration}
                                    </td>
                                    <td>
                                        {item.status}
                                    </td>
                                    <td>
                                        {
                                            item.status == 'Running' || item.status == 'cancelled' ? (
                                                <>-</>
                                            ) : (
                                                <>
                                                    {/* <i className="fa fa-times" aria-hidden="true" style={{ color: "#A3A6B4" }} expid={item.experimentId} onClick={e => cancelExp(e.target)}></i> */}
                                                    <Button
                                                        className="btnDefault btn-sm"
                                                        expid={item.id}
                                                        onClick={e => startExp(e.target)}
                                                    >
                                                        Start
                                                </Button>
                                                </>
                                            )
                                        }

                                    </td>
                                    <td>
                                        {
                                            item.status == 'Running' || item.status == 'cancelled' ? (
                                                <>
                                                    <i className="fa fa-times" aria-hidden="true" style={{ color: "#A3A6B4" }} expid={item.id} onClick={e => cancelExp(e.target)}></i>
                                                </>

                                            ) : <><p>-</p></>
                                        }

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </>
            )}
        </div>
    )
}

export default AdminArenaQueueTable;
