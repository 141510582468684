import React, { useState, useContext, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './Home.css'
import NewHeader from '../../components/Header/NewHeader';
import { Button } from '@mui/material';

function Home(props) {
    const [sensorAccess, setSensorAccess] = useState('');
    const [streetLightsAcess, setStreetLightsAcess] = useState('');
    const [showMenu, setShowMenu] = useState(false);
    const [smeAccess, setSmeAccess] = useState(false);
    const [ucareAccess, setUcareAccess] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            checkSensorUser();
        }, 0);
    }, [])

    // localStorage.clear();

    //code to check the current user have access to sensor dashboard or not
    function checkSensorUser() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ['sensor_data_admin']
        if (roles !== null) {
            if (roles.includes('sensor_data_admin')) {
                setSensorAccess(true);
            }

            if (roles.includes('street_app_admin')) {
                setStreetLightsAcess(true)
            }

            if (roles.includes('sme_admin')) {
                setSmeAccess(true);
            }

            if (roles.includes('ucare_admin')){
                setUcareAccess(true);
            }

            setShowMenu(true);
        } else {
            setSensorAccess(false);
            setStreetLightsAcess(false);
            setSmeAccess(false);
            setShowMenu(true)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <NewHeader />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-12" style={{padding: 0}}>
                        <h5 className="nwrkText">Select Network</h5>
                        <Jumbotron className="text-center bg-white homeBody">
                            <div className="row d-flex justify-content-center">
                                {showMenu == true ? (
                                    <>
                                        <div className="col-md-6">
                                            <Button href="#/umbrella/userrequest" className="btnDefault btnDefaultHome" >
                                                Umbrella Networks
                                            </Button>
                                        </div>
                                        <div className="col-md-6">
                                            <Button href="#/robotics" className="btnDefault btnDefaultHome" >
                                                Robotic Networks
                                            </Button>
                                        </div>
                                        <div className="col-md-6">
                                            <Button href="#/lora/userorgrequest" className="btnDefault btnDefaultHome" >
                                                Lora Networks
                                            </Button>
                                        </div>
                                        {sensorAccess == true ? (
                                            <>
                                                <div className="col-md-6">
                                                    <Button href="#/sensordashboard" className="btnDefault btnDefaultHome" >
                                                        Air Quality Dashboard
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null
                                        }
                                        {streetLightsAcess == true ? (
                                            <>
                                                <div className="col-md-6">
                                                    <Button href="https://streetcare.umbrellaiot.com/tables/streetlights" target="_blank" className="btnDefault btnDefaultHome" >
                                                        Street Lights
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null
                                        }
                                        {smeAccess == true ? (
                                            <>
                                                <div className="col-md-6">
                                                    <Button href="#/smeform" className="btnDefault btnDefaultHome smebtn" >
                                                        SME Competition Form
                                                    </Button>
                                                </div>
                                            </>                        
                                        ) : null
                                        }
                                        {ucareAccess == true ? (
                                            <>
                                                <div className="col-md-6">
                                                    <Button href="#/ucaredashboard" className="btnDefault btnDefaultHome" >
                                                        UCARE
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null
                                        }

                                                
                                    </>
                                ) : null}

                            </div>
                        </Jumbotron>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Home;