import React from 'react';
import PropTypes from 'prop-types';
import './CheckBox.css';

const checkBox = ({name, event, checked , id , disabled}) => {
//    let checkBoxName =  name === undefined ? Math.random()+ "checkBox": name ;
    return (
        // <div className="custom-control custom-checkbox create-exp-checkBox">
        <div>
            <input type="checkbox" hostname={name} checked={checked} onChange={event} index={id} disabled={disabled}/>
        </div>
    );
}

// checkBox.propTypes = {
//     // type: PropTypes.string,
//     // name: PropTypes.string.isRequired,
//     checked: PropTypes.bool,
//     // onChange: PropTypes.func.isRequired,
//   }

export default checkBox;