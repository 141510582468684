import React from 'react';
import { useEffect, useState, useContext } from 'react';
import HTTPCard from './Http/HTTPCard'
import InfluxDBCard from './InfluxDB/InfluxDBCard'
import Row from 'react-bootstrap/Row';
import { useAxios } from '../../../../utils/Axios';
import Context from '../../../../store/Context';

function IntegrationsList(props) {
    const [configured, setConfigured] = useState([]);
    const [available, setAvailable] = useState([]);
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    let orgid = localStorage.getItem('orgid');

    useEffect(() => {
        fetchIntegraions();
    }, [axiosInstance]);

    // useEffect(() => {
    //     console.log(integrationsInfo);
    // }, [integrationsInfo]);

    const fetchIntegraions = () => {

        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true }});
            axiosInstance
                .get(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}/integrations`)
                .then((response) => {
                    // console.log(response);
                    filterIntegrations(response.data.result);

                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } })
                });

        }
    };

    //code to filter all integrations and set in store for rendering
    function filterIntegrations(data){
    let configured = [];
      let available = [];
      const includes = (integrations, kind) => {
        for (let x of integrations) {
          if (x.kind === kind) {
            return true;
          } 
        }

        return false;
      };

      // HTTP
      if(includes(data, "HTTP")) {
        configured.push(<HTTPCard organizationID={orgid} applicationID={props.match.params.id} status={handleSubmit} options={props.options} edit/>);
      } else {
        available.push(<HTTPCard organizationID={orgid} applicationID={props.match.params.id} status={handleSubmit} options={props.options} add />);
      }

      // InfluxDB
      if(includes(data, "INFLUXDB")) {
        configured.push(<InfluxDBCard  organizationID={orgid} applicationID={props.match.params.id} status={handleSubmit} options={props.options} edit/>);
      } else {
        available.push(<InfluxDBCard organizationID={orgid} applicationID={props.match.params.id} status={handleSubmit} options={props.options} add/>);
      }

      setConfigured(configured);
      setAvailable(available);
    }
    
    //code to recall the api
    const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchIntegraions();
			}, 1000);

		};
    };
    
    return (
        <>
            <Row style={{marginBottom : '3%'}}>
                {configured}
                {available}
            </Row>            
        </>
    )
}

export default IntegrationsList;
