import React, { useState, useEffect, useContext } from 'react';
import LoraLayout from '../../../LoraLayout/LoraLayout';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ToastContainer, Zoom } from 'react-toastify';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PopUp from '../../../../UIElements/PopUp'
import ErrorPopUp from '../../../../UIElements/ErrorPopup'
import { useAxios } from '../../../../../utils/Axios';
import Context from '../../../../../store/Context';
import { useHistory } from 'react-router-dom';

function InfluxDBEditCard(props) {
    const [influxDBData, setInfluxDBData] = useState({
        
    })
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);
    const history = useHistory();
    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    //Code to update Org Name and Disaply Name
    function changeHandler(e) {
        const name = e.target.name;
        const value = e.target.value;
        let textFieldRegex = /^[A-Za-z0-9\-]+$/;
        if(name != 'password'){
            if(name == 'endpoint'){
                textFieldRegex = /^[A-Za-z0-9:/.\-]+$/;
             }
             if (!textFieldRegex.test(value)) {
                 setError(name + ' is invalid');
                 setIsValid(false);
             }else{
                 setError('');
                 setIsValid(true);
             }
        }
        setInfluxDBData({ ...influxDBData, [name]: value });
    }
    useEffect(() => {
        fetchInfluxDBDetails();
    }, [axiosInstance]);


    function fetchInfluxDBDetails(){
        if(axiosInstance){
            actions({type:'setState',payload:{state,blocking:false}})
            axiosInstance
            .get(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}/integrations/influxdb`)
            .then((response)=>{
                 console.log(response.data.integration);
                 setInfluxDBData(response.data.integration);
            }).catch((err)=>{
                ErrorPopUp("Fetching Device Profile Failed");
                 
            })

        }
    }
    //Code to submit adding HTTP Integration Request
    const submitINFLUXDB = (e) => {
        e.preventDefault();
        let data = { "integration": { ...influxDBData}};
        if (axiosInstance) {
            actions({ type: 'setState', payload: { ...state, blocking: true } })
            axiosInstance
                .put(`${process.env.REACT_APP_LORA_URL}/applications/${props.match.params.id}/integrations/influxdb`, data)
                .then((response) => {
                    // console.log(response);
                    actions({ type: 'etState', payload: { ...state, blocking: false } });
                    PopUp("InfluxDB Updated successfuly");
                    setTimeout(() => {
                        history.push(`/lora/applications/${props.match.params.id}`);
                    }, 1000);

                })
                .catch((err) => {
                    // console.log(err);
                    actions({ type: 'setState', payload: { ...state, blocking: false } });
                    ErrorPopUp("Request Failed. Try Again")
                    // history.push(`/lora/applications/${props.match.params.id}`);
                });

        }
    }

    return (
        <>
            <LoraLayout>
            <div style={{ color: 'red'}}>
                {error}
            </div>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Edit InfluxDB integration</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        <Row>
                            <Col md={11} className="mapCol">
                                <Form onSubmit={submitINFLUXDB}>
                                    <Form.Group>
                                        <Form.Label>Api Endpoint</Form.Label>
                                        <Form.Control
                                            name="endpoint"
                                            onChange={changeHandler}
                                            type="text"
                                            value={influxDBData.endpoint || ''}
                                            placeholder="API endpoint (write)"
                                            autoComplete="off"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control
                                            name="username"
                                            onChange={changeHandler}
                                            type="text"
                                            value={influxDBData.username || ''}
                                            placeholder="username"
                                            autoComplete="off"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            name="password"
                                            onChange={changeHandler}
                                            type="text"
                                            value={influxDBData.password || ''}
                                            placeholder="password"
                                            autoComplete="off"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Database Name</Form.Label>
                                        <Form.Control
                                            name="db"
                                            value={influxDBData.db || ''}
                                            onChange={changeHandler}
                                            type="text"
                                            placeholder="DB name"
                                            autoComplete="off"
                                            required
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Retention Policy name</Form.Label>
                                        <Form.Control
                                            name="retentionPolicyName"
                                            onChange={changeHandler}
                                            type="text"
                                            value={influxDBData.retentionPolicyName || ''}
                                            placeholder="Retention policy name"
                                            autoComplete="off"
                                            required
                                        />
                                         <Form.Text className="text-muted">
                                            Sets the target retention policy for the write. InfluxDB writes to the DEFAULT retention policy if you do not specify a retention policy.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Timestamp precision</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={influxDBData.precision || ''}
                                            onChange={changeHandler}
                                            name="precision"
                                            required
                                            custom
                                        >
                                            <option value="">Select timestamp precision </option>
                                            <option value="NS">Nanosecond</option>
                                            <option value="U">Microsecond</option>
                                            <option value="MS">Millisecond</option>
                                            <option value="S">Second</option>
                                            <option value="M">Minute</option>
                                            <option value="H">Hour</option>
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            It is recommented to use the least precise precision possible as this can result in significant improvements in compression.
                                        </Form.Text>
                                    </Form.Group>
                                    <div className="text-right">
                                        <Button disabled={!isValid} className="btnDefault" type="submit">
                                            SUBMIT
                                        </Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom} />
        </>
    )
}

export default InfluxDBEditCard;
