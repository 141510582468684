import React,{useEffect} from 'react';
import FuotaList from './FuotaList';
import Button from 'react-bootstrap/Button';

function Fuota(props) {

    // useEffect(() => {
    //     console.log(props)
    // },[props])

    return (
        <>
            <div style={{ margin: '2%' }}>
                <FuotaList {...props} />
                <div className="col-md-12 text-center no-padding" style={{ textAlign: 'right' }}>
                    <Button className="btnDefault btnSpace" href={`#/lora/applications/${props.match.params.id}/FuotaCreate/create`}>
                        CREATE FIRMWARE UPDATE JOB
                </Button>
                </div>
            </div>
        </>
    )
}

export default Fuota
