import React, { useState, useEffect } from 'react';
import './CreateExperiment.css';
import Button from 'react-bootstrap/Button';
import { Modal, Accordion, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import swal from 'sweetalert';
import CreateExpTable from '../../CreateExpTable/CreateExpTable';
import { useAxios } from '../../../utils/Axios';
import PopUp from '../../UIElements/PopUp'
import ErrorPopUp from '../../UIElements/ErrorPopup'


function CreateExperiment(props) {
    const axiosInstance = useAxios();

    const [modalPageNo, setModalPageNo] = useState(1);
    const [firstModalOpen, setFirstModalOpen] = useState(false);
    const [lgModal, setLgModal] = useState('');
    const [expNodeView, setExpNodeView] = useState(false);

    const [severity,setSeverity] = useState(['']);
    const [runMode, setRunMode] = useState('');
    const [validationboxCheck, setvalidationboxCheck] = useState(true);
    const [worldFileSelection, setWorldFileSelection] = useState(true);
    const [originalArenas, setOriginalArenas] = useState([]);
    const [robotArenas, setRobotArenas] = useState([]);
    const [robotCountEntry, setRobotCountEntry] = useState(true);
    const [robotCount, setRobotCount] = useState(0);
    const [robotLabelSelection, setRobotLabelSelection] = useState(true);
    const [validationRobotsCheck, setValidationRobotsCheck] = useState(true);
    const [defaultAreanasCheck, setDefaultAreanasCheck] = useState(true);

    const [robotsData, setRobotsData] = useState([]);

    const [imgValidated, setImgValidated] = useState(0);
    const [selectConfigEnable , setSelectConfigEnable] =useState(false)
    const [selectBinaryArena,setSelectBinaryArena] = useState(false);
    const [expNameCheck, setNameCheck] = useState(false)
    const [expDescCheck, setDescCheck] = useState(false)
    const [expConfigImageCheck, setConfigImageCheck] = useState(false)
    const [expModeCheck, setExpModeCheck] = useState(false)
    const [expDurationCheck, setExpDurationCheck] = useState(false);

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    // const [createExp, setCreateExp] = useState({ "name": '', "description": '', "schedule": { "startTime": '', "startDate": '', "duration": '', "days": 0, "hours": 0, "min": 0, "runOnNodeAvailability": false, "runIndefinitely": false }, "configurations": [{ "name": '', "binaryFiles": [{ "path": '', "type": '' }], "imageFile": { "repoPath": '', "tag": '', "type": '' }, "nodes": '' }] })
    const [createExp, setCreateExp] = useState(
        {
            "name": '',
            "description": '',
            "duration": '',
            "days": 0,
            "hours": 0,
            "min": 0,
            "imageFile1": '',
            "imageFile2": '',
            "binaryFile1": '',
            "binaryFile2": '',
            "binaryArena": '',
            "experimentMode": '',
            "useDefaultNames": false,
            "robotSimulatorDetails": {
                "isValidation": true,
                "count": 0,
                "robots": [{
                    "aliasName": ''
                }]
            },
            "robotArenaDetails": {
                "robots": [{
                    "name": '',
                    "aliasName": ''
                }]
            }
        }
    )

    // useEffect(() => {
    //     getSimulatorRobotsCount();
    // }, [axiosInstance])

    useEffect(() => {
        // console.log(props.robotArenas)
        if (props.robotArenas.length > 0) {
            // setRobotArenas(props.robotArenas)
            addNames(props.robotArenas)
            let count = props.robotArenas.length
            createRobotTable(count);
            setRobotCount(count);
            setCreateExp((prevState) => {
                prevState.robotSimulatorDetails['count'] = count;
                return {
                    ...prevState
                };
            });
        }
    }, [props.robotArenas])

    // useEffect(() => {
    //     console.log(createExp);
    // }, [createExp])

    // useEffect(() => {
    //     console.log(originalArenas);
    // }, [originalArenas])
    useEffect(() => {
        getSeverityBackend();
    }, [axiosInstance])

	function getSeverityBackend(){
        if(axiosInstance){
            axiosInstance
                .get(`${process.env.REACT_APP_PROJECT_URL}/approval/severity/${props.project_id}`)
                .then((response)=>{
					setSeverity(response.data.message);
                    console.log(response.data.message);
                }).catch((err)=>{
                    // actions({type : 'setState', payload : {...state , blocking : false}});
                if (err.response) {
					ErrorPopUp(err.response.data.detail)
                }else {
                    ErrorPopUp("Request Failed. Try Again")
                }
                });
        }       
    }
    //code to add Names to robot arenas list and storing in state
    function addNames(input) {
         // console.log(input);
        if (input.length > 0) {
            // let addinput = parseInt(input) + 1
            // console.log(input);
            const array = [];
            for (let i = 0; i < input.length; i++) {
                const obj = {};
                obj['name'] = input[i].hostName;
                // obj['checkDisabled'] = true;
                obj['inputDisabled'] = true;
                obj['checked'] = false;
                if (i >= 0) {
                    obj['id'] = input[i].hostName.replace(/-/g, "_")
                    array.push(obj)
                }

            }
            // console.log(array)
            setRobotArenas(array);
            // setTimeout(() => {
            //     setOriginalArenas(array);
            // }, 200);
        }
    }

    //code to set default robot labels to inputs
    function setDefaultRobotLabels(input) {
        // console.log(input);
        // if (input.length > 0) {
            // let addinput = parseInt(input) + 1
            // console.log(input);
            const array = [];
            for (let i = 0; i < input.length; i++) {
                const obj = {};
                obj['name'] = input[i].name;
                obj['inputDisabled'] = input[i].inputDisabled;
                obj['checked'] = input[i].checked;
                if (i >= 0) {
                    obj['id'] = input[i].name.replace(/-/g, "_")
                    array.push(obj)
                }

            }
            // console.log(array);
            setRobotArenas(array);
            // setTimeout(() => {
            //     setOriginalArenas(array);
            // }, 200);
        // }
    }

    function openFirstModal() {
        setFirstModalOpen(true);
        setLgModal('')
    };

    function openSecondModal() {
        if (createExp.name == '') {
            setNameCheck(true)
            return
        } else {
            setNameCheck(false)
        }

        if (createExp.description == '') {
            setDescCheck(true)
            return
        } else {
            setDescCheck(false)
        }


        // if (createExp.min == '' || createExp.min == 0) {
        //     setExpDurationCheck(true)
        //     return
        // } else {
        //     setExpDurationCheck(false)
        // }

        if(!createExp.min  && !createExp.hours && !createExp.days ){
            setExpDurationCheck(true)
            return
        }else{
            setExpDurationCheck(false)
        }

        if (createExp.imageFile1 == '') {
            setConfigImageCheck(true)
            return
        } else {
            setConfigImageCheck(false)
        }

        if (createExp.imageFile1.repoPath === null) {
            setConfigImageCheck(true)
            return
        } else {
            setConfigImageCheck(false)
        }

        if (createExp.experimentMode == '') {
            setExpModeCheck(true)
            return
        } else {
            setExpModeCheck(false)
        }

        if (createExp.experimentMode == null) {
            setExpModeCheck(true)
            return
        } else {
            setExpModeCheck(false)
        }

        setModalPageNo(2)
        document.querySelector('#create-exp-title').textContent = 'New Experiment (Step 2 of 2)';
        document.querySelector('.create-exp-first').style.display = 'none';
        document.querySelector('.create-exp-second').style.display = 'block';
    };

    function closeAllModal() {
        setCreateExp({ "name": "", "description": "", "duration": "", "days": 0, "hours": 0, "min": 0, "imageFile1": "", "imageFile2": "", "binaryFile1": "", "binaryFile2": "", "binaryArena" : "","experimentMode": "", "useDefaultNames": false, "robotSimulatorDetails": { "isValidation": true, "count": 0, "robots": [{ "aliasName": "" }] }, "robotArenaDetails": { "robots": [{ "name": "", "aliasName": "" }] } })
        // setImgRadioState('')
        setImgValidated(0)
        setSelectConfigEnable(false);
        setRobotCount(props.robotArenas.length);
        createRobotTable(props.robotArenas.length);
        setFirstModalOpen(false);
        setModalPageNo(1)
        addNames(props.robotArenas)
    };

    function gotoFirstModal() {

        setModalPageNo(1)
        document.querySelector('#create-exp-title').textContent = 'New Experiment (Step 1 of 2)';
        document.querySelector('.create-exp-first').style.display = 'block';
        document.querySelector('.create-exp-second').style.display = 'none';

    };

    //code to get simulation experiment time
    function simualtionExpTime(){
         
    return axiosInstance({
                method: 'get',
                url: `${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/experiments/nextAvailable`,
            })
            .then((response) => {
                // console.log(response);
                let date = response.data.dateTime
                let format = new Date(date*1000);
                return format.toLocaleString();
            })
            .catch((err) => {
                // console.log(err);
                ErrorPopUp("Fetching Experiment Time Failed")
                return 'ASAP';
            })
    }

    function newExperiment() {
        if (createExp.experimentMode == "simulation") {
            simualtionExpTime()
                .then((response)=>{
                    // console.log(response);
                    swal({
                        title: "Are you sure?",
                        // text: `Experiment is schedule to run at ${createExp.days}D:${createExp.hours}H:${createExp.min}M tentatively.Email notification will be sent when the experiment is rescheduled or about to start`,
                        text: `Experiment is schedule to run at ${response} tentatively.Email notification will be sent when the experiment is rescheduled or about to start`,
                        icon: "info",
                        buttons: true,
                        dangerMode: true,
                        className: "dangerSwal"
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            submitExperiment()
                        }
                    });
            })
        } else if (createExp.experimentMode == "robot_arena") {
            swal({
                title: "Are you sure?",
                text: 'Experiment will be queued and email notification will be sent when the experiment starts.',
                icon: "info",
                buttons: true,
                dangerMode: true,
                className: "dangerSwal"
            })
                .then((willDelete) => {
                    if (willDelete) {
                        submitArenaExperiment()
                    }
                });
        }
    }

    //Storing Experiment Name 
    function expName(e) {
        const name = e.target.value;
        setCreateExp((prevState) => {
            prevState.name = name;
            return {
                ...prevState
            };
        });
    }

    //Storing Experiment Description
    function expDescription(e) {
        const description = e.target.value;
        setCreateExp((prevState) => {
            prevState.description = description;
            return {
                ...prevState
            };
        });
    }

    //Storing Experiment Days
    function experimentDays(e) {
        // setExpDays(e.target.value)
        const name = e.target.name
        const value = e.target.value
        // console.log(e.target.value)
        setCreateExp((prevState) => {
            prevState[name] = value;
            return {
                ...prevState
            };
        });
    }

    //Storing Selected Controller Image
    function configImageSelect(e) {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var path = optionElement.getAttribute('path');
        var tag = optionElement.getAttribute('tag');
        var type = optionElement.getAttribute('type');
        // console.log(path);
        // console.log(tag);
        // console.log(type);

        let validated = e.target.value
        if (validated == 'false') {
             setImgValidated(1)
            //setImgValidated(2)
        } else if (validated == 'true') {
            setImgValidated(2)
        } else {
            setImgValidated(0)
        }

        setCreateExp((prevState) => {
            prevState.imageFile1 = { "repoPath": path, "tag": tag, "type": type };
            return {
                ...prevState
            };
        });
    }

    //Storing Simulation Image Selection 
    function simulationImage1Select(e) {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var path = optionElement.getAttribute('path');
        var tag = optionElement.getAttribute('tag');
        var type = optionElement.getAttribute('type');
        // console.log(path);
        // console.log(tag);
        // console.log(type);
        setCreateExp((prevState) => {
            prevState.imageFile2 = { "repoPath": path, "tag": tag, "type": type };
            return {
                ...prevState
            };
        });
    }

    //Storing Simulation Image Selection 
    function simulationWorldSelect(e) {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var path = optionElement.getAttribute('path');
        var type = optionElement.getAttribute('type');
        setCreateExp((prevState) => {
            prevState.binaryFile2 = { "path": path, "type": type };
            return {
                ...prevState
            };
        });
    }

    function simulationRobotCount(e) {
        let input = e.target.value;

        if(input <= 0){
            setRobotCount(0);
            setCreateExp((prevState) => {
                prevState.robotSimulatorDetails['count'] = 0;
                return {
                    ...prevState
                };
            });
            createRobotTable(0);
        }

        if (input > 0) {
            setCreateExp((prevState) => {
                prevState.robotSimulatorDetails['count'] = parseInt(input);
                return {
                    ...prevState
                };
            });

            const re = /^[0-9\b]+$/;
            if (input === '' || re.test(input)) {
                setRobotCount(input)
                setTimeout(() => {
                    createRobotTable(input)
                }, 500);
            }
        }

    }

    //code to generate robotos table and save in usestate
    function createRobotTable(input) {
        // console.log(input)
        if (input > 0) {
            let addinput = parseInt(input) + 1
            const array = [];
            for (let i = 0; i < addinput; i++) {
                const obj = {};
                obj['inputDisabled'] = true
                obj['checked'] = false
                obj['checkDisabled'] = true
                if (i > 0) {
                    obj['name'] = `Robot ${i}`
                    if (i < 10) {
                        obj['id'] = `rb0${i}`
                    } else {
                        obj['id'] = `rb${i}`
                    }
                    array.push(obj)
                }

            }

            setRobotsData(array)
        } else {
            setRobotsData([])
        }
    }

    //Storing Selected Configuration
    function configurationSelect(e) {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var path = optionElement.getAttribute('path');
        var type = optionElement.getAttribute('type');
        var value = optionElement.getAttribute('value');
        // console.log(value);
        if(value == ''){
            setCreateExp((prevState) => {
                prevState.binaryFile1 = '';
                return {
                    ...prevState
                };
            });
        }else{
            setCreateExp((prevState) => {
                prevState.binaryFile1 = { "path": path, "type": type };
                return {
                    ...prevState
                };
            });
        }
    }

    //code to store selected binary file for robotic arena
    function binarySelectArena(e){
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index]
        var path = optionElement.getAttribute('path');
        var type = optionElement.getAttribute('type');
        var value = optionElement.getAttribute('value');
        // console.log(value);
        if(value == ''){
            setCreateExp((prevState) => {
                prevState.binaryArena = '';
                return {
                    ...prevState
                };
            });
        }else{
            setCreateExp((prevState) => {
                prevState.binaryArena = { "path": path, "type": type };
                return {
                    ...prevState
                };
            });
        }
    }

    //Storing simulator validation mode checkbox is selected or not
    function simulatorValidationMode(e) {
        let check = e.target.checked
        // console.log(check)
        if (check == true) {
            let count = props.robotArenas.length
            createRobotTable(count);
            setRobotCount(count);
            setCreateExp((prevState) => {
                prevState.robotSimulatorDetails['count'] = count;
                return {
                    ...prevState
                };
            });
            setWorldFileSelection(true)
            setRobotCountEntry(true)
            setvalidationboxCheck(!validationboxCheck)
        } else {
            setWorldFileSelection(false)
            setRobotCountEntry(false)
            setvalidationboxCheck(!validationboxCheck)
        }
    }

    //Storing simulator Robotos Labels checkbox is selected or not
    function simulatorRobotLabels(e) {
        let check = e.target.checked
        // console.log(check)
        if (check == true) {
            setRobotLabelSelection(true)
            setValidationRobotsCheck(!validationRobotsCheck)
            createRobotTable(robotCount);
        } 
        if(check == false) {
            setRobotLabelSelection(false)
            setValidationRobotsCheck(!validationRobotsCheck)

            let olddata = [...robotsData];
            olddata.map((item) => {
                // console.log(item);
                if (item) {
                    item['checkDisabled'] = false;
                    item['inputDisabled'] = true;
                    item['checked'] = false;
                }
            })
            setRobotsData(olddata)
        }
    }

    //Stroing Run mode option selected
    function runModeSelect(e) {
        let input = e.target.value;
        setRunMode(e.target.value)
        if (input == 'simulation') {
            setCreateExp((prevState) => {
                prevState.experimentMode = 'simulation';
                return {
                    ...prevState
                };
            });
            setCreateExp((prevState) => {
                prevState.binaryFile1 = '';
                return {
                    ...prevState
                };
            });
            setSelectConfigEnable(true);
            setSelectBinaryArena(false);
        } else if (input == 'robot_arena') {
            setCreateExp((prevState) => {
                prevState.experimentMode = 'robot_arena';
                return {
                    ...prevState
                };
            });
            setSelectConfigEnable(true);
            setSelectBinaryArena(true);
        } else {
            setCreateExp((prevState) => {
                prevState.experimentMode = '';
                return {
                    ...prevState
                };
            });
            setCreateExp((prevState) => {
                prevState.binaryFile1 = '';
                return {
                    ...prevState
                };
            });
            setSelectConfigEnable(false);
            setSelectBinaryArena(false);
        }
    }

    //Code to prepare dynamic genrated Robotic Labelss
    function filterSimulationRobots() {
        const array = [];
        for (let i = 0; i < robotsData.length; i++) {
            const obj = {};
            obj['aliasName'] = robotsData[i].id
            array.push(obj)
        }

        return array
    }

    //code to filter checked arenas
    function filterArenaRobots() {
        // if (defaultAreanasCheck == false) {
            const array = [];
            for (let i = 0; i < robotArenas.length; i++) {
                if (robotArenas[i].checked == true) {
                    const obj = {};
                    obj['name'] = robotArenas[i].name
                    obj['aliasName'] = robotArenas[i].id
                    array.push(obj)
                }
            }
            // console.log(array)
            //if(array.length == 0){
                // console.log(array)
            //    ErrorPopUp("Select Arena");
            //}else{
                // console.log(array)
                return array
            //}
        // }

        // if (defaultAreanasCheck == true) {
        //     const array = [];
        //     for (let i = 0; i < originalArenas.length; i++) {
        //         const obj = {};
        //         obj['name'] = originalArenas[i].name
        //         obj['aliasName'] = originalArenas[i].id
        //         array.push(obj)
        //     }

        //     return array
        // }

    }

    //Storing simulator Robotos Labels checkbox is selected or not
    function defaultRobotArenas(e) {
        let check = e.target.checked
        // console.log(check)
        // console.log(originalArenas);
        if (check == true) {
            setDefaultAreanasCheck(true);
            setDefaultRobotLabels(robotArenas)
        }

        if (check == false) {
            setDefaultAreanasCheck(false);
            // let olddata = [...robotArenas];
            // olddata.map((item) => {
            //     item['inputDisabled'] = true;
            //     item['checkDisabled'] = false;
            //     item['checked'] = false;
            // })

            // setRobotArenas(olddata)
        }
    }

    //code to update selected robot and enable name edit
    function editRobotName(e) {
        let { checked } = e.target;
        let robotid = e.target.getAttribute('robotid');
        // console.log(robotid);

        if (checked == true) {
            let olddata = [...robotArenas];
            olddata.map((item) => {
                // console.log(item);
                if (item.name == robotid) {
                    // console.log("found")
                    item['inputDisabled'] = false;
                    item['checked'] = true;
                }
            })

            setRobotArenas(olddata)
        }

        if (checked == false) {
            let olddata = [...robotArenas];
            olddata.map((item) => {
                // console.log(item);
                if (item.name == robotid) {
                    // console.log("found")
                    item['inputDisabled'] = true;
                    item['checked'] = false;
                }
            })

            setRobotArenas(olddata)
        }

        // let value = e.target.value;
        // console.log(robotid);
    }

    //code to disable or enable robot name input
    function editSimRobotName(e){
        let { checked } = e.target;
        let robotid = e.target.getAttribute('index');
        // console.log(robotid);
        if (checked == true) {
            let olddata = [...robotsData];
            olddata.map((item,index) => {
                // console.log(item);
                if (index == robotid) {
                    // console.log("found")
                    item['inputDisabled'] = false;
                    item['checked'] = true;
                }
            })

            // console.log(olddata);
            setRobotsData(olddata)
        }

        if (checked == false) {
            let olddata = [...robotsData];
            olddata.map((item,index) => {
                // console.log(item);
                if (index == robotid) {
                    // console.log("found")
                    item['inputDisabled'] = true;
                    item['checked'] = false;
                }
            })
            // console.log(olddata);
            setRobotsData(olddata)
        }
    }

    //code to update Robot Name
    function handleNameChange(e) {
        setDefaultAreanasCheck(false)
        let robotid = e.target.getAttribute('robotid');
        let value = e.target.value;
        // console.log(robotid);
        // console.log(value);

        const textFieldRegex = /^[A-Za-z0-9\_]+$/;
		if (!textFieldRegex.test(value)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}

        let olddata = [...robotArenas];
        olddata.map((item) => {
             // console.log(item);
            if (item.name == robotid) {
                // console.log("found")
                item['id'] = value;
            }
        })

        setRobotArenas(olddata);
    }

    //code to update Simulator Robot Name
    function handleSimNameChange(e) {
        setValidationRobotsCheck(false)
        let robotid = e.target.getAttribute('index');
        let value = e.target.value;
        // console.log(robotid);
        // console.log(value);

        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(e.target.name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}

        let olddata = [...robotsData];
        olddata.map((item,index) => {
            // console.log(item);
            if (index == robotid) {
                // console.log("found")
                item['id'] = value;
            }
        })

        setRobotsData(olddata);
    }
    //Submit the experiment data
    function submitExperiment(e) {

        let expCalcDays = createExp.days * 24 * 60;
        let expCalcHours = createExp.hours * 60;
        let expCalcMin = createExp.min;
        let expTotalMin = parseInt(`${expCalcDays}`) + parseInt(`${expCalcHours}`) + parseInt(`${expCalcMin}`)

        let binaryFileCheck = function () {
            if (createExp.binaryFile1 == '' && createExp.binaryFile2 == '') {
                return []
            } else if (createExp.binaryFile1 == '' && createExp.binaryFile2 !== '') {
                return [createExp.binaryFile2]
            } else if (createExp.binaryFile1 !== '' && createExp.binaryFile2 == '') {
                return [createExp.binaryFile1]
            } else {
                return [createExp.binaryFile1, createExp.binaryFile2]
            }
        }

        let imageFileCheck = function () {
            if (createExp.imageFile1 == '' && createExp.imageFile2 == '') {
                return []
            } else if (createExp.imageFile1 == '' && createExp.imageFile2 !== '') {
                return [createExp.imageFile2]
            } else if (createExp.imageFile1 !== '' && createExp.imageFile2 == '') {
                return [createExp.imageFile1]
            } else {
                return [createExp.imageFile1, createExp.imageFile2]
            }
        }

        const expData = {
            "name": createExp.name,
            "description": createExp.description,
            "duration": expTotalMin,
            "imageFiles": imageFileCheck(),
            "binaryFiles": binaryFileCheck(),
            "experimentMode": createExp.experimentMode,
            "useDefaultNames": createExp.useDefaultNames,
            "robotSimulatorDetails": {
                "isValidation": createExp.robotSimulatorDetails.isValidation,
                "count": createExp.robotSimulatorDetails.count,
                "robots": filterSimulationRobots()
            },
            "robotArenaDetails": {
                "robots": []
            }
        }

        // console.log(expData);

        axiosInstance({
            method: 'post',
            url: `${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/${props.project_id}/experiments`,
            data: expData,
        })
            .then((response) => {
                // console.log(response);
                PopUp("Experiment Created Successfully")
            })
            .then(() => {
                setFirstModalOpen(false);
                setModalPageNo(1)
                setCreateExp({ "name": "", "description": "", "duration": "", "days": 0, "hours": 0, "min": 0, "imageFile1": "", "imageFile2": "", "binaryFile1": "", "binaryFile2": "", "experimentMode": "", "useDefaultNames": false, "robotSimulatorDetails": { "isValidation": true, "count": 0, "robots": [{ "aliasName": "" }] }, "robotArenaDetails": { "robots": [{ "name": "", "aliasName": "" }] } })
                setRobotCount(props.robotArenas.length);
                setCreateExp((prevState) => {
                    prevState.robotSimulatorDetails['count'] = 0;
                    return {
                        ...prevState
                    };
                });
                createRobotTable(props.robotArenas.length);
            })
            .then(
                sendUpdateState()
            )
            .catch((err) => {
                 //console.log(err);   
                 if(err.response){
                    ErrorPopUp(err.response.data.detail)
                 }else{
                    ErrorPopUp("Request Failed. Try Again")
                 }  
            })

    }


    //code to submit arena experiment
    function submitArenaExperiment(e) {
        let expCalcDays = createExp.days * 24 * 60;
        let expCalcHours = createExp.hours * 60;
        let expCalcMin = createExp.min;
        let expTotalMin = parseInt(`${expCalcDays}`) + parseInt(`${expCalcHours}`) + parseInt(`${expCalcMin}`)

        let binaryFileCheck = function () {
            let binArray = []
            if (createExp.binaryFile1 !== '') {
                 binArray.push(createExp.binaryFile1)
            }

            if(createExp.binaryArena !== ''){
                binArray.push(createExp.binaryArena)
            }
            // console.log(binArray)
            return binArray;
            // if (createExp.binaryFile1[0].path !== null) {
            //     return [createExp.binaryFile1]
            // }
        }

        let imageFileCheck = function () {
            if (createExp.imageFile1 == '') {
                return []
            }else{
                return [createExp.imageFile1]
            }
            // if (createExp.imageFile1.repoPath !== null) {
            //     return [createExp.imageFile1]
            // }
        }

        const expData = {
            "name": createExp.name,
            "description": createExp.description,
            "duration": expTotalMin,
            "imageFiles": imageFileCheck(),
            "binaryFiles": binaryFileCheck(),
            "experimentMode": createExp.experimentMode,
            "useDefaultNames": createExp.useDefaultNames,
            "robotSimulatorDetails": {},
            "robotArenaDetails": {
                "robots": filterArenaRobots()
            }
        }

        // console.log(expData);

        if(expData.robotArenaDetails.robots == undefined){
            // console.log("Robotarenas empty")
            return
        }else{
            // console.log("still arenas found")
        
            axiosInstance({
                method: 'post',
                url: `${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/projects/${props.project_id}/experiments`,
                data: expData,
            })
                .then((response) => {
                    // console.log(response);
                    PopUp("Experiment Created Successfully")
                })
                .then(() => {
                    setFirstModalOpen(false);
                    setModalPageNo(1)
                    setCreateExp({ "name": "", "description": "", "duration": "", "days": 0, "hours": 0, "min": 0, "imageFile1": "", "imageFile2": "", "binaryFile1": "", "binaryFile2": "", "binaryArena" : "","experimentMode": "", "useDefaultNames": false, "robotSimulatorDetails": { "isValidation": true, "count": 0, "robots": [{ "aliasName": "" }] }, "robotArenaDetails": { "robots": [{ "name": "", "aliasName": "" }] } })
                    setDefaultAreanasCheck(true);
                    setImgValidated(0)
                    setSelectConfigEnable(false);
                    // setRobotCount(0);
                    // setCreateExp((prevState) => {
                    //     prevState.robotSimulatorDetails['count'] = 0;
                    //     return {
                    //         ...prevState
                    //     };
                    // });
                    // createRobotTable(0);
                    sendUpdateState()
                })
                .catch((err) => {
                    // console.log(err);
                    ErrorPopUp("Request Failed. Try Again")
                })

            }   
     }

    // Code to refresh apis when child state changed
    const sendUpdateState = () => {
        setTimeout(() => {
            props.changeCurState()
        }, 500);
    }

    let expNameStatus = expNameCheck ? 'expNameEmpty' : 'expNameFilled';
    let expDescStatus = expDescCheck ? 'expDescEmpty' : 'expDescFilled';
    let expconfigImageStatus = expConfigImageCheck ? 'expConfigImageEmpty' : 'expConfigImageFilled';
    let expModeStatus = expModeCheck ? 'expModeEmpty' : 'expModeFilled';
    let expDurationStatus = expDurationCheck ? 'expDurationEmpty' : 'expDurationFilled';

    return (
        <>
            <Button className="btnDefault" onClick={() => openFirstModal()}>
                New Experiment
            </Button>

            <Modal show={firstModalOpen} onHide={() => closeAllModal()} animation={false} centered backdrop="static" keyboard={false} size={lgModal} >
                <Modal.Header closeButton>
                    <Modal.Title id="create-exp-title">New Experiment (Step {modalPageNo} of 2)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="Container">
                        <div className="row">
                            <div style={{ color: 'red'}}>
                                {error}
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-12 create-exp-first">
                                <label ><b>Details</b></label>
                                <div style={{ width: "100%" }}>
                                    <Form id="formPage" onSubmit={(e) => e.preventDefault(e)}>
                                        <Form.Group>
                                            <Form.Label>Experiment Name</Form.Label>
                                            <Form.Control className={expNameStatus} type="text" name="name" placeholder="Enter a name for your Experiment" onChange={(e) =>
                                                {
                                                    const value = e.target.value;
                                                    const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                                                    if (!textFieldRegex.test(value)) {
                                                        setError(e.target.name + ' is invalid');
                                                        setIsValid(false);
                                                    }else{
                                                        setError('');
                                                        setIsValid(true);
                                                    }
                                                    expName(e)
                                                } 
                                            } />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control className={expDescStatus} name="description" as="textarea" rows="5" placeholder="Enter a description for your Experiment" onChange={(e) => 
                                                {
                                                    const value = e.target.value;
                                                    const textFieldRegex = /^[A-Za-z0-9 \-]+$/;
                                                    if (!textFieldRegex.test(value)) {
                                                        setError(e.target.name + ' is invalid');
                                                        setIsValid(false);
                                                    }else{
                                                        setError('');
                                                        setIsValid(true);
                                                    }
                                                    expDescription(e)
                                                }
                                            } />
                                        </Form.Group>
                                    </Form>

                                    <label ><b>Duration</b></label>
                                    <div className={expDurationStatus}>
                                        <div className="create-exp-no-inp">
                                            <Form style={{ display: "flex" }}>
                                                <div>
                                                    <label >Days</label>
                                                    <input type="number" name="days" className="form-control" min="0" onChange={(e) => experimentDays(e)}></input>
                                                </div>
                                                <div>
                                                    <label >Hours</label>
                                                    <input type="number" name="hours" className="form-control" min="0" onChange={(e) => experimentDays(e)}></input>
                                                </div>
                                                <div>
                                                    <label >Min</label>
                                                    <input type="number" name="min" className="form-control" min="0" onChange={(e) => experimentDays(e)}></input>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                    <br />
                                    <Form.Group className={expconfigImageStatus}>
                                        <Form.Label>Select Controller Image</Form.Label>
                                        <Form.Control as="select" custom onChange={(e) => configImageSelect(e)}>
                                            <option value=''>Select Image</option>
                                            {props.controlerimg.length > 0 ? (
                                                <>
                                                    {props.controlerimg.map((item, index) => (
                                                        <>{item.artifacts.map((img, i) => (
                                                            <>{
                                                                (
                                                                    ((severity =='critical'&& (img.scanOverview.severity == 'High' ||img.scanOverview.severity == 'Medium'||img.scanOverview.severity == 'low' || img.scanOverview.severity == 'None' || img.scanOverview.severity == 'Unknown'))
                                                                    ||((severity =='high'&& ( img.scanOverview.severity == 'High' ||img.scanOverview.severity == 'Medium'||img.scanOverview.severity == 'low' || img.scanOverview.severity == 'None' || img.scanOverview.severity == 'Unknown'))) ||
                                                                    ((severity=='medium'&& (img.scanOverview.severity == 'Medium'||img.scanOverview.severity == 'low' || img.scanOverview.severity == 'None' ||img.scanOverview.severity == 'Unknown'))) 
                                                                    ||((severity=='low'&& (img.scanOverview.severity == 'low' || img.scanOverview.severity == 'None'|| img.scanOverview.severity == 'Unknown' ))) 
                                                                    ||((severity=='none'&& (img.scanOverview.severity == 'None' || img.scanOverview.severity == 'Unknown' )))
                                                                )   
                                                                    
                                                                )  ? ( <>
                                                                    <option value={img.validated} path={item.name} tag={img.tag} type={img.type}>{item.name}</option>
                                                                    </>) : (<></>
                                                                
                                                               )}  
                                                            </>
                                                        ))}
                                                        </>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <option value=''>No Images Found</option>
                                                </>
                                            )}
                                        </Form.Control>
                                    </Form.Group>

                                    {imgValidated == 1 ? (
                                        <>
                                            <Form.Group className={expModeStatus}>
                                                <Form.Label>Run On</Form.Label>
                                                <Form.Control as="select" custom onChange={(e) => runModeSelect(e)}>
                                                    <option value=''>Select Mode</option>
                                                    <option value='simulation'>Simulator</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                    }
                                    {imgValidated == 2 ? (
                                        <>
                                            <Form.Group className={expModeStatus}>
                                                <Form.Label>Run On</Form.Label>
                                                <Form.Control as="select" custom onChange={(e) => runModeSelect(e)}>
                                                    <option value=''>Select Mode</option>
                                                    <option value='simulation'>Simulator</option>
                                                    <option value='robot_arena'>Robot Arena</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                    }

                                    {selectConfigEnable == true ? (
                                        <>
                                            <Form.Group>
                                                <Form.Label>Select Configuration(Optional)</Form.Label>
                                                <Form.Control as="select" custom onChange={(e) => configurationSelect(e)}>
                                                    <option value=''>Select Configuration</option>
                                                    {props.configfiles.length > 0 ? (
                                                        <>
                                                            {props.configfiles.map((item, index) => (
                                                                <>
                                                                    <option value={item.validation} path={item.path} type={item.type}>{item.name}</option>
                                                                </>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option value=''>No Configurations Found</option>
                                                        </>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                    ) : null}

                                    {selectBinaryArena == true ? (
                                        <>
                                            <Form.Group>
                                                <Form.Label>Select Binary(Optional)</Form.Label>
                                                <Form.Control as="select" custom onChange={(e) => binarySelectArena(e)}>
                                                    <option value=''>Select Binary</option>
                                                    {props.binaryarenafiles.length > 0 ? (
                                                        <>
                                                            {props.binaryarenafiles.map((item, index) => (
                                                                <>
                                                                    <option value={item.validation} path={item.path} type={item.type}>{item.name}</option>
                                                                </>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option value=''>No Binaries Found</option>
                                                        </>
                                                    )}
                                                </Form.Control>
                                            </Form.Group>
                                        </>
                                    ) : null}
                                </div>
                                <div className="create-exp-footer">
                                    <Button className="btnNormal btnSpace" onClick={() => closeAllModal()}>
                                        Cancel
                                    </Button>
                                    <Button disabled={!isValid} type="submit" className="btnDefault" onClick={() => openSecondModal()}>
                                        Next
                                    </Button>
                                </div>
                                <p className="text-center">{modalPageNo} of 2</p>
                            </div>

                            <div className="col-md-12 col-sm-12 col-lg-12 create-exp-second" style={{ display: "none" }}>
                                <div style={{ width: "100%" }}>

                                    {
                                        runMode == 'simulation' ? (
                                            <>
                                                <label ><b>Simualtion Mode</b></label>
                                                <br />
                                                <Form.Group>
                                                    <Form.Check type="checkbox" label="Validation Mode" checked={validationboxCheck} onChange={(e) => simulatorValidationMode(e)} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Select Simulator Image (Optional)</Form.Label>
                                                    <Form.Control as="select" custom onChange={(e) => simulationImage1Select(e)} >
                                                        <option value=''>Select Image</option>
                                                        {props.radiosimimg.length > 0 ? (
                                                            <>
                                                                {props.radiosimimg.map((item, index) => (
                                                                    <>{item.artifacts.map((img, i) => (
                                                                        <>{img.scanOverview.severity == 'High' ? (<> </>) : (
                                                                            <>
                                                                             <option value={item.name} path={item.name} tag={img.tag} type={img.type}>{item.name}</option>
                                                                            </>
                                                                        )}</>
                                                                    ))}
                                                                    </>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value=''>No Images Found</option>
                                                            </>
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Select World File</Form.Label>
                                                    <Form.Control as="select" custom onChange={(e) => simulationWorldSelect(e)} disabled={worldFileSelection}>
                                                        <option value=''>Select World</option>
                                                        {props.configwordfiles.length > 0 ? (
                                                            <>
                                                                {props.configwordfiles.map((item, index) => (
                                                                    <>
                                                                        <option value={item.name} name={item.name} path={item.path} type={item.type}>{item.name}</option>
                                                                    </>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <option value=''>No Images Found</option>
                                                            </>
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Robot Count</Form.Label>
                                                    <Form.Control type="number" value={robotCount} disabled={robotCountEntry} onChange={(e) => simulationRobotCount(e)} />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Check type="checkbox" label="Use default robot labels (Robot ids will be labeled as rb01, rb02, rb03....)" checked={validationRobotsCheck} onChange={(e) => simulatorRobotLabels(e)} />
                                                </Form.Group>
                                                <div className="RoboticLabelsTable">
                                                    <Table responsive bordered className="projectTableData">
                                                        <thead>
                                                            <tr>
                                                                <th>Robot</th>
                                                                <th>Label</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {robotsData.length > 0 ? (
                                                                <>
                                                                    {robotsData.map((item, index) => (
                                                                        <tr key={index} >
                                                                            <td>
                                                                                {/* {item.name} */}
                                                                                <Form.Check type="checkbox" checked={item.checked} label={item.name} robotid={item.name} index={index} onChange={(e) => editSimRobotName(e)} disabled={item.checkDisabled}/>
                                                                            </td>
                                                                            <td>
                                                                                {/* {item.id} */}
                                                                                <input type="text" name="simname" className="form-control form-control-sm" robotid={item.name} index={index} value={item.id} onChange={(e) => handleSimNameChange(e)} disabled={item.inputDisabled} />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <tr>
                                                                        <td>No data</td>
                                                                        <td>No data</td>
                                                                    </tr>
                                                                </>
                                                            )}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        ) : null
                                    }

                                    {
                                        runMode == 'robot_arena' ? (
                                            <>
                                                <label ><b>Arena Mode</b></label>
                                                <Form.Group>
                                                    <Form.Check type="checkbox" label="Use default robot labels (Robot ids will be labeled after hostname with custom syntax)" checked={defaultAreanasCheck} onChange={(e) => defaultRobotArenas(e)} />
                                                </Form.Group>
                                                <div className="RoboticAreanasTable">
                                                    <Table responsive bordered className="projectTableData">
                                                        <thead>
                                                            <tr>
                                                                <th>Robot</th>
                                                                <th>Label</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {robotArenas.length > 0 ? (
                                                                <>
                                                                    {robotArenas.map((item, index) => (
                                                                        <tr key={index} >
                                                                            <td>
                                                                                {/* <Form.Check type="checkbox" label={item.name} robotid={item.name} index={index} onChange={(e) => editRobotName(e)} checked={item.checked} disabled={item.checkDisabled} /> */}
                                                                                <Form.Check type="checkbox" label={item.name} robotid={item.name} index={index} onChange={(e) => editRobotName(e)} />
                                                                            </td>
                                                                            <td>
                                                                                <input type="text" name="name" className="form-control form-control-sm" robotid={item.name} index={index} value={item.id} onChange={(e) => handleNameChange(e)} disabled={item.inputDisabled} />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <tr>
                                                                        <td>No data</td>
                                                                        <td>No data</td>
                                                                    </tr>
                                                                </>
                                                            )}

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </>
                                        ) : null
                                    }
                                </div>
                                <br />
                                <div className="create-exp-footer">
                                    <Button className="btnNormal btnSpace" onClick={() => gotoFirstModal()}>
                                        Back
                                    </Button>
                                    <Button disabled={!isValid} className="btnDefault" onClick={() => newExperiment()}>
                                        Create
                                    </Button>
                                </div>
                                <p className="text-center">{modalPageNo} of 2</p>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateExperiment
