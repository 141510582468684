import React, { useEffect, useContext } from 'react';
import { HashRouter as Router, Redirect, Route, Switch, NavLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Home from '../pages/Home/Home';
import SensorDashboard from '../pages/SensorDashboard/SensorDashboard';
import UcareDashboard from '../pages/UcareDashboard/UcareDashboard';
import SensorMap from '../pages/SensorDashboard/SensorMap';
import SMEForm from '../pages/SMEForm/SMEForm';
import SMEFormResult from '../pages/SMEForm/SMEFormResult';

//Umbrella components or pages import
import ProjectDashboard from '../pages/ProjectDashboard/ProjectDashboard';
import LoginPage from '../pages/Login/Login';
import { PrivateRoute } from './utils';
import NetworkDashboard from '../pages/NetworkDashboard/NetworkDashboard';
import Quota from '../pages/AdminDashboard/Quota';
import NodeInventory from '../pages/AdminDashboard/NodeInventory';
import ManageUsers from '../pages/AdminDashboard/ManageUsers';
import Project from '../pages/Project/Project';
import Experiment from '../pages/Experiment/Experiment';
import UsersRequest from '../pages/UsersRequest/UsersRequest';
import SearchUnProjects from '../pages/AdminDashboard/SearchUnProjects';
import SearchRnProjects from '../pages/RoboticNw/Admin/SearchRnProjects';
import UmbExperimentHistory from '../pages/AdminDashboard/UmbExperimentHistory';
//Robotics components or pages import
import RoboticsProjectsDashboard from '../pages/RoboticNw/RoboticsProjectsDashboard/RoboticsProjectsDashboard'
import RoboticsProjects from '../pages/RoboticNw/RoboticsProjects/RoboticsProjects';
import RoboticExperiment from '../pages/RoboticNw/Experiment/RoboticExperiment';
import ArenaNetworkDashboard from '../pages/RoboticNw/ArenaNetworkDashboard/ArenaNetworkDashboard';
import SimulationQueue from '../pages/RoboticNw/RoboticsQueue/SimulationQueue';
import ArenaQueue from '../pages/RoboticNw/RoboticsQueue/ArenaQueue';
import AdminArenaQueue from '../pages/RoboticNw/Admin/ArenaQueue';
import QuotaRn from '../pages/RoboticNw/Admin/QuotaRn';
import ManageUsersRn from '../pages/RoboticNw/Admin/ManageUsersRn';
import AdminOverride from '../pages/RoboticNw/Admin/AdminOverride';
import RobotExperimentHistory from '../pages/RoboticNw/Admin/RobotExperimentHistory';
//LoRa components or pages import
import LoraProjectsDashboard from '../pages/LoraNw/LoraProjectsDashboard/LoraProjectsDashboard';
import LoraOrgApiKeys from '../pages/LoraNw/LoraOrgApiKeys/LoraOrgApiKeys';
import ServiceProfiles from '../pages/LoraNw/ServiceProfiles/ServiceProfiles';
import EditServiceProfile from '../components/LoraNetworks/CreateServiceProfile/EditServiceProfile';
import DeviceProfiles from '../pages/LoraNw/DeviceProfiles/DeviceProfiles';
import EditDeviceProfileTab from '../components/LoraNetworks/CreateDeviceProfile/EditDeviceProfileTab';
import Applications from '../pages/LoraNw/Applications/Applications';
import AppDetails from '../components/LoraNetworks/AppDetails/AppDetails';
import HTTPCreate from '../components/LoraNetworks/AppDetails/Integrations/Http/HTTPCreate';
import InfluxDBCreate from '../components/LoraNetworks/AppDetails/Integrations/InfluxDB/InfluxDBCreate';
import CreateDevice from '../components/LoraNetworks/AppDetails/Devices/CreateDevice';
import DeviceInfo from '../components/LoraNetworks/AppDetails/Devices/DeviceInfo';
import FuotaCreate from '../components/LoraNetworks/AppDetails/Devices/Fuota/FuotaCreate'
import UsersOrgRequest from '../pages/LoraNw/UsersOrgRequest/UsersOrgRequest';
import UsersOrgApprove from '../pages/LoraNw/UsersOrgApprove/UsersOrgApprove';
import LoraAdmin from '../pages/LoraNw/Admin/LoraAdmin';
import InfluxDBEditCard from '../components/LoraNetworks/AppDetails/Integrations/InfluxDB/InfluxDBEditCard';
import HTTPCardEdit from '../components/LoraNetworks/AppDetails/Integrations/Http/HTTPCardEdit';
import Profile from '../pages/Home/Profile';
import { Spinner } from 'react-bootstrap';

export const AppRouter = () => {
	const [, initialized] = useKeycloak();

	if (!initialized) {
		return (
		  <div
			style={{
			  display: 'flex',
			  justifyContent: 'center',
			  alignItems: 'center',
			  height: '100vh',
			}}
		  >
			<Spinner animation="border" variant="primary" /> Loading...
		  </div>
		);
	  }

	return (
		<Router>
			<Switch>

				<Redirect exact from="/" to="/home" />
				<PrivateRoute exact path="/home" component={Home} />
				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute exact path="/sensormap" component={SensorMap} />
				<PrivateRoute exact path="/sensordashboard" component={SensorDashboard} />
				<PrivateRoute exact path="/smeform" component={SMEForm} />
				<PrivateRoute exact path="/smeformresult" component={SMEFormResult} />
				<PrivateRoute exact path="/ucaredashboard" component={UcareDashboard} />
				{/* Umbrella Dashboard Pages*/}
				<PrivateRoute exact path="/projects" component={ProjectDashboard} />
				<PrivateRoute exact path="/projects/:id" component={Project} />
				<PrivateRoute exact path="/networks" component={NetworkDashboard} />
				<PrivateRoute exact path="/projects/:id/experiments/:id" component={Experiment} />
				<PrivateRoute exact path="/umbrella/userrequest" component={UsersRequest} />
				<PrivateRoute exact path="/admin/quota" component={Quota} />
				<PrivateRoute exact path="/admin/nodeinventory" component={NodeInventory} />
				<PrivateRoute exact path="/admin/manageusers" component={ManageUsers} />
				<PrivateRoute exact path="/admin/searchun" component={SearchUnProjects} />
				<PrivateRoute exact path="/admin/umbexperimenthistory" component={UmbExperimentHistory} />


				{/* Robotics Dashboard Pages*/}
				<PrivateRoute exact path="/robotics" component={RoboticsProjectsDashboard} />
				<PrivateRoute exact path="/robotics/projects/:id" component={RoboticsProjects} />
				<PrivateRoute exact path="/robotics/projects/:id/experiments/:id" component={RoboticExperiment} />
				<PrivateRoute exact path="/arenanetworks" component={ArenaNetworkDashboard} />
				<PrivateRoute exact path="/robotics/simulationqueue" component={SimulationQueue} />
				<PrivateRoute exact path="/robotics/arenaqueue" component={ArenaQueue} />
				<PrivateRoute exact path="/admin/robotics/arenaqueue" component={AdminArenaQueue} />
				<PrivateRoute exact path="/admin/robotics/manageusers" component={ManageUsersRn} />
				<PrivateRoute exact path="/admin/robotics/quota" component={QuotaRn} />
				<PrivateRoute exact path="/admin/robotics/searchrn" component={SearchRnProjects} />
				<PrivateRoute exact path="/admin/robotics/adminoverride" component={AdminOverride} />
				<PrivateRoute exact path="/admin/robotics/experimenthistory" component={RobotExperimentHistory} />
				{/* LoRa Dashboard Pages*/}
				<PrivateRoute exact path="/lora" component={LoraProjectsDashboard} />
				<PrivateRoute exact path="/lora/apikeys" component={LoraOrgApiKeys} />
				<PrivateRoute exact path="/lora/service-profiles" component={ServiceProfiles} />
				<PrivateRoute exact path="/lora/service-profiles/:id/edit" component={EditServiceProfile} />
				<PrivateRoute exact path="/lora/device-profiles" component={DeviceProfiles} />
				<PrivateRoute exact path="/lora/userorgrequest" component={UsersOrgRequest} />
				<PrivateRoute exact path="/lora/orguserrequests" component={UsersOrgApprove} />
				<PrivateRoute exact path="/lora/applications" component={Applications} />
				<PrivateRoute exact path="/lora/applications/:id" component={AppDetails} />
				<PrivateRoute exact path="/lora/applications/:id/create" component={CreateDevice} />
				<PrivateRoute exact path="/lora/applications/:id/http/create" component={HTTPCreate} />
				<PrivateRoute exact path="/lora/applications/:id/influxdb/create" component={InfluxDBCreate} />
				<PrivateRoute exact path="/lora/applications/:id/FuotaCreate/create" component={FuotaCreate} />
				<PrivateRoute exact path="/lora/applications/:id/deviceinfo/:id" component={DeviceInfo} />
				<PrivateRoute exact path="/lora/admin/userrequests" component={LoraAdmin} />
				<PrivateRoute exact path="/lora/device-profiles/:id/edit" component={EditDeviceProfileTab} />
				<PrivateRoute exact path="/lora/applications/:id/influxdb/edit" component={InfluxDBEditCard} />
				<PrivateRoute exact path="/lora/applications/:id/http/edit" component={HTTPCardEdit} />

				{/* <Route path='/' render = {() => <div>404 Page Not found</div>}/> */}
				<Route path="/login" component={LoginPage} />
				<Route path='*' exact={true} component={Home} />
			</Switch>
		</Router>
	);
};
