import React from 'react';
import './App.css';
import { AppRouter } from './routes';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from '@react-keycloak/web';
import * as jwt_decode from 'jwt-decode';
import * as axios from 'axios';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';


const keycloak = new Keycloak({
	realm: process.env.REACT_APP_KEYCLOAK_REALM,
	url: process.env.REACT_APP_KEYCLOAK_URL,
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

const keycloakProviderInitConfig = {
	onLoad: 'login-required',
};

const theme = createTheme({
	typography: {
		"fontFamily": `"Poppins", sans-serif`,		
	   }
});


class App extends React.PureComponent {
	onKeycloakEvent = (event, error) => {
		// console.log('onKeycloakEvent', event, error);
	};

	onKeycloakTokens = (tokens) => {
		// console.log('onKeycloakTokens', tokens);
		const decoded = jwt_decode(tokens.idToken);
		// console.log(decoded);

		localStorage.setItem('name', decoded.name);
		localStorage.setItem('username', decoded.preferred_username);
		localStorage.setItem('useremail', decoded.email);
		localStorage.setItem('roles', JSON.stringify(decoded.roles));
		localStorage.setItem('usertoken', tokens.token)

		axios({
			method: 'post',
			url: `${process.env.REACT_APP_PROJECT_URL}/users/find`,
			// url: `http://34.89.72.62/cr/api/v1/users?search=${decoded.preferred_username}`,
			headers: { 'Authorization': `Bearer ${tokens.token}` },
			data: {
				userName: decoded.preferred_username,
			}
		}).then(response => {
			let user = response.data;
			//   console.log(user);
			let currentObj;
			async function userCheck() {
				for (let index = 0; index < user.length; index++) {
					// console.log(user[index]);
					if (user[index].userName == decoded.preferred_username) {
						// console.log(decoded.preferred_username)
						currentObj = user[index];
						return true
					}
				}
				return false
			}

			userCheck().then(
				function (value) {
					//   console.log(value)
					if (value == true) {
						//   console.log(currentObj.userId);
						localStorage.setItem('userid', currentObj.userId);
					} else {
						axios({
							method: 'post',
							url: `${process.env.REACT_APP_PROJECT_URL}/users/onboard`,
							headers: { 'Authorization': `Bearer ${tokens.token}` },
							data: {
								"userName": decoded.preferred_username
							}
						})
					}
				}
			);

		}).catch(err => {
			axios({
				method: 'post',
				url: `${process.env.REACT_APP_PROJECT_URL}/users/onboard`,
				headers: { 'Authorization': `Bearer ${tokens.token}` },
				data: {
					"userName": decoded.preferred_username
				}
			})
			// console.log(err)
		});
		// console.log(decoded);
	};

	render() {
		return (
			<>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<KeycloakProvider
						keycloak={keycloak}
						initConfig={keycloakProviderInitConfig}
						onEvent={this.onKeycloakEvent}
						onTokens={this.onKeycloakTokens}
					>
						<div className="App">
							<AppRouter />
						</div>
					</KeycloakProvider>
				</ThemeProvider>
			</>
		);
	}
}

export default App;
