import React from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import swal from 'sweetalert';
import { useAxios } from '../../../../../utils/Axios';
import PopUp from '../../../../UIElements/PopUp'
import ErrorPopUp from '../../../../UIElements/ErrorPopup'
import influxImg from '../../../../../assests/integrations/influxdb.png';

function InfluxDBCard(props) {
    const axiosInstance = useAxios();

     //code to delete HTTP 
     function deleteInfluxDB(e){
        e.preventDefault();
        swal({
			title: "Are you sure?",
			text: "InfluxDB Integration will be deleted",
			icon: "warning",
			buttons: true,
			dangerMode: true,
			className: "dangerSwal"
		})
			.then((willDelete) => {
				if (willDelete) {
					axiosInstance
						.delete(`${process.env.REACT_APP_LORA_URL}/applications/${props.applicationID}/integrations/influxdb`)
						.then((response) => {
							PopUp("Success");
							props.status(true);
						})
						.catch((err) => {
							// console.log(err);
                            ErrorPopUp("Deleting Failed. Try Again");
                            // props.status(true);
						});
				}
			});
    }

    return (
        <Col md={4} lg={3} className="cardsCol">
            <Card style={{ width: '18rem' }}>
                <Card.Body>
                    <Card.Title>
                        <img src={influxImg} className="integrationImages"/>
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">InfluxDB</Card.Subtitle>
                    <Card.Text>
                        The InfluxDB integration writes events into an InfluxDB time-series database.
                    </Card.Text>
                    {props.options.integrations == true ? (
                        <>
                            {!!props.add && <Card.Link href={`#/lora/applications/${props.applicationID}/influxdb/create`}>Add</Card.Link>}
                            {!!props.edit && 
                                <>
                                    <Card.Link href={`#/lora/applications/${props.applicationID}/influxdb/edit`}>Edit</Card.Link>
                                    <Card.Link href="#" onClick={(e)=>deleteInfluxDB(e)}>Delete</Card.Link>
                                </>
                            }
                        </>
                    ) : null}
                </Card.Body>
            </Card>
        </Col>

    )
}

export default InfluxDBCard
