import React from 'react';
import ArenaQueueTable from '../../../components/RoboticsNetworks/RoboticsQueueTables/ArenaQueueTable';
import RoboticsLayout from '../../../components/RoboticsNetworks/RoboticsLayout/RoboticsLayout'
import { useAxios } from '../../../utils/Axios';
import { useEffect, useState, useContext } from 'react';
import Context from '../../../store/Context'
import { ToastContainer, Zoom } from 'react-toastify';


const ArenaQueue = (props) => {

	const [expQueue, setExpQueue] = useState([]);

	const axiosInstance = useAxios();
	const { state, actions } = useContext(Context);

	useEffect(() => {
		fetchExpQueue();
	}, [axiosInstance]);

	const fetchExpQueue = () => {

		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_ROBOT_EXPERIMENT_URL}/robotarena-queue`)
				.then((response) => {
					// console.log(response);
					fetchProjects(response.data);
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				})
				.catch((err) => {
					// console.log(err);
					setExpQueue([{"id" : "123","duration": 30,"status": 'running'},{"id" : "456","duration": 20,"status": 'pending'},{"id" : "789","duration": 20,"status": 'cancelled'}])
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}
	};
	//code to fetch projects and store project ides to compare with experiment project id's
	function fetchProjects(inputs){
		if (axiosInstance) {
			actions({ type: 'setState', payload: { ...state, blocking: true } })
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/ra/projects`)
				.then((response) => {
					actions({ type: 'setState', payload: { ...state, blocking: false } });
					// console.log(response.data);
					// setExpQueue(inputs);
					checkCancelBtnRequired(response.data,inputs)
				})
				.catch((err) => {
					// console.log(err);
					// setExpQueue([{"experimentId" : "123","duration": 30,"status": 'running'},{"experimentId" : "456","duration": 20,"status": 'pending'},{"experimentId" : "789","duration": 20,"status": 'cancelled'}])
					actions({ type: 'setState', payload: { ...state, blocking: false } })
				});

		}

	}

	//add cancel status to array
	function checkCancelBtnRequired(projects, simulationData){
		// console.log(projects);
		// console.log(simulationData);

		let projectIds = [];
		for(let i=0;i<projects.length;i++){
			projectIds.push(projects[i].projectId)
		}
		
		let data = simulationData.map((item)=>{
			if(projectIds.includes(item.projectId)){
				item['cancel'] = true
			}else{
				item['cancel'] = false
			}
			return item;
		})

		setExpQueue(data);
	}
	//To refresh Exp Queue list after 2 seconds when event is called 
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchExpQueue();
			}, 2000);

		};
	};

	return (
		<>
			<RoboticsLayout>
				<div className="col-md-12 col-sm-12 col-lg-12 no-padding">
					<div className="default-main-page">
						<>
							{expQueue.length > 0 ? (
								<>
									<div className="project-table-group">
										<ArenaQueueTable  data={expQueue} status={handleSubmit}/>
									</div>
								</>
							) : (
									<div className="default-content-center" style={{ margin: '3%' }}>
										You don't have Arena Experiment Queue
									</div>
								)}
						</>
					</div>
				</div>
			</RoboticsLayout>
			<ToastContainer draggable={false} transition={Zoom}/>
		</>
	)
}

export default ArenaQueue;