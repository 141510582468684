import React, { useState, useEffect } from 'react';
import MultipleConfigurations from './MultipleConfigurations'
import './CreateExperiment.css';
import Button from 'react-bootstrap/Button';
import { Modal,Accordion,Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CheckBox from '../CheckBox/CheckBox';
import CreateExpTable from '../CreateExpTable/CreateExpTable';
import { DatePicker, TimePicker } from 'react-tempusdominus-bootstrap';
import { useAxios } from '../../utils/Axios';
// import Radiobutton from '../UIElements/RadioButton/RadioButton';
import ImgRadioButtons from '../UIElements/RadioButton/ImgRadioButtons'
import BinRadioButtons from '../UIElements/RadioButton/BinRadioButtons'
import Geohash from 'latlon-geohash'
import PopUp from '../UIElements/PopUp'
import ErrorPopUp from '../UIElements/ErrorPopup'

function CreateExperiment(props) {

    const [modalPageNo, setModalPageNo] = useState(1);
    const [firstModalOpen, setFirstModalOpen] = useState(false);
    const [lgModal, setLgModal] = useState('');
    const [expNodeView, setExpNodeView] = useState(false);
    const [checked, unChecked] = useState(true);
    const [ expNodeAvail , setExpNodeAvail] = useState(false);
    const [ expRunIndefinit , setExpRunIndefinit] = useState(false);
    const [expNodeList , setExpNodeList] = useState([]);
    const [nodeOriginal , setNodeOriginal] = useState([]);
    const [nodeObjBackup , setNodeObjBackup] = useState({});
    const [singleConfiguration , setSingleConfiguration] = useState({})

    const [expNameCheck, setNameCheck] = useState(false);
    const [expDescCheck, setDescCheck] = useState(false);
    const [expTimeDateCheck, setExpTimeDateCheck] = useState(false);
    const [expDurationCheck, setExpDurationCheck] = useState(false);

    const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

    const [createExp, setCreateExp] = useState(
        {"name": '',
        "description": '',
        "schedule":
            {
                "startTime":'',
                "startDate":'',
                "duration": '',
                "days" : 0,
                "hours": 0,
                "min" : 0,
                "runOnNodeAvailability": false,
                "runIndefinitely": false
            },
        "configurations":[
            // {
            //     "name":'',
            //     "binaryFiles":[{"path": '',"type" : ''}],
            //     "imageFile":{"repoPath":'',"tag":'',"type": ''},
            //     "nodes": ''
            // }
        ]
    })

    useEffect(()=> {
        // console.log(expNodes)
        if(Object.keys( props.nodelist ).length > 0) {
            convertGeohash(props.nodelist)
        }
        //code to store images and binaries which is recieved in props
        // console.log(props.images);
        // console.log(props.binaries);
        // setAllImages(props.images);
        // setAllBinaries(props.binaries);
    },[props.nodelist])

    // useEffect(()=> {
    //     console.log(createExp)
    // },[createExp.configurations])

    // useEffect(()=> {
    //     console.log(nodeObjBackup);
    // },[nodeObjBackup,expNodeList,nodeOriginal])

    // useEffect(()=> {
    //     console.log(allBinaries);
    // },[allBinaries])

    const axiosInstance = useAxios();

    //Code to convert GPs Hash to location and adding required data to original json
	function convertGeohash (input) {
		let oldData = input
        // console.log(oldData)
        for (let i=0;i<oldData.data.nodes.length;i++){
            const locationSlice = oldData.data.nodes[i].gpsHash
            if(locationSlice !== null && locationSlice !== ''){
				const res = locationSlice.slice(0, 9)
				const latlon = Geohash.decode(`${res}`)
				let obj = oldData.data.nodes[i];
				Object.assign(obj, {lat: parseFloat(latlon.lat),lng: parseFloat(latlon.lon), selected: false});
			}else{
				let obj = oldData.data.nodes[i];
				Object.assign(obj, { lat: 51.5074, lng: 0.1278 ,selected: false});
			}
        }
        
        setExpNodeList(oldData);
        setNodeOriginal(oldData);
        setNodeObjBackup(oldData)
    }

    //Code to change nodelist based on selected image (raspberry pi or jetson nano)
    function filterNodesByImage(type) {
        // console.log(type);
        let olddata = expNodeList

        if (type == 'jetson-nano') {
            filterJetsonType()
        }

        if (type == 'raspberry-pi') {
           filterRaspberryType()
        }

        if (type == '') {
            setExpNodeList(olddata)
        }

    }

    //filter jetson supported node type images and storing in state
    function filterJetsonType(data){
        let jetsondata = expNodeList.data.nodes
        // console.log(jetsondata)
        let setnodes = [];
        let jetsonnodes = ["RSE"]
        jetsonnodes.map((item) => {
            jetsondata.map((b) => {
                b.selected = false
                if (b.nodeType == item) {
                    setnodes.push(b)
                }
            })
        })
        
        setExpNodeList((prevState) => ({
           data: {
               ...prevState.data,
                nodes : setnodes
           }
        }))

    }

    //filter raspberry supported node type images and storing in state
    function filterRaspberryType(data){
        let raspberrydata = expNodeList.data.nodes
        // console.log(raspberrydata)
        let raspiannodes = [];
        let raspinodes = ["RSE", "RSS"];
        raspinodes.map((item) => {
            raspberrydata.map((b) => {
                b.selected = false
                if (b.nodeType === item) {
                    raspiannodes.push(b)
                }
            })
        })
        setExpNodeList((prevState) => ({
            data: {
                ...prevState.data,
                 nodes : raspiannodes
            }
         }))
    }

    function openFirstModal () {
        setFirstModalOpen(true);
        setLgModal('');
    };

    function openSecondModal (){
        if(createExp.name == '' ){
            setNameCheck(true)
            return
        }else{
            setNameCheck(false)
        }

        if(createExp.description == '' ){
            setDescCheck(true)
            return
        }else{
            setDescCheck(false)
        }

        if(createExp.schedule.runOnNodeAvailability == false){
            if(createExp.schedule.startTime == '' || createExp.schedule.startTime == 0 || createExp.schedule.startDate == '' || createExp.schedule.startDate == 0){
                setExpTimeDateCheck(true)
                return
            }else{
                setExpTimeDateCheck(false)
            }
        }

        if(!createExp.schedule.days  && !createExp.schedule.hours && !createExp.schedule.min ){
            setExpDurationCheck(true)
            return
        }else{
            setExpDurationCheck(false)
        }
        
        setModalPageNo(2)
        document.querySelector('#create-exp-title').textContent = 'New Experiment (Step 2 of 2)';
        document.querySelector('.create-exp-first').style.display = 'none';
        document.querySelector('.create-exp-second').style.display = 'block';
    };

    function closeAllModal () {
        setCreateExp({"name": '',"description": '',"schedule":{"startTime":'',"startDate":'',"duration": '',"days" : 0,"hours": 0,"min" : 0,"runOnNodeAvailability": false,"runIndefinitely": false},"configurations":[]})
        //code to disable the selected nodes
        resetExpNodes();
        setFirstModalOpen(false);
        setModalPageNo(1);
        setNameCheck(false);
        setDescCheck(false);
        setExpTimeDateCheck(false);
        setExpDurationCheck(false);
    };

    function gotoFirstModal () {
        
        setModalPageNo(1)
        document.querySelector('#create-exp-title').textContent = 'New Experiment (step 1 of 2)';
        document.querySelector('.create-exp-first').style.display = 'block';
        document.querySelector('.create-exp-second').style.display = 'none';
        
    };

    function gotoSecondModal() {
        setExpNodeView(false)
        setModalPageNo(2)
        setLgModal('')
        document.querySelector('#create-exp-title').textContent = 'Add configuration (Step 2 of 3)';
        document.querySelector('.create-exp-second').style.display = 'block';
        document.querySelector('.create-exp-third').style.display = 'none';

    };

    //Storing Experiment Name 
    function expName(e){
        const name = e.target.value;
        setCreateExp((prevState) => {
            prevState.name = name;
            return {
              ...prevState
            };
          });
    }

    //Storing Experiment Description
    function expDescription(e){
        const description = e.target.value;
        setCreateExp((prevState) => {
            prevState.description = description;
            return {
              ...prevState
            };
          });
    }

    //Storing Experiment Start Time 
    function experimentStartTime(e){
        // console.log(e.date.format("hh:mm"))
        const time = e.date.format('HH:mm')
        setCreateExp((prevState) => {
            prevState.schedule.startTime = time;
            return {
              ...prevState
            };
          });
    }

    //Storing Experiment Start Date
    function experimentStartDate(e){
        const date = e.date.format('YYYY-MM-DD');
        setCreateExp((prevState) => {
            prevState.schedule.startDate = date;
            return {
              ...prevState
            };
          });
    }

    //Storing Experiment Days
    function experimentDays(e){
        // setExpDays(e.target.value)
        const name = e.target.name
        const value = e.target.value
        // console.log(e.target.value)
        setCreateExp((prevState) => {
            prevState.schedule[name] = value;
            return {
              ...prevState
            };
          });
    }


    //Storing Selected Nodes and passing event to map
    function selectNodes(e){
        // console.log(expNodeList.data.nodes);
        let {checked} = e.target
		let nodeid = e.target.getAttribute('index'); 
        let hostname = e.target.getAttribute('hostname'); 
		// console.log(e.target)
		let items = expNodeList.data.nodes.map((row,index) => {
            if (row["hostName"] == hostname) {
                // console.log(row);
				row['selected'] = checked;
                // row['disabled'] = !row.disabled;   
			}
			return row
		});
		
		setExpNodeList((prevState) => {
			prevState.data.nodes = items;
			return {
			  ...prevState
			};
		});
    }

    //Storing Selected Nodes and passing event to map
    function selectAllNodes(check){
        // console.log(expNodeList.data.nodes);
        let storedconfigs = createExp.configurations;
        let allhostnames = [];
        for(let i =0;i<storedconfigs.length;i++){
            let nodes = storedconfigs[i].nodes;
            for(let j =0;j<nodes.length;j++){
                allhostnames.push(nodes[j].hostName)
            }    
        }
        // console.log(allhostnames);
        if(check == true){
        
            let items = expNodeList.data.nodes.map((row,index) => {
                if(row){
                    if (allhostnames.includes(row.hostName) == true) { 
                        // return row 
                    }else{
                        row['selected'] = check;
                    }
                }
                return row
            });
            
            setExpNodeList((prevState) => {
                prevState.data.nodes = items;
                return {
                    ...prevState
                };
            });
            
        }

        if(check == false){
                    
            let items = expNodeList.data.nodes.map((row,index) => {
                if(row){
                    if (allhostnames.includes(row.hostName) == true) { 
                        // return row 
                    }else{
                        row['selected'] = check;
                    }
                }
                return row
            });
            
            setExpNodeList((prevState) => {
                prevState.data.nodes = items;
                return {
                    ...prevState
                };
            });
        }
    }

    //code to validate the checkboxes
    function validateAvailableNodes(input){
        // console.log(input)
        if(input == true){
            fetchAvailableNodes();
        }

        if(input == false){
            // console.log(nodeObjBackup);
            setExpNodeList((prevState) => ({
                ...prevState,
                data : {
                    ...prevState.nodes,
                    nodes : nodeObjBackup.data.nodes
                }
            }));
        }
    }

    //code to get available nodes based on start time and end time
    function fetchAvailableNodes(){
        let expCalcDays = createExp.schedule.days * 24 * 60;
        let expCalcHours = createExp.schedule.hours * 60;
        let expCalcMin = createExp.schedule.min ;
        let expTotalMin = () =>{
            return parseInt(`${expCalcDays}`) + parseInt(`${expCalcHours}`) + parseInt(`${expCalcMin}`)
        }
        
        let epochTime = () => {
            return timeToEpoch(createExp.schedule.startDate, createExp.schedule.startTime);
        }

        let startTime = epochTime();
        let endTime = startTime + (expTotalMin() * 60);

        // console.log(startTime , endTime);

        if(axiosInstance){
            axiosInstance
				.get(`${process.env.REACT_APP_EXPERIMENT_URL}/unavailable-nodes/${startTime}/${endTime}`)
				.then((response) => {
					// console.log(response)
                    let data = response.data;
                    let array=[];
                    if(data.length > 0){
                        let tempnodes = [...nodeOriginal.data.nodes];
                        for(let i = 0;i<tempnodes.length;i++){
                            if(data.includes(tempnodes[i].hostName) == true){
    
                            }else{
                                array.push(tempnodes[i]);
                            }
                        }
                        setExpNodeList((prevState) => ({
                            ...prevState,
                            data : {
                                ...prevState.nodes,
                                nodes : array
                            }
                          }));
                    }else{
                        setExpNodeList((prevState) => ({
                            ...prevState,
                            data : {
                                ...prevState.nodes,
                                nodes : nodeObjBackup.data.nodes
                            }
                        }));
                    }
				})
				.catch((err) => {   
                    console.log(err);
                   
				});
        }

    }

    //Getting unique Node List 
    function filterArray(myArray){
        // console.log(myArray)
        let data = myArray
        let array = [];

        data.map((item,index) => {
            if(item.selected == true){
                array.push({"hostName" : item.hostName})
            }
        })

        if(array.length == 0){
            return 'Empty'
        }else{
            return array
        }
    }
    
    // converting timestamp to epoch format
    function timeToEpoch(a, b){
        let currDate = new Date(`${a} ${b}`)
        let epoch = currDate.getTime()/1000.0
        return epoch;
    }

    //Code to add configuration
    function addConfig(data){
        let inputData = data.nodes;
        let oldconfigs = [...createExp.configurations];
        oldconfigs.push(data);
        setCreateExp((prevState) => {
            prevState.configurations = oldconfigs;
            return {
              ...prevState
            };
        });

        //code to disable the selected nodes
        let items = expNodeList.data.nodes.map((currentNode,firstIndex) => {
            inputData.map((presentNode,secondIndex)=>{
                if(presentNode.hostName == currentNode.hostName){
                    currentNode['disabled'] = true;
                    currentNode['selected'] = true;   
                }
            })
			return currentNode
		});
		
		setExpNodeList((prevState) => {
			prevState.data.nodes = items;
			return {
			  ...prevState
			};
		});
    }

    //code to edit configuration
    function editConfiguration(index){
        // console.log(index);
        
        let configs = [...createExp.configurations];
        let currentConfig = configs[index];
        
        //code to disable the selected nodes
        let items = expNodeList.data.nodes.map((currentNode,firstIndex) => {
            currentConfig.nodes.map((presentNode,secondIndex)=>{
                if(presentNode.hostName == currentNode.hostName){
                    currentNode['disabled'] = false;   
                }
            })
			return currentNode
		});
		
		setExpNodeList((prevState) => {
			prevState.data.nodes = items;
			return {
			  ...prevState
			};
		});
        
        //code to set single configuration an send as child in prop
        setSingleConfiguration({});
        setTimeout(() => {
            setSingleConfiguration(currentConfig);
        }, 300);
    }

    //code to update configuration
    function updateConfiguration(obj,num){
        
        //code to disable the selected nodes
        let items = expNodeList.data.nodes.map((currentNode,firstIndex) => {
            // console.log(currentNode);
            obj.nodes.map((presentNode,secondIndex)=>{
                // console.log(presentNode);
                if(presentNode.hostName == currentNode.hostName){
                    currentNode['disabled'] = true;   
                }
            })
            return currentNode
        });
		
        setExpNodeList((prevState) => {
			prevState.data.nodes = items;
			return {
			  ...prevState
			};
		});

        setCreateExp((prevState) => {
            prevState.configurations[num] = obj;
            return {
              ...prevState
            };
        });
    }

    //code to delete configuration
    function deleteConfiguration(index){
        // console.log(index);
        let configs = [...createExp.configurations];
        let currentConfig = configs[index];

        //code to enable the selected nodes
        let items = expNodeList.data.nodes.map((currentNode,firstIndex) => {
            currentConfig.nodes.map((presentNode,secondIndex)=>{
                if(presentNode.hostName == currentNode.hostName){
                    currentNode['disabled'] = false;
                    currentNode['selected'] = false;   
                }
            })
            return currentNode
        });
        
        setExpNodeList((prevState) => {
            prevState.data.nodes = items;
            return {
                ...prevState
            };
        });

        //Removing configuration in array by slicing method
        configs.splice(index,1);
        setCreateExp((prevState) => {
            prevState.configurations = configs;
            return {
                ...prevState
            };
        });   
    }

    //code to reset nodes list if the child multiconfig modal is closed
    function resetNodes(inputData){
        //code to uncheck the selected nodes
        let items = expNodeList.data.nodes.map((currentNode,firstIndex) => {
            inputData.map((presentNode,secondIndex)=>{
                if(presentNode.hostName == currentNode.hostName){
                    currentNode['selected'] = false;   
                }
            })
            return currentNode
        });
        
        setExpNodeList((prevState) => {
            prevState.data.nodes = items;
            return {
                ...prevState
            };
        });
    }

    //code to reset exp nodes
    function resetExpNodes(){
        let items = expNodeList.data.nodes.map((currentNode,firstIndex) => {
            if(currentNode){
                currentNode['disabled'] = false;
                currentNode['selected'] = false;
            }
			return currentNode
		});
		
		setExpNodeList((prevState) => {
			prevState.data.nodes = items;
			return {
			  ...prevState
			};
		});
    }

    //code to change run as soon as nodes available
    function runAsSoonAsNodes(e){
        // console.log(e);
        // console.log(e.target.checked);
        if(e.target.checked == true){
            setCreateExp((prevState) => {
                prevState.schedule.runOnNodeAvailability = true;
                return {
                    ...prevState
                };
            });
            setExpTimeDateCheck(false);
        }else{
            setCreateExp((prevState) => {
                prevState.schedule.runOnNodeAvailability = false;
                return {
                    ...prevState
                };
            });
            setExpTimeDateCheck(false);
        }
    }

    //Submit the experiment data
    function submitExperiment(e){

        if(createExp.configurations.length == 0){
            ErrorPopUp("Minimum one config is required");
            return
        }

        let expCalcDays = createExp.schedule.days * 24 * 60;
        let expCalcHours = createExp.schedule.hours * 60;
        let expCalcMin = createExp.schedule.min ;
        let expTotalMin = () =>{
            return parseInt(`${expCalcDays}`) + parseInt(`${expCalcHours}`) + parseInt(`${expCalcMin}`)
        }
        
        let epocTime = () => {
            if(createExp.schedule.runOnNodeAvailability == true){
                return '';
            }else{
                return timeToEpoch(createExp.schedule.startDate, createExp.schedule.startTime);
            }
        }
        let filteredNodes = filterArray(expNodeList.data.nodes)
        let checkRadioEmpty = function(){
            if(createExp.configurations[0].binaryFiles[0].path == ''){
                return []
            }else{
                return createExp.configurations[0].binaryFiles
            }
        }

        let expData;
        
        if(createExp.schedule.runOnNodeAvailability == false){
            expData = {
                "name": createExp.name,
                "description": createExp.description,
                "schedule":{
                    "startTime":epocTime(),
                    "duration": expTotalMin(),
                    "runOnNodeAvailability": createExp.schedule.runOnNodeAvailability,
                    "runIndefinitely": false
                },
                "configurations" : createExp.configurations
            }
        }else{
            expData = {
                "name": createExp.name,
                "description": createExp.description,
                "schedule":{
                    "duration": expTotalMin(),
                    "runOnNodeAvailability": createExp.schedule.runOnNodeAvailability,
                    "runIndefinitely": false
                },
                "configurations" : createExp.configurations
            }
        }

        // console.log(expData);

		axiosInstance({
			method: 'post',
			url: `${process.env.REACT_APP_EXPERIMENT_URL}/projects/${props.project_id}/experiments`,
            data: expData,
		})
		.then((response) => {
			// console.log(response);
            if (response.response) {
                PopUp(response.response.data.detail)
            }else {
                PopUp("Experiment Created Successfully")
            }
        })
        .then(() => {
            setFirstModalOpen(false);
            setModalPageNo(1)
            setCreateExp({"name": '',"description": '',"schedule":{"startTime":'',"startDate":'',"duration": '',"days" : 0,"hours": 0,"min" : 0,"runOnNodeAvailability": false,"runIndefinitely": false},"configurations":[]})
            
            resetExpNodes();

            sendUpdateState()
        })
		.catch((err) => {
			// console.log(err);
            if (err.response.status == 500) {
                ErrorPopUp("Request Failed")
            }
            if (err.response) {
                ErrorPopUp(err.response.data.detail)
            }else {
                ErrorPopUp("Upload Failed. Try Again")
            }
		})
   
    }
    
    // Code to refresh apis when child state changed
    const sendUpdateState = () => {
        setTimeout(() => {
            props.changeCurState()    
        }, 2000);
    }

    let expNameStatus = expNameCheck ? 'expNameEmpty' : 'expNameFilled';
    let expDescStatus = expDescCheck ? 'expDescEmpty' : 'expDescFilled';
    let expTimeDateStatus = expTimeDateCheck ? 'startTimeDateEmpty' : 'startTimeDateFilled';
    let expDurationStatus = expDurationCheck ? 'expDurationEmpty' : 'expDurationFilled';
    
    return (
       <>
            <Button className="btnDefault" onClick={() =>openFirstModal()}>
                    New Experiment
            </Button>

                <Modal show={firstModalOpen} onHide={() => closeAllModal()} animation={false} centered backdrop="static" keyboard={false} size={lgModal} >
                    <Modal.Header closeButton>
                        <Modal.Title id="create-exp-title">New Experiment (Step {modalPageNo} of 2)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="Container">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-lg-12 create-exp-first">
                                    <label ><b>Details</b></label>
                                    <div style={{ color: 'red'}}>
                                        {error}
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <Form id="formPage" onSubmit={(e)=> e.preventDefault(e)}>
                                            <Form.Group>
                                                <Form.Label>Experiment Name</Form.Label>
                                                <Form.Control className={expNameStatus} type="text" name="name" placeholder="Enter a name for your Experiment" onChange={(e) => 
                                                    {
                                                        const value = e.target.value;
                                                        const textFieldRegex = /^[A-Za-z0-9\-]+$/;
                                                        if (!textFieldRegex.test(value)) {
                                                            setError(e.target.name + ' is invalid');
                                                            setIsValid(false);
                                                        }else{
                                                            setError('');
                                                            setIsValid(true);
                                                        }
                                                        expName(e)
                                                    }
                                                    }/>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control className={expDescStatus} name="description" as="textarea" rows="5" placeholder="Enter a description for your Experiment" onChange={(e) => 
                                                    {
                                                        const value = e.target.value;
                                                        const textFieldRegex = /^[A-Za-z0-9 \-]+$/;
                                                        if (!textFieldRegex.test(value)) {
                                                            setError(e.target.name + ' is invalid');
                                                            setIsValid(false);
                                                        }else{
                                                            setError('');
                                                            setIsValid(true);
                                                        }
                                                        expDescription(e)
                                                    }
                                                    }/>
                                            </Form.Group>
                                        </Form>
                                       
                                        <div>
                                            <div className={expTimeDateStatus}>
                                                <div className="date-time-picker">
                                                    <div>
                                                        <label >Next Available Start Time</label>
                                                        <TimePicker className="picker" iconClassName="far fa-clock" onChange={(e) => experimentStartTime(e)} />
                                                    </div>
                                                    <div className="date-picker">
                                                        <label >Date</label>
                                                        <DatePicker className="picker" onChange={(e) => experimentStartDate(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="custom-control custom-checkbox create-exp-checkBox" style={{paddingLeft : 0}}>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="runOnNodeAvailability"
                                                    label="Run as soon as nodes available"
                                                    // checked = {storeDefaultImage.serialLog}
                                                    className="runAsSoonNodes"
                                                    // disabled={props.binaries.length > 0 ? false : true}
                                                    onChange={(e) => runAsSoonAsNodes(e)}
                                                />
                                            </div>
                                        </div>
                                        
                                        <label ><b>Duration</b></label>
                                        <div className={expDurationStatus}>
                                            <div className="create-exp-no-inp">
                                                <Form style={{ display: "flex" }}>
                                                    <div>
                                                        <label >Days</label>
                                                        <input type="number" name="days" className="form-control" min="0" onChange={(e) => experimentDays(e)}></input>
                                                    </div>
                                                    <div>
                                                        <label >Hours</label>
                                                        <input type="number" name="hours" className="form-control" min="0"  onChange={(e) => experimentDays(e)}></input>
                                                    </div>
                                                    <div>
                                                        <label >Min</label>
                                                        <input type="number" name="min" className="form-control" min="0"  onChange={(e) => experimentDays(e)}></input>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="create-exp-footer">
                                        <Button className="btnNormal create-exp-btn btnSpace" onClick={() => closeAllModal()}>
                                            Cancel
                                        </Button>
                                        <Button disabled={!isValid} type="submit" className="btnDefault create-exp-btn" onClick={() => openSecondModal()}>
                                            Next
                                        </Button>
                                    </div>
                                    <p className="text-center">{modalPageNo} of 2</p>
                                </div>
                                    

                                <div className="col-md-12 col-sm-12 col-lg-12 create-exp-second" style={{ display: "none" }}>
                                    <div style={{ width: "100%" }}>
                                        <MultipleConfigurations 
                                            tempNodes={expNodeList} 
                                            allNodes={nodeOriginal}
                                            configurations = {createExp.configurations}
                                            selectedNodes={(e) => selectNodes(e)}
                                            selectallnodes={(e)=>selectAllNodes(e)}
                                            getnodesbytime={(e)=>validateAvailableNodes(e)}
                                            images={props.images}
                                            binaries={props.binaries}
                                            addConfig={(e) => addConfig(e)}
                                            editConfiguration={(e)=>editConfiguration(e)}
                                            updateConfiguration={(obj,num)=>updateConfiguration(obj,num)}
                                            deleteConfiguration={(e)=>deleteConfiguration(e)}
                                            singleconfiguration={singleConfiguration}
                                            resetNodes={(data)=>resetNodes(data)}
                                            firstmodal={(e) => gotoFirstModal(e)}
                                            submitExperiment={(e)=>submitExperiment(e)}
                                        />  
                                     </div>
                                    <p className="text-center">{modalPageNo} of 2</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
       </>
    )
}

export default CreateExperiment;