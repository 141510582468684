import React, { useState, useEffect,useContext } from 'react';
import Layout from '../../components/Layout/Layout';
import './Experiment.css';
import Configuration from '../../components/Configuration/Configuration';
import NewConfiguration from '../../components/NewConfiguration/NewConfiguration';
import Chart from '../../components/Chart/Chart'
import { useHistory } from 'react-router-dom';
import { useAxios } from '../../utils/Axios';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Context from '../../store/Context'
import { ToastContainer, Zoom, toast } from 'react-toastify';

const Experiment = (props) => {
	const [config , setConfig] = useState([]);
	const [getImages, setImages] = useState([]);
	const [getBinaries, setBinaries] = useState([]);
	const [exp, setExp] = useState([]);
	const [projectRole , setProjectRole] = useState('')

	const {state , actions} = useContext(Context);

	let projectName = localStorage.getItem('projectName');
	let projectId = localStorage.getItem('projectId')
	let expName = localStorage.getItem('expName');
	let description = localStorage.getItem('description');
	let expId = localStorage.getItem('expid');
	
	// alert(projectName)

	let history = useHistory();
	
	// console.log(history.location.pathname);

	const axiosInstance = useAxios();

	useEffect(() => {
		fetchConfiguration();
	}, [axiosInstance]);

	useEffect(() => {
		let role = localStorage.getItem('projectrole');
		setProjectRole(role);
	},[props])

	//code to get Configuration
	const fetchConfiguration = () => {

		// console.log(history.location)
		if (axiosInstance){
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/${expId}/configurations`)
				.then((response) => {
					setConfig(response.data)

				})
				.then(
					setTimeout(() => {
						fetchImages()
					}, 2000)
				)
				.catch((err) => {
					// console.log(err);
				});
			
		}
	}

	//code to fetch Images and storing in State
	const fetchImages = () => {
		
		if (axiosInstance){
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/projects/${projectId}/images`)
				.then((response) => {
					setImages(response.data);
				})
				.then(
					setTimeout(() => {
						fetchBinaries()
					}, 2000)
				)
				.catch((err) => {
					// console.log(err);
					fetchBinaries();
					// let response = [{

				});
			}

				
	}

	const fetchBinaries = () => {
		// alert()
		if (axiosInstance)
			axiosInstance
				.get(`${process.env.REACT_APP_PROJECT_URL}/projects/${projectId}/binaries`)
				.then((response) => {
					setBinaries(response.data);
					fetchChartData()
				})
				.catch((err) => {
					// console.log(err);
					fetchChartData()
				});
	}

	const fetchChartData = () => {
		if (axiosInstance){
			axiosInstance
				.get(`${process.env.REACT_APP_EXPERIMENT_URL}/projects/experiments/${expId}`)
				.then((response) => {
					// console.log(response.data)
					setExp(response.data)
				})
				.catch((err) => {
					// console.log(err);
				});
		}
		actions({type : 'setState', payload : {...state , blocking : false}})		
	}

		// const sampleList = sampleProjectsList;
		const refreshConfigList = (flag) => {
			// console.log(flag);
			if (flag) {
	
				const timer = setTimeout(() => {
					fetchConfiguration()
				}, 1000);
	
			};
		};

	return (
		<>
		<Layout>
			<div
				className=" col-md-12 col-sm-12 col-lg-12 text-left"
				style={{ margin: '2rem 2.5rem' }}
			>

				<h5 className="create-proj-text-color">
					<b>{expName}</b>
				</h5>
				<p className="create-proj-text-color" style={{ width: '50%' }}>
					{description}
				</p>
				<h5 className="create-proj-text-color">Configurations</h5>
				<Jumbotron
					className="text-center bg-white "
					style={{ marginRight: '5rem' }}
				>
					<Configuration configList={config} projectrole={projectRole} expdata = {exp} status={refreshConfigList}/>
					{/* <NewConfiguration images={getImages} binaries={getBinaries}/> */}
				</Jumbotron>
				{Object.keys(exp).length > 0 ? (
					<>
						{exp.status != 'Failed' ? (
							<>
							<h5 className="create-proj-text-color">Data</h5>
							<Jumbotron
								className="text-center bg-white "
								style={{ marginRight: '5rem' }}
							>	
								<Chart data = {exp}/>
							</Jumbotron>
							</>
						) : null}
						{exp.status == 'Failed' || 
							(exp.status == 'Ended' && exp.schedulingInfo.statusDetail !== null) ? (
							<>
								<h5 className="create-proj-text-color">Failure reason:</h5>
								<p>{exp.schedulingInfo.statusDetail}</p> 

							</>
						): null}
					</>
				) : null}
			</div>
		</Layout>
		<ToastContainer draggable={false} transition={Zoom}/>
		</>
	);
};

export default Experiment;
