import React,{useState,useEffect,useContext} from 'react';
import ApplicationsTable from '../../../components/LoraNetworks/ApplicationsTable/ApplicationsTable';
import CreateApplications from '../../../components/LoraNetworks/CreateApplications/CreateApplications';
import LoraLayout from '../../../components/LoraNetworks/LoraLayout/LoraLayout';
import { ToastContainer, Zoom } from 'react-toastify';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context';

function Applications() {
    const [applications, setApplications] = useState([]);
    const [optionsEnabled, setOptionsEnabled] = useState({
        createLoraApp: false,
        editdeleteApp : false
    })

    const axiosInstance = useAxios();
    const {state , actions} = useContext(Context);
    
    useEffect(() => {
		checkCurrentUser()
    }, [axiosInstance]);

    useEffect(() => {
		fetchAppList();
    }, [axiosInstance]);
    
    //code to check current user role
    function checkCurrentUser() {
        let roles = JSON.parse(localStorage.getItem("roles"));
        // let roles = ["ln_deviceadmin"]

        // console.log(roles.includes('ln_admin'));
        if (roles.includes('ln_admin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraApp: true,
                    editdeleteApp : true
                })
            )
        }
        if (roles.includes('ln_orgadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraApp: true,
                    editdeleteApp : true
                })
            )
        }
        if (roles.includes('ln_user')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraApp: true,
                    editdeleteApp : false
                })
            )
        }
        if (roles.includes('ln_deviceadmin')) {
            setOptionsEnabled(
                prevState => ({
                    ...prevState,
                    createLoraApp: false,
                    editdeleteApp : false
                })
            )
            console.log(optionsEnabled);
        }

    }

    //Code to fetch Organization Api Keysand store in state
    function fetchAppList(){
        let orgid = localStorage.getItem('orgid');
        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_LORA_URL}/applications?limit=100&organizationId=${orgid}`)
				.then((response) => {
					// console.log(response);
					setApplications(response.data.result);
					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
                    // console.log(err);
                    // setApplications([{"id":"12","name":"app1","description":"app","organizationID":"21","serviceProfileID":"6842939b-10d2-4cdf-a08d-1e6959756e1e","serviceProfileName":"sample3"},{"id":"13","name":"sampleapp3","description":"3","organizationID":"21","serviceProfileID":"6842939b-10d2-4cdf-a08d-1e6959756e1e","serviceProfileName":"sample3"}])
					actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
    }

    // const sampleList = sampleProjectsList;
	const handleSubmit = (flag) => {
		// console.log(flag);
		if (flag) {

			const timer = setTimeout(() => {
				fetchAppList();
			}, 2000);

		};
	};

    return (
        <>
            <LoraLayout>
                <div className="col-md-12 col-sm-12 col-lg-12 no-padding">
                    <div className="row" style={{ margin: '0 3rem' }}>
                        <div
                            style={{ marginTop: '1rem', color: '#43425D' }}
                            className="col-md-6 col-sm-6 col-lg-6"
                        >
                            <h5>Applications</h5>
                        </div>
                    </div>
                    <div className="default-main-page">
                        {applications.length > 0 ? (
                            <>
                                <div className="project-table-group">
                                    <ApplicationsTable data={applications} status={handleSubmit} editdelete={optionsEnabled.editdeleteApp}/>
                                </div>
                                <br />
                                {optionsEnabled.createLoraApp == true ? (
                                    <>
                                        <div className="col-md-12 text-right no-padding" >
                                            <CreateApplications status={handleSubmit} />
                                        </div>
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <div className="default-content-center" style={{ margin: '3%', textAlign: 'center' }}>
                                You don't have any Applications right now
                                <br />
                                <br />
                                {optionsEnabled.createLoraApp == true ? (
                                    <>
                                        <div className="col-md-12 text-right no-padding" >
                                            <CreateApplications status={handleSubmit} />
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
            </LoraLayout>
            <ToastContainer draggable={false} transition={Zoom}/>
        </>
    )
}

export default Applications;