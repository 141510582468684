import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import { useAxios } from '../../utils/Axios';
import Context from '../../store/Context';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import logo from '../../assests/icons/umbrella-logo3.png';
import { Image } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PopUp from '../../components/UIElements/PopUp';
import ErrorPopUp from '../UIElements/ErrorPopup';
import Container from '@mui/material/Container';

import './NewHeader.css';
import { Tooltip } from '@mui/material';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function NewHeader() {
    const theme = useTheme();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const goToHome = () => history.push('/home');
    const goToNetworks = () => history.push('/networks');

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [currentUsername, setCurrentUsername] = useState('');
    const [currentUserid, setCurrentUserid] = useState('');
    const { keycloak } = useKeycloak();
    const axiosInstance = useAxios();
    const { state, actions } = useContext(Context);

    useEffect(() => {
        // console.log(currentUsername);
        // console.log(currentUserid);
        setTimeout(() => {
            getUserid();
            getUsername()
        }, 2000);
    }, [])

    useEffect(() => {
        // console.log(currentUsername);
        // console.log(currentUserid);
        if (currentUserid == null || currentUserid == '') {
            getUserid();
        }
    }, [currentUserid])

    useEffect(() => {
        // console.log(currentUsername);
        // console.log(currentUserid);
        if (currentUsername == null || currentUsername == '') {
            getUsername();
        }
    }, [currentUsername])

    //code to get data from localstorage
    function getUsername() {
        const username = localStorage.getItem('name');
        setCurrentUsername(username);
    }

    //code to get data from localstorage
    function getUserid() {
        const userid = localStorage.getItem('userid');
        setCurrentUserid(userid);
    }

    const userProfile = {
        alignItems: 'center'
    };

    //Code to self deregister user
    function deregister(e) {
        swal({
            title: 'Are you sure?',
            text: 'You will be deregistered Permanently',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
            className: "dangerSwal"
        })
            .then((willDelete) => {
                if (willDelete) {
                    if (axiosInstance) {
                        axiosInstance
                            .post(`${process.env.REACT_APP_PROJECT_URL}/users/deregistration/${currentUserid}`)
                            .then((response) => {
                                if (response.response) {
                                    PopUp(response.response.data.detail)
                                } else {
                                    PopUp("Success")
                                }

                                const timer = setTimeout(() => {
                                    clearLocalstorage()
                                }, 500);
                                return () => clearTimeout(timer)

                            })
                            .catch((err) => {
                                if (err.response) {
                                    ErrorPopUp(err.response.data.detail)
                                } else {
                                    ErrorPopUp("RequestFailed")
                                }
                            });
                    }
                }
            });
    }

    function profile(e) {
        history.push('/profile');
    }

    //code to clear localstorage when user logged out
    function clearLocalstorage() {
        history.push('/home');
        localStorage.clear();
        keycloak.logout();
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" color='inherit'>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        <a href="#/home"><Image src={logo} className="custom-icon" /></a>
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 1 }}>
                        <IconButton
                            size="small"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            {currentUsername} <AccountCircle style={{ color: "#1c7b8e" }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >

                            <MenuItem onClick={(e) => profile(e)}>Profile</MenuItem>
                            <MenuItem onClick={(e) => deregister(e)}>Deregister</MenuItem>
                            <MenuItem onClick={() => clearLocalstorage()}>Logout</MenuItem>
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 6 }}>
                        <Tooltip title="Report Issue" placement="bottom-start">
                            <a href="https://report.umbrellaiot.com" target="_blank">
                                <ReportProblemIcon style={{ color: "#1c7b8e" }} />
                            </a>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            </Box>
        </Box>
    );

}