import React, { useState,useEffect,useContext } from 'react';
import './CreateApplications.css';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import { useAxios } from '../../../utils/Axios';
import Context from '../../../store/Context'
import PopUp from '../../UIElements/PopUp';
import ErrorPopUp from '../../UIElements/ErrorPopup';

function CreateApplications({ status }, props) {
	const axiosInstance = useAxios();
	const {state , actions} = useContext(Context);
	const [serviceProfiles, setServiceProfiles] = useState([]);
	const [open, setOpen] = useState(false);
	const [app, newApp] = useState({});
	const [error, setError] = useState('');
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		fetchServiceProfiles();
	}, [axiosInstance]);
	
    //code to fetch service profiles and store it and pass as prop
    function fetchServiceProfiles(){  
        let orgid = localStorage.getItem('orgid');
        if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
				.get(`${process.env.REACT_APP_LORA_URL}/service-profile?limit=100&organizationID=${orgid}`)
				.then((response) => {
					// console.log(response);
					setServiceProfiles(response.data.result);

					actions({type : 'setState', payload : {...state , blocking : false}})
				})
				.catch((err) => {
					// console.log(err);
					setServiceProfiles([{"id":"6842939b-10d2-4cdf-a08d-1e6959756e1e","name":"sample3","organizationID":"21","networkServerID":"4","createdAt":"2021-01-20T10:17:19.170363Z","updatedAt":"2021-01-20T10:17:19.170363Z","networkServerName":"network-server-test"}])
					actions({type : 'setState', payload : {...state , blocking : false}})
				});

		}
    }
	//Code to store app input
	const changeHandler = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		const textFieldRegex = /^[A-Za-z0-9\-]+$/;
		if (!textFieldRegex.test(value)) {
			setError(name + ' is invalid');
			setIsValid(false);
		}else{
			setError('');
			setIsValid(true);
		}
		newApp({ ...app, [name]: value });
	};
	
	const storeServiceProfile = (e) => {
		let index = e.target.selectedIndex;
       	let optionElement = e.target.childNodes[index];
		let name = e.target.name
		let val = optionElement.value;
		newApp({ ...app, [name]: val });
	}

	//Code to send request to create app
	const handleSubmit = (event) => {
		event.preventDefault();
		let orgid = localStorage.getItem('orgid');
		let newAppData = {"application":{...app,"organizationID":orgid}}
		if (axiosInstance) {
			actions({type : 'setState', payload : {...state , blocking : true}})
			axiosInstance
			.post(`${process.env.REACT_APP_LORA_URL}/applications`, newAppData)
			.then((response) => {
				setOpen(false);
				PopUp("Application Created Successfully")
				status(true);
				actions({type : 'setState', payload : {...state , blocking : false}})
			})
			.catch((err) => {
				// console.log(err);
				ErrorPopUp("Failed. Try Again")
				actions({type : 'setState', payload : {...state , blocking : false}})
			});
		}	
	};

	return (
		<div>
			<Button className="btnDefault" style={{margin : '2%'}} onClick={() => setOpen(true)}>
				+ Create Application
			</Button>

			<Modal
				show={open}
				onHide={() => setOpen(false)}
				animation={false}
				centered
				keyboard={false}
			>
				<Form onSubmit={handleSubmit}>

					<Modal.Header closeButton>
						<Modal.Title>New Application</Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<div style={{ color: 'red'}}>
						{error}
					</div>
						<Form.Group>
							<Form.Label>Name</Form.Label>
							<Form.Control
								name="name"
								onChange={changeHandler}
								type="text"
								placeholder="Application name"
								autoComplete="off"
								required
							/>
						</Form.Group>	
						<Form.Group>
							<Form.Label>Description</Form.Label>
							<Form.Control
								name="description"
								onChange={changeHandler}
								type="text"
								placeholder="Description"
								autoComplete="off"
								required
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Service-profile</Form.Label>
							<Form.Control
								as="select"
								name="serviceProfileID"
								onChange={storeServiceProfile}
								required
								custom
							>
								{
									serviceProfiles.length > 0 ? (
										<>
											<option value="">Select service Profile</option>
											{serviceProfiles.map((item, index) => {
												return (
													<option value={item.id} key={index}>{item.name}</option>
												);
											})}
										</>
									) : (
										<>
											<option value="">No Service Profile Found</option>
										</>
									)
								}
							</Form.Control>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button className = "btnNormal"
							variant="btnColor btn-primary-outline"
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button disabled={!isValid} className="btnDefault" type="submit">
							Create Application
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			
		</div>
	);
}

export default CreateApplications;
